import axios from "axios";
import { API } from "../../utils/api";
import { Routes } from "../../constants/routes";

export const createAxiosInstance = (info) => {
  console.log("API", API)
  const { url, method, headers, data, params } = info;
  const axiosInstance = axios.create({
    baseURL: API.baseURL[API.currenEnv],
    headers,
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === "403") {
        if (error.response.data && error.response.data?.message === "E10017") {
          sessionStorage.clear();
          window.location.href = Routes.HOME;
        }
      }
      return error;
    }
  );

  return axiosInstance({ url, method, headers, data, params });
};
