import { createAsyncThunk } from "@reduxjs/toolkit";
import { signIn } from "../../services/api";
import { Routes } from "../../constants/routes";
import { defaultValues } from "../../constants";

const roleMapping = {
  '/super-admin-login' : {role_name:defaultValues.role_names.super_admin},
  '/admin-login' :  {role_name:defaultValues.role_names.admin},
  [Routes.HOME] : {role_name:''}
}



export const postData = createAsyncThunk('loggedInUserSlice/postData', async (args, {rejectWithValue}) =>{
  try {
    const {path , email , password} = args;
    const {data} = await signIn(path, {email, password});
     let role;
     if(path === Routes.HOME){
      role=[data.data?.userByEmail?.primary_role]
     }else
       role = roleMapping[path] ?  [roleMapping[path]] : [data.data?.userByEmail?.primary_role];
     return {data , role}
  } catch(e) {
    return e.response.data.message
   
  }
})