import React, { useEffect, useState } from "react";
import Button from "../../layout/Button";
import Form from "react-bootstrap/Form";

import Modal from "react-bootstrap/Modal";
import { FormControl } from "react-bootstrap";
import { useSelector } from "react-redux";

import { getStages } from "../../services/api";



export default function Page({
  isOpen = false,
  onClose = () => { },
  onSubmit = () => { },
  currentType,
  currentChecklist}) {
  const [isDiscard, setIsDiscard] = useState(false);
  const [modalOpen, setModalOpen] = useState(isOpen);
  const [stages, setStages] = useState([]);
  const loggedInUser = useSelector((state) => state.loggedInUser);
  const [checklist, setChecklist] = useState({
  });

  useEffect(() => {
    getStages().then((res) => {
      if (res?.data?.stages) {
        setStages(res?.data?.stages);
      }
    });
  }, []);

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  const onDoSubmit = (e) => {
    e.preventDefault();
    checklist.created_by = `${loggedInUser.data.data.superAdminByEmail._id}`;
    checklist.PropertyType = currentChecklist?.checklist_type;
    onSubmit(checklist);
    onClose();
  };

  const onDoClose = (e) => {
    e.preventDefault();
    setIsDiscard(true);
  };

  const handleCloseModal = () => {
    setIsDiscard(false);
    setModalOpen(false);
    onClose();
    setModalOpen(false);
  };

  return (
    <Modal
      show={modalOpen}
      onHide={handleCloseModal}
      size="xl"
      aria-labelledby="example-modal-sizes-title-lg"
      className="editProperty"
    >
      <div className="blueBg form-area">
        {isDiscard ? (
          <div className="flex">
            <img className="mt-50" src="/imgs/discard.png" alt="discard" />
            <div className="mt-50"></div>
            <Button
              type="button"
              variant="secondary"
              className="btn-lg pull-left"
              text="Discard"
              onClick={handleCloseModal}
            />
            <Button
              type="button"
              variant="secondary"
              className="btn-lg pull-right"
              text="No"
              onClick={() => setIsDiscard(false)}
            />
          </div>
        ) : (
          <Form className="form-middle container-fluid">
            <h1 className="mb-50">Add Checklist</h1>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Document Name</Form.Label>
              <FormControl
                type="text"
                placeholder="Enter Checklist Name"
                fullWidth
                onChange={(e) => {
                  setChecklist({ ...checklist, title: e.target.value });
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Document Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(e) => {
                  setChecklist({ ...checklist, description: e.target.value });
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Property Type</Form.Label>
              <FormControl
                type="text"
                placeholder="Property Type"
                fullWidth
                disabled
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "var(--white)",
                    cursor: "not-allowed"
                  },
                }}
                value={currentChecklist?.checklist_type}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="stages">
              {stages?.map((item) => {
                if (item.order_number < 7) {
                return (
                  <Form.Check
                    className="ml-4"
                    label={item.stage_name}
                    name={'stage'}
                    type={"radio"}
                    onChange={() => {
                      setChecklist({ ...checklist, stages: item._id });
                    }}
                  />                  
                );
                  }
                return null; 
              })}
            </Form.Group>
            <br />
            <div className="mt-50"></div>
            <Button
              type="button"
              variant="secondary"
              className="btn-lg pull-left"
              text="Add"
              onClick={onDoSubmit}
            />
            <Button
              type="button"
              variant="secondary"
              className="btn-lg pull-right"
              text="Cancel"
              onClick={(e) => onDoClose(e)}
            />
          </Form>
        )}
      </div>
    </Modal>
  );
}
