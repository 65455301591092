import { createAsyncThunk } from "@reduxjs/toolkit";
import { addChecklistData, deleteChecklist, getChecklistData, updateChecklistData } from "../../services/api";

export const AddChecklistData = createAsyncThunk('checklistSlice/AddChecklistData', async (args, {rejectWithValue}) =>{
    try {
       const {data}  = await addChecklistData(args);
       return data
    } catch(e) {
        rejectWithValue(e.response.data)
    }
})


export const GetChecklistData = createAsyncThunk('checklistSlice/GetChecklistData', async (args, {rejectWithValue}) =>{
    try {
       const {data}  = await getChecklistData();
       return data
    } catch(e) {
        rejectWithValue(e.response.data)
    }
})


export const UpdateChecklistData = createAsyncThunk('checklistSlice/updateChecklistData', async (args, {rejectWithValue}) =>{
    try {
       const {data}  = await updateChecklistData(args);
       return data
    } catch(e) {
        rejectWithValue(e.response.data)
    }
})

export const DeleteChecklistData = createAsyncThunk( "checklistSlice/deleteChecklistData", async (checklistId, { rejectWithValue }) => {
      try {
        const { data } = await deleteChecklist(checklistId);
        return data;
      } catch (e) {
        rejectWithValue(e.response.data);
      }
    }
  );
