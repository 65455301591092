export const historyFormatter = (user, user_id, property_id, activity) => {
    let historyArray = [];
    historyArray.push(
        `${user?.primary_role?.role_name
        ? 
        user.first_name
        : ""
        } ${activity} `
    );
    const historyData = {
        activity: historyArray.join(", "),
        name : !user?.primary_role?.role_name ? user.first_name : '',
        activity_by : user_id,
        activity_date : new Date(),
        property_id : property_id,
    }

    return historyData;
} 