import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useToasts } from "react-toast-notifications";
import Axios from "axios";

import "../login/style.scss";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  InputLabel,
  FormControl,
  CssBaseline,
  Button,
  TextField,
  Grid,
  Typography,
  Container,
  Box,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";

import LandingFooter from "../general-landing-page/LandingFooter";
import GeneralNav from "../general-landing-page/GeneralNav";
import { API } from "../../utils/api";
import { ERROR_KEY, SUCCESS_KEY, defaultValues, getErrorMessage } from "../../constants";
import { Routes } from "../../constants/routes";

const ResetPassword = () => {
  const { addToast } = useToasts();
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const user = queryParams.get("user");
  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfimPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfimPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const confirmPasswordSchema = yup.object({
    otp: yup.string().required(getErrorMessage('E-10025')),
    password: yup
      .string()
      .required(getErrorMessage('E-10063'))
      .test(
        "password-strength",
        getErrorMessage('E-10007'),
        (value) => {
          if (value) {
            const passwordRegex =
              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
            return passwordRegex.test(value);
          }
          return true;
        }
      ),
    email: yup
      .string()
      .email(getErrorMessage('E-10008'))
      .required(getErrorMessage('E-10029')),
    confirmPassword: yup
      .string()
      .required(getErrorMessage('E-10221'))
      .oneOf([yup.ref("password"), null], getErrorMessage('E-10016')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(confirmPasswordSchema),
    mode: "onBlur",
  });

  const onSubmit = (_data, e) => {
    e.target.reset();
    e.preventDefault();
  };

  const handleClick = async () => {
    let url = "";
    if (user === defaultValues.role_names.super_admin) {
      url = `${API.baseURL[API.currenEnv]}superAdmin/reset`;
    } else if (user === defaultValues.role_names.admin) {
      url = `${API.baseURL[API.currenEnv]}admin/reset`;
    } else if (user === defaultValues.role_names.user) {
      url = `${API.baseURL[API.currenEnv]}user/reset`;
    }

    try {
      const response = await Axios({
        method: "post",
        url,
        data: { email, newPassword: password, resetPasswordToken: otp },
      });
      if (response.status === 200) {
        if (user === defaultValues.role_names.super_admin) {
          history.push(Routes.SUPER_ADMIN_LOGIN);
        } else if (user === defaultValues.role_names.admin) {
          history.push(Routes.ADMIN_LOGIN);
        } else {
          history.push(Routes.HOME);
        }
        addToast(response.data.message, {
          appearance: SUCCESS_KEY,
          autoDismiss: true,
        });
      }
    } catch (error) {
      addToast(error.response.data.message, {
        appearance: ERROR_KEY,
        autoDismiss: true,
      });
    }
  };

  return (
    <>
      <GeneralNav />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 11,
            marginBottom: 14,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h2" variant="h5" sx={{ mt: 4 }}>
            Reset Password
          </Typography>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="OTP"
                  label="OTP"
                  variant="outlined"
                  type="text"
                  name="otp"
                  required
                  fullWidth
                  {...register("otp")}
                  className="input-admin"
                  error={Boolean(errors.otp)}
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                  value={otp}
                />
                <div className="login-errors">
                  {errors.otp ? <>{errors.otp.message}</> : null}
                </div>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-password">
                    New Password
                  </InputLabel>
                  <OutlinedInput
                    type={showPassword ? "text" : "password"}
                    id="password"
                    label="Password"
                    variant="outlined"
                    name="password"
                    {...register("password")}
                    error={Boolean(errors.password)}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    value={password}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <div className="login-errors">
                    {errors.password ? <>{errors.password.message}</> : null}
                  </div>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    label="Confirm Password"
                    variant="outlined"
                    name="confirmPassword"
                    {...register("confirmPassword")}
                    error={Boolean(errors.confirmPassword)}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    value={confirmPassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <div className="login-errors">
                    {errors.confirmPassword ? (
                      <>{errors.confirmPassword.message}</>
                    ) : null}
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, bgcolor: "var(--primaryColor)" }}
              onClick={handleClick}
            >
              Reset
            </Button>
          </Box>
        </Box>
      </Container>
      <LandingFooter />
    </>
  );
};

export default ResetPassword;
