import React from "react";
import "./loader.css";

export default function DocumentLoader() {
  return (
    <div className="document-loader-container">
      <div className="document-loading-spinner"></div>
    </div>
  );
}
