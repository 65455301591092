import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllRentalAgreementAPI,
  getMyRentalAgreementAPI,
  getNewAgreement,
  r_updatePaymentDetailsAPI,
  submitRentalAgreementAPI,
  updateRentalPartyAPI,
} from "../../services/api";

export const getNewRentalAgreement = createAsyncThunk(
  "rentalAgreement/getNewRentalAgreement",
  async (payload, { rejectWithValue }) => {
    try {
      const data = await getNewAgreement(payload);
      return data;
    } catch (e) {
      rejectWithValue(e.response?.data || e);
    }
  }
);

export const submitNewRentalAgreement = createAsyncThunk(
  "rentalAgreement/submitNewRentalAgreement",
  async (payload, { rejectWithValue }) => {
    try {
      const data = await submitRentalAgreementAPI(payload);
      return data;
    } catch (e) {
      rejectWithValue(e.response?.data || e);
    }
  }
);

export const updateRentalParty = createAsyncThunk(
  "rentalAgreement/updateRentalParty",
  async (payload, { rejectWithValue }) => {
    try {
      return await updateRentalPartyAPI(payload);
    } catch (e) {
      rejectWithValue(e.response?.data);
    }
  }
);

export const getMyRentalAgreement = createAsyncThunk(
  "rentalAgreement/getMyRentalAgreement",
  async (payload, { rejectWithValue }) => {
    try {
      const data = await getMyRentalAgreementAPI(payload);
      return data;
    } catch (e) {
      rejectWithValue(e.response?.data || e);
    }
  }
);

export const getAllRentalAgreement = createAsyncThunk(
  "rentalAgreement/getAllRental",
  async (payload, { rejectWithValue }) => {
    try {
      const data = await getAllRentalAgreementAPI(payload);
      return data;
    } catch (e) {
      rejectWithValue(e.response?.data || e);
    }
  }
);

export const r_updatePaymentDetails = createAsyncThunk(
  "rentalAgreement/r_updatePaymentDetails",
  async (payload, { rejectWithValue }) => {
    try {
      const data = await r_updatePaymentDetailsAPI(payload);
      return data;
    } catch (e) {
      rejectWithValue(e.response?.data || e);
    }
  }
);
