import React, { useState, useEffect } from "react";
import Main from "../../layout/Main";
import Form from "react-bootstrap/Form";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { manageProfile } from "../../utils/validationSchemas";
import CircularProgress from '@mui/material/CircularProgress';
import "./style.scss";
import { TextField, Button,Typography,Container } from "@mui/material";
import { useToasts } from "react-toast-notifications";
import ChangePassword from "./changePassword";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateLoggedInUserData } from "../login/slices";
import DummyProfileImage from "../../assets/Icons/person-dummy.jpg";
import { getProfileImg } from "../../redux/extra-services/profile.services";
import { updateAdmin, updateSuperAdmin, uploadProfilePic } from "../../services/api";
import { ERROR_KEY, ROLE_KEY, SUCCESS_KEY, defaultValues, getErrorMessage, getSuccessMessage } from "../../constants";
import { Routes } from "../../constants/routes";

const ManageProfile = (props) => {
  const [isFieldChanged, setIsFieldChanged] = useState(false);
  const dispatch=useDispatch();
  const { addToast } = useToasts();
  const [openModal, setOpenModal] = useState(false);
  const [imageLoading , setImageLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const loggedInUser = useSelector((state) => state.loggedInUser);
  const role = useSelector((state) => state.loggedInUser.preferredRole);

  const history = useHistory();
  const [dataValue, setDataValue] = useState({
    [role === defaultValues.role_names.admin ? "_id" : "id"]:
      role === defaultValues.role_names.super_admin
        ? loggedInUser.data?.data?.superAdminByEmail?._id || ""
        : loggedInUser.data?.data?.adminByEmail?._id || "",
  });
  const accessToken = useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";
  const superAdminId = useSelector((state) => state.loggedInUser.data?.data?.superAdminByEmail?._id) || ''
  const adminId = useSelector((state) => state.loggedInUser.data?.data?.adminByEmail?._id) || ''
  const userId =  role === defaultValues.role_names.super_admin ? superAdminId : adminId ;
  const profileData = useSelector((state) => state.profileImage.image);


    useEffect(() => {
        if (!accessToken) {
            history.push(Routes.ADMIN_LOGIN);
        } 
        if (role !== defaultValues.role_names.super_admin && role !== defaultValues.role_names.admin) {
          props.history.push(Routes.HOME);
        }
          else {
            setProfileImage(profileData);
        }
    }, [accessToken, history, profileData]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(manageProfile),
    mode: "onBlur",
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
  };



  const handleSave = async () => {
    if (!isValid) { return; }   
    try {
        let response = {};
        if (role === defaultValues.role_names.super_admin) {
            response = await updateSuperAdmin(dataValue);
        } else {
            response = await updateAdmin(dataValue);
    
        }
      if(response.status === 200 ){
        let data = {}
        if (role === defaultValues.role_names.super_admin) {
          data = {...response.data.superAdminData}
        } else {
          data = {...response.data.adminData}

        }
        dispatch(updateLoggedInUserData({data, role}))
      }
      addToast(getSuccessMessage('S-10022'), {
        appearance: SUCCESS_KEY,
        autoDismiss: true,
      });
    } catch (error) {
      addToast(error?.response?.data?.message , {
        appearance: ERROR_KEY,
        autoDismiss: true,
      });
    }
  };

  const updateProfileImage = async (file)=>{
    try {
      const formData = new FormData();
        formData.append("userId" , userId);
        formData.append("images" , file)

        const response = await uploadProfilePic(formData);
        if(response.status === 201 && response.data.success){
          dispatch(getProfileImg(userId));
          setImageLoading(false);
          addToast(response.data.message, { appearance: SUCCESS_KEY, autoDismiss: true });
        }
    } catch (error) {
      addToast(getErrorMessage('"E-10112'), { appearance: ERROR_KEY, autoDismiss: true });
    }

  }


  const handleFileChange = async (event) => {
    setImageLoading(true)
    const selectedFile = event.target.files[0];
    if(selectedFile) await updateProfileImage(selectedFile);
  };


  return (
    <div>
            <Main
                showNav={!props?.showNav}
                showFooter={!props?.showFooter}
                showLogo={!props?.showLogo}
                showAdminFooter={!props?.showAdminFooter}
                showAdminNavbar={!props?.showAdminNavbar}
            >
                <Typography variant="h4" component="p" className="heading"> Manage Profile </Typography>
                <Container className="manage-profile-container">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            id="first_name"
                            label="Enter First Name"
                            variant="outlined"
                            type="text"
                            className="form-inputs"
                            size="small"
                            name="first_name"
                            error={Boolean(errors.first_name)}
                            helperText={errors.first_name?.message}
                            {...register("first_name")}
                            defaultValue={
                                dataValue.first_name ||
                                (role === defaultValues.role_names.super_admin
                                    ? loggedInUser.data?.data?.superAdminByEmail?.first_name
                                    : loggedInUser.data?.data?.adminByEmail?.first_name)
                            }
                            onChange={(e) => {
                                setDataValue({ ...dataValue, first_name: e.target.value });
                                setIsFieldChanged(true);
                            }}
                        />
                        <TextField
                            id="last_name"
                            label="Enter Last Name"
                            variant="outlined"
                            type="text"
                            className="form-inputs"
                            size="small"
                            name="last_name"
                            error={Boolean(errors.last_name)}
                            helperText={errors.last_name?.message}
                            {...register("last_name")}
                            defaultValue={
                                dataValue.last_name ||
                                (role === defaultValues.role_names.super_admin
                                    ? loggedInUser.data?.data?.superAdminByEmail?.last_name
                                    : loggedInUser.data?.data?.adminByEmail?.last_name)
                            }
                            onChange={(e) => {
                                setDataValue({ ...dataValue, last_name: e.target.value });
                                setIsFieldChanged(true);
                            }}
                        />
                        <TextField
                            id="email_id"
                            label="Email"
                            disabled
                            variant="outlined"
                            type="text"
                            size="small"
                            name="email_id "
                            {...register("email_id")}
                            className="form-inputs"
                            defaultValue={
                                dataValue.email_id ||
                                (role === defaultValues.role_names.super_admin
                                    ? loggedInUser.data?.data?.superAdminByEmail?.email_id
                                    : loggedInUser.data?.data?.adminByEmail?.email_id)
                            }
                            onChange={(e) =>
                                setDataValue({ ...dataValue, email_id: e.target.value })
                            }
                        />
                        <TextField
                            id="phone"
                            label="Phone No."
                            variant="outlined"
                            type="number"
                            className="form-inputs"
                            size="small"
                            name="phone"
                            error={Boolean(errors.phone)}
                            helperText={errors.phone?.message}
                            {...register("phone")}
                            defaultValue={
                                dataValue.phone ||
                                (role === defaultValues.role_names.super_admin
                                    ? loggedInUser.data?.data?.superAdminByEmail?.phone
                                    : loggedInUser.data?.data?.adminByEmail?.phone)
                            }
                            onChange={(e) => {
                                setDataValue({ ...dataValue, phone: e.target.value });
                                setIsFieldChanged(true);
                            }}
                        />
                        <Typography variant="body1" component="p" className="change-link">
                            Change your password below
                        </Typography>
                        <Button
                            variant="contained"
                            type="submit"
                            className="form-btn mt-3"
                            onClick={() => setOpenModal(true)}
                        >
                            Change password
                        </Button>
                        {openModal && (
                            <ChangePassword
                                openModal={openModal}
                                setOpenModal={setOpenModal}
                            />
                        )}
                        <div className="profile-upload">
                            <div className="image-container">
                                {imageLoading ? (
                                    <div className="loader">
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <img
                                        className="image"
                                        src={profileImage ? profileImage : DummyProfileImage}
                                        alt="ProfilePicture"
                                    />
                                )}
                            </div>
                            <div className="input-selection">
                                <input
                                    style={{ display: "none" }}
                                    id="profile-image"
                                    onChange={handleFileChange}
                                    type="file"
                                />
                                <label className="label" htmlFor="profile-image">
                                    <CameraAltIcon />
                                </label>
                            </div>
                        </div>

                        <Button
                            type="submit"
                            variant="contained"
                            onClick={handleSave}
                            className={`save-button ${
                                isFieldChanged ? "saveDisplay" : "saveHidden"
                            }`}
                        >
                            Save
                        </Button>
                    </Form>
                </Container>
            </Main>
        </div>
    );
};
export default ManageProfile;

