import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Table, Button } from 'react-bootstrap';
import backBtn from "../../../assets/Icons/arrow-left.svg";
import { getAllFilesInsideChecklist, getCheckListFolder, updateCheckListDocument, updateCheckListType } from "../../../pages/super-admin-manageProperties/service";
import { useLocation } from "react-router-dom";
import Loader from "../../Loader/Loader";
import { useToasts } from "react-toast-notifications";
import {  addPropertyHistory, getChecklistFiles,  updatePropertyDocument } from "../../../services/api";
import { ERROR_KEY, PID_KEY, SUCCESS_KEY, defaultValues, getErrorMessage } from "../../../constants";
import { historyFormatter } from "../../../utils/historyFormatter";

const PropertyDocument = ({ isPropertyModal, setIsPropertyModal, openModal, modalInitial, title, setTitle }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState(title?.title)
  const [documentSubmit, setDocumentSubmit] = useState(false);
  const userRole = useSelector((state) => state.loggedInUser.preferredRole);
  const [isLoading, setIsLoading] = useState(false);
  const getId = useLocation().search;
  const searchParams = new URLSearchParams(getId);
  const propertyId = searchParams.get(PID_KEY)
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { addToast } = useToasts();
  const [selectedFile, setSelectedFile] = useState("");
  const role = defaultValues.role_names.seller || defaultValues.role_names.seller_agent;
  const user = useSelector((state) => state.loggedInUser.data.data.userByEmail);
  const userId = useSelector(
    (state) => state.loggedInUser?.data?.data?.userByEmail?._id
  );

  const subStages = useSelector((state) => state?.stages?.subStages);
  const getSubStage = (order) => subStages.filter((substage)=> substage.order === order)[0]._id


  const checkListTypeData = useSelector(
    (state) => state.superAdminProperties?.checkListData
  );

  const checkListFolderData = useSelector(
    (state) => state.superAdminProperties
  );

  const addData = () => {
    setDocumentSubmit(true);
    const fileNames = [];
    const formData = new FormData();
    selectedFiles.forEach((fileDoc) => {
      formData.append('propertydoc', fileDoc);
      fileNames.push(fileDoc.name);
    });
    formData.append('property_id', propertyId);
    formData.append('checklist_name', name);
    setIsLoading(true)
    dispatch(updateCheckListDocument(formData)).then(() => setIsLoading(false)).catch(() => {
      setIsLoading(false)
    });
    setSelectedFiles([]);
    const msgText = "Uploaded"
    const historyData = historyFormatter(
      user,
      userId,
      viewPropertiesData._id.toString(),
      `has ${msgText} the ${fileNames} documents.`
    );
    addPropertyHistory(historyData);
  }

  useEffect(() => {
    if (checkListFolderData.status && documentSubmit) {
      if (checkListFolderData.status === "Success") {
        addToast(checkListFolderData.message, {
          appearance: SUCCESS_KEY, autoDismiss: true
        });
        setDocumentSubmit(false);
      } else {
        addToast(checkListFolderData.message, { appearance: ERROR_KEY, autoDismiss: true });
      }
    }
  }, [checkListFolderData]);

  const getData = async(propertyData) => {
    const getAllFilesdata = {
      property_id: propertyId,
      checklist_name: propertyData.title
    }
    setIsLoading(true)

    dispatch(getAllFilesInsideChecklist(getAllFilesdata)).then(() => {
      setIsLoading(false)
      setShow(!show);
    }
    )
    .catch(() => {
      setIsLoading(false);
      setShow(!show);
    });
  }

  const getPropertyDocuments = async (data) => {
    setIsLoading(true)
    try {
      const response = await getChecklistFiles({
        property_id: propertyId,
        checklist_name: title.title,
        document_name: getFileName(data?.Key),
      });
      setIsLoading(false)
      const document = {
        uri: response.data.url,
        fileType: response.data.fileType,
      };
      setSelectedFile(document);
    } catch (error) {
      setIsLoading(false)
      addToast(getErrorMessage("E-10250"), {
        appearance: ERROR_KEY,
        autoDismiss: true,
      });
    }
  }

  const getFileName = (Key) => {
    const fileArr = Key.split('/');
    const fileName = fileArr[fileArr.length - 1];
    return fileName;
  }
  const getFolderName = (Key) => {
    const fileArr = Key.split('/');
    const fileName = fileArr[fileArr.length - 2];
    return fileName;
  }
  const loggedInUser = useSelector((state) => state.loggedInUser);
  const viewPropertiesData = useSelector(
    (state) => state.superAdminProperties.propertyData
  );
  useEffect(() => {
    if (viewPropertiesData?.PropertyType) {
      dispatch(updateCheckListType({ type: viewPropertiesData?.PropertyType, accessToken: loggedInUser.data.data?.accessToken }))
      dispatch(getCheckListFolder(viewPropertiesData._id));
    }
  }, [dispatch, viewPropertiesData])

  const handleDeleteDocument = async (data) => {
    const folderName = data?.Key;
    const fileName = getFileName(folderName);
    const FolderName = getFolderName(folderName);
    
    
    setIsLoading(true);
    
    const payload = {
      propertyId: propertyId,
      folderName: FolderName,
      fileName: fileName,
    };
  
    updatePropertyDocument(payload)
      .then((res) => {
        addToast("Deleted successfully", {appearance: SUCCESS_KEY, autoDismiss: true})
        setIsLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        setIsLoading(false);
        addToast(err.response?.data?.message || "Error deleting document", {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
      });
  };
  const [show, setShow] = useState();
  useEffect(() => {
    setShow(openModal);
    if (!openModal && title?._id) {
      setName(title?.title)
      getData(title);
    }
  }, [openModal, title])

  useEffect(() => {
    if (checkListFolderData.checkListStatus == "Success" && !title?._id) {
      setShow(!show)
    }
  }, [checkListFolderData]);

  const sub = getSubStage(10) === viewPropertiesData.sub_current_stage

  return (
		<>
			<Modal
				size="lg"
				show={isPropertyModal}
				onHide={() => {
					setTitle({});
					modalInitial(false);
					setIsPropertyModal(false);
          setSelectedFile('')
				}}
				aria-labelledby="example-modal-sizes-title-lg"
				className="propertyData"
			>
				<Modal.Header closeButton className="property-modal">
					{!show ? (
						<Button
							variant="transparent"
							onClick={() => {
								setShow(!show);
								setName("");
								setSelectedFile("");
							}}
						>
							<img src={backBtn} />
						</Button>
					) : (
						""
					)}
					<span className="black-text fw-bold">{name}</span>
				</Modal.Header>
				{isLoading ? (
					<Loader />
				) : (
					<Modal.Body className="">
						{!selectedFile ? (
							<Table>
								<thead>
									<tr>
										<th className="ps-3">Document Name</th>
										<th className="ps-2">Last Modified</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr>
                  {userRole === role &&(
										<td className="ps-3">
											<input
												type="file"
												multiple
												id="fileField"
												onChange={(e) => setSelectedFiles([...e.target.files])}
											/>
										</td>
                  )}
                   {userRole === role &&(
										<td>
											<Button
												className="button-class"
												onClick={addData}
												disabled={selectedFiles.length === 0}
											>
												Upload
											</Button>
										</td>
                    )}
									</tr>
									{checkListFolderData?.checkListDoc?.map(
										(data, index) =>
											index !== 0 && (
                        <tr key={index}>
													<td className="ps-3">{getFileName(data.Key)}</td>
													<td className="ps-2">
														{new Date(data.LastModified).toLocaleDateString(
															"en-GB"
														)}
													</td>
													<td
														className="ps-5"
														onClick={() => getPropertyDocuments(data)}
														style={{
															textDecoration: "underline",
															cursor: "pointer",
														}}
													>
														View
													</td>
                              <td>
                                {userRole === role && sub && (
                                  <button
                                    className="btn btn-link text-danger"
                                    onClick={() => handleDeleteDocument(data)}
                                  >
                                    Delete
                                  </button>
                                )}
                              </td>
												</tr>
											)
									)}
								</tbody>
							</Table>
						) : (
							<></>
						)}
						{selectedFile && (
							<>
								<Button
									onClick={() => {
										setSelectedFile("");
									}}
								>
									close
								</Button>
								{renderfile(selectedFile)}
							</>
						)}
					</Modal.Body>
				)}
			</Modal>
		</>
	);
};


const renderfile=(selectedFile)=>{
  const displayHeight=window.innerHeight*0.8;
  const displayWidth="90%";
  const dimensions={height:displayHeight,width:displayWidth};
  if (selectedFile.fileType === ".pdf") {
		return <object  style={dimensions} data={selectedFile.uri}></object>;
	} else if (
		selectedFile.fileType === ".png" ||
		selectedFile.fileType === ".jpg" ||
		selectedFile.fileType === ".jpeg"
	) {
		return (
			<div style={{ alignContent: "center", margin: "10% 10% 10% 10%" }}>
				<img
					width={displayWidth}
					src={selectedFile.uri}
          alt="file"
				></img>
			</div>
		);
	} else {
		return (
			<iframe
        style={dimensions}
				src={`https://docs.google.com/gview?url=${encodeURIComponent(
					`${selectedFile.uri}`
				)}&embedded=true`}
			></iframe>
		);
	}
}

export default PropertyDocument;
