import React from "react";
import ApplyLoanTds from "../../commonComponent/applyLoan";

const ApplyTDS = () => {
  return (
    <ApplyLoanTds
      pageTitle="Upload TDS Details"
      questionText="Have you filled the TDS?"
      roleSide="CA"
      path="pay-tds"
      toastMessage="CA already exists for this property"
      loanAppliedProp="tds_status"
      yesMessage="Successfully updated tds application status"
      noMessage="Error updating tds application status"
    />
  );
};

export default ApplyTDS;
