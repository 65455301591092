import { createSlice } from "@reduxjs/toolkit";
import { postData } from "./service";
import { updateSuperAdminData } from "../manage-users/service";
import { ERROR_KEY, defaultValues, getErrorMessage } from "../../constants";

const initialState = {
  value: 0,
  data: [],
  roles: [],
  preferredRole: "",
  isRoleSelectionModalOpen: false,
  selectedRole: {},
  error: {},
};

export const loggedInUserSlice = createSlice({
  name: "loggedInUserSlice",
  initialState,
  reducers: {
    updatePreferredRole: (state, action) => ({
      ...state,
      preferredRole: action.payload,
      isRoleSelectionModalOpen: false,
    }),
    toggleRoleSelectionModal: (state, action) => {
      state.isRoleSelectionModalOpen = !state.isRoleSelectionModalOpen;
    },
    updateLoggedInUserData: (state, action) => {
      const role = action.payload.role;
      if (role === defaultValues.role_names.super_admin) {
        state.data.data.superAdminByEmail = action.payload.data;
      } else if (role === defaultValues.role_names.admin) {
        state.data.data.adminByEmail = action.payload.data;
      } else {
        state.data.data.userByEmail = action.payload.data;
      }
    },
  },
  extraReducers: {
    [postData.pending]: (state, action) => ({
      ...initialState,
      loading: true,
    }),

    [postData.fulfilled]: (state, action) => {
      if (action?.payload?.data) {
        return {
          ...initialState,
          loading: false,
          data: action.payload?.data,
          roles: action.payload?.role,
          preferredRole:
            Array.isArray(action.payload?.role) &&
            action.payload.role?.length === 1
              ? action.payload.role?.[0]?.role_name
              : "",
          selectedRole:
            Array.isArray(action.payload?.role) &&
            action.payload.role?.length === 1
              ? action.payload.role?.[0]
              : {},
          isRoleSelectionModalOpen:
            Array.isArray(action.payload?.role) &&
            action.payload.role?.length > 1,
          loginerrormessage: null
        };
      } else {
        return {
          ...initialState,
          loginerrormessage:
            action.payload || getErrorMessage('E-10070'),
          logstatus: ERROR_KEY,
          loading: false,
        };
      }
    },

    [postData.rejected]: (state, action) => ({
      ...initialState,
      loginerrormessage: action.payload || getErrorMessage('E-10070'),
      logstatus: ERROR_KEY,
      loading: false,
    }),

    [updateSuperAdminData.fulfilled]: (state, action) => {
      state.data = action.payload.data;
    },
  },
});

export const {
  updatePreferredRole,
  toggleRoleSelectionModal,
  updateLoggedInUserData,
} = loggedInUserSlice.actions;

export default loggedInUserSlice.reducer;
