import React, { useState, useEffect } from "react";
import "./style.scss";
import { Form } from "react-bootstrap";
import {getPropertiesData } from "../super-admin-manageProperties/service";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "../manage-users/service";
import Modal from "react-bootstrap/Modal";
import { useToasts } from "react-toast-notifications";
import {
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  Typography
} from "@mui/material";
import {getUserId, updateSubStage, validatePhone} from "../../utils/HelperFunction";
import {addSelectedProperty, updateSelectedProperty} from "../user-home/slices"
import Loader from "../../layout/Loader/Loader";
import { addAdminSchema  } from "../../utils/validationSchemas";
import { useFormik } from "formik";
import { addPropertyHistory, addUser, getPropertyDetails } from "../../services/api";
import { ADMIN_KEY, SUPER_ADMIN_KEY, USER_KEY, defaultValues, SUCCESS_KEY, ERROR_KEY } from "../../constants";
import {getSuccessMessage} from '../../constants/getMessages';
import { COUNTRY_CODE } from "../../constants/countryCode";

const initialValues = {
  first_name: "",
  last_name: "",
  email_id: "",
  phone: "",
  primary_role : "",
  select_role : "",
  percent_share : ""
};

const AddUsers = ({
  setIsDisplay,
  isDisplay,
  isAssignDisplay = false,
  userType,
  name,
  roleName,
  viewProperty,
  data,
  origin,
}) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [selectOption, setSelectOption] = useState("");
  const [roleList, setRoleList] = useState([]);
  const getMessage = useSelector((state) => state.superAdminProperties);
  const propertyId = useSelector((state)=> state?.buyerHomeData?.selectedProperty?._id) || '';
  const propertyData = useSelector((state)=> state?.buyerHomeData?.selectedProperty);
  const currentSubStage = useSelector((state)=> state?.buyerHomeData?.selectedProperty?.sub_current_stage) || '';
  const [error, setError] = useState(false);
  const [click, setClick] = useState(isAssignDisplay);
  const [dataValue, setDataValue] = useState(null);
  const allRoles = useSelector((state) => state.roles.roles);
  const roleNames = useSelector((state)=> state.loggedInUser?.selectedRole?.role_name);
  const loggedUser = useSelector((state) => state.loggedInUser);
  const subStages = useSelector((state)=> state?.stages?.subStages) || [];
  const [getShareUsers, setGetShareUsers] = useState([]);
  const [balShareValue, setBalShareValue] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState("+91")
  let loggedInUser = USER_KEY;
  if(roleNames === defaultValues.role_names.super_admin){
    loggedInUser = SUPER_ADMIN_KEY
  }else if(roleNames === defaultValues.role_names.admin){
    loggedInUser =  ADMIN_KEY
  }else{
    loggedInUser =  USER_KEY
  }
  const { resetForm, values, handleBlur, handleChange, handleSubmit, touched, errors, setFieldValue } =  useFormik({
    initialValues : initialValues,
    validationSchema : addAdminSchema(balShareValue),
    validateOnChange: true,
    validateOnBlur : true,    
    onSubmit : (values, action) => {
      onSave(values);
    }
  });

  const loggedInUserRole = useSelector(
    (state) => state.loggedInUser.data.data?.[loggedInUser]
  );

  const SELLER = data?.reduce((count, item) => {
    if (item?.role?.role_name === defaultValues.role_names.seller) {
      count++;
    }
    return count;
  }, 0);

  const BUYER = data?.reduce((count, item) => {
    if (item?.role?.role_name === defaultValues.role_names.buyer) {
      count++;
    }
    return count;
  }, 0);

  useEffect(() => {
    let payload = null;
    if(origin === defaultValues.add_user_origins.manage_user) {
      payload = {
        is_admin: true,
      };
    } else {
      payload = {
       propertyId,
       assigned_by: loggedInUserRole?._id,
       is_admin: false,
      };
    }
    setDataValue(payload);

  }, [loggedInUserRole, origin, propertyId])

  const onSave = (userData) => {
    setIsLoading(true);
    let his = [];
    setIsLoading(true)
    let roleType = ""
    if (selectOption.includes(defaultValues.role_names.seller)) {
      roleType = defaultValues.role_names.seller;
    } else {
      roleType = defaultValues.role_names.buyer;
    }

    let data = {
        ...dataValue,
        ...userData,
        phone: userData.phone.toString(),
        country_code: selectedCountry,
      };

    data.role_side = roleType;

    addUser(data).then(async(res) => {
      setIsLoading(false);
      addToast(getSuccessMessage('S-10004'), { appearance: SUCCESS_KEY, autoDismiss: true });
      dispatch(getData());
      if(origin === defaultValues.add_user_origins.manage_user) {
        setIsDisplay(false);
        resetForm();
      } else {
        dispatch(getPropertiesData(propertyId));
        const assignData = {
          property_parties_details: [
            {
              user_id: res?.data?.data?._id,
              role: res?.data?.data?.primary_role,
              role_side: roleType,
              assigned_date: new Date(),
              assigned_by: loggedInUserRole?._id,
            },
          ],
        };
  
        const user = res?.data?.data;
        his.push(
          `${user?.primary_role?.role_name
            ? user.primary_role.role_name +
            "(" +
            user.first_name +
            ")"
            : ""
          }   has been added to ${data.select_role
          }`
        );
  
        const historyData = {
          activity: his.join(", "),
          name : user.first_name,
          activity_by : getUserId(loggedUser),
          activity_date : new Date(),
          property_id : propertyId,
        }
        await addPropertyHistory(historyData);
  
        const countBuyers = (property_parties_details) => {
          if (!Array.isArray(property_parties_details) || property_parties_details.length === 0) {
            return 0; 
          }
          const buyersCount = property_parties_details.reduce((count, party) => {
            if (party.role.role_name === defaultValues.role_names.buyer) {
              return count + 1;
            }
            return count;
          }, 0);
        
          return buyersCount;
        };
        const countsellers = (property_parties_details) => {
          if (!Array.isArray(property_parties_details) || property_parties_details.length === 0) {
            return 0; 
          }
          const sellersCount = property_parties_details.reduce((count, party) => {
            if (party.role.role_name === defaultValues.role_names.seller) {
              return count + 1;
            }
            return count;
          }, 0);
        
          return sellersCount;
        };
          
        const getSubStage = (order) => subStages.filter((substage)=> substage.order === order)[0]._id;      
        const getSubStageOrderNo = (id) => subStages.filter((substage)=> substage._id === id)[0].order;
        const substage = getSubStageOrderNo(currentSubStage);
  
        if (countBuyers(viewProperty.property_parties_details) === viewProperty.no_of_buyers || countsellers(viewProperty.property_parties_details) === viewProperty.no_of_owners) {
          if(substage < 2){
            updateSubStage({propertyId, substageId:getSubStage(2), loggedInUserId:user._id})
            .then((response) => {
              if (response.status === 200) {
                dispatch(updateSelectedProperty(response.data.propertyData));
                setIsDisplay(false);
                resetForm();
              }
            })
            .catch((error) => {
              setIsLoading(false);
              addToast(error.response.data.message, { appearance: ERROR_KEY, autoDismiss: true });
            });
          }
        }
        if(origin === defaultValues.add_user_origins.seller_details) {
         await getPropertyDetails(propertyId).then(response => {
          dispatch(addSelectedProperty(response.data.propertyData));
         });
        }
      }
    }).catch(err => {
      setIsLoading(false);
      addToast(err.response.data.message, { appearance: ERROR_KEY, autoDismiss: true });
    });
  };
  
  useEffect(() => {
    dispatch(getData());
  }, [dispatch]);

  useEffect(()=> {
    if(origin === defaultValues.add_user_origins.manage_user) {
      setRoleList(allRoles); 
    } else {
      let buyerList = [...defaultValues.buyer_side_users];
      let sellerList = [...defaultValues.seller_side_users];    
      
      const currentBuyer = (propertyData.property_parties_details
        .filter(userData => userData.role.role_name === defaultValues.role_names.buyer));
      const currentSeller = propertyData.property_parties_details
        .filter(userData => userData.role.role_name === defaultValues.role_names.seller)
        
      if(currentBuyer.length < propertyData.no_of_buyers){
        buyerList = [ ...buyerList, defaultValues.role_names.buyer ];
        sellerList = [ ...sellerList, defaultValues.role_names.buyer ];
      }
  
      if(currentSeller.length < propertyData.no_of_owners){
        buyerList = [ ...buyerList, defaultValues.role_names.seller ];
        sellerList = [ ...sellerList, defaultValues.role_names.seller ];
      }
  
      let roles= [];
      if(getMessage?.roles?.length) {
        getMessage.roles.forEach(roleData => {
          if(roleName === defaultValues.role_names.buyer && buyerList.includes(roleData.role_name)){
              roles.push(roleData);
          }else if(roleName === defaultValues.role_names.seller && sellerList.includes(roleData.role_name)){
            roles.push(roleData);
          }else if(roleName === defaultValues.role_names.admin || roleName === defaultValues.role_names.super_admin){
            if(buyerList.includes(roleData.role_name) || sellerList.includes(roleData.role_name)) {
              roles.push(roleData);
            }
          }
        });
  
  
        if(userType === defaultValues.role_names.buyer && roles.length){
          roles = roles.filter(role => 
            (role.role_name !== defaultValues.role_names.seller && role.role_name !== defaultValues.role_names.seller_poa && role.role_name !== defaultValues.role_names.seller_agent)
            );
        }else if(userType === defaultValues.role_names.seller  && roles.length){
          roles = roles.filter(role => 
            (role.role_name === defaultValues.role_names.seller || role.role_name === defaultValues.role_names.seller_poa || role.role_name === defaultValues.role_names.seller_agent));
        }
  
        setRoleList(roles);  
        setGetShareUsers([]);
        setSelectOption("");
      } 
    }
  },[roleName, userType, viewProperty, origin, allRoles]);
  

  useEffect(() => {
    let data = [];
    let bal = 100;
    
    if(selectOption === defaultValues.role_names.buyer && viewProperty?.buyers_share?.length){
        viewProperty.buyers_share.forEach(user => {
          bal = bal - user.percentage_share;
          data = [
            ...data,
            `${user.buyer_id.first_name} ${user.buyer_id.last_name} - ${user.percentage_share}%`          
          ];
        });      
      
    }
    else if(selectOption === defaultValues.role_names.seller && viewProperty?.sellers_share?.length){
      viewProperty.sellers_share.forEach(user => {
        bal = bal - user.percentage_share;
        data = [
          ...data,
          `${user.seller_id.first_name} ${user.seller_id.last_name} - ${user.percentage_share}%`          
        ];
      });
    }
    setBalShareValue(bal);
    values.percent_share = bal;
    setGetShareUsers(data);

  },[selectOption])

  return (
    <Modal
      size="md"
      show={isDisplay}
      onHide={() => setIsDisplay(false)}
      aria-labelledby="example-modal-sizes-title-lg"
      className="editProperty"
      centered
    >
      {
      isLoading ?       
        <Loader/> 
        : 
      (
        <div className="add-form">
          <div className="add-user-form">
            <div className="close-btn"></div>
            {isAssignDisplay && (
              <div className="text">
                <Button
                  className={click ? "active" : "deActive"}
                  onClick={() => setClick(!click)}
                >
                  Assign
                </Button>
                <Button
                  className={!click ? "active" : "deActive"}
                  onClick={() => setClick(!click)}
                >
                  Add
                </Button>
              </div>
            )}
            <>
              <h1 className="assignAdd">Assign/Add User</h1>
              <Form>
                <div className="add-admin-column">
                  <div>
                    <TextField
                      fullWidth
                      name="first_name"
                      id="First Name"
                      label="First Name"
                      variant="outlined"
                      autoComplete="off"
                      type="text"
                      value = {values.first_name} onBlur={handleBlur} onChange={handleChange}                      
                      />
                      {touched.first_name && errors.first_name ? (
                        <p className="formError">{errors.first_name}</p>
                      ) : null }
                    </div>
                    <div>
                      <TextField
                        fullWidth
                        name="last_name"
                        id="last name"
                        label="Last name"
                        variant="outlined"
                        autoComplete="off"
                        type="text"
                        value = {values.last_name} onBlur={handleBlur} onChange={handleChange}                        
                        />
                        {touched.last_name && errors.last_name ? (
                          <p className="formError">{errors.last_name}</p>
                        ) : null }
                    </div>
                </div>
                <div>
                  <TextField
                    fullWidth
                    name="email_id"
                    id="Email-ID"
                    label="Email-ID"
                    variant="outlined"
                    autoComplete="off"
                    type="text"
                    value = {values.email_id} onBlur={handleBlur} onChange={handleChange}                    
                    />
                    {touched.email_id && errors.email_id ? (
                      <p className="formError">{errors.email_id}</p>
                    ) : null }
                  </div>
                <div className="add-cc-phone">
                  <div style={{width: "6rem"}}>
                  <FormControl fullWidth>
                  <InputLabel id="countryCodeLabel">Country Code</InputLabel>
                  <Select
                    labelId="countryCodeLabel"
                    id="countryCode"
                    name="countryCode"
                    label="Country Code"
                    value={selectedCountry || "+91"}
                    onChange={(e) => {
                      const selectedCountryCode = e.target.value;
                      setSelectedCountry(selectedCountryCode);
                    }}
                    renderValue={(selected) => {
                      const selectedCountry = COUNTRY_CODE.find(country => country.value === selected);
                      return selectedCountry ? selectedCountry.value : '';
                    }}
                  >
                    {COUNTRY_CODE.map((country, index) => (
                      <MenuItem key={index} value={country.value}>
                        {`${country.label} (${country.value})`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                  </div>
                  <div>
                    <TextField
                    type="number"
                    name="phone"
                    id="Phone no"
                    label="Phone No."
                    variant="outlined"
                    autoComplete="off"
                    error={Boolean(errors.phone)}
                    value = {values.phone} onBlur={handleBlur}
                    onChange={(e) =>{
                      if(validatePhone(e.target.value))
                      {handleChange(e)}
                    }}
                    style={{width: '255%', paddingLeft: "0.5rem"}}
                    />
                    {touched.phone && error.phone ? (
                      <p className="formError">{errors.phone}</p>
                    ): null}
                  </div>
                </div>
                <div className="add-admin-column">
                  <div>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Role
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="SelectRole"
                        name="select_role"
                        onBlur={handleBlur} 
                        onChange={(e) => {
                          setFieldValue("primary_role",e.target.value?._id);
                          if (isAssignDisplay === true) {
                            if ((e.target.value?.role_name === defaultValues.role_names.buyer) && viewProperty.no_of_buyers > BUYER) {
                              setSelectOption(e.target.value?.role_name);
                              setFieldValue("select_role", (e.target.value?.role_name))
                              setError(false)
                            }
                            else if (e.target.value?.role_name === defaultValues.role_names.seller && viewProperty.no_of_owners > SELLER) {
                              setSelectOption(e.target.value?.role_name)
                              setFieldValue("select_role", (e.target.value?.role_name))
                              setError(false)
                            }
                            else if (e.target.value?.role_name !== defaultValues.role_names.buyer && e.target.value?.role_name !== defaultValues.role_names.seller) {
                              setSelectOption(e.target.value?.role_name)
                              setFieldValue("select_role", (e.target.value?.role_name))
                              setError(false)
                            }
                            else {
                              setSelectOption(e.target.value?.role_name)
                              setFieldValue("select_role", (e.target.value?.role_name))
                              setError(true)
                            }
                          } else {
                            setSelectOption(e.target.value?.role_name)
                            setFieldValue("select_role", (e.target.value?.role_name))
                          }
                          setDataValue({
                            ...dataValue,
                            role:e.target.value?._id,
                            role_side:e.target.value?.role_name,
                            primary_role: e.target.value?._id
                          });
                        } 
                        }>
                        {roleList?.length > 0 && 
                          (roleList
                            .map((roleData, index) => (
                              <MenuItem key={roleData + index} value={roleData || ""}>
                                {roleData.role_name}   
                              </MenuItem>
                              ))
                            )
                        }
                      </Select>
                        {touched.select_role && errors.select_role && 
                          <p className="formError">{errors.select_role}</p>
                        }
                      {error && <Typography variant='p' className="text-light">No of user for {selectOption} role has reached to maximum</Typography>}
                    </FormControl>
                  </div>
                  <div>
                    {(selectOption === defaultValues.role_names.seller || selectOption === defaultValues.role_names.buyer) && origin !== defaultValues.add_user_origins.manage_user && (
                      <>
                      <TextField
                        fullWidth
                        required
                        id="Percent Share"
                        label="Percent Share"
                        variant="outlined"
                        name="percent_share"
                        type="number"
                        disabled={balShareValue === 0}
                        value={values.percent_share}
                        onBlur={handleBlur}
                        InputProps = {{
                          max: balShareValue,
                          min: 0,
                        }}
                        onChange={(event) => {
                          const data = event.target.value;
                          if(data <= balShareValue){
                            setFieldValue("percent_share", data);
                          }
                        }}                      
                        />
                        {touched.percent_share && errors.percent_share && 
                          <p className="formError">{errors.percent_share}</p>
                        }
                      </>
                    )}
                  </div>
                </div>
                <div className="add-admin-column">
                  <div>
                    {(selectOption === defaultValues.role_names.seller || selectOption === defaultValues.role_names.buyer) && origin !== defaultValues.add_user_origins.manage_user && (
                      <div className="col">
                        {getShareUsers.map(name =>
                          <p className="formError">{name}</p>
                        )}
                        <p className="formError">Balance : {balShareValue}%</p>
                      </div>
                    )}
                    </div>
                </div>
                <div className="add-btn ml-5">
                  <div className="ml-3"></div>
                  <button type="submit" className={`btn-lg pull-left custom-offset ${error ? "not-allowed" : "add-admin-btn"}`}
                    disabled={isLoading}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }
                    }>
                    Add
                  </button>
                  <div className="ml-3"></div>
                  <button 
                  type="reset" 
                  className="btn-lg pull-left custom-offset add-admin-btn" 
                  onClick={() => {
                    setIsDisplay(false);
                    resetForm();
                  }}>
                    Cancel
                  </button>
                  <div className="ml-3"></div>
                </div>
              </Form>
            </>
          </div>
        </div>
      )}
    </Modal>
  );
};
export default AddUsers;
