import React from "react";
import Main from "../../layout/Main";
import { Typography, Box } from "@mui/material";

const SignPage = (props) => {
  const { pageTitle, showUserNavbar, showUser } = props;

  return (
    <div>
      <Main showUserNavbar={!showUserNavbar} showUser={!showUser}>
        <Box
          display="flex"
          flexDirection="row-reverse"
          justifyContent="start"
          alignItems="start"
          minHeight="70vh"
          bgcolor="var(--white)"
        >
           <div className="container">
            <div className="inner-container">
          <Typography variant="h5" component="h3" sx={{ fontWeight: 100 }}>
            {pageTitle}
          </Typography>
          </div>
          </div>
        </Box>
      </Main>
    </div>
  );
};

export default SignPage;
