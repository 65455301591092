/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import Main from "../../layout/Main";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { postGenerateApi } from "./service";
import { getPropertiesData, stageValidation } from "../super-admin-manageProperties/service";
import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import Loader from "../../layout/Loader/Loader";
import { initiateSigning } from "../../utils/signed";
import { updateManagePropData } from "../super-admin-manageProperties/service";
import { useHistory, useLocation } from "react-router-dom";
import {
  convertNumberToWord,
  updateSubStage,
} from "../../utils/HelperFunction";
import { addSelectedProperty, updateSelectedProperty } from "../user-home/slices";
import {
  addPropertyHistory,
  checkPartyDetails,
  generateDoc,
  getDocByPropId,
  getPropertyDetails,
  getSignedDoc,
} from "../../services/api";
import { API } from "../../utils/api";
import { CustomEditor } from "../../utils/CustomEditor";
import moment from "moment";
import { Typography } from "@mui/material";
import  Tooltip  from "@mui/material/Tooltip";
import { historyFormatter } from "../../utils/historyFormatter";
import { ACCESS_TOKEN_KEY, ERROR_KEY, PROPERTY_ID_KEY, SUCCESS_KEY, defaultValues, getErrorMessage, getSuccessMessage } from "../../constants";
import { Routes } from "../../constants/routes";

const InitiateOffer = (props) => {
  const history = useHistory();
  const apiResponse = useSelector((state) => state.initiateOffer);
  const dispatch = useDispatch();

  const location = useLocation();
  const propertyId =
    useSelector((state) => state?.buyerHomeData?.selectedProperty?._id) || "";
  const selectedProperty = useSelector(
    (state) => state?.buyerHomeData?.selectedProperty
  );
  const pid = location.search.split("=")[1];

  const { addToast } = useToasts();
  const [data, setData] = useState({
    PropertyID: sessionStorage.getItem(PROPERTY_ID_KEY),
    templateSubType: defaultValues.doc_sub_types.offer_letter, // currently harrdcoded will change in future
    templateType: defaultValues.template_types.document, // currrently hardcoded will change in future
    ExtraFields: {
      checkNo: "",
      checkDate: "",
      bankName: "",
      signAmount: "",
    },
    closing_date: new Date().toLocaleDateString("en-GB"),
  });
  const [showEditor, setShowEditor] = useState(false);
  const [signedPdfData, setSignedPdfData] = useState("");
  const dateFormatList = ["DD/MM/YYYY"];
  const [loader, setLoader] = useState(false);
  const [fileData, setFileData] = useState("");
  const [showPdf, setShowPdf] = useState(false);
  const [signedPdfShow, setSignedPdfShow] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isDocumentSigned, setIsDocumentSigned] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [incompleteUserDetails, setIncompleteUserDetails] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const subStages = useSelector((state) => state?.stages?.subStages);
  const accessToken =
    useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";
  const userId = useSelector(
    (state) => state.loggedInUser.data.data?.userByEmail._id
  );
  const user = useSelector(
    (state) => state.loggedInUser.data.data?.userByEmail
  );
  const roleId = useSelector((state)=> state.loggedInUser?.selectedRole?._id);
  const getSubStage = (order) => subStages.filter((substage)=> substage.order === order)[0]._id
  useEffect(() => {
    if (!accessToken) {
      history.push(Routes.HOME);
    } else {
      if (!propertyId) history.push(Routes.PROPERTIES);
      else if (pid !== propertyId) history.push(`${Routes.INITIATE_OFFER}?pid=${propertyId}`);
    }
  }, [accessToken, history, pid, propertyId]);

  useEffect(() => {
    if(selectedProperty) {
    checkPartyDetails(propertyId).then(response =>  {
      if(response.data.data) {
        setIncompleteUserDetails(response.data.data);
      }
      setIsLoading(false);
    }).catch(err => {
      setIsLoading(false);
    });      
  }
  }, [propertyId, selectedProperty]);


  useEffect(() => {
    const {
      document_meta_data: {
        down_payment_amount,
        offered_purchase_price,
        balance_due,
        closing_date,
      } = {},
    } = selectedProperty;
    if (
      offered_purchase_price &&
      selectedProperty.sub_current_stage === getSubStage(4)
    ) {
      const payload = {
        PropertyID: propertyId,
        templateSubType: defaultValues.template_types.offer_letter,
        templateType: defaultValues.template_types.document,
        ExtraFields: {
          checkNo: "",
          checkDate: "",
          bankName: "",
          signAmount: "",
          offerPrice: offered_purchase_price,
          token: "0",
        },
        closing_date: closing_date,
        offered_purchase_price: offered_purchase_price,
        balance_Due: balance_due,
        down_payment_amount: down_payment_amount,
      };
      getGeneratedOffer(payload);
    }
    if (selectedProperty.sub_current_stage === getSubStage(5)) {
      getSigneduserInfo();
    }
  }, [selectedProperty.sub_current_stage]);

  const getSubStageUpdated = async (propertyId, substage)=>{
    try{
    const response = await updateSubStage({propertyId, substageId: substage, loggedInUserId:userId});
        if(response.status === 200){
          dispatch(updateSelectedProperty(response.data.propertyData));
        }
      else addToast(getErrorMessage('E-10070'), { appearance: ERROR_KEY, autoDismiss: true });
      }catch(error){
        addToast(getErrorMessage('E-10111'), { appearance: ERROR_KEY, autoDismiss: true });
      }
  }
  const getOfferData = useCallback(() => {
    const {
      document_meta_data: {
        down_payment_amount,
        offered_purchase_price,
        balance_due,
        closing_date
      } = {},
    } = selectedProperty;
      const payload = {
        PropertyID: propertyId,
        templateSubType: defaultValues.template_types.offer_letter,
        templateType: defaultValues.template_types.document,
        ExtraFields: {
          checkNo: "",
          checkDate: "",
          bankName: "",
          signAmount: "",
          offerPrice: offered_purchase_price,
          token: "0",
        },
        closing_date: closing_date,
        offered_purchase_price: offered_purchase_price,
        balance_Due: balance_due,
        down_payment_amount: down_payment_amount,
      };
      getGeneratedOffer(payload);
  }, [getSubStage, propertyId, selectedProperty]);

  useEffect(() => {
    const {
      document_meta_data: {
        offered_purchase_price,
      } = {},
    } = selectedProperty;
    if (
      offered_purchase_price &&
      selectedProperty.sub_current_stage === getSubStage(4)
    ) {
      getOfferData();
    }
    if (selectedProperty.sub_current_stage === getSubStage(5)) {
      getSigneduserInfo();
    }
  }, [propertyId, selectedProperty]);

  // Use effect to dispaly toast message and show ckeditor
  useEffect(() => {
    if (
      apiResponse?.addPropStatus &&
      selectedProperty.sub_current_stage === getSubStage(3)
    ) {
      if (apiResponse?.addPropStatus === SUCCESS_KEY) {
        addToast(apiResponse.addPropMessage, {
          appearance: SUCCESS_KEY,
          autoDismiss: true,
        });
        setFileData(apiResponse?.addPropertyData);
        if (
          apiResponse.addPropertyData &&
          selectedProperty.sub_current_stage === getSubStage(3)
        )
          getSubStageUpdated(propertyId, getSubStage(4));
        setShowEditor(true);
        setLoader(false);
      } else if (apiResponse?.addPropStatus === ERROR_KEY) {
        addToast(apiResponse.addPropMessage, {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
        setLoader(false);
      }
    }
    if (!pdfUrl && selectedProperty.sub_current_stage === getSubStage(5))
      getFinalizedOffer();
  }, [apiResponse, pdfUrl]);

  // function to call the API to create generate offer
  const generateOffer = (e) => {
    e.preventDefault();
    setLoader(true);
    dispatch(
      updateManagePropData({
        id: sessionStorage.getItem(PROPERTY_ID_KEY),
        document_meta_data: {
          down_payment_amount: data?.down_payment_amount,
          offered_purchase_price: data?.offered_purchase_price,
          balance_due: data?.balance_Due,
          closing_date : data?.closing_date
        },
      })
    );
    dispatch(postGenerateApi(data)).then((response) => {
      setFileData(response.createPropertyData?.HTMLParsedData);
      let historyData = historyFormatter(user, userId, propertyId, 'has generated the offer.');
      addPropertyHistory(historyData);
      setShowEditor(true);
      setLoader(false);
    });
  };

  const getGeneratedOffer = async (payload) => {
    setLoader(true);
    const response = await generateDoc(payload);
   if(response.status === 200){
    setFileData(response.data.createPropertyData?.HTMLParsedData);
    setShowEditor(true)
    setLoader(false);
   }
  }

  const getFinalizedOffer = () => {
    setLoader(true);
    const payload = {
      propertyId,
      templateSubType: defaultValues.template_types.offer_letter,
      templateType: defaultValues.template_types.document,
    };
    const options = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(payload),
    };
    fetch(`${API.baseURL[API.currenEnv]}property/getDocumentFromS3`, options)
      .then((res) => res.blob())
      .then((data) => {
        if (data.type === "application/pdf") {
          const file = new File([data], "offer_letter", { type: data.type });
          const url = URL.createObjectURL(file);
          setPdfUrl(url);
          addToast(getSuccessMessage('S-10021'), {
            appearance: SUCCESS_KEY,
            autoDismiss: true,
          });
          setShowEditor(true);
          setShowPdf(true);
        }
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
        addToast(getErrorMessage('E-10113'), {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
      });
  };
  const getToken = () => sessionStorage.getItem(ACCESS_TOKEN_KEY);

  const initiateFinalize = () => {
    setLoader(true);
    const payload = {
      propertyID: sessionStorage.getItem(PROPERTY_ID_KEY),
      templateSubType: defaultValues.template_types.offer_letter,
      templateType: defaultValues.template_types.document,
      file: fileData,
      userId : userId
    };
    const options = {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(payload),
    };
    fetch(`${API.baseURL[API.currenEnv]}property/storeDocument`, options)
      .then((res) => res.blob())
      .then((data) => {
        if (data.type === "application/pdf") {
          const file = new File([data], "offer_letter", { type: data.type });
          const url = URL.createObjectURL(file);
          setPdfUrl(url);
          getSubStageUpdated(propertyId, getSubStage(4.1));
          addToast(getSuccessMessage('S-10021'), {
            appearance: SUCCESS_KEY,
            autoDismiss: true,
          });
          setShowPdf(true);
          history.push(Routes.VIEW_PROPERTY);
        }
        const historyData = historyFormatter(user, userId, propertyId, 'finalized the offer.')
        addPropertyHistory(historyData);
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
        addToast(getErrorMessage('E-10227'), {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
      });
  };

  const getSigneduserInfo = async()=>{
    const {property_parties_details = []} = selectedProperty;
    const buyerSellers = property_parties_details.filter((party)=> [defaultValues.role_names.buyer,defaultValues.role_names.seller].includes( party.role_side));
    const userIds = buyerSellers.map((user)=>{
      return user.user_id._id
    });
    let data = {
      property_id: propertyId,
      documentType: defaultValues.doc_types.document,
      subType: defaultValues.doc_sub_types.offer_letter,
    };
    const response = await getDocByPropId(data);
    if (response.status === 200) {
      const signedUserArray = response.data.data.signing || [];
      const signedUserIdArray = signedUserArray.map((user) => user.signed_by);
      const unsignedUser = userIds.filter(
        (id) => !signedUserIdArray.includes(id)
      );

      if (!unsignedUser.length) {
        dispatch(stageValidation({ propertyId, roleId })).then(async () => {
          await getSubStageUpdated(propertyId, getSubStage(6));
          setProceed(true);
        });
      } else {
        if (signedUserArray.includes(userId)) setIsDocumentSigned(true);
        setErrorText(getErrorMessage('E-10228'));
      }
    }
  };

  const handleProceed = () => {
    if (selectedProperty.sub_current_stage === getSubStage(6)) {
      history.push(Routes.REVIEW_MOU);
    }
  };

  const callbackFunction = async (res, docketId) => {
    setIsLoading(true);
    getSignedDoc({
        docket_id: docketId,
        document_id: res?.document_id,
        user_id : userId,
        template_type: defaultValues.template_types.document,
        template_subtype: defaultValues.template_types.offer_letter,
      }).then((res) => {
        if(res.data.status === "signed"){
          addToast(getSuccessMessage('S-10013'), { appearance: SUCCESS_KEY, autoDismiss: true });
          getSigneduserInfo();
          getPropertyDetails(propertyId).then(response => {
            if(response.data) {
             dispatch(addSelectedProperty(response.data.propertyData));
            }
          });
          dispatch(getPropertiesData(propertyId));
          setIsLoading(false);
          history.push(Routes.VIEW_PROPERTY);
        }else {
          addToast(getErrorMessage('E-10118'), { appearance: ERROR_KEY, autoDismiss: true });
        }
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div style={{marginTop: '2%'}}>
      {loader ? (
        <Loader />
      ) : (
        <div>
          <Main
            showUserNavbar={!props?.showUserNavbar}
            showUser={!props?.showUser}
          >
            <div className="fee-container fee-containers">
              <div className="fee-heading">
                <p>Initiate Offer</p>
              </div>
              {incompleteUserDetails?.length ? (
                <div className="error-header">
                  <Typography className="error-header" component="div">
                    Below users have not completed their mandatory profile
                    details to proceed further. Kindly complete all the details
                    on profile page for all the users to initiate the offer.
                  </Typography>
                  {incompleteUserDetails.map((item) => {
                    return (
                      <Typography className="error-text" component="div">
                        <ul>
                          <li>
                            {item.first_name} {item.last_name}
                          </li>
                        </ul>
                      </Typography>
                    );
                  })}
                </div>
              ) : (
                <div className="platform-fee-container">
                  {signedPdfShow ? (
                    <div className="platform-fee-container-embed">
                      {" "}
                      <embed
                        src={`data:application/pdf;base64,${signedPdfData}`}
                        height={500}
                        width={800}
                      />
                    </div>
                  ) : showEditor ? (
                    <>
                      {!showPdf ? (
                        <>
                          <div className="platform-fee-ck-container">
                            <CustomEditor
                              data={fileData}
                              onChange={(text) => {
                                setFileData(text);
                              }}
                            />
                          </div>
                          <div>
                            <Button
                              className="fee-button"
                              onClick={initiateFinalize}
                            >
                              {" "}
                              Finalize Offer{" "}
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="platform-fee-ck-container">
                            <object
                              data={pdfUrl}
                              type="application/pdf"
                              width="100%"
                              height="100%"
                            >
                              <p>
                                Alternative text - include a link{" "}
                                <a href={pdfUrl}>to the PDF!</a>
                              </p>
                            </object>
                          </div>

                          {isDocumentSigned ? (
                            proceed ? (
                              <Button
                                className="procced"
                                onClick={handleProceed}
                              >
                                {" "}
                                Procced
                              </Button>
                            ) : (
                              <Tooltip title={errorText} placement="left" arrow>
                                <div className="proceedButton">
                                  <span className="proccedText">Proceed</span>
                                </div>
                              </Tooltip>
                            )
                          ) : (
                            <div>
                              <Button
                                className="fee-buttonEdit"
                                disabled={isDocumentSigned}
                                onClick={() => {
                                  setShowPdf(false);
                                  setShowEditor(true);
                                  getOfferData()
                                }}
                              >
                                {" "}
                                Back To Edit{" "}
                              </Button>
                              <Button
                                className="fee-buttonRight"
                                disabled={isDocumentSigned}
                                onClick={() => {
                                  setLoader(true);
                                  initiateSigning({
                                    propertyId:
                                      sessionStorage.getItem("propertyID"),
                                    userId,
                                    templateType: "DOCUMENT",
                                    templateSubType: "OFFER_LETTER",
                                    callbackFunction: callbackFunction,
                                    loaderFalse: setLoader,
                                    addToastMessage: (message) =>
                                      addToast(message, {
                                        appearance: "error",
                                        autoDismiss: true,
                                      }),
                                  });
                                }}
                              >
                                Sign Here{" "}
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <Form onSubmit={(e) => generateOffer(e)}>
                      <Form.Group className="mb-3">
                        <div className="d-flex flex-row align-items-center">
                          <div className="p-2">
                            <Form.Label className="fee-label">
                              Offer Purchased Price
                            </Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter Price"
                              className="fee-inputs mb-1"
                              value={data.offered_purchase_price}
                              required
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  offered_purchase_price: parseInt(
                                    e.target.value
                                  ),
                                  ExtraFields: {
                                    ...data.ExtraFields,
                                    offerPrice: e.target.value,
                                  },
                                  balance_Due: parseInt(
                                    e.target.value - data.down_payment_amount
                                  ),
                                })
                              }
                            />
                          </div>
                          <p className="small-text pl-4">
                            {convertNumberToWord(data.offered_purchase_price)}
                          </p>
                        </div>

                        <div className="d-flex flex-row align-items-center">
                          <div className="p-2">
                            <Form.Label className="fee-label">
                              Down Payment Amount
                            </Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter Amount"
                              className="fee-inputs"
                              required
                              value={data.down_payment_amount}
                              isValid={false}
                              onChange={(e) => {
                                let due =
                                  data.offered_purchase_price - e.target.value;
                                setData({
                                  ...data,
                                  down_payment_amount: parseInt(e.target.value),
                                  ExtraFields: {
                                    ...data.ExtraFields,
                                    token: e.target.value,
                                  },
                                  balance_Due: parseInt(due),
                                });
                              }}
                            />
                          </div>
                          <p className="small-text pl-4">
                            {convertNumberToWord(data.down_payment_amount)}
                          </p>
                        </div>
                        {parseInt(data.down_payment_amount) >
                        parseInt(data.offered_purchase_price) ? (
                          <Form.Label className="fee-label text-danger">
                            Down Payment cannot be greater than offered purchase
                            price
                          </Form.Label>
                        ) : (
                          ""
                        )}

                        <div className="d-flex flex-row align-items-center">
                          <div className="p-2">
                            <Form.Label className="fee-label">
                              Balance Due
                            </Form.Label>
                            <Form.Control
                              type="number"
                              min="0"
                              step="1"
                              placeholder="Enter Due"
                              className="fee-inputs"
                              disabled
                              value={
                                parseInt(data.down_payment_amount) >
                                parseInt(data.offered_purchase_price)
                                  ? 0
                                  : parseInt(
                                      data.offered_purchase_price -
                                        data.down_payment_amount
                                    )
                              }
                            />
                          </div>
                          <p className="small-text pl-4">
                            {convertNumberToWord(
                              parseInt(data.down_payment_amount) >
                                parseInt(data.offered_purchase_price)
                                ? 0
                                : parseInt(
                                    data.offered_purchase_price -
                                      data.down_payment_amount
                                  )
                            )}
                          </p>
                        </div>
                      </Form.Group>
                      <Form.Label className="fee-label">
                        Closing Date :
                      </Form.Label>{" "}
                      &nbsp;
                      <DatePicker
                        max
                        defaultValue={dayjs(
                          new Date().toLocaleDateString("en-GB"),
                          dateFormatList[0]
                        )}
                        format={dateFormatList}
                        disabledDate={(current) =>
                          current.isBefore(dayjs(), "day") ||
                          current.isAfter(dayjs(moment().add(60, "day")))
                        }
                        onChange={(e) => {
                          if (e) {
                            setData({
                              ...data,
                              closing_date: new Date(e.$d).toLocaleDateString(
                                "en-GB"
                              ),
                              ExtraFields: {
                                ...data.ExtraFields,
                                closingDate: new Date(e.$d).toLocaleDateString(
                                  "en-GB"
                                ),
                              },
                            });
                          }
                        }}
                      />
                      <Button
                        variant="primary"
                        type="submit"
                        className="fee-btn"
                        disabled={
                          incompleteUserDetails.length ||
                          (parseInt(data.down_payment_amount) <
                          parseInt(data.offered_purchase_price)
                            ? 0
                            : parseInt(
                                data.offered_purchase_price -
                                  data.down_payment_amount
                              ))
                        }
                      >
                        Generate Offer
                      </Button>
                    </Form>
                  )}
                </div>
              )}
            </div>
          </Main>
        </div>
      )}
    </div>
  );
};
export default InitiateOffer;

