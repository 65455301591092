import React, { useState, useEffect } from "react";
import Main from "../../layout/Main";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card, Table, Modal } from "react-bootstrap";
import openFolder from "../../assets/openFolder.svg";
import ChatModal from "../../ChatModal/index";

import { Button, TextField, Typography, Skeleton, Box } from "@mui/material";
import { useToasts } from "react-toast-notifications";
import { ArrowLeft } from "react-bootstrap-icons";
import pulse from "../../assets/Pulse-0.9s-200px.svg";
import {
  getChecklistFiles,
  getPropertyChecklistFiles,
  approveorRejectDoc,
  addCommentToDoc,
  lawyerApproval,
  updateSUbStages,
  addPropertyHistory,
} from "../../services/api";
import { updateSelectedProperty } from "../user-home/slices";
import { historyFormatter } from "../../utils/historyFormatter";
import {
  ERROR_KEY,
  SUCCESS_KEY,
  defaultValues,
  getErrorMessage,
  getSuccessMessage,
} from "../../constants";
import { Routes } from "../../constants/routes";
import { useHistory } from "react-router-dom";
import DocumentLoader from "./loader";
import Loader from "../../layout/Loader/Loader";

const ReviewDocuments = () => {
  const [loader, setloader] = useState(false);
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();

  const role = useSelector((state) => state.loggedInUser.prefferedRole);
  const userRoleId = useSelector(
    (state) => state.loggedInUser.data.data.userByEmail.primary_role._id
  );
  const userId = useSelector(
    (state) => state.loggedInUser.data.data.userByEmail._id
  );

  const user = useSelector((state) => state?.loggedInUser?.data?.data?.userByEmail);

  const viewPropertiesData = useSelector(
    (state) => state.superAdminProperties.propertyData
  );

  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [selectedFileData, setSelectedFileData] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedCheckID, setSelectedCheckID] = useState("");
  const [listLoading, setListLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [isRejectAllChecked, setIsRejectAllChecked] = useState(false);
  const [reasonOfRejectAll, setReasonOfRejectAll] = useState("");
  const [viewingFile, setViewingFile] = useState("");
  const [approveRejectOneByOne, setApproveRejectOneByOne] = useState(true);
  const [refinedfiles, setRefinedFiles] = useState([]); // stores files which only contain data related to current lawyer
  const [filteredFolders, setFilteredFolders] = useState([]);
  const stageList = useSelector((state) => state.stages?.stages);
  const getStageOrder = (id) =>
    stageList.filter((stage) => stage._id === id)[0].order_number;
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const checkListTypeData = useSelector(
    (state) => state.superAdminProperties?.checkListData
  );
  const subStages = useSelector((state) => state?.stages?.subStages) || [];
  const selectedProperty = useSelector(
    (state) => state.buyerHomeData?.selectedProperty
  );
  const getSubStage = (order) =>
    subStages.filter((substage) => substage.order === order)[0]._id;
  useEffect(() => {
    if (checkListTypeData?.length) {
      let tempArr = [...checkListTypeData];
      tempArr = tempArr.filter(
        (item) => !defaultValues.exclude_folder_lawyer.includes(item.title)
      );
      tempArr = tempArr.filter(
        (item) =>
          getStageOrder(item.stages) <=
          selectedProperty.current_stage.order_number
      );
      setFilteredFolders(tempArr);
    }
  }, [checkListTypeData, selectedProperty]);

  //Get folder files API
  const fetchFilesInsideChecklist = async (clickedTitle) => {
    const getAllFilesdata = {
      property_id: viewPropertiesData._id,
      checklist_name: clickedTitle,
    };
    await getPropertyChecklistFiles(getAllFilesdata)
      .then((response) => {
        const files = response.data;
        const fileKeys = files.filesInDb.map((file) => file);
        getFieldsToBeDisplayed(fileKeys);
      })
      .catch((error) => {
        addToast(getErrorMessage("E-10125"), {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
      });
    setListLoading(false);
  };

  //View Document API
  const handleViewDocument = async (fileLink) => {
    setShowModal(true);
    setFileLoading(true);
    setViewingFile(fileLink);
    setIsLoading(true);
    try {
      let payload = {
        property_id: viewPropertiesData._id,
        checklist_name: selectedTitle,
        document_name: fileLink,
      };
      const response = await getChecklistFiles(payload);
      const document = {
        uri: response.data.url,
        fileType: response.data.fileType,
      };
      setSelectedFile(document);
      setIsLoading(false)
    } catch (error) {
      addToast(getErrorMessage("E-10195"), {
        appearance: ERROR_KEY,
        autoDismiss: true,
      });
      setIsLoading(false)
    }
    setFileLoading(false);
  };

  const handleReasonChange = (event) => {
    setReasonOfRejectAll(event.target.value);
  };

  const handleCloseModal = () => {
    setSelectedFile("");
    setShowModal(false);
  };

  //Approve-single doc API
  const handleApprove = async () => {
    setloader(true);
    setIsLoading(true);

    const payload = {
      checklistName: selectedTitle,
      checklistId: selectedCheckID,
      property_id: viewPropertiesData._id,
      fileName: viewingFile,
      documentType: defaultValues.doc_types.checklist,
      approved: true,
      userRoleId: userRoleId,
      userId: userId,
    };

    await approveorRejectDoc(payload)
      .then(async (res) => {
        addToast(getSuccessMessage("S-10024"), {
          appearance: SUCCESS_KEY,
          autoDismiss: true,
        });
        await fetchFilesInsideChecklist(selectedTitle);
        const msgText = "approved";
        const historyData = historyFormatter(
          user,
          userId,
          viewPropertiesData._id.toString(),
          `has ${msgText} the "${selectedTitle}" documents.`
        );
        await addPropertyHistory(historyData);
      })
      .catch((error) => {
        addToast(error.response.data.message, {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
      })
      .finally(() => {
        setloader(false);
        setShowModal(false);
        setIsLoading(false);
      });
  };

  const handleReject = async (fileLink) => {
    if (fileLink) {
      const uriParts = fileLink.uri.split("/");
      const fileNameWithQuery = uriParts.pop();
      const fileNameWithoutQuery = fileNameWithQuery.split("?")[0];
      const decodedFileName = decodeURIComponent(fileNameWithoutQuery);
      setSelectedFile(decodedFileName);
      getComments(selectedFileData);
      setShowCommentsModal(true);
    }
  };
  const rejectFile = async (fileLink) => {
    setShowModal(true);
    if (fileLink) {
      const payload = {
        checklistName: selectedTitle,
        checklistId: selectedCheckID,
        property_id: viewPropertiesData._id,
        fileName: viewingFile,
        documentType: defaultValues.doc_types.checklist,
        approved: false,
        userRoleId: userRoleId,
        userId: userId,
        comment: newComment,
      };
      await approveorRejectDoc(payload)
        .then(async (res) => {
          addToast(getSuccessMessage("S-10025"), {
            appearance: SUCCESS_KEY,
            autoDismiss: true,
          });
          await fetchFilesInsideChecklist(selectedTitle);
          const msgText = "rejected";
          const historyData = historyFormatter(
            user,
            userId,
            viewPropertiesData._id.toString(),
            `has ${msgText} the "${selectedTitle}" documents.`
          );
          await addPropertyHistory(historyData);
          setShowModal(false);
          setShowCommentsModal(false);
        })
        .catch((error) => {
          addToast(error.response.data.message, {
            appearance: ERROR_KEY,
            autoDismiss: true,
          });
          setShowModal(false);
        });
    }
  };

  const getFieldsToBeDisplayed = (files) => {
    try {
      for (let i in files) {
        let file = files[i];
        if (file.approvals.length) {
          let my_approvals = [];
          for (let j in file.approvals) {
            let approval = file.approvals[j];
            if (approval.approved_by === userId && approval.approver_role) {
              my_approvals = [{ approval }];
              break;
            }
          }
          files[i].approvals = my_approvals;
        } else {
          files[i].approvals = [];
        }
      }
      setRefinedFiles(files);
    } catch (error) {
      addToast(getErrorMessage("E-10190"), {
        appearance: ERROR_KEY,
        autoDismiss: true,
      });
    }
  };

  const handleCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const handleSendComment = async () => {
    if (newComment.trim() !== "") {
      setIsLoading(true);
      let currentDate = new Date().toLocaleString();
      setComments([
        ...comments,
        { message: newComment, created_at: currentDate },
      ]);
      setNewComment("");
      const payload = {
        checklistName: selectedTitle,
        propertyId: viewPropertiesData._id,
        fileName: selectedFile,
        documentType: defaultValues.doc_types.checklist,
        userRoleId: userRoleId,
        userId: userId,
        comment: newComment,
      };
      await addCommentToDoc(payload)
        .then(async (res) => {
          showModal && await rejectFile(selectedFile);
          await fetchFilesInsideChecklist(selectedTitle);
        })
        .catch((error) => {
          addToast(error.response.data.message, {
            appearance: ERROR_KEY,
            autoDismiss: true,
          });
        }).finally(() => setIsLoading(false));
    }
  };

  const getSubStageUpdated = async (propertyId, substage) => {
    try {
      const response = await updateSUbStages({
        propertyId,
        substageId: substage,
        loggedInUser: userId,
      });
      if (response.status === 200) {
        dispatch(updateSelectedProperty(response.data.propertyData));
        addToast(getSuccessMessage("S-10006"), {
          appearance: SUCCESS_KEY,
          autoDismiss: true,
        });
      } else
        addToast(getErrorMessage("E-10070"), {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
    } catch (error) {
      addToast(error.response.data.message || getErrorMessage("E-10070"), {
        appearance: ERROR_KEY,
        autoDismiss: true,
      });
    }
  };

  const approverejectallDocs = async (status) => {
    setIsLoading(true);
    await lawyerApproval({ propertyId: viewPropertiesData._id, status })
      .then(async (response) => {
        const msgText = status === true ? "approved" : "rejected";
        addToast(`Documents ${msgText} successfully!`, {
          appearance: SUCCESS_KEY,
          autoDismiss: true,
        });
        const historyData = historyFormatter(
          user,
          userId,
          viewPropertiesData._id.toString(),
          `has ${msgText} the documents.`
        );
        await addPropertyHistory(historyData);
        if (status) {
          await getSubStageUpdated(viewPropertiesData._id, getSubStage(11));
        }
        setIsLoading(false);
        history.push(Routes.VIEW_PROPERTY);
      })
      .catch((error) => {
        addToast(error.response.data.message, {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
        setIsLoading(false);
      });
  };

  const getComments = async (fileDoc) => {
    setShowCommentsModal(true);
    setSelectedFile(fileDoc.fileName);
    const docData = fileDoc.approvals.filter(
      (i) => i.approval.approved_by === userId
    );
    setComments(docData.length === 1 ? docData[0].approval.description : []);
  };

  return (
    <div style={{ marginTop: "2%" }}>
      <Main showUserNavbar={true} role={role}>
        <div className="container">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="fee-container">
              <div className="inner-container mr-5 pr-5">
                {show ? (
                  <>
                    <div className="d-flex justify-content-start">
                      <span>
                        <Button
                          onClick={() => {
                            setShow(false);
                            setIsRejectAllChecked(false);
                          }}
                        >
                          <ArrowLeft size={20} /> Back
                        </Button>
                      </span>
                    </div>
                    <Table className="myTable ">
                      <thead
                        style={{
                          backgroundColor: "var(--primaryColor)",
                          width: "100vh",
                        }}
                      >
                        <tr>
                          <th
                            colSpan="4"
                            style={{ textAlign: "center", color: "white" }}
                          >
                            <div>{selectedTitle}</div>
                          </th>
                        </tr>
                      </thead>

                      {isLoading ? (
                        <Box sx={{ pt: 0.5 }}>
                          <Typography
                            variant="h5"
                            component="h5"
                            align="center"
                            sx={{ color: "var(--primaryColor)" }}
                          >
                            Listing Documents
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Skeleton
                              sx={{
                                width: "70%",
                                height: "50px",
                                borderRadius: "4px",
                                my: 1,
                              }}
                              animation="wave"
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Skeleton
                              sx={{
                                width: "30%",
                                height: "50px",
                                borderRadius: "4px",
                                my: 1,
                              }}
                              animation="wave"
                            />
                          </Box>
                        </Box>
                      ) : (
                        <tbody>
                          <tr>
                            <th>S.No</th>
                            <th>File Name</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                          {refinedfiles.map((fileKey, index) => {
                            let file_status = "PENDING";
                            if (fileKey.approvals.length > 0) {
                              const approval = fileKey.approvals[0].approval;
                              if (approval.approved === null) {
                                file_status = "PENDING";
                              } else {
                                file_status = approval.approved
                                  ? "APPROVED"
                                  : "REJECTED";
                              }
                            }

                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{fileKey.fileName}</td>
                                <td>{file_status}</td>
                                <td>
                                  <div className="btn-container">
                                    <Button
                                      style={{
                                        marginRight: "20px",
                                        background: "var(--primaryButton)",
                                      }}
                                      variant="contained"
                                      color="primary"
                                      onClick={() => {
                                        handleViewDocument(fileKey.fileName);
                                        setSelectedFileData(fileKey);
                                      }}
                                    >
                                      View Document
                                    </Button>
                                    <Button
                                      style={{
                                        background: "var(--primaryButton)",
                                      }}
                                      variant="contained"
                                      color="primary"
                                      onClick={() => getComments(fileKey)}
                                    >
                                      Comments
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                          {isRejectAllChecked && (
                            <tr>
                              <td colSpan="4">
                                <div>
                                  <TextField
                                    placeholder="Reason of Rejecting all Documents"
                                    value={reasonOfRejectAll}
                                    onChange={handleReasonChange}
                                    fullWidth
                                    required
                                    error={!reasonOfRejectAll ? true : false}
                                    helperText="Mandatory Field"
                                  />
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      )}
                      <Modal show={showModal} dialogClassName="custom-modal">
                        <div className="d-flex justify-content-start">
                          <span>
                            <Button onClick={() => handleCloseModal()}>
                              <ArrowLeft size={20} /> Back
                            </Button>
                          </span>
                        </div>
                        <Modal.Body>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Card elevation={5}>
                              {fileLoading && <img src={pulse} alt="loading" />}
                            </Card>
                          </Box>

                          {selectedFile && renderfile(selectedFile)}
                        </Modal.Body>
                        {approveRejectOneByOne && selectedFileData?.approvals.length <= 0 && (
                          <Modal.Footer>
                            {loader ? (
                              <DocumentLoader /> // Render the loader component
                            ) : (
                              <Button
                                className="btn-container button"
                                variant="contained"
                                onClick={() => handleApprove()}
                              >
                                Approve
                              </Button>
                            )}
                            <Button
                              className="btn-container button"
                              variant="contained"
                              onClick={() => handleReject(selectedFile)}
                            >
                              Reject
                            </Button>
                          </Modal.Footer>
                        )}
                      </Modal>

                      <Modal show={showCommentsModal} size="xl">
                        <Modal.Body>
                          <div style={{ maxHeight: window.innerHeight * 0.9 }}>
                            {ChatModal(
                              comments,
                              newComment,
                              handleSendComment,
                              handleCommentChange,
                              () => {
                                setShowCommentsModal(false);
                              },
                              showModal
                            )}
                          </div>
                        </Modal.Body>
                      </Modal>
                    </Table>
                  </>
                ) : loading ? (
                  <Box sx={{ pt: 0.5 }}>
                    <Typography
                      variant="h5"
                      component="h5"
                      align="center"
                      sx={{ color: "var(--primaryColor)" }}
                    >
                      Fetching Files...
                    </Typography>
                    <Skeleton
                      sx={{ height: 190, borderRadius: "8px" }}
                      animation="wave"
                      variant="rectangular"
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Skeleton
                        sx={{
                          width: "70%",
                          height: "20px",
                          borderRadius: "4px",
                          my: 1,
                        }}
                        animation="wave"
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Skeleton
                        sx={{
                          width: "40%",
                          height: "20px",
                          borderRadius: "4px",
                          my: 1,
                        }}
                        animation="wave"
                      />
                    </Box>
                  </Box>
                ) : (
                  <Row xl={3}>
                    {filteredFolders?.map((folder) => (
                      <Col
                        className="text-center mb-5"
                        onClick={async () => {
                          setShow(true);
                          const clickedTitle = folder.title;
                          setSelectedCheckID(folder._id);
                          setSelectedTitle(clickedTitle);
                          setIsLoading(true);
                          await fetchFilesInsideChecklist(clickedTitle);
                          setIsLoading(false);
                          setListLoading(true);
                        }}
                      >
                        <Card className="reviewDocumentsCard">
                          <Card.Img
                            variant="top"
                            src={openFolder}
                            className="card-img"
                          />
                          <Card.Body>
                            <Card.Title>{folder.title}</Card.Title>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                )}
              </div>
              {!show && !showModal && !loading && (
                <div className="btn-container">
                  <button
                    onClick={() => approverejectallDocs(true)}
                    className="approve-btn"
                  >
                    Approve to proceed
                  </button>
                  <button
                    className="approve-btn"
                    onClick={() => approverejectallDocs(false)}
                  >
                    Reject Transaction
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </Main>
    </div>
  );
};

const renderfile = (selectedFile) => {
  const displayHeight = window.innerHeight * 0.8;
  const displayWidth = "90%";
  const dimensions = { height: displayHeight, width: displayWidth };
  if (selectedFile.fileType === ".pdf") {
    return <object style={dimensions} data={selectedFile.uri}></object>;
  } else if (
    selectedFile.fileType === ".png" ||
    selectedFile.fileType === ".jpg" ||
    selectedFile.fileType === ".jpeg"
  ) {
    return (
      <div style={{ alignContent: "center", margin: "10% 10% 10% 10%" }}>
        <img
          width={displayWidth}
          src={selectedFile.uri}
          alt="selected-doc"
        ></img>
      </div>
    );
  } else {
    return (
      <iframe
        style={dimensions}
        src={`https://docs.google.com/gview?url=${encodeURIComponent(
          `${selectedFile.uri}`
        )}&embedded=true`}
        title="Selected File"
      ></iframe>
    );
  }
};

export default ReviewDocuments;
