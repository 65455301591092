import React from "react";
import { ArrowLeft } from "react-bootstrap-icons";
import { Button, TextField, Typography  } from "@mui/material";
import sendButton from "../assets/send_button.svg";
import "./style.scss";

const ChatModal = (
	comments,
	newComment,
	handleSendComment,
	handleCommentChange,
	setShowCommentsModal,
	showAddComment
) => {
	return (
		<>
			<span>
				<Button onClick={() => setShowCommentsModal(false)}>
					<ArrowLeft size={20} /> Back
				</Button>
			</span>

			<h2>Comments</h2>
			<div style={{
				minHeight: window.innerHeight * 0.6,
				maxHeight: window.innerHeight * 0.6,
				overflow: "auto"
				}}>
  				{comments.length === 0 ? (
        		  <Typography variant="body1" align="center">
        		    No comments
        		  </Typography>
        		) : (
        		  comments.map((comment, index) => (
        		    <div key={index}>
        		      {ChatBubble(comment.message, comment.created_at, "You")}
        		    </div>
        		  ))
        		)}
			</div>

			<div>

			{showAddComment && <TextField
				placeholder="Write a comment..."
				className="inputBox fieldWidth"
				style={{width:"70%"}}
				onChange={handleCommentChange}
				value={newComment}
			/>}

			{showAddComment && <img
				style={{ marginLeft: "30px", marginTop: "10px" }}
				width="25px"
				onClick={handleSendComment}
				src={sendButton}
				alt="send-btn"
			/>}
			</div>
		</>
	);
};


const ChatBubble = (message, date, sender) => {
	let sent_date = new Date(date).toLocaleString();
	return (
		<div class="chat-bubble">
			<div className="message">{message}</div>
			<div className="meta">
				<div className="sender">{"Sent By " + sender}</div>
				<div className="date">{sent_date}</div>
			</div>
		</div>
	);
};

export default ChatModal;