import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import "./style.scss";
import { Delete, Edit } from "@mui/icons-material";
import { partyDetails } from "../../../utils/validationSchemas";
import {
  defaultValues,
  ERROR_KEY,
  getErrorMessage,
  ROLE_KEY,
  SUCCESS_KEY,
} from "../../../constants";
import { useToasts } from "react-toast-notifications";

import { AddPartyDetails } from "../add_party_details";
import { updateRentalParty } from "../../../pages/rental-agreement-new/service";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--secondaryColor)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const PartyDetails = ({ partyType, onBack, onSaveList, readOnly }) => {
  const role = sessionStorage.getItem(ROLE_KEY)
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { values, handleSubmit, errors, touched } = useFormik({
    initialValues: { rental_parties: [] },
    validationSchema: partyDetails,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => onSaveList(values),
  });
  const [argData, setArgData] = useState(null);
  const [showAddParty, setShowAddParty] = useState(false);
  const [liData, setLiData] = useState([]);
  const [editData, setEditData] = React.useState({});

  const selectedRental = useSelector(
    (state) => state.rentalAgreement.selectedRental
  );


  useEffect(() => {
    if (selectedRental) {
      setArgData(selectedRental);
    }
  }, [selectedRental]);

  useEffect(() => {
    if (selectedRental) {
      const pendingList = selectedRental.rental_parties.filter(
        (i) => i.type && i.type === partyType
      );
      setLiData(pendingList);
      values.rental_parties = pendingList;
    }
  }, [selectedRental]);

  const handelPartyAction = (payload) => {
    dispatch(updateRentalParty(payload)).then((res) => {
      if (!res.payload?.data || !res.payload?.data.status) {
        addToast(res.payload?.data?.message || getErrorMessage("E-10070"), {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
      } else {
        addToast(res.payload.data?.message, {
          appearance: SUCCESS_KEY,
          autoDismiss: true,
        });
      }
    });
  };

  const handelDeleteParty = (user) => {
    const payload = {
      ...user,
      age: parseInt(user.age),
      argId: argData._id,
      action: defaultValues.rental_parties_action.remove,
      type: partyType,
    };
    handelPartyAction(payload);
  };

  return (
    <div className="pl-3 mt-1 container-fluid">
      <div className="row">
        <div className="col-12 pt-2 r-table">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Full Name</StyledTableCell>
                  <StyledTableCell>Contact No</StyledTableCell>
                  <StyledTableCell>PAN No</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>Permanent Address</StyledTableCell>
                  <StyledTableCell className="text-center">
                    Action
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {liData.length > 0 ? (
                  liData.map((user, index) => {
                    return (
                      <TableRow key={index}>
                        <StyledTableCell>
                          {user.first_name + " " + user.last_name}
                        </StyledTableCell>
                        <StyledTableCell>{user.country_code + user.mobile}</StyledTableCell>
                        <StyledTableCell>{user.pan}</StyledTableCell>
                        <StyledTableCell style={{ maxWidth: "12vw" }}>
                          {user.email}
                        </StyledTableCell>
                        <StyledTableCell style={{ maxWidth: "15vw" }}>
                          {`${user.address}, ${user.city}, ${user.state} - ${user.pin}`}
                        </StyledTableCell>
                        <StyledTableCell sx={{ maxWidth: "100px" }}>
                          {!readOnly &&
                            <div className="d-flex">
                              <IconButton
                                className="mr-3"
                                aria-label="edit"
                                onClick={() => {
                                  setShowAddParty(true);
                                  setEditData(user);
                                }}
                                style={{
                                  backgroundColor: "var(--secondaryColor)",
                                }}
                              >
                                <Edit style={{ color: "var(--white)" }} />
                              </IconButton>                              
                              <IconButton
                                className="mr-3"
                                aria-label="delete"
                                onClick={() => handelDeleteParty(user)}
                                style={{ backgroundColor: "var(--beanRed)" }}
                              >
                                <Delete style={{ color: "var(--white)" }} />
                              </IconButton>                              
                            </div>
                          }
                        </StyledTableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <StyledTableCell>Empty</StyledTableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {touched.rental_parties && errors.rental_parties && (
            <p className="r-formError mb-0">{errors.rental_parties}</p>
          )}
        </div>
        {!readOnly && argData?.type !== "renew" && (
          <div className="proceed-button d-flex flex-row my-3 mr-auto">
            <Button
              className="button px-4 mr-4"
              variant="primary"
              disabled={liData.length >= 5}
              onClick={() => {
                setShowAddParty(true);
                setEditData({});
              }}
            >
              + Add {partyType}
            </Button>
          </div>
        )}
      </div>

      {!readOnly && (
        <div className="proceed-button my-3 text-center">
          <Button
            className="px-5 mr-2 mt-3 btn-outline-secondary"
            variant="outlined"
            onClick={onBack}
          >
            Back
          </Button>
          <Button
            className="button px-5 mt-3"
            variant="primary"
            onClick={handleSubmit}
          >
            Save & Proceed
          </Button>
        </div>
      )}

      <AddPartyDetails
        type={partyType}
        onCancel={() => {
          setShowAddParty(false);
          setEditData({});
        }}
        showModel={showAddParty}
        editData={editData}
        role={role}
      />
    </div>
  );
};
