import React from "react";
import { Box, Step, StepLabel, Stepper } from "@mui/material";

export const SteppeBar = ({activeStep}) => {
    const steps = [
        'Generate Rental Agreement',
        'Pay Platform Fees + Stamp Duty',
        'Review Rental Agreement',
      ];
  
    return (
    <Box sx={{ width: '100%', padding: "20px", overflowX:"auto" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
            <Step key={label}>
            <StepLabel>{label}</StepLabel>
            </Step>
        ))}
        </Stepper>
    </Box>
    );
}