export const defaultValues = {
    isResponsive: window.innerWidth < 1060,
    actions: {
        pay_platform_fees: 'Pay Platform Fees',
        initiate_offer: 'Initiate Offer',
        reinitiate_offer: 'Reinitiate Offer',
        finalize_offer: 'Finalize Offer',
        review_offer: 'Review Offer',
        sign_offer: 'Sign Offer Letter',
        initiate_mou: 'Initiate MOU',
        reinitiate_mou: 'Reinitiate MOU',
        review_mou: 'Review MOU',
        pay_token: 'Pay Token',
        sign_mou: 'Sign MOU',
        upload_docs: 'Upload Property Docs',
        verify_docs: 'Verify Property Docs',
        initiate_sale_agreement: 'Initiate Sale Agreement',
        reinitiate_sale_agreement: 'Reinitiate Sale Agreement',
        review_sale_agreement: 'Review Sale Agreement',
        pay_agreement_fee: 'Pay Agreement Fees.',
        sign_agreement: 'Sign Sale Agreement',
        apply_loan: 'Apply Loan',
        set_inspection_Date: 'Set Property Inspection Date',
        set_loan_section_status: 'Set Loan Sanction Status',
        accept_loan: 'Accept Loan Agreement',
        confirm_tds: 'Confirm TDS Amount',
        upload_challan: 'Upload TDS challan',
        initiate_sale_deed: 'Initiate Sale Deed',
        review_sale_deed: 'Review Sale Deed',
        set_registration_date: 'Set Registration Date',
        complete_registration: 'Mark as registered',
        backout: 'Backout',
        to_be_paid: 'To be paid',
        property_inspection: 'Property Inspection',
        loan_sanction: 'Loan Sanction',
        review_documents: 'Review Property Documents',
        update_tds: 'Update TDS Details',
        manage_profile: 'Manage Profile',
        manage_properties: 'Manage Properties',
        manage_admin: 'Manage Admins',
        manage_users: 'Manage Users',
        manage_templates: 'Manage Templates',
        manage_checklist: 'Manage Checklist',
        settings: 'Settings',
        logout: 'Logout'
    },
    action_info: {
        add_seller: 'Seller must be added to the property.',
        add_buyer: 'Buyer must be added to the property.',
        platform_fee_pending: 'Platform fee payment is yet pending from Buyer.',
        offer_initiate_pending: 'Offer is not yet initiated from buyer.',
        offer_reinitiate_pending: 'Offer is not yet reinitiated from buyer.',
        offer_finalize_pending: 'Offer is not yet finalized from buyer.',
        review_offer_pending_single_seller: 'Review Offer is pending from the Seller.',
        review_offer_pending_multiple_seller: 'Review Offer is pending from other Seller.',
        offer_signed_current_user: 'Offer is yet to signed by other parties.',
        offer_sign_pending_all: 'Offer is yet to signed by parties.',
        mou_initiate_pending: 'MOU is not yet initiated from buyer.',
        mou_reinitiate_pending: 'MOU is not yet reinitiated from buyer.',
        add_lawyer: 'Please add Lawyer.',
        review_mou_pending_lawyer: 'MOU Review is pending from Lawyer.',
        review_mou_pending_single_seller: 'Review MOU is pending from Seller.',
        mou_reviewed_current_seller: 'Review MOU is pending from other Seller.',
        token_payment_pending: 'Token payment is pending from buyer.',
        mou_sign_pending_all: 'MOU is yet to be signed by both parties.',
        mou_sign_pending_other_party: 'MoU signing is pending from other party.',
        generate_stamp_pending: 'MoU on e-Stamp is yet to be generated by Buyer.',
        document_upload_pending: 'Documents needs to be uploaded from Seller.',
        upload_documents: 'Please upload property documents.',
        document_review_pending: 'Documents needs to be approved from Lawyer.',
        sale_agreement_initate_pending: 'Sale Agreement is not yet initiated from buyer.',
        sale_agreement_reinitate_pending: 'Sale Agreement is not yet reinitiated from buyer.',
        review_sale_agreement_pending_lawyer: 'Sale Agreement Documents needs to be approved from Lawyer',
        review_sale_agreement_pending_seller: 'Review Sale Agreement is pending from Seller.',
        review_sale_agreement_pending_other_seller: 'Review Sale Agreement is pending from other Seller.',
        agreement_fee_pending: 'Agreement Fees is not yet paid from buyer.',
        agreement_sign_pending_all: 'Sale Agreement is yet to be signed by both parties.',
        agreement_sign_pending_other_party: 'Sale Agreement signing is pending from other party.',
        agreement_stamp_pending: 'Sale Agreement on e-Stamp is yet to be generated by Buyer.',
        loan_status_pending: 'Loan Status is not yet updated from buyer.',
        loan_confirmation_pending: 'Loan confirmation is pending from bank agent.',
        tds_confirmation_pending: 'TDS computation is pending from CA.',
        tds_challan_pending: 'TDS Challan upload is pending from buyer.',
        final_sale_deed_pending: 'Sale Deed is yet to be finalized from buyer.',
        review_sale_deed_pending_single_seller: 'Review Sale Deed is pending from Seller',
        review_sale_deed_pending_other_seller: 'Review Sale Deed is pending from other Seller.',
        registration_date_pending: 'Registration date is yet to be finalized from lawyer.',
        registration_date: 'Registration Date:',
        registration_details: 'Check your Email for Registration Details.',
        registration_completed: 'Registration Completed',
        backout_stage: 'Property is in Backout stage.',
        add_ca: 'Add CA',
        add_bank_agent: 'Add Bank Agent',
    },
    all_roles: ['SUPERADMIN','ADMIN', 'SELLER', 'BUYER','BUYER_POA','SELLER_POA','BUYER_AGENT','BANK_AGENT','SELLER_AGENT','LAWYER','CA'],
    date_format: 'DD-MM-YYYY',
    modalTitles: {
        delete_property_image: 'Delete Property Images',
    },
    role_names: {
        super_admin: 'SUPERADMIN',
        admin: 'ADMIN',
        seller: 'SELLER',
        buyer: 'BUYER',
        buyer_poa: 'BUYER_POA',
        seller_poa: 'SELLER_POA',
        buyer_agent: 'BUYER_AGENT',
        bank_agent: 'BANK_AGENT',
        seller_agent: 'SELLER_AGENT',
        lawyer: 'LAWYER',
        ca: 'CA',
        agent: 'AGENT',
        user: 'USER',
        poa: 'POA'
    },
    sales_signup : [
        'BUYER', 
        'SELLER',
        'LAWYER',
        'CA',
        'BANK_AGENT',
        'BANK_AGENT',
        'SELLER_AGENT',
        'BUYER_POA',
        'SELLER_POA'
    ],
    screenTitles: {
        apply_loan: 'Apply Bank Loan',
        sign_in: 'Sign In',
        sign_up: 'Sign Up',
        profile: 'Profile Settings',
    },
    screenSubTitles: {
        apply_loan: 'Do you plan to apply for Loan?',
    },
    doc_types: {
        document: 'DOCUMENT',
        checklist: 'CHECKLIST_CHECKLIST',
    },
    doc_sub_types: {
        offer_letter: 'OFFER_LETTER',
        mou: 'MOU',
        agreement: 'SALE_AGREEMENT',
        sale_deed: 'SALE_DEED',
    },
    buyer_side_users: ["BUYER_POA","LAWYER","BANK_AGENT","BUYER_AGENT","CA" ],
    seller_side_users: ["SELLER_POA","SELLER_AGENT"],
    add_user_origins: {
        manage_user: 'manage-users',
        seller_details: 'seller-details',
    },
    flow_list: {
        pay_platform_fee: 'Pay Platform Fee',
        initiate_offer: 'Initiate Offer',
        sign_offer: 'Sign Offer',
        initiate_mou: 'Initiate MOU',
        pay_token_amount: 'Pay Token Amount',
        sign_mou: 'Sign MOU',
        apply_loan: 'Apply Loan',
        initiate_sale_agreement: 'Initiate Sale Agreement',
        pay_agreement_fee: 'Pay Agreement Fee',
        sign_sale_agreement: 'Sign Sale Agreement',
        initiate_sale_deed: 'Initiate Sale Deed',
        pay_tds: 'Pay TDS',
        offer_letter: 'Offer Letter',
        sign_sale_deed: 'Sign Sale Deed'
    },
    property_types: {
        flat: 'Flat_Apartment',
        villa: 'Villa_Independent_house_Row_house',
        pent_house: 'Pent_house',
        empty_land: 'Empty_land',
    },
    template_types: {
        offer_letter: 'OFFER_LETTER',
        mou: 'MOU',
        agreement: 'SALE_AGREEMENT',
        sale_deed: 'SALE_DEED',
        document: 'DOCUMENT',
        email: 'EMAIL',
        sms: 'SMS',
    },
    password_validation: [
        'Must contain one lowercase character',
        'Must contain one uppercase character',
        'Must contain one number character',
        'Must contain one special character',
        'Must contain 8 characters'
    ],
    payment_types: {
        registration: "REGISTRATION",
        token: "TOKEN",
    },
    dispatch_actions: {
        logout: 'USER_LOGOUT',
    },
    exclude_folder_lawyer: [
        'PropertyImages',
    ],
    signing_option: {
        aadhaar: 'aadhaar',
        electronic: 'electronic'
    },
    rental_states: {
        karnataka : 'KA'
    },
    rental_roles : {
        owner : "owner",
        tenant : "tenant"
    },
    rental_agreement_type: {
        new : "new",
        renew: "renew"
    },
    rental_role_name : [ "OWNER", "TENANT", "AGENT"],
    rental_parties_action : {
        add : "add",
        remove : "remove",
        edit : "edit"
    },
    rental_stamp_value : [ "100", "200", "500" ],
    shcilestamp_link : "https://www.shcilestamp.com/",
    copyRight : "Copyright © 2020. Agarwal Estates. All rights reserved."
}