import React from "react";
import Main from "../../layout/Main";
import { useState, useEffect } from "react";
import { useHistory,useLocation } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Form from "react-bootstrap/Form";
import { TextField, Button, FormLabel, Grid, Box, InputAdornment } from "@mui/material";
import { Divider, Typography } from "antd";
import { useToasts } from "react-toast-notifications";

import { updateTds,getTds } from "./service";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import { addPropertyHistory, getPropertyDetails, updateProperty } from "../../services/api";
import Loader from "../../layout/Loader/Loader";
import { updateSubStage } from "../../utils/HelperFunction";
import { updateSelectedProperty } from "../user-home/slices";
import { ERROR_KEY, PID_KEY, SUCCESS_KEY, getErrorMessage, getSuccessMessage } from "../../constants";
import { Routes } from "../../constants/routes";
import { historyFormatter } from "../../utils/historyFormatter";

function UpdateTdsDetails() {
  const [ propertyId, setPropertyId ] = useState("");
  const [ isLoading, setLoading ] = useState(false);
  const role = useSelector((state) => state.loggedInUser.prefferedRole);
  
  const getId = useLocation().search;
  const searchParams = new URLSearchParams(getId);
  
  const dispatch = useDispatch();
  const [tdsAmount, setTdsAmount] = useState("");
  const [tdsCalculation, setTdsCalculation] = useState("");
  const [currentStage, setCurrentStage] = useState("");
  const subStages = useSelector((state)=> state?.stages?.subStages);
  const getSubStage = (order) => subStages.filter((substage)=> substage.order === order)[0]._id
  const history = useHistory();
  const user = useSelector((state) => state.loggedInUser.data.data.userByEmail);
  const userId = useSelector((state) => state.loggedInUser.data?.data?.userByEmail._id)
  const { addToast } = useToasts();
  const accessToken = useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";

  useEffect(() => {
    if (!accessToken) {
      history.push(Routes.HOME);
    }
    setLoading(true);
    const proId = searchParams.get(PID_KEY);
    setPropertyId(proId);    
    let args = {
      id:proId,      
    }
    dispatch(getTds(proId));    
    
    getPropertyDetails(proId)
    .then(async (res) => {
       let data = {};
       	if(!res.data.propertyData.ca){
          // dispatch tds with initial amount 0
        	args = { ...args, ca:{tds_amount:0,tds_instruction:''}};
        	
        	await dispatch(updateTds(args));
        	data = {tds_amount:0, tds_instruction:'',stage_name:''}
      	}
      	data = {...res.data.propertyData.ca, ...res.data.propertyData.current_stage};
	
      	setTdsAmount(data.tds_amount);
      	setTdsCalculation(data.tds_instruction);
      	setCurrentStage(data.stage_name);
        setLoading(false);
    })
    .catch(error => {
      addToast(getErrorMessage('E-10151'), { position: { X: 'Right', Y: "20" },appearance: ERROR_KEY ,autoDismiss: true})
      setLoading(false); 
    });
    
  }, [propertyId]);
  
  const handleTdsAmountChange = (e) => {
    const value = e.target.value;
    if (value === "" || (/^\d+$/.test(value) && parseInt(value) >= 0)) {
      setTdsAmount(value);
    }
  };

  const schema = yup.object({
    tdsAmount: yup
      .number()
      .typeError("TDS amount must be a number")
      .required("TDS amount is required")
      .min(0, "TDS amount must be greater than or equal to 0"),
  });
  
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const getSubStageUpdated = async (propertyId, substage)=>{
    try{
      const response = await updateSubStage({propertyId, substageId: substage, loggedInUserId:userId});
        if(response.status === 200){
          await dispatch(updateSelectedProperty(response.data.propertyData));
          addToast(getSuccessMessage('S-10019'), { appearance: SUCCESS_KEY, autoDismiss: true });
          history.push(Routes.PROPERTIES);
        }
      else addToast(getErrorMessage('E-10070'), { appearance: ERROR_KEY, autoDismiss: true });
  
      }catch(error){
        addToast(getErrorMessage('E-10111'), { appearance: ERROR_KEY, autoDismiss: true });
      }  
  }


  const onSubmit = (_data,event) => {
    event.preventDefault();
    setLoading(true);
    const args = {
      id: propertyId,
      ca: {
        tds_amount: Number(tdsAmount),
        tds_instruction: tdsCalculation,
      },
    };

    updateProperty(args).then(async ()=>{
      setTdsAmount(tdsAmount);
      setTdsCalculation(tdsCalculation);
      const historyData = historyFormatter(user, user._id, propertyId, 'has updated the TDS details.');
      addPropertyHistory(historyData);
      addToast(getSuccessMessage('S-10010'), { position: { X: 'Right', Y: "20" },appearance: SUCCESS_KEY, autoDismiss: true});
      await getSubStageUpdated(propertyId, getSubStage(17));
      setLoading(false);

    })
    .catch(error => {
      addToast(getErrorMessage('E-10151'), { position: { X: 'Right', Y: "20" },appearance: ERROR_KEY, autoDismiss: true})
      setLoading(false); 
    });
    
  };

  return (
    <div>
      <Main showUserNavbar={true} role={role} className="hello">
        <div className="container">
          <div className="fee-container">
            { isLoading
              ? <Loader/>
              : <div className="inner-container">
                  <div className="d-flex">
                    <Divider plain>
                    <Typography
                              variant="h3"
                              component="h3"
                              align="center"
                              sx={{ color: "var(--primaryColor)" }}
                            >TDS Update</Typography>
                    </Divider>
                  </div>
                  <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
                    <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center">
                      <Form.Group className="mb-3">
                        <Grid item xs={12}>
                          <FormLabel className="form-label">TDS to be paid :</FormLabel>
                          <TextField 
                          placeholder="TDS Amount"
                          className="col-12 custom-border"
                          name="tdsAmount"                          
                          value={tdsAmount}
                          {...register("tdsAmount")}
                          error={Boolean(errors.tdsAmount)}
                          onChange={handleTdsAmountChange} // Use the custom event handler
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">₹</InputAdornment>
                            ),
                          }}
                          />
                          <div className="login-errors">
                            {errors.tdsAmount ? (<>{errors.tdsAmount.message}</>
                            ) : null}
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel className="form-label">TDS Calculations :</FormLabel>
                          <Form.Control
                            as="textarea"
                            rows={10}
                            className="col-md-12 bg"
                            name="tdsCalculations"
                            value={tdsCalculation}
                            onChange={(e) => setTdsCalculation(e.target.value)}
                          />
                        </Grid>
                      </Form.Group>
                    </Grid>
                    <div className="text-center">
                      <Button variant="contained" type="submit" className="formBtns" 
                        style={{ mt: 3, mb: 2, backgroundColor: 'var(--primaryButton)' }}>
                          Update
                      </Button>
                    </div>
                  </Box>
                </div>
            }
          </div>
        </div>
      </Main>
    </div>
  );
}

export default UpdateTdsDetails;
