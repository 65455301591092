import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUserProperty } from "../../services/api";

export const getBuyerPropData = createAsyncThunk(
    "buyerHomePropertiesSlice/getBuyerPropData", async (userId, { rejectWithValue }) => {
      try {
        const { data } = await getUserProperty(userId);
        return data;
      } catch (e) {
        rejectWithValue(e.response.data);
      }
    }
  );
  