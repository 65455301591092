import * as yup from "yup";

export const loginSchema = yup.object({
      phone: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .test(
      "phone",
      "Phone number must be 10 digits",
      (value) => value && value.toString().length === 10)
      .nullable()
      .required("Enter your Phone No."),
  });
  