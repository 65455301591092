import {
  Accordion,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import "./style.scss";
import cross from "../../../assets/cross.svg";
import done from "../../../assets/done.svg";
import {
  AgreementDetails,
  PartyDetails,
  PropertyDetails,
  RentDetails,
} from "../../../commonComponent/rental-agreement";
import {
  defaultValues,
  ERROR_KEY,
  getErrorMessage,
  getSuccessMessage,
  SUCCESS_KEY,
} from "../../../constants";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { submitNewRentalAgreement } from "../service";
import { JsStyles } from "./jsStyle";
import { clearAll } from "../slices";
import { API } from "../../../utils/api";
import { Routes } from "../../../constants/routes";

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={JsStyles.font09} />}
    {...props}
  />
))(({ theme }) => JsStyles.accordionSum(theme));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) =>
  JsStyles.accordionDet(theme)
);

export const GenerateRentalAgreement = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [expanded, setExpanded] = useState(1);
  const [isReviewing, setIsReviewing] = useState(false);
  const [argData, setArgData] = useState({});
  const [pages, setPages] = useState([]);
  const [showTerms, setShowTerms] = useState(false);
  const [allCompleted, setAllCompleted] = useState(false);
  const [isClearAll, setIsClearAll] = useState(false);

  const selectedRental = useSelector(
    (state) => state?.rentalAgreement?.selectedRental
  );
  const userId = useSelector(
    (state) => {
      const user = state.loggedInUser?.data?.data?.userByEmail?._id || state.loggedInUser?.data?.data?.superAdminByEmail?._id
      return user
    }
  );

  useEffect(() => {
    if (selectedRental) {
      setArgData(selectedRental);
    }
  }, [selectedRental]);

  useEffect(() => {
    if (argData) {
      const pg = [
        {
          id: 1,
          title: "Rental Agreement Details",
          status: getStatusForm(1),
          comment: (
            <AgreementDetails
              onSave={saveAgreement}
              agreementData={argData}
              readOnly={isReviewing}
              isRenew={argData?.type === "renew"}
            />
          ),
        },
        {
          id: 2,
          title: "Owner Details",
          status: getStatusForm(2),
          comment: (
            <PartyDetails
              partyType={defaultValues.rental_roles.owner}
              onBack={() => setExpanded(1)}
              readOnly={isReviewing}
              onSaveList={(data) =>
                saveAgreement(
                  {
                    stageNo: argData?.stageNo === 3.1 ? 3.1 : 1.2,
                  },
                  true
                )
              }
            />
          ),
        },
        {
          id: 3,
          title: "Tenant Details",
          status: getStatusForm(3),
          comment: (
            <PartyDetails
              partyType={defaultValues.rental_roles.tenant}
              onBack={() => setExpanded(2)}
              readOnly={isReviewing}
              onSaveList={(data) =>
                saveAgreement(
                  {
                    stageNo: argData?.stageNo === 3.1 ? 3.1 : 1.3,
                  },
                  true
                )
              }
              argType={argData?.type}
              argData={selectedRental}
            />
          ),
        },
        {
          id: 4,
          title: "Property Details",
          status: getStatusForm(4),
          comment: (
            <PropertyDetails
              onBack={() => setExpanded(3)}
              onSave={saveAgreement}
              agreementData={argData}
              readOnly={isReviewing}
              isRenew={argData?.type === "renew"}
            />
          ),
        },
        {
          id: 5,
          title: "Rent Details",
          status: getStatusForm(5),
          comment: (
            <RentDetails
              onBack={() => setExpanded(4)}
              onSave={saveAgreement}
              agreementData={argData}
              readOnly={isReviewing}
              isRenew={argData?.type === "renew"}
            />
          ),
        },
      ];
      setPages(pg);
      const index = pg.findIndex((item) => !item.status);      
      if (index !== -1) {
        setAllCompleted(false);
      } else setAllCompleted(true);
    } 
      
  }, [argData, isReviewing]);

  const getStatusForm = (id) => {
    if (id === 1) {
      return argData?.signDate?.length > 0;
    } else if (id === 2) {
      return (
        argData?.rental_parties?.filter(
          (item) => item.type === defaultValues.rental_roles.owner
        ).length > 0
      );
    } else if (id === 3) {
      return (
        argData?.rental_parties?.filter(
          (item) => item.type === defaultValues.rental_roles.tenant
        ).length > 0
      );
    } else if (id === 4) {
      return argData?.propertyAddress?.length > 0;
    } else if (id === 5) {
      return argData?.licenseDate?.length > 0;
    }
      
  };

  const handleSave = () => {
    if (!isReviewing) {
      setIsReviewing(true);
      window.scrollTo(0, 0);
    } else {
      if (argData?.paymentDetails?.pay_status) saveAgreement({ stageNo: 3 });
      else if (argData?.stageNo === 3.1) saveAgreement({ stageNo: 3 });
      else setShowTerms(true);
    }
  };

  const handlePageChange = (page) => {
    setExpanded(page);
  };

  const handleClose = () => {
    setShowTerms(false);
  };

  const saveAgreement = (data, gotoNext) => {
    setIsReviewing(false);
    const _id = argData?._id || null;
    let payload = {};
    if (_id) {
      payload = isClearAll ? { _id, ...argData, ...data } : { _id, ...data };
    } else {
      var redirectUrl =
      `${API.redirectUrl[API.currenEnv]}` +
      `${Routes.SUBMIT_RENTAL_AGREEMENT}?id=`;

      payload = { ...data, createdBy: userId, redirectUrl };
    }

    dispatch(submitNewRentalAgreement(payload))
      .then((res) => {
        if (res.payload.data?.status) {
          addToast(res.payload.data?.message, {
            appearance: SUCCESS_KEY,
            autoDismiss: true,
          });
          if (gotoNext) {
            setExpanded((old) => parseInt(old) + 1);
          }
          setIsClearAll(false);
        }
      })
      .catch((err) => {
        addToast(err?.response?.data?.message || getErrorMessage("E-10070"), {
          appearance: ERROR_KEY,
          autoDismiss: false,
        });
      });
  };

  const handelClearAll = () => {
    dispatch(clearAll(argData));
    setIsClearAll(true);
    setIsReviewing(false);
    setExpanded(1);
  };

  return (
    <div className="r-page">
      <div className="r-page-container">
        {isReviewing && (
          <div className="row">
            <div className="col-md-12 text-center">
              <p className="badge badge-success message">
                {getSuccessMessage("S-10046")}
              </p>
            </div>
          </div>
        )}
        <div className="page-body">
          {pages.map((item) => {
            return (
              <Accordion
                onChange={() => handlePageChange(item.id)}
                key={item.id}
                expanded={expanded === item.id || isReviewing}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <div className="accordion-heading row align-items-center justify-content-between">
                    <Typography className="col-auto acc-title mr-auto">
                      {item.title}
                    </Typography>
                    <div className="col-auto status">
                      <img
                        className="pic"
                        src={item.status ? done : cross}
                        alt="nav-ic"
                      />
                      <div className="acc-title">
                        {item.status ? "Completed" : "Incomplete"}
                      </div>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails key={item.id}>
                  {item.comment}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="proceed-button my-3 text-center">
              {isReviewing && (
                <Button
                  className="btn-outline-secondary px-5 mx-3 r-backBtn mt-3"
                  variant="outlined"
                  onClick={() => {
                    setExpanded(1);
                    setIsReviewing(false);
                  }}
                >
                  Back to Edit
                </Button>
              )}
              {!argData.document_data &&
                !isReviewing &&
                !argData.paymentDetails?.tra_id && (
                  <Button
                    className="btn-outline-secondary px-5 mx-3 r-backBtn mt-3"
                    variant="outlined"
                    onClick={handelClearAll}
                  >
                    Clear all
                  </Button>
                )}
              {allCompleted && (
                <Button
                  className="button px-5 mt-3 mx-3"
                  variant="primary"
                  onClick={handleSave}
                >
                  {isReviewing ? "Submit" : "Review"}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>

      <Dialog open={showTerms} key="showTerms">
        <DialogTitle id="responsive-dialog-title">
          Important Notice:
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText className="text-justify">
            Our real-time eStamping process ensures instant payment, which
            provides an eStamp challan only, which serves as a proof of payment
            to the government.
            <br />
            <br />
            Kindly ensure that the entered stamp paper amount complies with your
            state’s legal requirements for your rental agreement. Your lawyer
            can advise you on the appropriate stamp duty amount required.
            ZeroChaos is not liable for the eStamp amount paid to the
            government, and once purchased, the money is non-refundable. You can
            verify the authenticity of the stamp duty paid by visiting the SHCIL
            website at &nbsp;
            <Link
              onClick={() =>
                window.open(defaultValues.shcilestamp_link, "_blank")
              }
            >
              www.shcilestamp.com
            </Link>            
            <br />
            <br />
            If you require a physical stamp paper, you can call us at +91 70900
            11220. Please note that the same will be dispatched approximately 50
            days after your purchase. The eStamp paper is generated by the Stock
            Holding Corporation of India on the 10th of the following month.
            Subsequently, it takes an additional 10-20 days for the papers to
            reach us before dispatching them to you.
            <br />
            <br />
            P.S.: The rental agreement merged with the eStamp challan is legally
            valid only when the correct stamp duty amount, as per state
            regulations, has been purchase
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={JsStyles.dialogActions}>
          <Button onClick={handleClose} variant="outlined" color="error">
            Cancel
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              handleClose();
              saveAgreement({ stageNo: 2 });
            }}
          >
            Accept
          </Button>
          &nbsp;
        </DialogActions>
      </Dialog>
    </div>
  );
};
