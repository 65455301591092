import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button, Dialog, DialogTitle, DialogActions } from "@mui/material";
import { useToasts } from "react-toast-notifications";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { addSelectedProperty } from "../../pages/user-home/slices";
import './style.scss'
import { updatePreferredRole } from "../../pages/login/slices";

import {
  getManagePropData,
} from "../../pages/super-admin-manageProperties/service";

import "../../pages/super-admin-manageProperties/style.scss"
import { deleteProperty, validateStage, waveFee } from "../../services/api";
import { getErrorMessage, getSuccessMessage } from "../../constants/getMessages";
import { ERROR_KEY, PROPERTY_ID_KEY, SUCCESS_KEY, defaultValues } from "../../constants";
import { Routes } from "../../constants/routes";

const theme = createTheme({
  palette: {
    white: {
      main: 'var(--white)',
      contrastText: 'var(--darkBlack)',
    },
  },
});

const waiverModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  background:'var(--primaryColor)'
};

const contentContainerStyle = {
  border: 3,
  borderStyle: 'solid',
  borderColor: 'var(--primaryColorLight)',
  borderRadius: 10  ,
  display: 'flex',
  width: '96%',
  marginLeft: '2%',
  marginRight: '2%',
  paddingTop: 2,
  marginTop: 4,
}


const PropertyBox = ({ data, callBack }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showWaiverDialog, setShowWaiverDialog] = useState(false);
  const [waiveOffPercentage, setWaiveOffPercentage] = useState("");
  const [waivePlatformFeeLoader, setWaivePlatformFeeLoader] = useState(false);
  // this will be used to identify admin and super admin and enable registration cokmpleted btn for them
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const { addToast } = useToasts();

  const history = useHistory();
  const dispatch = useDispatch();
  const [userId, setUserId] = useState(null);
  const [propertyUserId, setPropertyUserId] = useState("");
  const [userData, setUserData] = useState(null);
  const role = useSelector((state) => state.loggedInUser.preferredRole);
  const stages = useSelector((state) => state?.stages?.stages) || [];
  const user = useSelector((state) => state.loggedInUser);
  const allRoles = useSelector((state) => state.roles.roles || []);
  const subStages = useSelector((state) => state?.stages?.subStages) || [];
  const buyerRoleId = allRoles.find(
    (data) => data.role_name === defaultValues.role_names.buyer
  )?._id;
  const sellerRoleId = allRoles.find(
    (data) => data.role_name === defaultValues.role_names.seller
  )?._id;
  const getSubStageOrder = (id) =>
    subStages.filter((substage) => substage._id === id)[0]?.order;
  const currentStages = stages?.find(
    (stage) => stage?._id === data?.current_stage
  );
  const currentStageOrderNumber = currentStages?.order_number;
  useEffect(() => {
    if(user) {
      if (user?.preferredRole === defaultValues.role_names.super_admin) {
        setUserId(user?.data?.data?.superAdminByEmail?._id);
        setUserData(user?.data?.data?.superAdminByEmail);
        setIsSuperAdmin(true);
      } else if (user?.preferredRole === defaultValues.role_names.admin){
        setUserId(user?.data?.data?.adminByEmail?._id);
        setUserData(user?.data?.data?.adminByEmail);
        setIsAdmin(true);
      } else {
        setUserData(user?.data?.data?.userByEmail);
      }
    }
  }, [user]);

  const onChange = (id, property_parties_details) => {
    sessionStorage.setItem(PROPERTY_ID_KEY, id);
    dispatch(addSelectedProperty(data));
    if( !isSuperAdmin && !isAdmin) {
      if(property_parties_details){
        const filtered = property_parties_details
        .filter(user => user?.user_id?._id === userData._id);    
        dispatch(updatePreferredRole(filtered[0]?.role.role_name));    
      }    
    }
    history.push(`${Routes.VIEW_PROPERTY}?pid=${id}`);
  };



  const onDelete = (id) => {
    setShowConfirmation(false);
    deleteProperty(id)
      .then((res) => {
        if (res.data.status) {
          addToast(`${getSuccessMessage("S-10014")} - ${id}`, {
            appearance: SUCCESS_KEY,
            autoDismiss: true,
          });
          dispatch(getManagePropData());
        } else if (!res.data.status) {
          addToast(res.data.message, {
            appearance: ERROR_KEY,
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        addToast(getErrorMessage("E-10070"), {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
      });
  };

  const handleDeleteClick = () => {
    setShowConfirmation(true);
  };

  const handleClose = () => {
    setShowConfirmation(false);
  };

  const handleFeeWaiver = async (propertyId)=>{
    try {
      setWaivePlatformFeeLoader(true);
      const payload = {
        propertyId,
        userId,
        waiveOffPercentage: parseInt(waiveOffPercentage)
      }
      const response = await waveFee(payload)
      if(response.status === 200){
        if(response.data.success){
          addToast(response.data.message, { appearance: SUCCESS_KEY, autoDismiss:true });
        }else{
          addToast(response.data.message, { appearance: ERROR_KEY, autoDismiss:true });
        }
        setWaivePlatformFeeLoader(false);
        handleWaiverDialogClose();
        setWaiveOffPercentage('')
      }
    } catch (error) {
      addToast(getErrorMessage("E-10070"), { appearance: ERROR_KEY, autoDismiss:true });
      setWaivePlatformFeeLoader(false);
    }
  }

  const handleWaiverchange = (e)=>{
      if(parseInt(e.target.value) <= 100 || e.target.value===''){
        setWaiveOffPercentage(e.target.value)
      }
  }

  const handleDiscountValue = (e)=>{
    const accepetedChar = ['0','1','2','3','4','5','6','7','8','9','Backspace']
      if(!accepetedChar.includes(e.key)){
        e.preventDefault()
      }
      if(!e.target.value && ['0', 'Backspace'].includes(e.key) ){
        e.preventDefault()
      }
      if(e.target.value.length===3 && ['.'].includes(e.key)){
        e.preventDefault()
      }
  }
    
  useEffect(() => {
    if(data?.property_parties_details){
      const filtered = data?.property_parties_details
        .filter(user => user?.user_id?._id === userData?._id);    
        setPropertyUserId(filtered[0]?.role.role_name);
    }
  },[data, userData?._id]);

  const formatFlatNo = (flatNo) => {
    if (flatNo?.length > 5) {
      return flatNo?.slice(0, 5) + "...";
    }
    return flatNo;
  };

  const currentStage = stages?.find(
    (stage) => stage?._id === data?.current_stage
  );

  const stageName = currentStage ? currentStage.stage_name : null;

  const seller = data?.property_parties_details
    .map((user) => {      
      return user?.role?._id === sellerRoleId
        ? `${user?.user_id?.first_name} ${user?.user_id?.last_name}, ` 
        : null;
      }).join("").slice(0, -2);

  const sellerName = seller ? seller : null;

  const buyer = data?.property_parties_details
    .map((user) => user?.role?._id === buyerRoleId
    ? `${user?.user_id?.first_name} ${user?.user_id?.last_name}, `
    : null).join("").slice(0, -2);

  const buyerName = buyer ? buyer : null;

  const handleWaiverDialogOpen = () => {
    setShowWaiverDialog(true);
  };

  const handleWaiverDialogClose = () => {
    setShowWaiverDialog(false);
  };

  const registrationCompletion = useCallback(async() => {
    await validateStage({propertyId: data._id, roleId: ''}).then(res => {
      addToast(getSuccessMessage("S-10015"), {appearance: SUCCESS_KEY, autoDismiss: true});
      callBack();
    }).catch(error => {
      addToast(getErrorMessage("E-10070"), {appearance: ERROR_KEY, autoDismiss: true});
    })
  }, [addToast, callBack, data._id]);

  return (
    <Row style={contentContainerStyle}>
      <Col xs={12} md={2} className="imageContainer">
        <Image
          src="/imgs/interior.jpg"
          alt="addProp"
          style={{ height: 120, width: "100%", borderRadius: 25 }}
        />
      </Col>
      <Col xs={12} md={3} className="fieldsContainer">
        <p>
          <span className="labelArea">ID No:</span> <span>{data?.re_id}</span>
        </p>
        <p>
          <span className="labelArea">Seller: </span>
          <span>{sellerName}</span>
        </p>
        <p>
          <span className="labelArea">Current Stage: </span>
          <span>{stageName}</span>
        </p>
      </Col>
      <Col xs={12} md={4} className="fieldsContainer">
        <p>
          <span className="labelArea">Property type:</span>{" "}
          {data.PropertyType === "Villa_Independent_house_Row_house"
            ? "Villa/Independent House/Row House"
            : data.PropertyType === "Pent_house"
            ? "Pent House"
            : data.PropertyType?.replaceAll("_", "/")}
        </p>
        <p>
          <span className="labelArea">Buyer: </span>
          <span>{buyerName}</span>
        </p>
        <p>
          <span className="labelArea">Listed Date: </span>{" "}
          {new Date(data?.created_at).toLocaleDateString()}
        </p>
      </Col>
      <Col xs={12} md={3} className="fieldsContainer">
        <p>
          <span className="labelArea">
            {data.PropertyType === defaultValues.property_types.villa
              ? "Villa no:"
              : data.PropertyType === defaultValues.property_types.pent_house
              ? "Pent House no:"
              : "Flat no:"}
          </span>
          {formatFlatNo(data.flat_no)}
        </p>
        <span
          className="user-type-display"
          style={{ right: "2%", top: "2%", position: 'absolute' }}
        >
          {isAdmin || isSuperAdmin ? role : propertyUserId}
        </span>
        <div className="d-flex"></div>
        <div className="d-flex justify-content-end">
          <Button
            variant="text"
            sx={{
              textTransform: "none",
              color: "var(--primaryColor)",
              ":hover": { fontSize: "18px" },
            }}
            onClick={() => onChange(data._id, data.property_parties_details)}
          >
            View More
            <ArrowForwardIcon />
          </Button>
        </div>
        <div className="d-flex justify-content-end">
          {role === defaultValues.role_names.admin &&
            currentStageOrderNumber === 1 && (
              <Button
                variant="contained"
                sx={{ textTransform: "none", bgcolor: "var(--primaryColor)" }}
                onClick={handleWaiverDialogOpen}
              >
                {" "}
                Waive-Off
              </Button>
            )}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showWaiverDialog}
            onClose={handleWaiverDialogClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={showWaiverDialog}>
              <Box sx={waiverModalStyle}>
                <div className="waiverWrapper">
                  <div className="confirmationText">
                    Are you sure You want to waive platform fees? If yes, Please
                    enter the discount percentage.
                  </div>
                  <div className="textField">
                    <ThemeProvider theme={theme}>
                      <TextField
                        color="white"
                        type="text"
                        size="medium"
                        id="outlined-basic"
                        label="Discount"
                        variant="outlined"
                        value={waiveOffPercentage}
                        onChange={handleWaiverchange}
                        onKeyDown={handleDiscountValue}
                      />
                    </ThemeProvider>
                    <span className="percent">%</span>
                  </div>
                  <div className="waiverButton">
                    <ThemeProvider theme={theme}>
                      <LoadingButton
                        size="medium"
                        loading={waivePlatformFeeLoader}
                        variant="contained"
                        color="white"
                        onClick={() => {
                          waiveOffPercentage && handleFeeWaiver(data._id);
                        }}
                      >
                        <span>Waive Off</span>
                      </LoadingButton>
                    </ThemeProvider>
                  </div>
                </div>
              </Box>
            </Fade>
          </Modal>
          <div className="d-flex">
            {isSuperAdmin && (
              <Button
                variant="contained"
                sx={{ textTransform: "none", bgcolor: "var(--primaryButton)","&:hover": { bgcolor: "var(--primaryButton)"} }}
                onClick={handleDeleteClick}
              >
                Delete
              </Button>
            )}
            {(isSuperAdmin || isAdmin) &&
              currentStage?.order_number === 5 &&
              getSubStageOrder(data?.sub_current_stage) === 20 && (
                <Button
                  variant="contained"
                  sx={{ textTransform: "none", bgcolor: "var(--primaryButton)","&:hover": { bgcolor: "var(--primaryButton)"} }}
                  onClick={registrationCompletion}
                  style={{ marginLeft: 15 }}
                >
                  {defaultValues.actions.complete_registration}
                </Button>
              )}
          </div>
        </div>
      </Col>
      <Dialog open={showConfirmation} onClose={handleClose}>
        <DialogTitle
          sx={{ background: "var(--primaryColor)", color: "var(--white)" }}
        >
          {"Are you sure you want to delete this property?"}
        </DialogTitle>
        <DialogActions sx={{ background: "var(--primaryColor)" }}>
          <Button
            onClick={handleClose}
            sx={{
              color: "var(--white)",
              "&:hover": {
                backgroundColor: "var(--white)",
                color: "var(--drakBlack)",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => onDelete(data._id)}
            sx={{
              color: "var(--white)",
              "&:hover": {
                backgroundColor: "var(--white)",
                color: "var(--red)",
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Row>
  );
};

export default PropertyBox;
