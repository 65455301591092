import { createSlice } from "@reduxjs/toolkit";
import { storeDocument, postGenerateApi } from "./service";
import { ERROR_KEY, SUCCESS_KEY } from "../../constants";
const initialState = {
  value: 0,
  data: [],
  offerLetterUrl:''
};

export const initiateOfferSlice = createSlice({
  name: "initiateOfferSlice",
  initialState,
  reducers: {
    removeDocs: (state, action)=>{
      return initialState
    },
  },
  extraReducers: {
    [postGenerateApi.pending]: (state, action) => {
      return {
        ...state,
        addPropMessage: "",
        addPropStatus: null,
        addPropertyData: []
      }
    },

    [postGenerateApi.fulfilled]: (state, action) => {
      if (action?.payload?.createPropertyData?.HTMLParsedData) {
        return {
          ...state,
          addPropMessage: "Offer Generated Successfully",
          addPropStatus: SUCCESS_KEY,
          addPropertyData: action.payload.createPropertyData.HTMLParsedData
        }
      } else {
        return {
          ...state,
          addPropMessage: "Unable to generate",
          addPropStatus: ERROR_KEY
        }
      }
    },
    [storeDocument.pending]: (state, action) => {
      return {
        ...state,
        addPropMessage: "",
        addPropStatus: null,
        addPropertyData: []
      }
    },

    [storeDocument.fulfilled]: (state, action) => {
      if (action) {
        return {
          ...state,
          offerLetterUrl: action.payload
        }
      } else {
        return {
          ...state,
          addPropMessage: "Unable to generate",
          addPropStatus: 'Failure'
        }
      }
    },
  },
});

export const { removeDocs } = initiateOfferSlice.actions;


export default initiateOfferSlice.reducer;
