import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";
import { singUpUser } from "./service";
import { ERROR_KEY, SUCCESS_KEY } from "../../constants";
const initialState = {
  value: 0,
  data:[],
  message: ""
};

export const superAdminAddUserSlice = createSlice({
  name: "superAdminAddUserSlice",
  initialState: {},
  reducers: {
   
  },
  extraReducers: {
    ["CLEAR_STORE"]: () => initialState,

    [singUpUser.pending]: (state, action) => {
      return {
        ...state,
        message : "",
        status : null,
        data:[]
      }  
    },
    [singUpUser.fulfilled] : (state, action)=> {
      if(action?.payload?.data){
        return {
          ...state,
          message : "Successfully Added",
          status : SUCCESS_KEY,
          data: action.payload.data
        }  
      }
    },

    [singUpUser.rejected] : (state, action)=> {      
      return {
        ...state,
        message : action.payload.message,
        status : ERROR_KEY
      }
    },

    [singUpUser.pending]: (state, action) => {      
      return {
        ...state,
        message: "",
        status: null,
        data: []
      };
    },

  },
});

export const { increment } = superAdminAddUserSlice.actions;

export default superAdminAddUserSlice.reducer;
