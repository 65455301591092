import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postData } from "./service";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useHistory, useLocation } from "react-router-dom";
import LandingFooter from "../general-landing-page/LandingFooter";
import GeneralNav from '../general-landing-page/GeneralNav'
import { useToasts } from "react-toast-notifications";


import "./style.scss";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  InputLabel,
  FormControl,
  CssBaseline,
  Button,
  TextField,
  Grid,
  Typography,
  Container,
  Box,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {
  ACCESS_TOKEN_KEY,
  ERROR_KEY,
  ROLE_KEY,
  SUCCESS_KEY,
  defaultValues,
  getErrorMessage,
  getSuccessMessage,
} from "../../constants";
import { Routes } from "../../constants/routes";
import { store } from "../../redux/store";

function Login() {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.loggedInUser);
  const role = useSelector((state) => state.loggedInUser.preferredRole);
  const [loginRequest,updateLoginRequest] = useState(false);
  const loading = useSelector((state) => state.loggedInUser.loading);
  const history = useHistory();
  const location = useLocation();
  const { addToast } = useToasts();
  const [showPassword, setShowPassword] = useState(false);
  const errorMessage = useSelector((state) => state.loggedInUser.loginerrormessage)
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    store.dispatch({ type: "USER_LOGOUT" });
  }, []);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  useEffect(() => {
    if (!loading && loginRequest) {
			if (loggedInUser.data?.data?.accessToken) {
        updateLoginRequest(false);
				addToast(getSuccessMessage("S-10001"), {
					appearance: SUCCESS_KEY,
					autoDismiss: true,
				});
				sessionStorage.setItem(ACCESS_TOKEN_KEY, loggedInUser.data.data.accessToken);
				sessionStorage.setItem(ROLE_KEY, role);
				if (role === defaultValues.role_names.super_admin || role === defaultValues.role_names.admin) {
					history.push(Routes.DASHBOARD);
				} else {
					history.push(Routes.PROPERTIES);
				}
			} else {
        addToast("Invalid username or password", {
					appearance: "error",
					autoDismiss: true,
				});
        updateLoginRequest(false);
			}
		}

  }, [addToast, errorMessage, history, loading, loggedInUser, loginRequest, role]);
  const loginSchema = yup.object({
    email: yup
      .string()
      .email(getErrorMessage('E-10008'))
      .required(getErrorMessage('E-10029')),
    password: yup.string().required(getErrorMessage('E-10063')).min(6),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
    mode: "onBlur"
  });

  const onSubmit = async (_data, e) => {
    e.target.reset();
    _data.path = location.pathname
    dispatch(postData(_data));
    updateLoginRequest(true);
  };

  let urlRoleMap = {
    '/super-admin-login': defaultValues.role_names.super_admin,
    '/admin-login' :  defaultValues.role_names.admin,
    [Routes.HOME] : defaultValues.role_names.user,
  }


  return (
    <>
    <GeneralNav />
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 11,
          marginBottom:14,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h2" variant="h5" sx={{ mt: 4 }}>
          {defaultValues.screenTitles.sign_in}
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        </Box>
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                {...register("email")}
                error={Boolean(errors.email)} />
              <div className="login-errors">
                {errors.email ? (<>{errors.email.message}</>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  type={showPassword ? "text" : "password"}
                  id="password"
                  label="Password"
                  variant="outlined"
                  name="password"
                  {...register("password")}
                  error={Boolean(errors.password)}
                  endAdornment={<InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>} />
                <div className="login-errors">
                  {errors.password ? (<>{errors.password.message}</>
                  ) : null}
                </div>
                <Link to={`${Routes.FORGOT_PASSWORD}?user=${urlRoleMap[location.pathname]}`} className="forgot-link">
                  Forgot Password?
                </Link>
              </FormControl>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={{background: 'var(--primaryButton)'}}
            sx={{ mt: 3, mb: 2, bgcolor: 'var(--primaryButton)' }}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
    <LandingFooter />
    </>
  );
}
export default Login;
