/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getData, updateData } from "./service";
import { useHistory } from "react-router-dom";

import Main from "../../layout/Main";
import AddAdminModal from "./addModal";
import EditAdmin from "./editModal";

import circlePlus from "../../assets/circle-plus.svg";

import "./style.scss";
import { AdminCard } from "./adminCard";
import { Routes } from "../../constants/routes";
import { ROLE_KEY, defaultValues } from "../../constants";

const Page = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentEdit, setCurrectEdit] = useState({});
  const [editModal, setEditModal] = useState(false);
  const dispatch = useDispatch();
  const role = sessionStorage.getItem(ROLE_KEY);
  const adminList = useSelector((state) => state.admins);
  const history = useHistory();

  const accessToken = useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";

  useEffect(() => {
    if (!accessToken) {
      history.push(Routes.SUPER_ADMIN_LOGIN);
    }
    if (role !== defaultValues.role_names.super_admin && role !== defaultValues.role_names.admin) {
      props.history.push(Routes.HOME);
    }
  }, [accessToken, history]);

  useEffect(() => {
    dispatch(getData());
  }, [dispatch]);


  return (
    <Main
      showNav={!props?.showNav}
      showFooter={!props?.showFooter}
      showLogo={!props?.showLogo}
      showAdminFooter={!props?.showAdminFooter}
      showAdminNavbar={!props?.showAdminNavbar}
    >
      <div className="plus-area">
        <div className="plus-btn-wrapper">
          <img
            src={circlePlus}
            className="plus-btn-img"
            onClick={() => setOpenModal(true)}
          />
        </div>

        <div className="container-admin-list">
          <div>
            <AddAdminModal
              isOpen={openModal}
              onClose={() => {
                setOpenModal(false);
              }}
            />
            <EditAdmin
              isOpen={editModal}
              item={currentEdit}
              onClose={() => {
                setEditModal(false);
              }}
              onSubmit={(data) => {
                dispatch(updateData(data)).then((res)=>{
                  dispatch(getData())
                });
              }}
            />
          </div>

          {adminList.adminData?.length > 0 &&
            adminList.adminData.slice().reverse().map((ele) => {
              return (
                <AdminCard ele={ele} setCurrectEdit={setCurrectEdit} setEditModal={setEditModal} />
              );
            })}
        </div>
      </div>
    </Main>
  );
};
export default Page;
