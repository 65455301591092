import React, { useState, useEffect } from "react";
import "./style.scss";
import { Form } from "react-bootstrap";
import {  useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import {
  TextField,
} from "@mui/material";
import Loader from "../../layout/Loader/Loader";
import { addAdminSchema  } from "../../utils/validationSchemas";
import { useFormik } from "formik";
import {  updatePercentage, getUserById } from "../../services/api";
import { ADMIN_KEY, SUPER_ADMIN_KEY, USER_KEY, defaultValues, SUCCESS_KEY, ERROR_KEY } from "../../constants";
import { useToasts } from "react-toast-notifications";
import { getErrorMessage, getSuccessMessage } from "../../constants";
const initialValues = {
  first_name: "",
  last_name: "",
  email_id: "",
  phone: "",
  primary_role : "",
  select_role : "",
  percent_share : 0,
  role: ""
};

const EditUser = ({
  setIsDisplay,
  isDisplay,
  viewProperty,
  origin,
  userId,
  roleName,
  editUserRole
}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [selectOption, setSelectOption] = useState("");
  const [error, setError] = useState(false);
  const roleNames = useSelector((state)=> state.loggedInUser?.selectedRole?.role_name);
  const [getShareUsers, setGetShareUsers] = useState([]);
  const [balShareValue, setBalShareValue] = useState(0);
  
  const { addToast } = useToasts();
  let loggedInUser = USER_KEY;
  if(roleNames === defaultValues.role_names.super_admin){
    loggedInUser = SUPER_ADMIN_KEY
  }else if(roleNames === defaultValues.role_names.admin){
    loggedInUser =  ADMIN_KEY
  }else{
    loggedInUser =  USER_KEY
  }

  const editUser = async () => {
    try {
      setIsLoading(true);

      const payload = {
        propertyId: viewProperty?._id,
        userId: userId,
        percentage: values.percent_share,
      };

      const response = await updatePercentage(payload);

      if (response.status === 200) {
        addToast(getSuccessMessage('S-10008'), { appearance: SUCCESS_KEY, autoDismiss: true });
        setIsLoading(false)
        setIsDisplay(false);
      } else {
        addToast(getErrorMessage('E-10009'), { appearance: ERROR_KEY, autoDismiss: true });
      }
    } catch (error) {
      addToast(getErrorMessage('E-10009'), { appearance: ERROR_KEY, autoDismiss: true });
    } finally {
      setIsLoading(false);
    }
  };
  const { values, touched, errors, setFieldValue } =  useFormik({
    initialValues : initialValues,
    validationSchema : addAdminSchema(balShareValue),
    validateOnChange: true,
    validateOnBlur : true,    
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userDetails = await getUserById(userId);
        
        // Set form field values based on the retrieved user details
        setFieldValue("first_name", userDetails?.data.data.first_name);
        setFieldValue("last_name", userDetails?.data.data.last_name);
        setFieldValue("email_id", userDetails?.data.data.email_id);
        setFieldValue("phone", userDetails?.data.data.phone);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };
    if (userId) {
      fetchUserDetails();
    }
  }, [userId, setFieldValue, viewProperty]);

  useEffect(() => {
  
    if (editUserRole === defaultValues.role_names.buyer) {
      const user = viewProperty.buyers_share.find(user => {
        return user.buyer_id._id === userId
      })
      if (user) {
        setFieldValue("percent_share", user.percentage_share || 0);
      }
    } else if (editUserRole === defaultValues.role_names.seller) {
      const user = viewProperty.sellers_share.find(user => {
        return user.seller_id._id === userId
      })
      if (user) {
        setFieldValue("percent_share", user.percentage_share || 0);
      }
    }
  }, [viewProperty, editUserRole, userId, setFieldValue, touched.percent_share]);

  const isBuyerOrSeller = (
    roleName === defaultValues.role_names.buyer ||
    roleName === defaultValues.role_names.seller
  );
  if (!isBuyerOrSeller) {
    return null;
  }


  return (
    <Modal
      size="md"
      show={isDisplay}
      onHide={() => setIsDisplay(false)}
      aria-labelledby="example-modal-sizes-title-lg"
      className="editProperty"
      centered
    >
      {
      isLoading ?       
        <Loader/> 
        : 
      (
        <div className="add-form">
          <div className="add-user-form">
            <div className="close-btn"></div>
            <>
              <h1 className="assignAdd">User Details</h1>
              <Form>
                <div className="add-admin-column">
                  <div>
                    <TextField
                      fullWidth
                      name="first_name"
                      id="First Name"
                      label="First Name"
                      variant="outlined"
                      autoComplete="off"
                      disabled = {true}
                      type="text"
                      value = {values.first_name} 
                      style={{ color: 'white' }}                    
                      />
                      {touched.first_name && errors.first_name ? (
                        <p className="formError">{errors.first_name}</p>
                      ) : null }
                    </div>
                    <div>
                      <TextField
                        fullWidth
                        name="last_name"
                        id="last name"
                        label="Last name"
                        variant="outlined"
                        autoComplete="off"
                        disabled = {true}
                        type="text"
                        value = {values.last_name}
                        style={{ color: 'white' }}                 
                        />
                        {touched.last_name && errors.last_name ? (
                          <p className="formError">{errors.last_name}</p>
                        ) : null }
                    </div>
                </div>
                <div>
                  <TextField
                    fullWidth
                    name="email_id"
                    id="Email-ID"
                    label="Email-ID"
                    variant="outlined"
                    autoComplete="off"
                    disabled = {true}
                    type="text"
                    value = {values.email_id}  
                    style={{ color: 'white' }}              
                    />
                    {touched.email_id && errors.email_id ? (
                      <p className="formError">{errors.email_id}</p>
                    ) : null }
                  </div>
                  <div>
                    <TextField
                      fullWidth
                      type="number"                
                      name="phone"
                      id="Phone no"
                      label="Phone No."
                      variant="outlined"
                      autoComplete="off"
                      value = {values.phone}
                      style={{ color: 'white' }}
                      disabled = {true}                 
                      />
                      {touched.phone && errors.phone ? (
                        <p className="formError">{errors.phone}</p>
                      ) : null }
                  </div>
                <div className="add-admin-column">

                  <div>
                      <>
                      <TextField
                        fullWidth
                        required
                        id="Percent"
                        label="Percent Share"
                        variant="outlined"
                        name="percent_share"
                        type="number"
                        value={values.percent_share}
                        InputProps = {{
                          max: 100,
                          min: 0,
                        }}
                        onChange={(event) => {
                          const data = event.target.value;
                          if(data <= 100){
                            setFieldValue("percent_share", data);
                          }
                        }}                      
                        />
                        {touched.percent_share && errors.percent_share && 
                          <p className="formError">{errors.percent_share}</p>
                        }
                      </>
                    {/* )} */}
                  </div>
                </div>
                <div className="add-admin-column">
                  <div>
                    {(selectOption === defaultValues.role_names.seller || selectOption === defaultValues.role_names.buyer) && origin !== defaultValues.add_user_origins.manage_user && (
                      <div className="col">
                        {getShareUsers.map(name =>
                          <p className="formError">{name}</p>
                        )}
                        <p className="formError">Balance : {balShareValue}%</p>
                      </div>
                    )}
                    </div>
                </div>
                <div className="add-btn ml-5">
                  <div className="ml-3"></div>
                  <button type="submit" className={`btn-lg pull-left custom-offset ${error ? "not-allowed" : "add-admin-btn"}`}
                    disabled={isLoading}
                    onClick={(e) => {
                      editUser();
                    }
                    }>
                    Save
                  </button>
                  <div className="ml-3"></div>
                  <button 
                  type="reset" 
                  className="btn-lg pull-left custom-offset add-admin-btn" 
                  onClick={() => {
                    setIsDisplay(false);
                  }}>
                    Cancel
                  </button>
                  <div className="ml-3"></div>
                </div>
              </Form>
            </>
          </div>
        </div>
      )}
    </Modal>
  )
};
export default EditUser;
