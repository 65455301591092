import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import Main from "../../layout/Main";
import "../../pages/review-mou/style.scss";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import Loader from "../../layout/Loader/Loader";
import { getPropertiesData, stageValidation } from "../../pages/super-admin-manageProperties/service";
import { useToasts } from "react-toast-notifications";
import { postGenerateApi } from "../../pages/initiate-offer/service";
import { useHistory } from "react-router-dom";
import { initiateE_Stamp, initiateSigning } from "../../utils/signed";
import {updateSubStage} from "../../utils/HelperFunction";
import {addSelectedProperty, updateSelectedProperty} from "../../pages/user-home/slices"
import { addPropertyHistory, getDocByPropId, getPropertyDetails, getSignedDoc, releasePayment, storeDoc, updateVerifyStatus } from "../../services/api";
import { API } from "../../utils/api";
import { CustomEditor } from "../../utils/CustomEditor";
import { getErrorMessage, getSuccessMessage } from "../../constants/getMessages";
import { Routes } from "../../constants/routes";
import MessageModel from "../../layout/MessageModel";
import { ACCESS_TOKEN_KEY, ERROR_KEY, PROPERTY_ID_KEY, SUCCESS_KEY, defaultValues } from "../../constants";
import { historyFormatter } from "../../utils/historyFormatter";
import { primaryButtonStyle } from "../../styles/commonstyles";

const InitiateOffers= ({ props,templateSubType, templateType, heading,subheading, buttonText, proceedUrl }) => {
  const [signedPdfData, setSignedPdfData] = useState('');
  const dispatch = useDispatch();

  const history = useHistory();

  const propertyId = useSelector((state) => state?.buyerHomeData?.selectedProperty?._id) || '';
  const preferredRole = useSelector((state) => state.loggedInUser.preferredRole);
  const selectedProperty = useSelector((state)=> state?.buyerHomeData?.selectedProperty) || {};
  const {document_meta_data:{ down_payment_amount , offered_purchase_price , balance_due} = {}} = selectedProperty;
  const subStages = useSelector((state)=> state?.stages?.subStages);
  const roleId = useSelector((state)=> state.loggedInUser?.selectedRole?._id);
  const userId = useSelector((state) => state.loggedInUser.data.data?.userByEmail._id);
  const user = useSelector((state) => state.loggedInUser.data.data?.userByEmail);
  const getSubStage = (order) => subStages.filter((substage)=> substage.order === order)[0]._id
  const getSubStageOrder = (id) => subStages.filter((substage)=> substage._id === id)[0].order;   

  const [selectedSigningOption, setSelectedSigningOption] = useState("aadhaar");
  const { addToast } = useToasts();
  const [data, setData] = useState({
    PropertyID: sessionStorage.getItem(PROPERTY_ID_KEY),
    templateSubType ,
    templateType, 
    payment_type: defaultValues.payment_types.token,
    ExtraFields: {
      "offerPrice": offered_purchase_price,
      "token": down_payment_amount,
      "closingDate": new Date().toLocaleDateString('en-GB')
    },
    closing_Date: new Date().toLocaleDateString("en-GB"),
    "offered_purchase_price":offered_purchase_price,
    "balance_Due": balance_due,
    "down_payment_amount": down_payment_amount
  });
  const [loader, setloader] = useState(false);
  const [isDocumentSigned , setIsDocumentSigned] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [showSignType, setShowSignType] = useState(false);
  const [fileData, setFileData] = useState("");
  const [showPdf, setShowPdf] = useState(false);
  const [signedPdfShow, setSignedPdfShow] = useState(false);
  const [pdfUrl , setPdfUrl] = useState('');
  const [proceed , setProceed] = useState(false)
  const [showSign , SetShowSign] = useState(true)
  const [showStamp , SetShowStamp] = useState(false);
  const [showAccept , setShowAccept] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [signType, setSignType] = useState("aadhaar");
  const accessToken =
    useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";

  useEffect(() => {
    if (!accessToken) {
      history.push(Routes.HOME);
    }
  }, [accessToken, history, preferredRole]);

   useEffect(()=>{
    if(selectedProperty.sub_current_stage === getSubStage(8) || selectedProperty.sub_current_stage === getSubStage(13)
    ){
      getFinalizedDocs();
      getStampStatus();
    }
    if(selectedProperty.sub_current_stage === getSubStage(6.2) || selectedProperty.sub_current_stage === getSubStage(11.2)){
      getFinalizedDocs();      
      setShowAccept(preferredRole === defaultValues.role_names.seller ? true : false);
    }
    if(selectedProperty.sub_current_stage === getSubStage(18.1)){
      getFinalizedDocs();      
      setShowAccept(preferredRole === defaultValues.role_names.seller ? true : false);
    }

    if(selectedProperty.sub_current_stage === getSubStage(9)){
      getSigneduserInfo(selectedProperty.sub_current_stage === getSubStage(18.1))
    }
    if(preferredRole === defaultValues.role_names.buyer && 
      (selectedProperty.sub_current_stage === getSubStage(4.1) ||
      selectedProperty.sub_current_stage === getSubStage(6.1) ||
      selectedProperty.sub_current_stage === getSubStage(6.2) ||
        selectedProperty.sub_current_stage === getSubStage(11.1) || 
      selectedProperty.sub_current_stage === getSubStage(11.2) ||
      selectedProperty.sub_current_stage === getSubStage(18.1)))
      {
      getFinalizedDocs();
      SetShowSign(false);
      setProceed(true);
      setShowPdf(true);
    }
  },[])

  const initiateFinalize = async () => {
    setloader(true);
    const payload = {
      propertyID: propertyId,
      templateSubType,
      templateType,
      file: fileData,
      userId : userId
    }
    storeDocuments(payload);
  };

  const getStampStatus = async() => {    
    
    await getDocByPropId({
      property_id: propertyId,
      documentType: templateType,
      subType : templateSubType
    }).then(res => {
      setShowSignType(res.data.data.e_sign?.reference_id ? false : true);
      SetShowStamp(
        res.data?.data?.e_stamp?.stamp_paper_number?.length > 0
        ? false : true);
      const signUser = res.data?.data?.signing.map(data => data.signed_by);
      SetShowSign(!signUser.includes(userId));
    });
  }  

  const storeDocuments = async (payload)=>{
    try{
      let message = '';
    const response = await storeDoc(payload);
    if(response.status === 200){
      getFinalizedDocs();
      setShowPdf(true);
      if(preferredRole === defaultValues.role_names.buyer || preferredRole === defaultValues.role_names.lawyer){
        setProceed(true);
      }
      if(selectedProperty.sub_current_stage === getSubStage(6)){
        await getSubStageUpdated(propertyId, getSubStage(6.1), true);
      } 
      if(selectedProperty.sub_current_stage === getSubStage(6.1)){
        await getSubStageUpdated(propertyId, getSubStage(6.2), true);
      } 
      if(selectedProperty.sub_current_stage === getSubStage(11)){
        await getSubStageUpdated(propertyId, getSubStage(11.1), true);
      }
      if(selectedProperty.sub_current_stage === getSubStage(11.1)){
        await getSubStageUpdated(propertyId, getSubStage(11.2), true);
      } 
      if(selectedProperty.sub_current_stage === getSubStage(18)){
        await getSubStageUpdated(propertyId, getSubStage(18.1), true);
      } 
      setProceed(true);
      setShowEditor(true)
      
      if(buttonText === 'Finalize MOU') {
        message = 'has finalized MOU';
      } else if(buttonText === 'Finalize Sale Agreement') {
        message = 'has finalized the Sale Agreement';
      } else if (buttonText === 'Finalize SALE DEED') {
        message = 'has finalized the Sale Deed';
      }
      const historyData = historyFormatter(user, userId, propertyId, message);
      addPropertyHistory(historyData);
    }
    setloader(false);
    SetShowSign(false);
    setShowEditor(true);
  }catch(error){
    setloader(false);
    SetShowSign(false);
    setShowEditor(true);
    addToast(getErrorMessage("E-10249"), { appearance: ERROR_KEY, autoDismiss: true });
  }
  }

  const getSubStageUpdated = async (propertyId, substage, status)=>{
    try{
      const response = await updateSubStage({propertyId, substageId: substage, loggedInUserId:userId, status});
        if(response.status === 200){
          dispatch(updateSelectedProperty(response.data.propertyData));
        }
      else addToast(getErrorMessage('E-10070'), { appearance: ERROR_KEY, autoDismiss: true });
  
      }catch(error){
        addToast(getErrorMessage('E-10111'), { appearance: ERROR_KEY, autoDismiss: true });
      }  
  }

  const getFinalizedDocs = ()=>{
    setloader(true)
    const payload = {
      propertyId,
      templateSubType,
      templateType,
    }
    const getToken = () => sessionStorage.getItem(ACCESS_TOKEN_KEY);
    const options = {
      method: 'post',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${getToken()}`
      },
      body: JSON.stringify(payload),
    }
    fetch(`${API.baseURL[API.currenEnv]}property/getDocumentFromS3`, options)
      .then(res => res.blob())
      .then(async (data) => {
        if(data.type === 'application/pdf'){
        const file = new File([data], templateSubType, { type: data.type });
        const url = URL.createObjectURL(file);
        setPdfUrl(url)
        addToast(getSuccessMessage("S-10012"), { appearance: SUCCESS_KEY, autoDismiss: true });
        setShowEditor(true)
        setShowPdf(true);
        setSignedPdfData(url);
        setloader(false);
        if(selectedProperty.sub_current_stage === getSubStage(7) ||
          selectedProperty.sub_current_stage === getSubStage(12)){
          setProceed(true);
        }
        setloader(false);
      }

      })
      .catch((e) => {
        addToast(getErrorMessage('E-10113'), { appearance: ERROR_KEY, autoDismiss: true });
      })
  }

  const getSigneduserInfo = async () => {
    const { property_parties_details = [] } = selectedProperty;
    const buyerSellers = property_parties_details.filter((party) =>
      [
        defaultValues.role_names.buyer,
        defaultValues.role_names.seller,
      ].includes(party.role.role_name)
    );
    const userIds = buyerSellers.map((user) => {
      return user.user_id._id || user.user_id;
    });
    const response = await getDocByPropId({
      property_id: propertyId,
      documentType: templateType,
      subType: templateSubType,
    });
    if (response.status === 200) {
      setShowSignType(response.data.data.e_sign?.reference_id ? false : true);
      const signedUserArray = response.data.data.signing || [];
      const signedUserIdArray = signedUserArray.map((user) => user.signed_by);
      const unsignedUser = userIds.filter(
        (id) => !signedUserIdArray.includes(id)
      );

      if (!unsignedUser.length) {
        dispatch(stageValidation({ propertyId, roleId })).then(async () => {
          if (selectedProperty.sub_current_stage === getSubStage(8)) {
            getSubStageUpdated(propertyId, getSubStage(9), true);
          }
          if (selectedProperty.sub_current_stage === getSubStage(13)) {
            getSubStageUpdated(propertyId, getSubStage(14), true);
          }
          setProceed(true);
          setIsDocumentSigned(true);
        });
      }
      history.push(Routes.PROPERTIES);
    }
  };

  const callbackFunction = async (res, docketId) => {    
    getSignedDoc({
      docket_id: docketId,
      document_id: res?.document_id,
      user_id : userId,
      template_type: templateType,
      template_subtype: templateSubType,
    }).then(async (resData) => {
      if(resData.data.status === "signed"){
        addToast(getSuccessMessage("S-10013"), { appearance: SUCCESS_KEY, autoDismiss: true });
        await getSigneduserInfo();  
        getPropertyDetails(propertyId).then(response => {
          if(response.data) {
           dispatch(addSelectedProperty(response.data.propertyData));
          }
        });
        let message = `has signed the ${templateSubType}`;
        let historyData = historyFormatter(user, userId, propertyId, message);
        addPropertyHistory(historyData);   
        dispatch(getPropertiesData(propertyId));
        history.push(Routes.VIEW_PROPERTY);
      }else {
        addToast(getErrorMessage('E-10118'), { appearance: ERROR_KEY, autoDismiss: true });
      }
    });
  };

  const callbackFunctionStamp = async (res) => {
    setShowEditor(false);
    setShowSignType(false);
    setShowPdf(false);
    setSignedPdfShow(true);
    setSignedPdfData(res?.data?.data);
    setloader(false)
  };

  useEffect(()=>{
    setloader(true)
    dispatch(postGenerateApi(data))
    .then((res)=>{
      setFileData(res?.payload?.createPropertyData?.HTMLParsedData)
      setShowEditor(true)
    })  
    setloader(false)
  },[data, dispatch])

  const handleProceed = ()=>{
    if(selectedProperty.sub_current_stage === getSubStage(7)){
      history.push(`${Routes.TOKEN_PAYMENT}?pid=${propertyId}`)
    }
    if(selectedProperty.sub_current_stage === getSubStage(12)){
      history.push(`${Routes.REGISTRATION}?pid=${propertyId}`)
    }
    else {
      history.push(Routes.VIEW_PROPERTY);     
    }
  }

  const handleVerify = async(status) => {
    try{   
      setloader(true);
      const payload = {
        propertyId : propertyId,
        documentType : templateType,
        subType : templateSubType, 
        userId : userId,
        status : status
      };
      
      let message = `has accepted the ${templateSubType}`;
      await updateVerifyStatus(payload).then(async(res) =>{
        const currentOrder = getSubStageOrder(selectedProperty.sub_current_stage);

        if(status){
          if(res.status === 200){
            const documentData = await getDocByPropId({
              property_id: propertyId,
              documentType: templateType,
              subType : templateSubType
            });
            if(documentData.status === 200){
              setShowSignType(res.data.data.e_sign?.reference_id ? false : true);
              const userList = documentData.data.data.verify.map(data => data.user_id);
              const {property_parties_details = []} = selectedProperty;
              const sellers = property_parties_details.filter((party)=> [defaultValues.role_names.seller].includes(party.role.role_name));
              const userIds = sellers.map((user)=>{
                return user.user_id._id || user.user_id
              });
              
              const unVerifyUser = userIds.filter((id)=> !userList.includes(id));
              if(unVerifyUser.length === 0){
                let order = 0;
                if(currentOrder < 7 && currentOrder >= 6) order = 7;
                if(currentOrder < 12 && currentOrder >= 11) order = 12;
                if(currentOrder === 18.1) order = 19;
                await getSubStageUpdated(propertyId, getSubStage(order), true);
              }
              history.push(Routes.VIEW_PROPERTY);
            }

          }else {
            addToast(getErrorMessage('E-10193'), {appearance: ERROR_KEY, autoDismiss: true});
          }
        } else {
          message = `has rejected the ${templateSubType}`;
          let order = 0;
          if(currentOrder < 7 && currentOrder >= 6) order = 6;
          if(currentOrder < 12 && currentOrder >= 11) order = 11;
          if(currentOrder === 18.1) order = 18;
          await getSubStageUpdated(propertyId, getSubStage(order), status);
          history.push(Routes.VIEW_PROPERTY);
        }
      });
      const historyData = historyFormatter(user, userId, propertyId, message);
      addPropertyHistory(historyData);
      setloader(false);
    }catch(error){
      setloader(false);
    }
  }

  const initiateSign = () => {
    setloader(true);
    initiateSigning({
      propertyId: sessionStorage.getItem(PROPERTY_ID_KEY),
      userId,
      templateType,
      templateSubType,
      callbackFunction: callbackFunction,
      signType: selectedSigningOption,
      "loaderFalse": setloader,
      "addToastMessage": (message) => addToast(message, {appearance: ERROR_KEY, autoDismiss: true}),
    });
  }

  const routeChange = (value) => {
    setShowPopup(false);
    if(value){      
      history.push(`${Routes.BACK_OUT}?pid=${propertyId}`);
    }
  };

  return (
    <div style={{marginTop: '2%'}}>
        { loader ?  <Loader /> : <div>
        <Main showUserNavbar={!props?.showUserNavbar} showUser={!props?.showUser}>
          <div className="fee-container fee-containers">
            <div className="fee-heading">
              <p>{heading}</p>
              {[defaultValues.role_names.buyer, defaultValues.role_names.seller].includes(preferredRole) &&
                 <button style={primaryButtonStyle} onClick={() => setShowPopup(true)}>Backout</button>}
            </div>

            {showSignType &&
            <>
              <div className="fee-subHeading">
                <p>Please choose the a signature method</p>
              </div>
            
              <div className="radio-Btn my-3">
                <label className="mx-2" style={{ color: 'black'}}>
                  <input
                    type="radio"
                    value="aadhaar"
                    className="price-radio"
                    checked={signType === "aadhaar"}
                    onChange={(e) => setSignType(e.target.value)}
                  />
                  Aadhaar
                </label>
                <label className="mx-2" style={{ color: 'black'}}>
                  <input
                    type="radio"
                    value="electronic"
                    className="price-radio"
                    checked={signType === "electronic"}
                    onChange={(e) => setSignType(e.target.value)}
                  />
                  Electronic Sign
                </label>
              </div>
            </>
            }
            <div className="platform-fee-container">
              {signedPdfShow ? (
                <div className="platform-fee-container-embed">
                  <embed
                    src={`data:application/pdf;base64,${signedPdfData}`}
                    height={500}
                    width={800}
                  />
                 {showSign && preferredRole !== defaultValues.role_names.lawyer && <Button
                          className="fee-buttonRight"
                          // style={primaryButtonStyle}
                          disabled={isDocumentSigned}
                          onClick={() => initiateSign()}>
                          Sign Here
                        </Button>
                 }
                </div>
              ) : !showPdf ? (
                    <>
                      <div className="platform-fee-ck-container">
                        <CustomEditor 
                          data={fileData}
                          onChange={(text) => {
                            setFileData(text);
                          }}
                        />
                      </div>
                      <div>
                        {
                        <Button
                          className="fee-button"
                          onClick={initiateFinalize}
                        >
                          {buttonText}
                        </Button>
                        }
                        
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="platform-fee-ck-container">
                        <object
                          data={pdfUrl}
                          type="application/pdf"
                          width="100%"
                          height="100%"
                        >
                          <p>
                            Alternative text - include a link{" "}
                            <a href={pdfUrl}>
                              to the PDF!
                            </a>
                          </p>
                        </object>
                      </div>
                        { proceed && getSubStageOrder(selectedProperty.sub_current_stage) < 19 &&
                          <div className="d-flex justify-content-center">
                            <Button className="fee-buttonRight"
                                onClick={handleProceed}>
                                Proceed
                            </Button>
                          </div>
                        }
                        { showAccept && (
                            <div className="d-flex justify-content-center">
                              <div>
                                <Button className="fee-button"
                                  onClick={()=> handleVerify(false)}>
                                  Reject
                                </Button>
                              </div>
                              <div className="ml-5 pl-5">
                                <Button className="fee-button"
                                  onClick={()=> handleVerify(true)}>
                                  Accept
                                </Button>                          
                              </div>
                            </div>
                          )}                          
                        { showSign && !proceed && !showAccept && preferredRole !== defaultValues.role_names.lawyer && !showStamp &&
                          <div className="d-flex justify-content-center">
                            <Button
                              className="fee-buttonRight"
                              disabled={isDocumentSigned}
                              onClick={() => initiateSign()}>
                              Sign Here
                            </Button>
                          </div>
                        }
                      <div>
                        { !showSign && selectedProperty.sub_current_stage < getSubStage(18) &&
                          <Button
                              className="fee-buttonEdit"
                              onClick={() => {
                                setShowPdf(false);
                                setShowEditor(true);
                                setProceed(false)
                              }}
                              disabled={isDocumentSigned}>
                              Back To Edit
                            </Button>
                        }
                        { preferredRole ===defaultValues.role_names.buyer && showStamp && 
                          <div className="d-flex justify-content-center">
                            <Button
                              style={primaryButtonStyle}
                              disabled={isDocumentSigned}
                              onClick={() => {
                                setloader(true);
                                initiateE_Stamp({
                                  propertyId: sessionStorage.getItem("propertyID"),
                                  userId,
                                  templateType,
                                  templateSubType,
                                  callbackFunction: callbackFunctionStamp,
                                  "loaderFalse": setloader,
                                  signType,                                  
                                  addToastMessage : (message) => addToast(message, {appearance: ERROR_KEY, autoDismiss: true}),
                                });
                              
                              }}>
                              Get E-Stamp
                            </Button>
                          </div>
                        }
                      </div>
                    </>
                  )}
            </div>
          </div>
        </Main>
        {showPopup && 
        <MessageModel title="Warning" 
          message="Are you sure you want to Backout ?" 
          callback={routeChange} showPopup={showPopup}>
        </MessageModel>
      }
      </div>
      }
    </div>
  );
};

export default InitiateOffers;