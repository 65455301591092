import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getAllRoles } from "../../redux/extra-services/roles.services";
import { useDispatch, useSelector } from "react-redux";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useToasts } from "react-toast-notifications";
import {
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CssBaseline,
  Button,
  TextField,
  Grid,
  Typography,
  Container,
  Box,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import "../login/style.scss";
import { COUNTRY_CODE } from "../../constants/countryCode";
import { useLocation } from "react-router-dom";

import LandingFooter from "../general-landing-page/LandingFooter";
import GeneralNav from "../general-landing-page/GeneralNav";
import {userSignUp} from "../../services/api";
import { ERROR_KEY, defaultValues, getErrorMessage } from "../../constants";
import { Routes } from "../../constants/routes";
import { Route } from "@mui/icons-material";

export default function SignUp() {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const { addToast } = useToasts();
  const location = useLocation();

  const [firstName, setFirstName] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const getRoles = useSelector((state) => state.roles.roles);
  const [selectedCountry, setSelectedCountry] = useState("+91");
  const [roleList, setRoleList] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const [dataValue, setDataValue] = useState({
    is_admin: false,
    gender:"",
  });

  useEffect(() => {
    if(location.pathname === Routes.RENTAL_AGREEMENT) {
      setRoleList(defaultValues.rental_role_name);
    }else {
      setRoleList([ 
        ...defaultValues.sales_signup, 
        ...defaultValues.rental_role_name
      ]);
    }
  }, [location.pathname]);
  

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const loginSchema = yup.object({
    selectRole: yup.string().required(getErrorMessage('E-10219')),
    firstName: yup.string().required(getErrorMessage('E-10034')),
    lastName: yup.string().required(getErrorMessage('E-10035')),
    email_id: yup
      .string()
      .email(getErrorMessage('E-10008'))
      .matches(emailRegex, getErrorMessage('E-10008'))  
      .required(getErrorMessage('E-10029')),
    country_code: yup.string(),
    phone: yup.string()
      .required(getErrorMessage(getErrorMessage('E-10026')))
      .when(
        "country_code", {
          is: (country_code) => country_code === "+91",
          then: () => yup.number()
            .test("phone", 
              getErrorMessage('E-10220'),
              (value) => value && value.toString().length === 10
            ),
          otherwise: () => yup.number()
            .test("phone", 
            getErrorMessage('E-10220'),
            (value) => value && value.toString().length > 5
          )
        }),
    password: yup.string().required(getErrorMessage('E-10063')).min(6),
  });  
  

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
    mode: "onBlur",
  });

  const onSubmit = (_data, e) => {
    e.target.reset();
    const selectedRole = getRoles.filter(
      (role) => role.role_name === dataValue.selectRole
    )[0];
    if (selectedRole) {
      const updatedPayload = {
        ...dataValue,
        primary_role: selectedRole._id,
        country_code: selectedCountry,
      };

      userSignUp(updatedPayload).then(res => {
        setFirstName(updatedPayload.first_name);
        setShowSuccessModal(true);
      }).catch(err => {
        addToast(err.response.data.message, { appearance: ERROR_KEY, autoDismiss: true });
      });
    }
  };
  useEffect(() => {
    dispatch(getAllRoles());
  }, [dispatch]);

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <>
      <GeneralNav />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 11,
            marginBottom: 14,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h2" variant="h5" sx={{ mt: 4 }}>
            {defaultValues.screenTitles.sign_up}
          </Typography>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="selectRole"
                    name="selectRole"
                    label="Select Role"
                    autoFocus
                    {...register("selectRole")}
                    error={Boolean(errors.selectRole)}
                    onChange={(e) => {
                      const roleValue = e.target.value;
                      setDataValue({ ...dataValue, selectRole: roleValue });
                    }}
                  >
                    {roleList.map((role) => <MenuItem value={role}>{role}</MenuItem>)}
                    
                  </Select>
                  <div className="login-errors">
                    {errors.selectRole ? (
                      <>{errors.selectRole.message}</>
                    ) : null}
                  </div>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  {...register("firstName")}
                  error={Boolean(errors.firstName)}
                  onChange={(e) =>
                    setDataValue({ ...dataValue, first_name: e.target.value })
                  }
                />
                <div className="login-errors">
                  {errors.firstName ? <>{errors.firstName.message}</> : null}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  {...register("lastName")}
                  error={Boolean(errors.lastName)}
                  onChange={(e) =>
                    setDataValue({ ...dataValue, last_name: e.target.value })
                  }
                />
                <div className="login-errors">
                  {errors.lastName ? <>{errors.lastName.message}</> : null}
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email_id"
                  label="Email Address"
                  name="email_id"
                  autoComplete="email_id"
                  {...register("email_id")}
                  error={Boolean(errors.email_id)}
                  onChange={(e) =>
                    setDataValue({ ...dataValue, email_id: e.target.value })
                  }
                />
                <div className="login-errors">
                  {errors.email_id ? <>{errors.email_id.message}</> : null}
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <InputLabel id="countryCodeLabel">Country Code</InputLabel>
                  <Select
                    labelId="countryCodeLabel"
                    id="country_code"
                    name="country_code"
                    label="Country Code"
                    value={selectedCountry || "+91"}
                    onChange={(e) => {
                      const selectedCountryCode = e.target.value;
                      setSelectedCountry(selectedCountryCode);
                    }}
                    renderValue={(selected) => {
                      const selectedCountry = COUNTRY_CODE.find(country => country.value === selected);
                      return selectedCountry ? selectedCountry.value : '';
                    }}
                    
                  >
                    {COUNTRY_CODE.map((country, index) => (
                      <MenuItem key={index} value={country.value}>
                        {`${country.label} (${country.value})`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  required
                  fullWidth
                  name="phone"
                  label="Phone No."
                  type="phone"
                  id="phone"
                  autoComplete="phone"
                  {...register("phone")}
                  error={Boolean(errors.phone)}
                  onChange={(e) =>
                    setDataValue({ ...dataValue, phone: e.target.value })
                  }
                />
                <div className="login-errors">
                  {errors.phone ? <>{errors.phone.message}</> : null}
                </div>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    type={showPassword ? "text" : "password"}
                    id="password"
                    label="Password"
                    variant="outlined"
                    name="password"
                    {...register("password")}
                    error={Boolean(errors.password)}
                    onChange={(e) =>
                      setDataValue({ ...dataValue, password: e.target.value })
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <div className="login-errors">
                    {errors.password ? <>{errors.password.message}</> : null}
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{background: 'var(--primaryButton)'}}
              sx={{ mt: 3, mb: 2, background: "var(--primaryButton)" }}
            >
              Sign Up
            </Button>
          </Box>
        </Box>
      </Container>
      <LandingFooter />
      <Dialog
        open={showSuccessModal}
        onClose={handleCloseSuccessModal}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent>
          <Typography sx={{ color: "var(--darkGreen)" }}>
            {firstName} registered successfully!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseSuccessModal}
            style={{background: 'var(--primaryButton)'}}
            component={Link}
            to={Routes.HOME}
          >
            Go to Sign In
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}