import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "../../layout/Button";
import "./style.scss";

import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Modal from "react-bootstrap/Modal";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { manageProfile } from "../../utils/validationSchemas";
import { validatePhone } from "../../utils/HelperFunction";


const EditAdmin = ({isOpen, setIsOpen, onClose, onSubmit, item, ...props }) => {
  const [editAdmin, setEditAdmin] = useState({ });
  const [isDiscard, setIsDiscard] = useState(false);

  useEffect(() => {
    setEditAdmin({ ...item });
  }, [item]);

  const {register,formState: { errors, isValid },} = useForm({
    resolver: yupResolver(manageProfile),
    mode: "onBlur",
  });

 
  const onDoSubmit = (e) => {
    if (isValid) { 
      e.preventDefault();
      let obj = { 
        _id : editAdmin._id,
        first_name: editAdmin.first_name,
        last_name: editAdmin.last_name,
        phone: editAdmin.phone,
        status: editAdmin.status
      };
      obj.is_admin = true;
      obj.middle_name = " ";
      onSubmit(obj);
      onClose();
    }   
    
  };

  const onDoClose = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <Modal
      size="lg"
      show={isOpen}
      onHide={() => setIsOpen(false)}
      aria-labelledby="example-modal-sizes-title-lg"
      className="editProperty"
    >
      <div className="blueBg form-area form-area-full">
        {isDiscard ? (
          <div className="flex">
            <img className="mt-50" src="/imgs/discard.png" alt="discard" />
            <div className="mt-50"></div>
            <Button
              type="button"
              variant="secondary"
              className="btn-lg pull-left"
              text="Discard"
              onSubmit={() => onClose()}
            />
            <Button
              type="button"
              variant="secondary"
              className="btn-lg pull-right"
              text="No"
              onClick={(e) => setIsDiscard(false)}
            />
          </div>
        ) : (
          <Form className="container-fluid" >
            <h1 className="mb-50">Edit Admin</h1>
            <div class="row">
              <div class="col-3"></div>
              <div class="col-7">
              <div className="custom-offset">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <TextField
                    type="text"
                    defaultValue={item.first_name}
                    variant="outlined"
                    label="Enter First Name"
                    fullWidth
                    autoComplete="off"
                    value={editAdmin.first_name}
                    error={Boolean(errors.first_name)}
                    {...register("first_name")}
                    helperText={
                      <span className={errors.first_name ? "errorText" : ""}>
                        {errors.first_name?.message}
                      </span>
                    }
                    onChange={(e) => {
                      setEditAdmin({
                        ...editAdmin,
                        first_name: e.target.value,
                      });
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <TextField
                    type="text"
                    defaultValue={item.last_name}
                    label="Enter Last Name"
                    fullWidth
                    autoComplete="off"
                    error={Boolean(errors.last_name)}
                    value={editAdmin.last_name}
                    {...register("last_name")}
                    helperText={
                      <span className={errors.last_name ? "errorText" : ""}>
                        {errors.last_name?.message}
                      </span>
                    }
                    variant="outlined"
                    onChange={(e) => {
                      setEditAdmin({ ...editAdmin, last_name: e.target.value });
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <TextField
                    type="text"
                    defaultValue={item.email_id}
                    label="Enter Email ID"
                    fullWidth
                    disabled
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#fff",
                        cursor:"not-allowed"
                      }
              }}
                    autoComplete="off"
                    value={editAdmin.email}
                    error={Boolean(errors.email)}
                    {...register("email")}
                    helperText={
                      <span className={errors.email ? "errorText" : ""}>
                        {errors.email?.message}
                      </span>
                    }
                    onChange={(e) => {
                      setEditAdmin({ ...editAdmin, email_id: e.target.value });
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <TextField
                    type="number"
                    defaultValue={item.phone}
                    label="Enter Phone Number As Per Aadhaar"
                    fullWidth
                    autoComplete="off"
                    value={editAdmin.phone}
                    error={Boolean(errors.phone)}
                    {...register("phone")}
                    helperText={
                      <span className={errors.phone ? "errorText" : ""}>
                        {errors.phone?.message}
                      </span>
                    }
                    onChange={(e) => {
                      if(validatePhone(e.target.value))
                      { setEditAdmin({ ...editAdmin, phone: e.target.value }); }
                    }}
                  />
                </Form.Group>

                <FormControl fullWidth>
                    <InputLabel id="status-select-label">Status</InputLabel>
                    <Select
                      defaultValue={item.status}
                      labelId="status-select-label"
                      id="status-select"
                      value={editAdmin.status}
                      label="Status"
                      onChange={(e) => setEditAdmin((prevDataValue) => ({
                        ...prevDataValue,
                        status: e.target.value
                      }))}
                    >
                      <MenuItem value={true}>Active</MenuItem>
                      <MenuItem value={false}>Inactive</MenuItem>
                    </Select>
                  </FormControl>
              </div>

                <br />
                <div className="mt-50"></div>
                <Button
                  type="button"
                  variant="secondary"
                  className="btn-lg pull-left custom-offset"
                  text="Update"
                  onClick={onDoSubmit}
                />
                <Button
                  type="button"
                  variant="secondary"
                  className="btn-lg pull-right custom-offset"
                  text="Cancel"
                  onClick={(e) => onDoClose(e)}
                />
              </div>
            </div>
          </Form>
        )}
      </div>
    </Modal>
  );
};
export default EditAdmin;