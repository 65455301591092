import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import Form from "react-bootstrap/Form";
import "./style.scss";
import dayjs from "dayjs";
import { rRentDetails } from "../../../utils/validationSchemas";

export const RentDetails = ({
  onBack,
  onSave,
  agreementData,
  readOnly,
  isRenew,
}) => {
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      securityDeposit: agreementData.securityDeposit || "",
      payDetails: agreementData.payDetails || "",
      rentAmount: isRenew
        ? (agreementData.rentAmount * agreementData.hikePer) / 100 +
            agreementData.rentAmount || ""
        : agreementData.rentAmount || "",
      maintenance: agreementData?.maintenance || "",
      maintenanceAmt: agreementData?.maintenanceAmt || "",
      hikePer: agreementData.hikePer || "",
      licenseDate: agreementData.licenseDate || "",
      duration: agreementData.duration || "",
      noticePeriod: agreementData.noticePeriod || "",
      lockPeriod: agreementData.lockPeriod || "",
      stayModel: agreementData.stayModel || "",
    },
    validationSchema: rRentDetails,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values, action) =>
      onSave({
        ...values,
        securityDeposit: values.securityDeposit.toString().replace(/,/g, ""),
        rentAmount: values.rentAmount.toString().replace(/,/g, ""),
        maintenanceAmt: values?.maintenanceAmt?.toString().replace(/,/g, ""),
        stageNo: agreementData.stageNo === 3.1 ? 3.1 : 1.5,
      }),
  });

  const [formattedSignDate, setFormattedSignDate] = useState("");
  const [licMaxDate, setLicMaxDate] = useState(null);

  useEffect(() => {
    const date = agreementData?.signDate
      ? new Date(agreementData.signDate)
      : new Date();

    const currentDate = date.toISOString().split("T")[0];
    setLicMaxDate(currentDate);
  }, [agreementData]);

  useEffect(() => {
    setFormattedSignDate(
      agreementData.licenseDate
        ? dayjs(new Date(agreementData.licenseDate)).format("YYYY-MM-DD")
        : ""
    );
  }, [agreementData.signDate]);

  function formatIndianRupees(number) {
    const dd = number.toString().replace(/(\d)(?=(\d\d)+\d(\d{2})?$)/g, "$1,");
    return dd;
  }
  const handleAmountChange = (e, name) => {
    const rawValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    const formattedValue = formatIndianRupees(rawValue);

    handleChange({
      target: {
        name: name,
        value: rawValue === "" ? "" : formattedValue,
      },
    });
  };


  return (
    <Form className="pl-3 mt-1 container-fluid" onSubmit={handleSubmit}>
      <div className="row">
        <Form.Group className="mb-3 col-md-5 pr-2">
          <Form.Label className="fee-label">Security Deposit</Form.Label>
          <Form.Control
            disabled={readOnly}
            type="text"
            name="securityDeposit"
            id="securityDeposit"
            required
            placeholder="security Deposit Amount"
            onBlur={handleBlur}
            onChange={(e) => handleAmountChange(e, "securityDeposit")}
            value={formatIndianRupees(values.securityDeposit)}
            className="fee-inputs-multiple mb-1 r-filed-box"
          />
          {touched.securityDeposit && errors.securityDeposit && (
            <p className="r-formError">{errors.securityDeposit}</p>
          )}
        </Form.Group>
        <Form.Group className="mb-3 col-md-5">
          <Form.Label className="fee-label">Cheque/UTR No (Optional).</Form.Label>
          <Form.Control
            disabled={readOnly}
            type="text"
            name="payDetails"
            id="payDetails"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Cheque/UTR No"
            value={values.payDetails}
            className="fee-inputs-multiple mb-1 r-filed-box"
          />
        </Form.Group>
      </div>
      <div className="row">
        <Form.Group className="mb-3 col-md-5">
          <Form.Label className="fee-label">
            Monthly Rental Amount (in Rupees)
          </Form.Label>
          <Form.Control
            disabled={readOnly}
            type="text"
            name="rentAmount"
            id="rentAmount"
            required
            placeholder="rent amount"
            onBlur={handleBlur}
            onChange={(e) => handleAmountChange(e, "rentAmount")}
            value={formatIndianRupees(values.rentAmount)}
            className="fee-inputs-multiple mb-1 r-filed-box"
          />
          {touched.rentAmount && errors.rentAmount && (
            <p className="r-formError">{errors.rentAmount}</p>
          )}
        </Form.Group>
        <Form.Group className="mb-3 col-md-5" style={{ maxWidth: "205px" }}>
          <Form.Label className="fee-label">Maintenance</Form.Label>
          <FormControl size="small" sx={{ minWidth: 220 }}>
            <Select
              required
              className="fee-inputs-multiple mb-1"
              disabled={readOnly}
              displayEmpty
              name="maintenance"
              id="maintenance"
              onBlur={handleBlur}              
              onChange={handleChange}
              value={values.maintenance}
            >
              <MenuItem value="" disabled>Select Maintenance</MenuItem>
              <MenuItem value={"Including"}>Including</MenuItem>
              <MenuItem value={"Excluding"}>Excluding</MenuItem>
            </Select>
          </FormControl>
          {touched.maintenance && errors.maintenance && (
            <p className="r-formError">{errors.maintenance}</p>
          )}
        </Form.Group>
      </div>
      <div className="row">
        <Form.Group className="mb-3 col-md-5">
          <Form.Label className="fee-label">
            Maintenance Amount (in Rupees) (Optional)
          </Form.Label>
          <Form.Control
            disabled={readOnly}
            type="text"
            name="maintenanceAmt"
            id="maintenanceAmt"
            placeholder="maintenance amount"
            onBlur={handleBlur}
            onChange={(e) => handleAmountChange(e, "maintenanceAmt")}
            value={formatIndianRupees(values.maintenanceAmt)}
            className="fee-inputs-multiple mb-1 r-filed-box"
          />
        </Form.Group>

        <Form.Group className="mb-3 col-md-2">
          <Form.Label className="fee-label">Hike Percentage</Form.Label>
          <Form.Control
            disabled={readOnly}
            type="number"
            name="hikePer"
            id="hikePer"
            required
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="hike percentage"
            value={values.hikePer}
            className="fee-inputs-multiple mb-1 r-filed-box"
          />
          {touched.hikePer && errors.hikePer && (
            <p className="r-formError">{errors.hikePer}</p>
          )}
        </Form.Group>
      </div>
      <div className="row">
        <Form.Group className="mb-3 col-md-5">
          <Form.Label className="fee-label">
            License Commencement Date
          </Form.Label>
          <Form.Control
            disabled={readOnly}
            type="date"
            name="licenseDate"
            id="licenseDate"
            required
            placeholder="license date"
            defaultValue={formattedSignDate}
            onBlur={handleBlur}
            onChange={handleChange}
            min={licMaxDate}
            className="fee-inputs-multiple mb-1 r-filed-box"
          />
          {touched.licenseDate && errors.licenseDate && (
            <p className="r-formError">{errors.licenseDate}</p>
          )}
        </Form.Group>
        <Form.Group className="mb-3 col-md-5" style={{ maxWidth: "160px" }}>
          <Form.Label className="fee-label">Stay Model</Form.Label>
          <FormControl size="small" sx={{ minWidth: 220 }}>
            <Select
              className="fee-inputs-multiple mb-1"
              disabled={readOnly}
              displayEmpty
              name="stayModel"
              id="stayModel"
              required
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.stayModel}
            >
              <MenuItem value="" disabled>Select Stay Model</MenuItem>
              <MenuItem value={"Pay and stay"}>Pay and stay</MenuItem>
              <MenuItem value={"Stay and Pay"}>Stay and Pay</MenuItem>
            </Select>
          </FormControl>
          {touched.stayModel && errors.stayModel && (
            <p className="r-formError">{errors.stayModel}</p>
          )}
        </Form.Group>
      </div>
      <div className="row">
        <Form.Group className="mb-3 col-md-5">
          <Form.Label className="fee-label">
            Duration of License (in months)
          </Form.Label>
          <Form.Control
            disabled={readOnly}
            type="number"
            name="duration"
            id="duration"
            required
            placeholder="duration in month"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.duration}
            className="fee-inputs-multiple mb-1 r-filed-box"
          />
          {touched.duration && errors.duration && (
            <p className="r-formError">{errors.duration}</p>
          )}
        </Form.Group>
        <Form.Group className="mb-3 col-md-5">
          <Form.Label className="fee-label">
            Notice Period (in months)
          </Form.Label>
          <Form.Control
            disabled={readOnly}
            type="number"
            name="noticePeriod"
            id="noticePeriod"
            required
            placeholder="notice period in month"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.noticePeriod}
            className="fee-inputs-multiple mb-1 r-filed-box"
          />
          {touched.noticePeriod && errors.noticePeriod && (
            <p className="r-formError">{errors.noticePeriod}</p>
          )}
        </Form.Group>
      </div>
      <div className="row">
        <Form.Group className="mb-3 col-md-5">
          <Form.Label className="fee-label">
            Lock-In Period (in months)
          </Form.Label>
          <Form.Control
            disabled={readOnly}
            type="number"
            name="lockPeriod"
            id="lockPeriod"
            required
            placeholder="lock-in period in month"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.lockPeriod}
            className="fee-inputs-multiple mb-1 r-filed-box"
          />
          {touched.lockPeriod && errors.lockPeriod && (
            <p className="r-formError">{errors.lockPeriod}</p>
          )}
        </Form.Group>
      </div>
      {!readOnly && (
        <div className="proceed-button my-3 text-center">
          <Button
            className="px-5 mr-3 btn-outline-secondary mt-3"
            variant="outlined"
            onClick={onBack}
          >
            Back
          </Button>
          <Button className="button px-5 mt-3" variant="primary" type="submit">
            Save & Proceed
          </Button>
        </div>
      )}
    </Form>
  );
};
