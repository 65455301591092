/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import editIcon from "../../../assets/edit-icon.svg";
import OtherDetailEditProperty from "../../../layout/PropertyModal/EditPropertyModal/otherDetailEditProperty";
import Loader from "../../../layout/Loader/Loader";
import { useLocation } from "react-router-dom";
import { getPropertiesData } from "../service";
import { useToasts } from "react-toast-notifications";
import { INFO_KEY, PID_KEY, getErrorMessage } from "../../../constants";

const OtherDetailsData = ({ user, callBack }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const [isLoading, setIsLoading] = useState(false);
  const [detailEditModal, setDetailEditModal] = useState(false);
  const [sdDate, setSdDate] = useState("");
  const [editDisabled, setEditDisabled] = useState(false);
  const viewPropertiesData = useSelector(
    (state) => state.superAdminProperties.propertyData
  );
  const role = useSelector((state) => state.loggedInUser.preferredRole);
  const subStages = useSelector((state) => state?.stages?.subStages);

  const getSubStageOrder = (id) =>
    subStages.filter((substage) => substage._id === id)[0].order;

  const getData = () => {
    setIsLoading(true);
    dispatch(getPropertiesData(searchParams.get(PID_KEY))).then((res) => {
      setIsLoading(false);
      callBack();
    });
  };

  useEffect(() => {
    let dateFormatted = "";
    if (viewPropertiesData?.sale_deed_date) {
      dateFormatted = new Date(viewPropertiesData?.sale_deed_date)
        .toISOString()
        .split("T")[0];
    }
    setSdDate(dateFormatted);
  }, [viewPropertiesData.sale_deed_date]);

  useEffect(() => {
    let disabled = false;
    if (viewPropertiesData?.current_stage?.order_number > 2) {
      disabled = true;
    } else if (viewPropertiesData?.current_stage?.order_number === 2) {
      if (getSubStageOrder(viewPropertiesData.sub_current_stage) > 3) {
        disabled = true;
      }
    }
    setEditDisabled(disabled);
  }, [viewPropertiesData]);

  const getModalData = () => {
    let dateFormatted = "";
    if (viewPropertiesData?.sale_deed_date) {
      dateFormatted = new Date(viewPropertiesData?.sale_deed_date)
        .toISOString()
        .split("T")[0];
    }
    let obj = {
      _id: viewPropertiesData?._id,
      khata_number: viewPropertiesData?.khata_number,
      registration_number: viewPropertiesData?.registration_number,
      no_of_owners: viewPropertiesData?.no_of_owners,
      no_of_buyers: viewPropertiesData?.no_of_buyers,
      usp: viewPropertiesData?.usp,
      schedule_number: viewPropertiesData?.schedule_number,
      cd_number: viewPropertiesData?.cd_number,
      survey_number: viewPropertiesData?.survey_number,
      sale_deed_number: viewPropertiesData?.sale_deed_number,
      sale_deed_date: dateFormatted,
      previous_owner: viewPropertiesData?.previous_owner,
      rented: viewPropertiesData?.rented ? "Yes" : "No",
      tenant_name: viewPropertiesData?.tenant?.name,
      tenant_email: viewPropertiesData?.tenant?.email,
      tenant_phone: viewPropertiesData?.tenant?.phone || "",
    };
    return obj;
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <details className="other-details-dropdown">
          <summary className="fw-bolder">
            <h4>Other Details</h4>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-caret-down"
                className="dropDown"
                viewBox="0 0 16 16"
              >
                <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
              </svg>
              {["BUYER", "SELLER", "SUPERADMIN", "ADMIN"].includes(role) && (
                <img
                  src={editIcon}
                  className="editIcons"
                  alt="edit"
                  onClick={() => {
                    if (editDisabled) {
                      addToast(getErrorMessage("E-10143"), {
                        appearance: INFO_KEY,
                        autoDismiss: true,
                      });
                    } else {
                      setDetailEditModal(!detailEditModal);
                    }
                  }}
                />
              )}
            </div>
          </summary>
          <br />
          <div className="description">
            <div>
              <table className="property-table">
                <tbody>
                  <tr>
                    <td className="labelArea">Khata No:</td>{" "}
                    <td>{viewPropertiesData?.khata_number}</td>
                  </tr>
                  <tr>
                    <td className="labelArea">No. of Sellers:</td>{" "}
                    <td>{viewPropertiesData?.no_of_owners}</td>
                  </tr>
                  <tr>
                    <td className="labelArea">No. of Buyers:</td>{" "}
                    <td>{viewPropertiesData?.no_of_buyers}</td>
                  </tr>
                  <tr>
                    <td className="labelArea">Is Rented:</td>{" "}
                    <td>{viewPropertiesData?.rented ? "Yes" : "No"}</td>
                  </tr>
                  {viewPropertiesData?.rented && (
                    <tr>
                      <td className="labelArea">Tenant Email:</td>{" "}
                      <td>{viewPropertiesData?.tenant?.email}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div>
              <table className="property-table">
                <tbody>
                  <tr>
                    <td className="labelArea">Unique Selling Proposition:</td>{" "}
                    <td>{viewPropertiesData?.usp}</td>
                  </tr>
                  <tr>
                    <td className="labelArea">Schedule No.:</td>{" "}
                    <td>{viewPropertiesData?.schedule_number}</td>
                  </tr>
                  <tr>
                    <td className="labelArea">CD Number:</td>{" "}
                    <td>{viewPropertiesData?.cd_number}</td>
                  </tr>
                  <tr>
                    <td className="labelArea">Sale Deed Number:</td>{" "}
                    <td>{viewPropertiesData?.sale_deed_number}</td>
                  </tr>
                  {viewPropertiesData?.rented && (
                    <>
                      <tr>
                        <td className="labelArea">Tenant Name:</td>{" "}
                        <td>{viewPropertiesData?.tenant?.name}</td>
                      </tr>
                      <tr>
                        <td className="labelArea">Tenant Phone:</td>{" "}
                        <td>{viewPropertiesData?.tenant?.phone}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div>
              <table className="property-table">
                <tbody>
                  <tr>
                    <td className="labelArea">Registration No:</td>{" "}
                    <td>{viewPropertiesData?.registration_number}</td>
                  </tr>
                  <tr>
                    <td className="labelArea">Previous Owner:</td>{" "}
                    <td>{viewPropertiesData?.previous_owner}</td>
                  </tr>
                  <tr>
                    <td className="labelArea">Survey Number:</td>{" "}
                    <td>{viewPropertiesData?.survey_number}</td>
                  </tr>
                  <tr>
                    <td className="labelArea">Sale Deed Date:</td>{" "}
                    <td>{sdDate}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </details>
      )}
      <OtherDetailEditProperty
        setIsDetailEditModal={() => setDetailEditModal(!detailEditModal)}
        isDetailEditModal={detailEditModal}
        data={getModalData()}
        callBack={getData}
        name={user}
      />
    </div>
  );
};
export default OtherDetailsData;
