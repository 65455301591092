import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteUserbyId, getUsers, updateAdmin, updateSuperAdmin, updateUser } from "../../services/api";

export const getData = createAsyncThunk(
  "manageUsersSlice/getData",
  async (args, { rejectWithValue }) => {
    try {
      const { data } = await getUsers();
      return data;
    } catch (e) {
      rejectWithValue(e.response.data);
    }
  }
);

export const updateUserData = createAsyncThunk(
  "adminManagePropertiesSlice/updateUserData",
  async (args, { rejectWithValue }) => {
    try {
      const { data } = await updateUser(args);
      return data;
    } catch (e) {
      rejectWithValue(e.response.data);
    }
  }
);

export const getDeleteData = createAsyncThunk(
  "manageUsersSlice/getDeleteData",
  async (userId, { rejectWithValue }) => {
    try {
      const { data } = await deleteUserbyId(userId);
      return data;
    } catch (e) {
      rejectWithValue(e.response.data);
    }
  }
);

export const updateSuperAdminData = createAsyncThunk(
  "adminManagePropertiesSlice/updateSuperAdminData",
  async (args, { rejectWithValue }) => {
    try {
      const { data } = await updateSuperAdmin(args);
      return data;
    } catch (e) {
      rejectWithValue(e.response.data);
    }
  }
);


export const updateAdminData = createAsyncThunk(
  "adminManagePropertiesSlice/updateAdminData",
  async (args, { rejectWithValue }) => {
    try {
      const { data } = await updateAdmin(args);
      return data;
    } catch (e) {
      rejectWithValue(e.response.data);
    }
  }
);

export const deleteUser = async ( userId ) => {
  try {
    const { data } = await deleteUserbyId(userId);
    return data;
  } catch (e) {
    return e.response.data;
  }
};