import React from "react";
import { useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useToasts } from "react-toast-notifications";
import { TextField, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { useFormik } from "formik";
import { editPropertySchema2 } from "../../../utils/validationSchemas";
import { addPropertyHistory, updateProperty } from "../../../services/api";
import { getUserId } from "../../../utils/HelperFunction";
import { ERROR_KEY, SUCCESS_KEY, defaultValues, getErrorMessage, getSuccessMessage } from "../../../constants";

const today = new Date();
const todayString = today.toISOString().split('T')[0];

const OtherDetailEditProperty = ({
  isDetailEditModal,
  setIsDetailEditModal,
  data,
  callBack,
  name
}) => {
  const { addToast } = useToasts();
  const loggedInUser = useSelector((state) => state.loggedInUser);
  const role = useSelector((state) => state.loggedInUser.preferredRole);

  const getLoggedInUserName = () => {
    if (role === defaultValues.role_names.super_admin) {
      return loggedInUser.data?.data?.superAdminByEmail?.first_name;
    } else if (role === defaultValues.role_names.admin) {
      return loggedInUser.data?.data?.adminByEmail?.first_name;
    } else if (role === defaultValues.role_names.seller || role === defaultValues.role_names.buyer || role === defaultValues.role_names.lawyer || 
    role === defaultValues.role_names.bank_agent ||
    role === defaultValues.role_names.buyer_agent ||
    role === defaultValues.role_names.seller_agent ||
    role === defaultValues.role_names.buyer_poa ||
    role === defaultValues.role_names.seller_poa ||
    role === defaultValues.role_names.ca) {
      return loggedInUser.data?.data?.userByEmail?.first_name;
    }
    return "";
  };

  const {
    resetForm,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isValid,
  } = useFormik({
    initialValues: data,
    validationSchema: editPropertySchema2,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      action.resetForm();
    },
  });

  const submitEditOtherDetail = async (e) => {
    const updateData = {};
    let his = "";
    for (const item in data) {
      if (data.hasOwnProperty(item)) {
        if (data[item] !== values[item]) {
          updateData.id = values._id;
          updateData[item] = values[item];
          his = ` ${his} ${(item).replaceAll("_", " ")} updated to ${values[item]}`;
        }
      }
    }
    const historyData = {
      activity: his,
      name : getLoggedInUserName(),
      activity_by : getUserId(loggedInUser),
      activity_date : new Date(),
      property_id : values._id,
    }
    await addPropertyHistory(historyData);

    if (role === defaultValues.role_names.super_admin) {
      updateData.user_id = `${loggedInUser.data?.data?.superAdminByEmail?._id}`;
    } else if (role === defaultValues.role_names.admin) {
      updateData.user_id = `${loggedInUser.data?.data?.adminByEmail?._id}`;
    } else if (role === defaultValues.role_names.seller || role === defaultValues.role_names.buyer || role === defaultValues.role_names.lawyer || 
    role === defaultValues.role_names.bank_agent ||
    role === defaultValues.role_names.buyer_agent ||
    role === defaultValues.role_names.seller_agent ||
    role === defaultValues.role_names.buyer_poa ||
    role === defaultValues.role_names.seller_poa ||
    role === defaultValues.role_names.ca) {
      updateData.user_id = `${loggedInUser.data?.data?.userByEmail?._id}`;
    }
    updateData.no_of_owners = parseInt(values?.no_of_owners);
    if(values.rented === "Yes") {
      let tenantObj = {
        name: values?.tenant_name,
        email: values?.tenant_email,
        phone: values?.tenant_phone
      }
      updateData.rented = true;
      updateData.tenant = tenantObj;
    } else {
      updateData.rented = false;
      updateData.tenant = {};
    }
    delete updateData.tenant_name;
    delete updateData.tenant_email;
    delete updateData.tenant_phone;

    updateProperty(updateData).then(res => {
      addToast(getSuccessMessage('S-10006'), {
        appearance: SUCCESS_KEY,
        autoDismiss: true
      });
      setIsDetailEditModal(!isDetailEditModal);
      callBack();
      resetForm()
    }).catch(err => {
      addToast(getErrorMessage('E-10070'), { appearance: ERROR_KEY, autoDismiss: true });
      setIsDetailEditModal(!isDetailEditModal);
      callBack();
    })
  };

  return (
    <>
      <Modal
        size="lg"
        show={isDetailEditModal}
        onHide={() => setIsDetailEditModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="editProperty"
      >
        <Modal.Header closeButton className="edit-modal" variant="white">
          <Modal.Title
            id="example-modal-sizes-title-lg"
            className="text-center w-100 text-light"
          >
            Edit Other Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="edit-modal">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="KhataNo">
                  <TextField
                    id="khata_number"
                    label="Khata No"
                    variant="outlined"
                    type="text"
                    className="inputBox fieldWidth"
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.khata_number}
                    name="khata_number"
                  />
                  {touched.khata_number && errors.khata_number && (
                    <p className="formError">{errors.khata_number}</p>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="RegistrationNo">
                  <TextField
                    id="Registration No"
                    label="Registration No"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    className="inputBox fieldWidth"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.registration_number}
                    name="registration_number"
                  />
                  {touched.registration_number && errors.registration_number && (
                    <p className="formError">{errors.registration_number}</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="NoOfOwners">
                  <TextField
                    id="no_of_owners"
                    label="No. Of Sellers"
                    variant="outlined"
                    type="number"
                    autoComplete="off"                 
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.no_of_owners}
                    name="no_of_owners"
                    InputProps={{
                      inputProps: {
                        max: 4,
                        min: 1,
                      },
                    }}
                    className="inputBox fieldWidth"
                  />
                  {touched.no_of_owners && errors.no_of_owners && (
                    <p className="formError">{errors.no_of_owners}</p>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="NoOfSellers">
                  <TextField
                    id="no_of_buyers"
                    label="No. Of buyers"
                    variant="outlined"
                    type="number"
                    autoComplete="off"
                    className="inputBox fieldWidth"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.no_of_buyers}
                    name="no_of_buyers"
                    InputProps={{
                      inputProps: {
                        max: 4,
                        min: 1,
                      },
                    }}
                  />
                  {touched.no_of_buyers && errors.no_of_buyers && (
                    <p className="formError">{errors.no_of_buyers}</p>
                  )}
                </Form.Group>
              </Col>
             
            </Row>
            <Row>
            <Col>
                <Form.Group className="mb-3" controlId="USP">
                  <TextField
                    id="usp"
                    label="Unique Selling Proposition"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    className="inputBox fieldWidth"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.usp}
                    name="usp"
                  />
                  {touched.usp && errors.usp && (
                    <p className="formError">{errors.usp}</p>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="ScheduleNo">
                  <TextField
                    id="schedule_number"
                    label="Schedule No"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    className="inputBox fieldWidth"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.schedule_number}
                    name="schedule_number"
                  />
                  {touched.schedule_number && errors.schedule_number && (
                    <p className="formError">{errors.schedule_number}</p>
                  )}
                </Form.Group>
              </Col>
              </Row>
              <Row>
              <Col>
                <Form.Group className="mb-3" controlId="SurveyNumber">
                  <TextField
                    id="survey_number"
                    label="Survey Number"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    className="inputBox fieldWidth"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.survey_number}
                    name="survey_number"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="CDNumber">
                  <TextField
                    id="cd_number"
                    label="CD Number"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    className="inputBox fieldWidth"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.cd_number}
                    name="cd_number"
                  />
                  {touched.cd_number && errors.cd_number && (
                    <p className="formError">{errors.cd_number}</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="saleDeedNumber">
                  <TextField
                    id="sale_deed_number"
                    label="Sale Deed Number"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    className="inputBox fieldWidth"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.sale_deed_number}
                    name="sale_deed_number"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="saleDeedDate">
                    <TextField
                      type="date"
                      variant="outlined"
                      label="Sale Deed Date"
                      className="col-12 custom-border "
                      name="sale_deed_date"
                      value={values.sale_deed_date}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputProps={{ max: todayString }}
                    />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="privousOwner">
                  <TextField
                    id="previous_owner"
                    label="Previous Owner"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    className="inputBox fieldWidth"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.previous_owner}
                    name="previous_owner"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Is Rented
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={values?.rented}
                    label="IsRented"
                    className="inputBox fieldWidth"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    defaultValue={"No"}
                    name="rented"
                  >
                    <MenuItem value={"Yes"}>Yes</MenuItem>
                    <MenuItem value={"No"}>No</MenuItem>
                  </Select>
                  {touched.rented && errors.rented && (
                    <p className="formError">{errors.rented}</p>
                  )}
                </FormControl>
              </Col>
              {values?.rented === "Yes" && (
                <Col>
                  <Form.Group className="mb-3" controlId="TenantName">
                    <TextField
                      id="tenant_name"
                      label="Tenant Name"
                      variant="outlined"
                      type="text"
                      autoComplete="off"
                      className="inputBox fieldWidth"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.tenant_name}
                      name="tenant_name"
                    />
                    {touched.tenant_name && errors.tenant_name && (
                      <p className="formError">{errors.tenant_name}</p>
                    )}
                  </Form.Group>
                </Col>
              )}
            </Row>
            {values?.rented === "Yes" && (
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="TenantMail">
                    <TextField
                      id="tenant_email"
                      label="Tenant Mail"
                      variant="outlined"
                      type="email"
                      autoComplete="off"
                      className="inputBox fieldWidth"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.tenant_email}
                      name="tenant_email"
                    />
                    {touched.tenant_email && errors.tenant_email && (
                      <p className="formError">{errors.tenant_email}</p>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="TenantPhone">
                    <TextField
                      id="tenant_phone"
                      label="Tenant Phone"
                      variant="outlined"
                      autoComplete="off"
                      type="text"
                      className="inputBox fieldWidth"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.tenant_phone}
                      name="tenant_phone"
                    />
                    {touched.tenant_phone && errors.tenant_phone && (
                      <p className="formError">{errors.tenant_phone}</p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            )}
            <Row>
              <Col className="text-center pt-5">
                <Button 
                style={{background: 'var(--primaryButton)'}}
                variant="px-5" 
                type="submit" 
                className="button"
                disabled={!isValid}
                onClick={() => submitEditOtherDetail(values)}
                >
                  Update
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OtherDetailEditProperty;
