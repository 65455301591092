import React, { useEffect, useState } from "react";
import Main from "../../layout/Main";
import Form from "react-bootstrap/Form";
import { TextField, FormLabel, Button, Select, MenuItem } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Unstable_Grid2";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import UpdatePassword from "./updatePassword";
import DummyProfileImage from "../../assets/Icons/person-dummy.jpg";
import "./style.scss";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToasts } from "react-toast-notifications";
import { updateLoggedInUserData } from "../login/slices";
import { settingsSchema } from "../../utils/validationSchemas";
import { getProfileImg } from "../../redux/extra-services/profile.services";
import { updateUser, uploadProfilePic } from "../../services/api";
import { Link } from "react-router-dom";
import Loader from "../../layout/Loader/Loader";
import { validatePhone } from "../../utils/ScreenWrapper";
import { Routes } from "../../constants/routes";
import {
  ERROR_KEY,
  INFO_KEY,
  ROLE_KEY,
  SUCCESS_KEY,
  defaultValues,
  getErrorMessage,
  getSuccessMessage,
} from "../../constants";
import { STATES } from "../../constants/states";
import { COUNTRY_CODE } from "../../constants/countryCode";
const today = new Date();
const todayString = today.toISOString().split("T")[0];

const ProfileSettings = (props) => {
  const [isFieldChanged, setIsFieldChanged] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [openModal, setOpenModal] = useState(false);
  const loggedInUser = useSelector((state) => state.loggedInUser);
  const [loading, setLoading] = useState(true);
  const role = sessionStorage.getItem(ROLE_KEY);
  const history = useHistory();
  const [dataValue, setDataValue] = useState(null);
  const [addressObj, setAddressObj] = useState(null);
  const profileImage = useSelector((state) => state.profileImage.image);
  const imageLoading = useSelector((state) => state.profileImage.loading);
  const accessToken =
    useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";
  const [userId, setUserId] = useState(null);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) await updateProfileImage(selectedFile);
  };

  useEffect(() => {
    setLoading(imageLoading);
  }, [imageLoading]);

  useEffect(() => {
    if (!accessToken) {
      history.push(Routes.HOME);
    } else {
      if (loggedInUser) {
        let user_data = loggedInUser.data?.data?.userByEmail;
        dispatch(getProfileImg(user_data?._id));
        setUserId(user_data?._id);

        let dobString = "";

        if (user_data?.dob) {
          const recevdDate = new Date(user_data?.dob);
          dobString = recevdDate.toISOString().split("T")[0];
        }

        const obj = {
          id: user_data._id,
          email_id: user_data?.email_id,
          primary_role: role,
          first_name: user_data?.first_name,
          last_name: user_data?.last_name,
          phone: user_data?.phone,
          country_code: user_data?.country_code,
          aadhaar: user_data?.aadhaar,
          pan: user_data?.pan,
          status: true,
          bank_account: {
            account_name: user_data?.bank_account?.account_name,
            country_code: user_data?.country_code,
            bank_name: user_data?.bank_account?.bank_name,
            account_number: user_data?.bank_account?.account_number,
            ifsc_code: user_data?.bank_account?.ifsc_code,
            branch_name: user_data?.bank_account?.branch_name,
          },
          current_address: user_data?.current_address,
          father_name: user_data?.father_name,
          spouse_name: user_data?.spouse_name,
          dob: dobString,
          gender: user_data?.gender,
        };
        setAddressObj({
          address_line1: user_data?.current_address[0]?.address_line1,
          address_line2: user_data?.current_address[0]?.address_line2,
          zipCode: user_data?.current_address[0]?.zipCode,
          city: user_data?.current_address[0]?.city,
          state: user_data?.current_address[0]?.state,
          state_code: user_data?.current_address[0]?.state_code,
        });
        setDataValue(obj);
      }
    }
  }, [accessToken, dispatch, history, loggedInUser, role]);

  const handleOpenModal = (e) => {
    e.preventDefault();
    setOpenModal(true);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(settingsSchema),
    mode: "all",
  });


  const handleSave = async () => {
    try {
      const formIsValid = Object.keys(errors).length === 0;
      if (!formIsValid) {
        addToast(getErrorMessage("E-10225"), {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
      } else {
        delete dataValue.primary_role; //deleting primary role from payload
        dataValue.current_address = [];
        dataValue.country_code = selectedCountryCode;
        dataValue.current_address.push(addressObj); //updating current address in existing data
        const response = await updateUser(dataValue);
        let data = { ...response.data?.data };
        dispatch(updateLoggedInUserData({ data, role }));
        addToast(getSuccessMessage("S-10018"), {
          appearance: SUCCESS_KEY,
          autoDismiss: true,
        });
        history.push(Routes.PROPERTIES);
      }
    } catch (error) {
      addToast(error.response.data.message || getErrorMessage("E-10226"), {
        appearance: ERROR_KEY,
        autoDismiss: true,
      });
    }
  };

  const updateProfileImage = async (file) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("images", file);

      await uploadProfilePic(formData)
        .then((res) => {
          if (res.data.success) {
            dispatch(getProfileImg(userId));
          }
        })
        .catch((e) => {
          setLoading(false);
          addToast(e.response.data.message, {
            appearance: INFO_KEY,
            autoDismiss: true,
          });
        });
    } catch (error) {
      setLoading(false);
      addToast(getErrorMessage("E-10112"), {
        appearance: ERROR_KEY,
        autoDismiss: true,
      });
    }
  };
  
  const getStateName = code => {
    let name = '';
    STATES.map(item => {
      if(item.value === code) {
        name = item.label;
      }
      return null;
    });
    return name;
  }

  if (!dataValue) {
    return <Loader />;
  }

  return (
    <div className="profile-container">
      <Main
        showWelcomePage={!props?.showWelcomePage}
        showChildren={!props?.showChildren}
      >
        <div className="profile-card">
          <p className="heading">{defaultValues.screenTitles.profile}</p>
          <div className="profile-settings-container">
            <Form onSubmit={handleSubmit(handleSave)}>
              <FormLabel className="form-labels">User Role</FormLabel>
              <TextField
                disabled
                id="Role"
                label="Primary role"
                variant="outlined"
                type="text"
                className="profile-inputs"
                size="small"
                name="userId"
                defaultValue={dataValue?.primary_role}
              />
              <Grid container spacing={2}>
                <Grid xs={6}>
                  <FormLabel className="form-label">First Name</FormLabel>
                  <TextField
                    required
                    id="first_name"
                    label="Enter First Name"
                    variant="outlined"
                    type="text"
                    className="profile-input"
                    size="small"
                    name="firstname"
                    {...register("first_name")}
                    error={Boolean(errors.first_name)}
                    helperText={errors.first_name?.message}
                    defaultValue={dataValue.first_name}
                    onChange={(e) => {
                      setDataValue({
                        ...dataValue,
                        first_name: e.target.value,
                      });
                      setIsFieldChanged(true);
                    }}
                  />
                </Grid>
                <Grid xs={6}>
                  <FormLabel className="form-label">Last Name</FormLabel>
                  <TextField
                    required
                    id="last_name"
                    label="Enter Last Name"
                    variant="outlined"
                    type="text"
                    className="profile-input"
                    size="small"
                    name="lastname"
                    error={Boolean(errors.last_name)}
                    helperText={errors.last_name?.message}
                    {...register("last_name")}
                    defaultValue={dataValue.last_name}
                    onChange={(e) => {
                      setDataValue({ ...dataValue, last_name: e.target.value });
                      setIsFieldChanged(true);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0}>
              <Grid xs={1.5} style={{ paddingTop: "2.6rem"}}>
                <Select
                  id="countryCode"
                  variant="outlined"
                  size="small"
                  label=" "
                  value={selectedCountryCode || dataValue.country_code}
                  onChange={(e) => {setSelectedCountryCode(e.target.value);
                                    setIsFieldChanged(true)}}
                  className="profile-input"
                  renderValue={(selected) => {
                    const selectedCountry = COUNTRY_CODE.find(country => country.value === selected);
                    return selectedCountry ? selectedCountry.value : '';
                  }}
                >
                  {COUNTRY_CODE.map((country) => (
                    <MenuItem key={country.value} value={country.value}>
                      {country.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
                <Grid xs={4}>
                <FormLabel className="form-label" >Phone No.</FormLabel>
                  <TextField
                    required
                    id="phone"
                    label="Enter Phone Number."
                    variant="outlined"
                    type="number"
                    className="profile-input"
                    size="small"
                    name="phone"
                    {...register("phone")}
                    error={Boolean(errors.phone)}
                    helperText={errors.phone?.message}
                    value={dataValue.phone}
                    onChange={(e) => {
                      if (validatePhone(e.target.value)) {
                        setDataValue({ ...dataValue, phone: e.target.value });
                        setIsFieldChanged(true);
                      }
                    }}
                  />
                </Grid>
                <Grid xs={6}>
                  <FormLabel className="form-label">Email</FormLabel>
                  <TextField
                    disabled
                    id="email_id"
                    label="Enter Email"
                    variant="outlined"
                    type="text"
                    className="profile-input"
                    size="small"
                    name="email"
                    {...register("email_id")}
                    defaultValue={dataValue.email_id}
                  />
                </Grid>
              </Grid>
              <FormLabel className="form-label">Address Line 1</FormLabel>
              <TextField
                required
                id="address"
                label="Enter Address Line 1"
                variant="outlined"
                type="text"
                className="profileInputs"
                size="small"
                name="address"
                {...register("address")}
                error={Boolean(errors.address)}
                helperText={errors.address?.message}
                value={addressObj?.address_line1}
                onChange={(e) => {
                  setAddressObj({
                    ...addressObj,
                    address_line1: e.target.value,
                  });
                  setIsFieldChanged(true);
                }}
              />

              <Grid container spacing={2}>
                <Grid xs={6}>
                  <FormLabel className="form-label">Address Line 2</FormLabel>
                  <TextField
                    id="address2"
                    label="Enter Address Line 2"
                    variant="outlined"
                    type="text"
                    className="profileInputs"
                    size="small"
                    name="address_two"
                    {...register("address2")}
                    error={Boolean(errors.address2)}
                    helperText={errors.address2?.message}
                    value={addressObj?.address_line2}
                    onChange={(e) => {
                      setAddressObj({
                        ...addressObj,
                        address_line2: e.target.value,
                      });
                      setIsFieldChanged(true);
                    }}
                  />
                </Grid>
                <Grid xs={6}>
                  <FormLabel className="form-label">City</FormLabel>
                  <TextField
                    required
                    id="city"
                    label="Enter City"
                    variant="outlined"
                    type="text"
                    className="profile-input"
                    size="small"
                    name="city"
                    {...register("city")}
                    error={Boolean(errors.city)}
                    helperText={errors.city?.message}
                    value={addressObj?.city}
                    onChange={(e) => {
                      setAddressObj({ ...addressObj, city: e.target.value });
                      setIsFieldChanged(true);
                    }}
                  />
                </Grid>
                <Grid xs={6}>
                  <FormLabel className="form-label">State</FormLabel>
                  <Select
                    required
                    id="state"
                    variant="outlined"
                    size="small"
                    value={addressObj?.state_code}
                    error={Boolean(errors.state)}
                    helperText={errors.state?.message}
                    defaultValue={addressObj?.state_code}
                    {...register("state")}
                    onChange={(e) => {
                      const stateName = getStateName(e.target.value);
                      setAddressObj({ ...addressObj, state: stateName, state_code: e.target.value });
                      setIsFieldChanged(true);
                    }}
                    className="profile-input"
                  >
                    {
                      STATES.map(item => {
                        return (
                          <MenuItem value={item.value}>{item.label}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </Grid>
                <Grid xs={6}>
                  <FormLabel className="form-label">Pin Code</FormLabel>
                  <TextField
                    required
                    id="pin_code"
                    label="Enter Pin Code"
                    variant="outlined"
                    type="text"
                    className="profile-input"
                    size="small"
                    name="pin_code"
                    {...register("pin_code")}
                    error={Boolean(errors.pin_code)}
                    helperText={errors.pin_code?.message}
                    value={addressObj?.zipCode}
                    onChange={(e) => {
                      setAddressObj({ ...addressObj, zipCode: e.target.value });
                      setIsFieldChanged(true);
                    }}
                  />
                </Grid>
                <Grid xs={6}>
                  <FormLabel className="form-label">Father's Name</FormLabel>
                  <TextField
                    required
                    id="father_name"
                    label="Enter Father's Name"
                    variant="outlined"
                    type="text"
                    className="profile-input"
                    size="small"
                    name="fathername"
                    {...register("father_name")}
                    error={Boolean(errors.father_name)}
                    helperText={errors.father_name?.message}
                    defaultValue={dataValue?.father_name}
                    onChange={(e) => {
                      setDataValue({
                        ...dataValue,
                        father_name: e.target.value,
                      });
                      setIsFieldChanged(true);
                    }}
                  />
                </Grid>
                <Grid xs={6}>
                  <FormLabel className="form-label">Spouse Name</FormLabel>
                  <TextField
                    id="spouse_name"
                    label="Enter Spouse Name"
                    variant="outlined"
                    type="text"
                    className="profile-input"
                    size="small"
                    name="spousename"
                    {...register("spouse_name")}
                    defaultValue={dataValue?.spouse_name}
                    helperText={errors.spouse_name?.message}
                    onChange={(e) => {
                      setDataValue({
                        ...dataValue,
                        spouse_name: e.target.value,
                      });
                      setIsFieldChanged(true);
                    }}
                  />
                </Grid>
                <Grid xs={6}>
                  <FormLabel className="form-label">DOB</FormLabel>
                  <TextField
                    required
                    id="dob"
                    variant="outlined"
                    type="date"
                    className="profile-input"
                    size="small"
                    name="dob"
                    {...register("dob")}
                    error={Boolean(errors.dob)}
                    helperText={errors.dob?.message}
                    value={dataValue.dob}
                    inputProps={{ max: todayString }}
                    defaultValue={dataValue?.dob}
                    onChange={(e) => {
                      setDataValue({
                        ...dataValue,
                        dob: e.target.value,
                      });
                      setIsFieldChanged(true);
                    }}
                  />
                </Grid>
                <Grid xs={6}>
                  <FormLabel className="form-label">Gender</FormLabel>
                  <Select
                    required
                    id="gender"
                    variant="outlined"
                    size="small"
                    value={dataValue.gender}
                    error={Boolean(errors.gender)}
                    helperText={errors.gender}
                    defaultValue={dataValue.gender}
                    {...register("gender")}
                    onChange={(e) => {
                      setDataValue({ ...dataValue, gender: e.target.value });
                      setIsFieldChanged(true);
                    }}
                    className="profile-input"
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <FormLabel className="formLabels">Bank Details</FormLabel>
              <div className="bank-details">
                <Grid container spacing={1.5}>
                  <Grid xs={4}>
                    <FormLabel className="form-label">Account Name</FormLabel>
                    <TextField
                      required
                      id="account_name"
                      label="Enter Account Name"
                      variant="outlined"
                      type="text"
                      className="profile-input"
                      size="small"
                      name="accountname"
                      {...register("account_name")}
                      error={Boolean(errors.account_name)}
                      helperText={errors.account_name?.message}
                      fullWidth
                      value={dataValue.bank_account?.account_name}
                      onChange={(e) => {
                        const updatedAccountName = e.target.value;
                        const accDetails = dataValue.bank_account;
                        accDetails.account_name = updatedAccountName;
                        setDataValue({
                          ...dataValue,
                          bank_account: accDetails,
                        });
                        setIsFieldChanged(true);
                      }}
                    />
                  </Grid>
                  <Grid xs={4}>
                    <FormLabel className="form-label">Account No.</FormLabel>
                    <TextField
                      required
                      id="account_number"
                      label="Enter Account No."
                      variant="outlined"
                      type="number"
                      className="profile-input"
                      size="small"
                      name="accountno"
                      {...register("account_number")}
                      error={Boolean(errors.account_number)}
                      helperText={errors.account_number?.message}
                      fullWidth
                      value={dataValue.bank_account?.account_number}
                      onChange={(e) => {
                        const updatedAccountNumber = e.target.value;
                        const accDetails = dataValue.bank_account;
                        accDetails.account_number = updatedAccountNumber;
                        setDataValue({
                          ...dataValue,
                          bank_account: accDetails,
                        });
                        setIsFieldChanged(true);
                      }}
                    />
                  </Grid>
                  <Grid xs={4}>
                    <FormLabel className="form-label">IFSC Code</FormLabel>
                    <TextField
                      required
                      id="ifsc_code"
                      label="Enter IFSC Code"
                      variant="outlined"
                      type="text"
                      className="profile-input"
                      size="small"
                      name="ifsccode"
                      {...register("ifsc_code")}
                      error={Boolean(errors.ifsc_code)}
                      helperText={errors.ifsc_code?.message}
                      fullWidth
                      value={dataValue.bank_account?.ifsc_code}
                      onChange={(e) => {
                        const updatedIfscName = e.target.value;
                        const accDetails = dataValue.bank_account;
                        accDetails.ifsc_code = updatedIfscName;
                        setDataValue({
                          ...dataValue,
                          bank_account: accDetails,
                        });
                        setIsFieldChanged(true);
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
              <Grid container spacing={2}>
                <Grid xs={6}>
                  <FormLabel className="form-label">Bank Name</FormLabel>
                  <TextField
                    required
                    id="bank_name"
                    label="Enter Bank Name"
                    variant="outlined"
                    type="text"
                    className="profile-input"
                    size="small"
                    name="bankname"
                    {...register("bank_name")}
                    error={Boolean(errors.bank_name)}
                    helperText={errors.bank_name?.message}
                    value={dataValue.bank_account?.bank_name}
                    onChange={(e) => {
                      const updatedBankName = e.target.value;
                      const accDetails = dataValue.bank_account;
                      accDetails.bank_name = updatedBankName;
                      setDataValue({ ...dataValue, bank_account: accDetails });
                      setIsFieldChanged(true);
                    }}
                  />
                </Grid>
                <Grid xs={6}>
                  <FormLabel className="form-label">Branch</FormLabel>
                  <TextField
                    required
                    id="branch"
                    label="Enter Branch"
                    variant="outlined"
                    type="text"
                    className="profile-input"
                    size="small"
                    name="branch"
                    {...register("branch_name")}
                    error={Boolean(errors.branch_name)}
                    helperText={errors.branch_name?.message}
                    value={dataValue.bank_account?.branch_name}
                    onChange={(e) => {
                      const updatedBranchName = e.target.value;
                      const accDetails = dataValue.bank_account;
                      accDetails.branch_name = updatedBranchName;
                      setDataValue({ ...dataValue, bank_account: accDetails });
                      setIsFieldChanged(true);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid xs={6}>
                  <FormLabel className="form-label">Aadhaar No.</FormLabel>
                  <TextField
                    required
                    id="adhaar_no"
                    label="Enter Aadhaar No."
                    variant="outlined"
                    type="text"
                    className="profile-input"
                    size="small"
                    name="aadhaar"
                    {...register("aadhaar")}
                    error={Boolean(errors.aadhaar)}
                    helperText={errors.aadhaar?.message}
                    defaultValue={dataValue.aadhaar}
                    onChange={(e) => {
                      setDataValue({ ...dataValue, aadhaar: e.target.value });
                      setIsFieldChanged(true);
                    }}
                  />
                </Grid>
                <Grid xs={6}>
                  <FormLabel className="form-label">PAN No.</FormLabel>
                  <TextField
                    required
                    id="pan_no"
                    label="Enter PAN No."
                    variant="outlined"
                    type="text"
                    className="profile-input"
                    size="small"
                    name="pan"
                    error={Boolean(errors.pan)}
                    helperText={errors.pan?.message}
                    {...register("pan")}
                    defaultValue={dataValue?.pan}
                    onChange={(e) => {
                      setDataValue({ ...dataValue, pan: e.target.value });
                      setIsFieldChanged(true);
                    }}
                  />
                </Grid>
              </Grid>
              <Button
                variant="outlined"
                type="submit"
                className="form-btns"
                onClick={handleOpenModal}
              >
                Change Password
              </Button>
              {openModal && (
                <UpdatePassword
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                />
              )}
              <div className="profile-upload">
                <div className="image-container">
                  {loading ? (
                    <div className="loader">
                      <CircularProgress />
                    </div>
                  ) : (
                    <img
                      className="image"
                      src={profileImage ? profileImage : DummyProfileImage}
                      alt="Profile"
                    />
                  )}
                </div>
                <div className="input-selection">
                  <input
                    style={{ display: "none" }}
                    id="profile-image"
                    onChange={handleFileChange}
                    type="file"
                  />
                  <label className="label" htmlFor="profile-image">
                    <CameraAltIcon />
                  </label>
                </div>
              </div>
              <Button
                variant="outlined"
                type="submit"
                className={`formBtns ${
                  isFieldChanged ? "saveDisplay" : "saveHidden"
                }`}
              >
                Save
              </Button>
            </Form>
          </div>
        </div>
        <div className="desktop-footer">
          <footer>
            <Button
              style={{ color: "grey" }}
              variant="text"
              component={Link}
              to="/TermsOfUse"
              target="_blank"
            >
              Terms & Conditions
            </Button>
            <Button
              style={{ color: "grey" }}
              variant="text"
              component={Link}
              to="/PrivacyPolicy"
              target="_blank"
            >
              Privacy Policy
            </Button>
          </footer>
          <p>Copyright © 2020. Agarwal Estates. All rights reserved.</p>
        </div>
      </Main>
    </div>
  );
};
export default ProfileSettings;
