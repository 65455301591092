import { createAsyncThunk } from "@reduxjs/toolkit";
import { addTemplate, getTemplate, updateTemplate } from "../../services/api";

export const AddTemplateData = createAsyncThunk('templateSlice/AddTemplateData', async (args, {rejectWithValue}) =>{
    try {
       const {data}  = await addTemplate(args);
       return data
    } catch(e) {
        rejectWithValue(e.response.data)
    }
})


export const GetTemplateData = createAsyncThunk('templateSlice/GetTemplateData', async (args, {rejectWithValue}) =>{
    try {
       const {data}  = await getTemplate();
       return data
    } catch(e) {
        rejectWithValue(e.response.data)
    }
})



export const updateTemplateData = createAsyncThunk('templateSlice/updateTemplateData', async (args, {rejectWithValue}) =>{
    if ((args["template_type"] === "EMAIL")) {
        delete args["template_subtype"];
    }
    try {
       const {data}  = await updateTemplate(args);
       return data
    } catch(e) {
        rejectWithValue(e.response.data)
    }
})
