import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import { API } from "../../utils/api";

export const postData = createAsyncThunk('superAdminResetSlice/postData', async (args, {rejectWithValue}) =>{
  try {
     const {data}  = await Axios.post(`${API.baseURL[API.currenEnv]}superadmin/reset`,args);
     return data
  } catch(e) {
      rejectWithValue(e.response.data)
  }
})