import React, { useState, useEffect } from "react";
import Main from "../../layout/Main";
import { useToasts } from "react-toast-notifications";
import "./style.scss";
import { useSelector } from "react-redux";
import { TextField, InputAdornment, Button } from "@mui/material";
import { Space, Spin, Typography, Divider } from "antd";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { getSuperAdminById, updateSuperAdmin } from "../../services/api";
import { ERROR_KEY, SUCCESS_KEY, defaultValues, getErrorMessage, getSuccessMessage } from "../../constants";
import { Routes } from "../../constants/routes";

const validationSchema = Yup.object().shape({
  platform_fee_config: Yup.object().shape({
      platform_fees: Yup.number().required("Platform Fee is required"),
      token_percentage: Yup.number()
        .required("Token Percentage is required")
        .test(
          "Token Percentage",
          "Percentage should be less than 100",
          (val) => val && val <= 100
        ),
      registration_percentage: Yup.number()
        .required("Registration Amount is required")
        .test(
          "Registration Percentage",
          "Percentage should be less than 100",
          (val) => val && val <= 100
        ),
    })
  
});

const SuperAdminSettings = (props) => {
  const { Title } = Typography;
  const { addToast } = useToasts();
  const loggedInUser = useSelector((state) => state.loggedInUser);

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const accessToken =
    useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";

    useEffect(() => {
    if (!accessToken) {
      history.push("/super-admin-login");
    }
    if(loggedInUser.selectedRole?.role_name !== defaultValues.role_names.super_admin && loggedInUser.selectedRole?.role_name !==  defaultValues.role_names.admin){
      history.push(Routes.HOME)
    }
  }, []);

  const [validationErrors, setValidationErrors] = useState({});
  const [formData, setFormData] = useState({
    id: `${loggedInUser.data?.data?.superAdminByEmail?._id}`,
    platform_fee_config:{
      platform_fees: "",
      platform_fee_description: "",
      token_percentage: "",
      token_percentage_description: "",
      registration_percentage: "",
      registration_percentage_description: "",
      backout_mou: "0",
      backout_mou_description: "",
      backout_agreement: "0",
      backout_agreement_description: "",
      backout_saleDeed: "0",
      backout_saleDeed_description: ""
    },
    
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getSuperAdminById(loggedInUser.data?.data?.superAdminByEmail._id);
        const data = response.data;
        if (data?.superAdminData.platform_fee_config ) {
          const platformFeeConfigData = data.superAdminData.platform_fee_config;
          setFormData(prevFormData => ({
            ...prevFormData,
            platform_fee_config: {
                platform_fees: platformFeeConfigData.platform_fees || '',
                platform_fee_description: platformFeeConfigData.platform_fee_description || '',
                token_percentage: platformFeeConfigData.token_percentage || '',
                token_percentage_description: platformFeeConfigData.token_percentage_description || '',
                registration_percentage: platformFeeConfigData.registration_percentage || '',
                registration_percentage_description: platformFeeConfigData.registration_percentage_description || '',
                backout_mou: platformFeeConfigData.backout_mou || '0',
                backout_mou_description: platformFeeConfigData.backout_mou_description || '',
                backout_agreement: platformFeeConfigData.backout_agreement || '0',
                backout_agreement_description: platformFeeConfigData.backout_agreement_description || '',
                backout_saleDeed: platformFeeConfigData.backout_saleDeed || '0',
                backout_saleDeed_description: platformFeeConfigData.backout_saleDeed_description || '',
              },            
          }));
        }
      } catch (error) {
        addToast(getErrorMessage('E-10150'), { appearance: ERROR_KEY, autoDismiss: true });
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
  
    let updatedValue = value;
    if (
      name === "token_percentage" ||
      name === "registration_percentage" ||
      name === "backout_mou" ||
      name === "backout_agreement" ||
      name === "backout_saleDeed"
    ) {
      const parsedValue = parseInt(value);
      if (parsedValue > 100) {
        updatedValue = "100";
      } else if (parsedValue < 0) {
        updatedValue = "0"; 
      }
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      platform_fee_config:
        {
          ...prevFormData.platform_fee_config,
          [name]: updatedValue,
        },
    }));
  };
  
  

  const onSave = async (event) => {
    event.preventDefault();
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setLoading(true);
      
      var response = await updateSuperAdmin(formData);
      
      if(response.status !== 200){
        addToast(response.data.message || response.data.error || getErrorMessage('E-10070'), {
          appearance: ERROR_KEY,
          autoDismiss: true
        });
      }else {
        addToast(getSuccessMessage('S-10009'), {
          appearance: SUCCESS_KEY,
          autoDismiss: true,
        });
      }
      setValidationErrors({});
    } catch (error) {
      const validationErrors = {};
    error.inner.forEach((error) => {
      let errorMessage = error.message;
      const maxLength = 50;
      if (errorMessage.length > maxLength) {
        errorMessage = `${errorMessage.substring(0, maxLength)}...`;
      }
      validationErrors[error.path] = errorMessage;
    });
    setValidationErrors(validationErrors);
      addToast(getErrorMessage("E-10235"), { appearance: ERROR_KEY, autoDismiss: true });
    }
    setLoading(false);
  };

  return (
    <Main
      showNav={!props?.showNav}
      showFooter={!props?.showFooter}
      showLogo={!props?.showLogo}
      showAdminFooter={!props?.showAdminFooter}
      showAdminNavbar={!props?.showAdminNavbar}
    >
      <div className="container">
        <div className="fee-container">
          <div className="inner-container">
            <div className="d-flex">
              <Divider plain>
                <Title level={3}>Property Fee Settings</Title>
              </Divider>
            </div>
            <form onSubmit={onSave}>
              <div className="mt-4 d-flex justify-content-between">
              
                <TextField
                className="textfield-gap"
                fullWidth= {true}
                  required
                  id="outlined-basic"
                  label="Platform Fee"
                  variant="outlined"
                  name="platform_fees"
                  value={formData.platform_fee_config.platform_fees}
                  onChange={handleInputChange}
                  size="large"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">₹</InputAdornment>
                    ),
                  }}
                  type="number"
                  error={Boolean(validationErrors["platform_fee_config.platform_fees"])}
                  helperText={validationErrors["platform_fee_config.platform_fees"]}
                />
                <TextField
                className="textfield-gap"
                fullWidth= {true}
                  required
                  id="outlined-multiline-flexible"
                  label="Description"
                  multiline
                  maxRows={4}
                  name="platform_fee_description"
                  size="medium"
                  value={
                    formData.platform_fee_config.platform_fee_description
                  }
                  onChange={handleInputChange}
                />
              </div>
              <div className="mt-4 d-flex justify-content-evenly">
                <TextField
                className="textfield-gap"
                fullWidth= {true}
                  required
                  id="outlined-basic"
                  label="Token Percentage"
                  variant="outlined"
                  name="token_percentage"
                  value={formData.platform_fee_config.token_percentage}
                  onChange={handleInputChange}
                  size="large"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                  type="number"
                  error={Boolean(validationErrors["platform_fee_config.token_percentage"])}
                  helperText={validationErrors["platform_fee_config.token_percentage"]}
                />
                <TextField
                className="textfield-gap"
                fullWidth= {true}
                  required
                  id="outlined-multiline-flexible"
                  label="Description"
                  multiline
                  maxRows={4}
                  name="token_percentage_description"
                  value={
                    formData.platform_fee_config.token_percentage_description
                  }
                  onChange={handleInputChange}
                  size="medium"
                />
              </div>
              <div className="mt-4 d-flex justify-content-evenly">
                <TextField
                className="textfield-gap"
                fullWidth= {true}
                  required
                  id="outlined-basic"
                  label="Registration Advance"
                  variant="outlined"
                  name="registration_percentage"
                  value={formData.platform_fee_config.registration_percentage}
                  onChange={handleInputChange}
                  size="large"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  type="number"
                  error={Boolean(validationErrors["platform_fee_config.registration_percentage"])}
                  helperText={validationErrors["platform_fee_config.registration_percentage"]}
                />
                <TextField
                className="textfield-gap"
                fullWidth= {true}
                  required
                  id="outlined-multiline-flexible"
                  label="Description"
                  multiline
                  maxRows={4}
                  name="registration_percentage_description"
                  value={
                    formData.platform_fee_config
                      .registration_percentage_description
                  }
                  onChange={handleInputChange}
                  size="medium"
                />
              </div>


              <div className="mt-4 d-flex justify-content-evenly">
                <TextField
                className="textfield-gap"
                fullWidth= {true}
                  id="outlined-basic"
                  label="Back out Penalty at MoU"
                  variant="outlined"
                  name="backout_mou"
                  value={formData.platform_fee_config.backout_mou}
                  onChange={handleInputChange}
                  size="large"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),

                  }}
                  type="number"
                  error={Boolean(validationErrors["platform_fee_config.backout_mou"])}
                  helperText={validationErrors["platform_fee_config.backout_mou"]}
                />
                <TextField
                  className="textfield-gap"
                  fullWidth= {true}
                  id="outlined-multiline-flexible"
                  label="Description"
                  multiline
                  maxRows={4}
                  name="backout_mou_description"
                  value={
                    formData.platform_fee_config
                      .backout_mou_description
                  }
                  onChange={handleInputChange}
                  size="medium"
                />
              </div>

              <div className="mt-4 d-flex justify-content-evenly">
                <TextField
                className="textfield-gap"
                fullWidth= {true}
                  id="outlined-basic"
                  label="Back out Penalty at Agreement"
                  variant="outlined"
                  name="backout_agreement"
                  value={formData.platform_fee_config.backout_agreement}
                  onChange={handleInputChange}
                  size="large"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  type="number"
                  error={Boolean(validationErrors["platform_fee_config.backout_agreement"])}
                  helperText={validationErrors["platform_fee_config.backout_agreement"]}
                />
                <TextField
                className="textfield-gap"
                fullWidth= {true}
                  id="outlined-multiline-flexible"
                  label="Description"
                  multiline
                  maxRows={4}
                  name="backout_agreement_description"
                  value={
                    formData.platform_fee_config
                      .backout_agreement_description
                  }
                  onChange={handleInputChange}
                  size="medium"
                />
              </div>

              <div className="mt-4 d-flex justify-content-evenly">
                <TextField
                className="textfield-gap"
                fullWidth= {true}
                  id="outlined-basic"
                  label="Back out penalty at Sale deed"
                  variant="outlined"
                  name="backout_saleDeed"
                  value={formData.platform_fee_config.backout_saleDeed}
                  onChange={handleInputChange}
                  size="large"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  type="number"
                  error={Boolean(validationErrors["platform_fee_config.backout_saleDeed"])}
                  helperText={validationErrors["platform_fee_config.backout_saleDeed"]}
                />
                <TextField
                className="textfield-gap"
                fullWidth= {true}
                  id="outlined-multiline-flexible"
                  label="Description"
                  multiline
                  maxRows={4}
                  name="backout_saleDeed_description"
                  value={
                    formData.platform_fee_config
                      .backout_saleDeed_description
                  }
                  onChange={handleInputChange}
                  size="medium"
                />
              </div>
            </form>

            <div className="mt-4 d-flex justify-content-center">
              <Button
                variant="contained"
                onClick={onSave}
                sx={{ bgcolor: "var(--primaryButton)", textTransform: "capitalize","&:hover": { bgcolor: "var(--primaryButton)"} }}
              >
                Save
              </Button>
              {loading ? (
                <Space size="middle">
                  <Spin size="large" />
                </Space>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default SuperAdminSettings;