export const Routes = {
    HOME: '/',
    SUPER_ADMIN_LOGIN: '/super-admin-login',
    ...{
        SUPER_ADMIN_SETTINGS: '/super-admin-settings',
        MANAGE_TEMPLATES: '/manage-templates',
        MANAGE_CHECKLISTS: '/manage-checklist',
        MANAGE_ADMINS: '/manage-admins',
        MANAGE_USERS: '/manage-users',
        MANAGE_PROPERTIES: '/manage-properties',
    },
    ADMIN_LOGIN: '/admin-login',
    SIGN_UP: '/signup',
    ...{
        USER_HOME: '/user-home',
        FORGOT_PASSWORD: '/forgot-password',
        RESET_PASSWORD: '/reset-password',
        PROFILE_SETTINGS: '/profile-settings',
    },
    DASHBOARD: '/dashboard',
    MANAGE_PROFILE: '/manage-profile',
    PROPERTIES: '/properties',
    ...{
        INITIATE_OFFER: '/initiate-offer',
        SIGN_OFFER: '/sign-offer',
        VIEW_PROPERTY: '/view-property',
        OFFER_LETTER: '/offer-letter',
        PLATFORM_FEE: '/platform-fee',
        VERIFY_PAYMENT: '/payment_verify',
        TOKEN_PAYMENT: '/token',
        REGISTRATION: '/registration',
        REVIEW_MOU: '/review-mou',
        REVIEW_SALE_AGREEMENT: '/review-sale-agreement',
        REVIEW_SALE_DEED: '/review-sale-deed',
        REVIEW_DOCUMENTS: '/review-documents',
        EDIT_MOU: '/edit-mou',
        UPDATE_TDS_DETAILS: '/update-tds-details',
        SET_REGISTRATION_DATE: '/set-registration-date',
        APPLY_LOAN: '/apply-loan',
        SIGN_MOU: '/sign-mou',
        SIGN_AGREEMENT: '/sign-sale-agreement',
        SIGN_DEED: '/sign-sale-deed',
        PAY_TDS: '/pay-tds',
        LEGAL_VERIFICATION: '/legal-verification',
        PROPERTY_INSPECTION: '/property-inspection',
        LOAN_AGREEMENT: '/loan-agreement',
        LOAN_SANCTION: '/loan-sanction',
        AGENT_PROFILE: '/agent-profile',
        BACK_OUT: '/backout',
        FALLBACK: '**',
    },
    SUBMIT_RENTAL_AGREEMENT: '/submit-rental-agreement',
    RENTAL_AGREEMENT: '/rental-agreement'
}