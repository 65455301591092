import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory,useLocation } from "react-router-dom";

import Axios from "axios";
import { useToasts } from "react-toast-notifications";

import {
  CssBaseline,
  Button,
  TextField,
  Typography,
  Container,
  Box,
} from "@mui/material";

import "../login/style.scss";

import LandingFooter from "../general-landing-page/LandingFooter";
import GeneralNav from '../general-landing-page/GeneralNav'
import { API } from "../../utils/api";
import { ERROR_KEY, SUCCESS_KEY, defaultValues, getErrorMessage, getSuccessMessage } from "../../constants";
import { Routes } from "../../constants/routes";


const ForgotPassword = () => {
  const [email,setEmail]=useState("")
  const history = useHistory();
  const { addToast } = useToasts();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const user = queryParams.get("user");

  const passwordSchema = yup.object({
    email: yup
      .string()
      .email(getErrorMessage('E-10008'))
      .required(getErrorMessage('E-10029')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(passwordSchema),
    mode: "onBlur"
  });

  const onSubmit = (_data, e) => {
    e.target.reset();
    e.preventDefault()
  };

  const handleClick = async ()=>{
    let url= ""
    if (user === defaultValues.role_names.super_admin) {
      url = `${API.baseURL[API.currenEnv]}superAdmin/forgot`;
    } else if(user === defaultValues.role_names.admin){
      url = `${API.baseURL[API.currenEnv]}admin/forgot`;
    }else if( user === defaultValues.role_names.user){
      url = `${API.baseURL[API.currenEnv]}user/forgot`;
    }

    try {
      const response = await Axios({
        method: "post",
        url,
        data: { email }
      });
      if(response.status === 200 ){
        history.push(`${Routes.RESET_PASSWORD}?email=${email}&user=${user}`);
        addToast(getSuccessMessage('S-10005'), { appearance: SUCCESS_KEY, autoDismiss: true });
      }
    }catch (error) {
      addToast(getErrorMessage('E-10142'), { appearance: ERROR_KEY, autoDismiss: true });
    }
  }
  
  return (
    <>
    <GeneralNav />
    <Container component="main" maxWidth="xs">
    <CssBaseline />
    <Box
      sx={{
        marginTop: 11,
        marginBottom:14,
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h2" variant="h5" sx={{textAlign:'center', mt:4}}>
        Forgot Password
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              {...register("email")}
              error={Boolean(errors.email)}
              onChange={(e)=>{setEmail(e.target.value)}}
              value={email}
            />
            <div className="login-errors">
             {errors.email ? (<>{errors.email.message}</>
          ) : null}
          </div>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, bgcolor:'var(--primaryColor)' }}
          onClick={handleClick}
        >
          Send OTP
        </Button>
      </Box>
    </Box>
  </Container>
  <LandingFooter />
    </>
  );
};

export default ForgotPassword;
