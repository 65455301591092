import React from "react";
import SignPage from "../../commonComponent/signPage";
import { defaultValues } from "../../constants";

const SignSaleDeed = () => {
  return (
    <SignPage pageTitle={defaultValues.flow_list.sign_sale_deed} />
  )
};

export default SignSaleDeed;
