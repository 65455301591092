import React from "react";
import "./style.scss";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { resetSelectedRental } from "../slices";
import { Routes } from "../../../constants/routes";

export const StatusRentalAgreement = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handelNewAgreement = () => {
    dispatch(resetSelectedRental());
    history.push({
      pathname: Routes.PROPERTIES,
      state: { argId: null, tab: "rental" },
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12 text-center mx-auto">
          <h4 className="mb-2 r-title text-center py-3">
            Rental Agreement generated successfully
          </h4>
          <p className="r-pText text-center mt-5">
            <b>Congratulations!</b> You've successfully completed all the
            necessary steps to create your rental agreement using our platform.
            Now, it's time to take the next exciting step – signing your
            document electronically.
          </p>
          <p className="r-pText r-p-heading mb-2">
            Action Required: E-Sign Your Rental Agreement :
          </p>
          <p className="r-pText mt-2">
            Please check your inbox for an email from SignDesk, our trusted
            e-signature partner. This email will guide you through the quick and
            easy process of electronically signing your rental agreement. If you
            can't find the email in your inbox, please be sure to check your
            spam or junk folder.
          </p>
          <p className="r-pText r-p-heading mb-2">What's Next?</p>
          <p className="r-pText mt-2">
            Once all parties have signed the rental agreement, you will receive
            another email from SignDesk. This email will contain a link to
            download the signed document for your records. Keep an eye on your
            inbox for this final step.
          </p>
          <p className="text-center badge badge-success r-p-subHeading mt-4">
            Thank you for trusting us with your rental agreement needs. We look
            forward to serving you in the future.
          </p>
          <p className="r-pText r-p-heading mb-2 text-center">
            <Button
              className="r-click-btn ml-2 link-primary"
              variant="primary"
              onClick={handelNewAgreement}
            >
              Back to home
            </Button>
          </p>
        </div>
      </div>
    </>
  );
}