import React, { useEffect, useState } from "react";
import Main from "../../layout/Main";

import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Pagination from "@mui/material/Pagination";

import {
  AddChecklistData,
  GetChecklistData,
  UpdateChecklistData,
  DeleteChecklistData,
} from "./service";

import Table from "react-bootstrap/Table";
import AddChecklist from "./AddChecklist";
import EditChecklist from "./EditChecklist";

import "./style.scss";

import circlePlus from "../../assets/circle-plus.svg";

import { Button, Dialog, DialogTitle, DialogActions } from "@mui/material";
import { Routes } from "../../constants/routes";
import { ERROR_KEY, ROLE_KEY, SUCCESS_KEY, defaultValues } from "../../constants";

function Page(props) {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const countPerPage = 20;

  const dispatch = useDispatch();
  const role = sessionStorage.getItem(ROLE_KEY);
  const adminChecklist = useSelector((state) => state.adminChecklist);
  const [state, setState] = useState({
    currentChecklist: {},
    checklist_type: "",
  });
  const { addToast } = useToasts();
  const history = useHistory();

  const accessToken =
    useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";

  useEffect(() => {
    if (!accessToken) {
      history.push(Routes.SUPER_ADMIN_LOGIN);
    }
    if (role !== defaultValues.role_names.super_admin && role !== defaultValues.role_names.admin) {
      props.history.push(Routes.HOME);
    }
  }, [accessToken, history]);

  useEffect(() => {
    dispatch(GetChecklistData());
  }, [dispatch]);

  useEffect(() => {
    if (adminChecklist.message) {
      if (adminChecklist.status === SUCCESS_KEY) {
        addToast(adminChecklist.message, {
          appearance: SUCCESS_KEY,
          autoDismiss: true,
        });
      } else {
        addToast(adminChecklist.message, {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
      }
    }
  }, [adminChecklist.message, adminChecklist.status, addToast]);

  useEffect(() => {
    const filteredChecklists = adminChecklist.checklists?.filter(
      (i) =>
        String(i.PropertyType).toLowerCase() ===
        String(state.checklist_type).toLowerCase()
    );
    setTotalCount(filteredChecklists?.length);
  }, [adminChecklist.checklists, state.checklist_type]);

  const handleCloseDeleteDialog = () => {
    setState({ ...state, currentChecklist: {} });
    setDeleteDialogOpen(false);
  };

  const handleDeleteChecklist = (checklist) => {
    setState({ ...state, currentChecklist: checklist });
    setDeleteDialogOpen(true);
  };

  const PropertyType = {
    FlatApartment: defaultValues.property_types.flat,
    VillaIndependentHouseRowHouse: defaultValues.property_types.villa,
    PentHouse: defaultValues.property_types.pent_house,
    EmptyLand: defaultValues.property_types.empty_land,
  };

  const propertyTypes = Object.values(PropertyType);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  const handleDeleteChecklistData = async (checklist) => {
    await dispatch(DeleteChecklistData(checklist._id));
    handleCloseDeleteDialog();
    await dispatch(GetChecklistData());
  };

  const handleUpdateDataChecklistData = async (data) => {
    if(data.stages?._id) {
      data.stages = data.stages._id;
    }
    await dispatch(UpdateChecklistData(data));
    await dispatch(GetChecklistData());
  };

  const handleAddChecklistData = async (data) => {
    await dispatch(AddChecklistData(data));
    await dispatch(GetChecklistData());
  }

  const startIndex = (currentPage - 1) * countPerPage;
  const endIndex = startIndex + countPerPage;
  const paginatedChecklists = adminChecklist.checklists
    ?.filter(
      (i) =>
        String(i.PropertyType).toLowerCase() ===
        String(state.checklist_type).toLowerCase()
    )
    .slice(startIndex, endIndex);

  return (
    <Main
      showNav={!props?.showNav}
      showFooter={!props?.showFooter}
      showLogo={!props?.showLogo}
      showAdminNavbar={!props?.showAdminNavbar}
    >
      <div className="row manage-checklist">
        <div className="btn-group-right">
          <select
          style={{ backgroundColor:"white", color:"black" }}
            className="form-select select-menu"
            onChange={(e) =>
              setState({
                ...state,
                checklist_type: String(e.target.value),
              })
            }
          >
            <option value="">-- Select Property Type --</option>
            <option value={defaultValues.property_types.flat}>Flat Apartment</option>
            <option value={defaultValues.property_types.villa}>
              Villa / Independent House / Row House
            </option>
            <option value={defaultValues.property_types.pent_house}>Pent House</option>
          </select>
        </div>
        {state.checklist_type ? (
          <>
            <div className="checklist-area col-12">
              <div className="plus-btn-wrap">
                <img
                  src={circlePlus}
                  className="plus-btn"
                  onClick={() => setState({ ...state, isAddOpen: true })}
                  alt="plus"
                />
              </div>
              <div className="checklist-table-row">
                {paginatedChecklists && paginatedChecklists.length ? (
                  <Table className="checklist-table ">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Document Name</th>
                        <th>Description</th>
                        <th>Stage</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedChecklists.map((item, index) => (
                        <tr key={index}>
                          <td>{startIndex + index + 1}</td>
                          <td className="document">{item.title}</td>
                          <td>{item.description}</td>
                          <td>{item.stages ? item.stages.stage_name : "-"}</td>
                          <td>
                          {item.title === "TDS" || item.title === "PropertyImages" ? null : (
                        <>
                          <img
                            src="/imgs/edit.svg"
                            alt="Edit"
                            className="edit-icon large-icon"
                            onClick={() =>
                              setState({
                                ...state,
                                isEditOpen: true,
                                currentChecklist: item,
                              })
                            }
                          />
                          <img
                            src="/imgs/delete-icon.svg"
                            alt="Delete"
                            className="edit-icon large-icon"
                            onClick={() => handleDeleteChecklist(item)}
                          />
                      </>
                      )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <p>No checklists found.</p>
                )}
              </div>
            </div>
            <Pagination
              className="mPage"
              count={Math.ceil(totalCount / countPerPage)}
              color="primary"
              onChange={handlePageChange}
              page={currentPage}
            />
          </>
        ) : null}
      </div>
      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle
          sx={{ background: "var(--primaryColor)", color: "var(--white)" }}
        >
          Are you sure you want to delete this checklist?
        </DialogTitle>
        <DialogActions sx={{ background: "var(--primaryColor)" }}>
          <Button
            onClick={handleCloseDeleteDialog}
            sx={{
              color: "var(--white)",
              "&:hover": {
                backgroundColor: "var(--white)",
                color: "var(--drakBlack)",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDeleteChecklistData(state.currentChecklist);
            }}
            sx={{
              color: "var(--white)",
              "&:hover": {
                backgroundColor: "var(--white)",
                color: "var(--red)",
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <AddChecklist
        isOpen={state.isAddOpen}
        onClose={() => {
          setState({ ...state, isAddOpen: false });
        }}
        onSubmit={handleAddChecklistData}
        currentType={propertyTypes}
        currentChecklist={state}
      />

      {state.currentChecklist ? (
        <EditChecklist
          isOpen={state.isEditOpen}
          currentChecklist={state.currentChecklist}
          currentType={state.checklist_type}
          onSubmit={handleUpdateDataChecklistData}
          onClose={() => {
            setState({ ...state, isEditOpen: false });
          }}
        />
      ) : null}
    </Main>
  );
}

export default Page;
