import React,{ useState, useEffect } from "react";
import Main from "../../layout/Main";
import "./style.scss";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  FormControl,
  FormGroup
} from "@mui/material";
import { useSelector } from "react-redux";
import { useHistory,useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getPropertyDetails, updateProperty } from "../../services/api";
import { ERROR_KEY, PID_KEY, SUCCESS_KEY, defaultValues, getErrorMessage, getSuccessMessage } from "../../constants";
import { Routes } from "../../constants/routes";


const LegalVerification = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const [propertyData, setPropertyData] = useState();

  const accessToken = useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";
  const propertyId = useSelector((state)=> state?.buyerHomeData?.selectedProperty?._id) || '';
  const roleName = useSelector((state) => state.loggedInUser?.roles[0]?.role_name);
  const [completed, setCompleted] = useState(false);

  const handleSave=(propertyId)=>{
    if (!completed) {
      addToast(getErrorMessage('E-10120'), {
        appearance: ERROR_KEY,
        autoDismiss: true,
      });
      return;
    }
    const payload = {
      id: propertyId,
      bank: {
        legal_verification: completed,
        loan_sanctioned: propertyData?.bank?.loan_sanctioned,
        inspection_time_date: propertyData?.bank?.inspection_time_date,
        loan_disbursement: propertyData?.bank?.loan_disbursement,
      }
    };

    updateProperty(payload)
      .then(() => {
        addToast(getSuccessMessage('S-10022'), { appearance: SUCCESS_KEY, autoDismiss: true });
        history.push(`${Routes.PROPERTY_INSPECTION}?pid=${propertyId}`);
      })
      .catch((error) => {
        addToast(error.response.data?.message || getErrorMessage('E-10070'), { appearance: ERROR_KEY, autoDismiss: true });
      });
  }

  useEffect(() => {
    const pid = searchParams.get(PID_KEY);
    if (!accessToken) {
      history.push(Routes.HOME);
    } else {
      if(roleName === defaultValues.role_names.bank_agent){   
        if (!propertyId) {
          history.push(Routes.USER_HOME);
        } else if (pid !== propertyId) {
          history.push(`${Routes.LEGAL_VERIFICATION}?pid=${propertyId}`);
        }
      } else { history.push(`${Routes.VIEW_PROPERTY}?pid=${propertyId}`); }
    }
  }, []);

  useEffect(() => {
    getPropertyDetails(propertyId).then(res => {
      setPropertyData(res.data.propertyData);
      if(res.data.propertyData?.bank?.legal_verification){
        setCompleted(res.data.propertyData?.bank?.legal_verification);
      }
    });
  },[propertyId]);

  return (
    <div>
      <Main showUserNavbar={!props?.showUserNavbar} showUser={!props?.showUser}>
      <Box
          display="flex"
          flexDirection="column"
          justifyContent="start"
          alignItems="start"
          minHeight="70vh"
          bgcolor="var(--white)"
        >
      <div className="container">
            <div className="inner-container">
        <div className="fee-container">
        <Typography variant="h5" component="h3" sx={{ fontWeight: 100 }}>
         Legal Verification Of Property Documents By Bank
          </Typography>
          <div className="picker">
          <FormControl
            required
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="inprogress"
                    disabled
                  />
                }
                label="In Progress"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="completed"
                    checked={completed}
                    onChange={(e) => setCompleted(e.target.checked)}
                  />
                }
                label="Completed"
                className="black-text"
              />
            </FormGroup>
            <Button variant="contained" sx={{bgcolor:'var(--primaryColor)'}} 
              onClick={()=>{handleSave(propertyId)}}>Proceed</Button>
          </FormControl>
          </div>
        </div>
        </div>
        </div>
        </Box>
      </Main>
    </div>
  );
};
export default LegalVerification;