export const JsStyles = {
  font09: { fontSize: "0.9rem" },
  accordionDet: (theme) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }),
  accordionSum: (theme) => ({
    backgroundColor: "var(--primaryColorLight)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }),
  dialogActions: {
    justifyContent: "center",
    marginY: "15px",
  },
};
