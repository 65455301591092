import React, { useEffect, useState } from "react";
import SimpleImageSlider from "react-simple-image-slider";
import { useSelector, useDispatch } from "react-redux";
import editIcon from "../../../assets/edit-icon.svg";
import EditProperty from "../../../layout/PropertyModal/EditPropertyModal/editProperty";
import { getPropertiesData } from "../service";
import { useLocation } from "react-router-dom";
import Loader from "../../../layout/Loader/Loader";
import ArrowCircleLeftSharpIcon from '@mui/icons-material/ArrowCircleLeftSharp';
import ArrowCircleRightSharpIcon from '@mui/icons-material/ArrowCircleRightSharp';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DummypropertyImage from "../../../assets/property-images/pexels-curtis-adams-3288100.jpg";
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import CircularProgress from '@mui/material/CircularProgress';
import { useToasts } from "react-toast-notifications";
import { DeleteRounded } from "@mui/icons-material";
import DeletePropertyImages from "../../../layout/PropertyModal/EditPropertyModal/deleteImageModal";
import { IconButton } from "@mui/material";
import { INFO_KEY, PID_KEY, defaultValues, getErrorMessage } from "../../../constants";

const PropertyDetailsData = ({user , propertyImageAttribute =[] , imageCallBack, addpropertyImages, propertyImageLoader, callBack}) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const [isLoading, setIsLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [imgArr, setImgArr] = useState([]);
  const [currentImage , setCurrentImage] = useState(0);
  const [showImagePreview , setShowImagePreview] = useState(false);
  const [editDisabled, setEditDisabled] = useState(false);
  const [showDeleteImgModal, setShowDeleteImgModal] = useState(false);
  const [showImageOptions, setShowImageOptions] = useState(false);
  const viewPropertiesData = useSelector(
    (state) => state.superAdminProperties.propertyData
  );
  const subStages = useSelector((state)=> state?.stages?.subStages)
  const role = useSelector((state) => state.loggedInUser.preferredRole);

  const getSubStageOrder = (id) => subStages.filter((substage)=> substage._id === id)[0].order; 

  
  useEffect(() => {
    if (
      role === defaultValues.role_names.super_admin ||
      role === defaultValues.role_names.admin ||
      role === defaultValues.role_names.buyer ||
      role === defaultValues.role_names.seller
    ) {
      setShowImageOptions(true);
    }
  }, [role]);

  useEffect(() => {
    if(propertyImageAttribute.length) {
      let tempArr = [];
      propertyImageAttribute.map(item => {
        let obj = {url: item.url};
        tempArr.push(obj);
        return tempArr;
      });
      setImgArr(tempArr)
    }
  }, [propertyImageAttribute]);

  useEffect(() => {
    let disabled = false;
    if (viewPropertiesData?.current_stage?.order_number > 2) {
      disabled = true;
    } else if (viewPropertiesData?.current_stage?.order_number === 2) {
      if (getSubStageOrder(viewPropertiesData.sub_current_stage) > 3) {
        disabled = true;
      }
    }
    setEditDisabled(disabled);
  }, [viewPropertiesData]);

  const getData = () => {
    setIsLoading(true);
    dispatch(getPropertiesData(searchParams.get(PID_KEY))).then(() => {
      setIsLoading(false);
      callBack();
    });
  };


  const nextSlide = ()=>{
    const length = propertyImageAttribute.length;
    setCurrentImage(currentImage === length-1 ? 0 : currentImage + 1)
  }

  const prevSlide = ()=> {
    const length = propertyImageAttribute.length;
    setCurrentImage(currentImage === 0 ? length -1  : currentImage - 1)
  }


  const handleFileChange = (e)=>{
    const selectedFile = e.target.files[0];
    if(selectedFile){
      addpropertyImages(selectedFile)
    }
  }

  const getEditPropertyObj = () => {
    let obj = {
      _id: viewPropertiesData?._id,
      property_type: viewPropertiesData?.PropertyType,
      flat_no: viewPropertiesData?.flat_no,
      super_built_up_area: viewPropertiesData?.super_built_up_area,
      door_facing_direction: viewPropertiesData?.door_facing_direction,
      car_park: viewPropertiesData?.car_park,
      carParkType: viewPropertiesData?.carParkType,
      sale_deed_number: viewPropertiesData?.sale_deed_number,
      sale_deed_date: viewPropertiesData?.sale_deed_date,
      previous_owner: viewPropertiesData?.previous_owner,
      common_area: viewPropertiesData?.common_area,
      bhk: viewPropertiesData?.bhk,
      furnishing_status: viewPropertiesData?.furnishing_status,
      floor_no: viewPropertiesData?.floor_no,
      no_of_floor: viewPropertiesData?.no_of_floor,
      society_name: viewPropertiesData?.society_name,
      address_line1: viewPropertiesData?.address
        ? viewPropertiesData?.address[0]?.address_line1
        : "",
      address_line2: viewPropertiesData?.address
        ? viewPropertiesData?.address[0]?.address_line2
        : "",
      city: viewPropertiesData?.address
        ? viewPropertiesData?.address[0]?.city
        : "",
      zipCode: viewPropertiesData?.address
        ? viewPropertiesData?.address[0]?.zipCode
        : "",
      terrace_area: viewPropertiesData?.terrace_area,
      additional_room: viewPropertiesData?.additional_room,
      tower: viewPropertiesData?.tower,
      price: viewPropertiesData?.price,
      developer_name: viewPropertiesData?.developer_name,
      plotArea: viewPropertiesData?.plotArea,
      re_id: viewPropertiesData?.re_id,
    };
    return obj;
  };

  return (
    <>
      <div className="add-property-container">
        {isLoading ? <Loader /> : 
          <div className="add-property-box">
            <div className="add-property-img">
            {
              propertyImageLoader ?
              <div className="loader">
                <CircularProgress/>
              </div>
              :
              imgArr.length > 0 ? (
                <div className="property-image overlay img rsis-image" onClick={()=>{setShowImagePreview(true)}}>
                <SimpleImageSlider
                  width={'100%'}
                  height={350}
                  autoPlay={true}
                  images={imgArr}
                  style={{
                    width: '100%'
                    
                  }}
                  showBullets={true}
                  showNavs={false}
                />
              </div>
              ) : (
                <div className="property-image overlay "> 
                  <img src={DummypropertyImage} alt='property' />
              </div>
              )
              
            }
            {
              showImageOptions && (
              <div className="outer">
                <div className="add-image">
                  <input
                    style={{ display: "none" }}
                    id='property-image'
                    onChange={handleFileChange}
                    type="file"
                  />
                  <label className="label" htmlFor='property-image'>  
                    <AddCircleSharpIcon style={{color: 'var(--primaryColor)'}}/>
                  </label>
                </div>
                {
                  imgArr.length > 0 && (
                    <IconButton className="add-image" onClick={() => setShowDeleteImgModal(!showDeleteImgModal)}>
                      <DeleteRounded style={{color: 'var(--primaryColor)'}}/>
                    </IconButton>     
                  )
                }
              </div>
              )
            }
            
          </div>
          <div className="add-plus-area">
            {['BUYER', 'SELLER', 'SUPERADMIN', 'ADMIN'].includes(role) && (
            <img
              src={editIcon}
              className="editIcon"
              alt="edit"
              onClick={() => {
                if(editDisabled) {
                  addToast(getErrorMessage('E-10143'), { appearance: INFO_KEY, autoDismiss: true });
                } else {
                  setEditModal(!editModal);
                }
              }}
            />
            )}
            <div className="left">
              <h4 className="fw-bold">Property Details</h4>
              <table className="property-table">
                <tbody>
                  <tr>
                    <td className="labelArea">Propery Type:</td>{" "}
                    <td className="labelAreaOne">
                      {viewPropertiesData?.PropertyType === defaultValues.property_types.villa 
                      ? 'Villa/Independent House/Row House' 
                      : viewPropertiesData?.PropertyType === defaultValues.property_types.pent_house ? 'Pent House' 
                      : viewPropertiesData?.PropertyType?.replaceAll("_", "/")}
                    </td>
                  </tr>
                  <tr>
                    <td className="labelArea">ID No:</td>{" "}
                    <td>{viewPropertiesData?.re_id}</td>
                  </tr>
                  { viewPropertiesData.PropertyType !== defaultValues.property_types.villa
                    ? <tr>
                        <td className="labelArea">Property Floor No: </td>{" "}
                        <td>{viewPropertiesData?.floor_no}</td>
                      </tr>
                  : <></>
                  }                  
                  <tr>
                    <td className="labelArea">Door Facing Direction:</td>{" "}
                    <td>{viewPropertiesData?.door_facing_direction}</td>
                  </tr>
                  <tr>
                    <td className="labelArea">Car parking:</td>{" "}
                    <td>{viewPropertiesData?.car_park}</td>
                  </tr>
                  <tr>
                    <td className="labelArea">Address 1:</td>
                    <td>
                      <td>
                        {" "}
                        {viewPropertiesData?.address?.[0]
                          ? `${viewPropertiesData?.address?.[0]?.address_line1}`
                          : ""}
                      </td>
                    </td>
                  </tr>
                  <tr>
                    <td className="labelArea">City:</td>{" "}
                    <td>
                      <td>{viewPropertiesData?.address?.[0]?.city}</td>
                    </td>
                  </tr>
                  <tr>
                    <td className="labelArea">Society Name:</td>{" "}
                    <td>{viewPropertiesData?.society_name}</td>
                  </tr>
                  <tr>
                    <td className="labelArea">Builder/Developer Name:</td>{" "}
                    <td>{viewPropertiesData?.developer_name}</td>
                  </tr>
                 
                </tbody>
              </table>
            </div>
            <div className="right">
              <table className="property-table">
                <tbody>
                  <tr>
                    <td className="labelArea">
                      {viewPropertiesData?.PropertyType ===defaultValues.property_types.villa
                      ? 'Villa/House no.:' 
                      : viewPropertiesData?.PropertyType === defaultValues.property_types.pent_house 
                      ? 'Pent House no.:' 
                      : 'Flat/unit no:'}</td>
                    <td>{viewPropertiesData?.flat_no}</td>
                  </tr>
                  <tr>
                    <td className="labelArea">Super Built Up Area:</td>{" "}
                    <td>{viewPropertiesData?.super_built_up_area}</td>
                  </tr>
                  <tr>
                    <td className="labelArea">BHK:</td>{" "}
                    <td className="labelAreaOne">{viewPropertiesData?.bhk}</td>
                  </tr>
                  <tr>
                    <td className="labelArea">Furnishing Status:</td>{" "}
                    <td>{viewPropertiesData?.furnishing_status}</td>
                  </tr>
                  <tr>
                    <td className="labelArea">Car park type:</td>{" "}
                    <td>{viewPropertiesData?.carParkType}</td>
                  </tr>
                  <tr>
                    <td className="labelArea">Address 2:</td>
                    <td>
                      <td>{viewPropertiesData?.address?.[0]?.address_line2}</td>
                    </td>
                  </tr>
                  <tr>
                    <td className="labelArea">Pin Code:</td>
                    <td>
                      {" "}
                      <td>{viewPropertiesData?.address?.[0]?.zipCode}</td>{" "}
                    </td>
                  </tr>
                  { viewPropertiesData.PropertyType === defaultValues.property_types.villa
                    ? <tr>
                        <td className="labelArea">Plot/Land area:</td>{" "}
                        <td>{viewPropertiesData?.plotArea}</td>
                      </tr>
                  : <></>
                  }
                  { viewPropertiesData.PropertyType !== defaultValues.property_types.villa
                    ? <tr>
                        <td className="labelArea">Max Floor in society:</td>{" "}
                        <td>{viewPropertiesData?.no_of_floor}</td>
                      </tr>
                  : <></>
                  }
                  {viewPropertiesData?.PropertyType === defaultValues.property_types.pent_house ? (
                    <>
                      <tr>
                        <td className="labelArea">Terrace Area:</td>
                        <td>
                          {" "}
                          <td>{viewPropertiesData?.terrace_area}</td>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="labelArea">Additional Room:</td>{" "}
                        <td>{viewPropertiesData?.additional_room}</td>
                      </tr>
                    </>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {
            viewPropertiesData && (
              <EditProperty
                setIsEditModal={() => setEditModal(false)}
                isEditModal={editModal}
                data={getEditPropertyObj()}
                callBack={getData}
                name={user}
                
              />
            )
          }
          {
            showDeleteImgModal && (
              <DeletePropertyImages
                showModal={showDeleteImgModal}
                setSHowModal={setShowDeleteImgModal}
                data={propertyImageAttribute}
                propertyId={viewPropertiesData._id}
                imageCallback={imageCallBack}
              />
            )
          }
        
        </div>
        }
      </div>
      {
        showImagePreview &&
        <div className="image-show">
        <section className="image-section">
          {
            propertyImageAttribute.length > 1 &&
            <>
              <div className="left-arrow" onClick={prevSlide}>
                <ArrowCircleLeftSharpIcon color="primary"/>
              </div>
              <div className="right-arrow" onClick={nextSlide}>
                <ArrowCircleRightSharpIcon color="primary"/>
              </div>
            </>
          }
          <div className="close-icon" onClick={()=>{setShowImagePreview(false)}}>
                <CancelOutlinedIcon color="error"/>
          </div>
          {
            propertyImageAttribute?.map((image,index)=>{
              return (
                <div className={ currentImage === index ? "slide active" : "slide"}>
                  {
                    index===currentImage &&
                    <img src={image.url} alt="property" className="image"/>
                  }
                </div>
              ) 
            })
          }
        </section>
      </div>
      }
    </>
  )

}
export default PropertyDetailsData;
