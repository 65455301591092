import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPropertyDetails, updateProperty } from "../../services/api";

export const updateTds = createAsyncThunk(
    "caTdsUpdateSlice/updateTds", async (args, { rejectWithValue }) => {
      try {
        const { data } = await updateProperty(args);
        return data;
      } catch (e) {
        rejectWithValue(e.response.data);
      }
    }
  );

export const getTds = createAsyncThunk(
  "caTdsGetSlice/getTds", async (propertyId, { rejectWithValue }) => {
    try {
      const { data } = await getPropertyDetails(propertyId);
      return data;
    } catch (e) {
      rejectWithValue(e.response.data);
    }
  }
);