import React , {useEffect} from "react";
import { Button } from '@mui/material'
import Main from "../../layout/Main";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";


import "./style.scss";
import { CustomEditor } from "../../utils/CustomEditor";
import { Routes } from "../../constants/routes";

const EditMou = (props) => {
  const history = useHistory()
  const location = useLocation();
  const accessToken = useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";
  const propertyId = useSelector((state)=> state?.buyerHomeData?.selectedProperty?._id) || '';
  const pid = location.search.split("=")[1]

  useEffect(() => {
    if (!accessToken) {
      history.push(Routes.HOME);
    }else{
      if(!propertyId) history.push(Routes.PROPERTIES);
      else if(pid !== propertyId) history.push(`${Routes.EDIT_MOU}?pid=${propertyId}`)
    }
  }, []);

  return (
    <div>
      <Main
      showUserNavbar={!props?.showUserNavbar}
      showUser={!props?.showUser}
      >
        <div className="fee-container">
          <div className="platform-fee-container">
              <div className="editorWrappers">
                <CustomEditor />
              </div>
              <Button variant="primary" type="submit" className="fee-btn" sx={{bgcolor:'var(--primaryColor)'}}>
                Approve
              </Button>
          </div>
        </div>
      </Main>
    </div>
  );
};
export default EditMou;
