import React from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import "../styles/editorStyle.css";

export const CustomEditor = ({
    data, 
    onChange,
    readonly = false,   //default value is false, pass true from parent if needed readonly editor
}) => {
    const modules = {
        toolbar: [
          [{ font: [] }, { size: ["small", false, "large", "huge"] }],
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
          ['link'],
          ['clean']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link',
    ];

  
    return (
      <div>
        <ReactQuill 
            value={data}
            modules={modules}
            formats={formats}
            onChange={onChange} 
            readOnly={readonly}
            theme="snow"
        />
      </div>
    )
    
  }