import React, { useState,useEffect } from "react";
import Main from "../../layout/Main";
import View from "./view";
import { v4 } from 'uuid';
import { useSelector , useDispatch} from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { numberFormat, updateSubStage } from "../../utils/HelperFunction";
import {updateSelectedProperty} from "../user-home/slices"
import { addPropertyHistory, generateDocWithPayment, getPropPaymentDetails, verifyPropPaymentStatus } from "../../services/api";
import MessageModel from "../../layout/MessageModel";
import { historyFormatter } from "../../utils/historyFormatter";
import { Routes } from "../../constants/routes";
import { ERROR_KEY, SUCCESS_KEY, defaultValues, getErrorMessage, getSuccessMessage } from "../../constants";

const Token = (props) => {

  const initialAddTransactionState = [{
    id: v4(),
    addButton: true,
    deleteButtton: false,
    transactionId: "",
    amount: "",
  }]

  const dispatch = useDispatch()
  const history = useHistory();
  const location = useLocation();
  const route = location.pathname.slice(1);

  const accessToken = useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";
  const propertyData = useSelector((state)=> state?.buyerHomeData?.selectedProperty);
  const propertyId = useSelector((state)=> state?.buyerHomeData?.selectedProperty?._id) || '';
  const pid = location.search.split("=")[1]

  const { addToast } = useToasts();

  const [proceed, setProceed] = useState(false);
  const [token, setToken] = useState({});
  const [historySteps, setHistorySteps] = useState([]);
  const [verifyLoading, setVerifyLoading] = useState(false)
  const [proceedLoading, setProceedLoading] = useState(false)
  const [addTransaction, setAddTransaction] = useState(initialAddTransactionState);
  const [showPopup, setShowPopup] = useState(false);
  const [pageLabel, setPageLabel] = useState('Token');

  const userId = useSelector((state) => state.loggedInUser.data.data.userByEmail._id);
  const user = useSelector((state) => state.loggedInUser.data.data.userByEmail);
  const subStages = useSelector((state)=> state?.stages?.subStages)
  const getSubStage = (order) => subStages.filter((substage)=> substage.order === order)[0]._id
  const getSubStageOrder = (id) => subStages.filter((substage)=> substage._id === id)[0].order;


  useEffect(() => {
    if (!accessToken) {
      history.push(Routes.HOME);
    }else{
      if(!propertyId) history.push(Routes.PROPERTIES);
      else if(pid !== propertyId) history.push(`/${route}?pid=${propertyId}`)
    }

  }, []);

  useEffect(() => {
    if(route.toLocaleLowerCase().includes('registration')) {
      setPageLabel('Agreement');
    }
  }, [route]);
  

  useEffect(() => {
    getPropertyPaymentDetail(propertyId);
  }, [propertyId])

  const handleAddTransaction = () => {
    if (addTransaction.length < 3) {
      const order = getSubStageOrder(propertyData.sub_current_stage);
      let message = '';
      let addTransactionRecall = [...addTransaction];
      addTransactionRecall = addTransactionRecall.map((transaction) => {
        transaction.addButton = false;
        return transaction
      })
      let transaction = { ...initialAddTransactionState[0], deleteButtton: true };
      if (addTransaction.length === 2) transaction = { ...initialAddTransactionState[0], deleteButtton: true, addButton: false }
      setAddTransaction([...addTransactionRecall, transaction]);
      if(order === 7) {
        message = 'has paid the Token Amount';
      } else {
        message = 'has paid the registration amount';
      }

      const historyData = historyFormatter(user, userId, propertyId, message);
      addPropertyHistory(historyData);
    }
  }

  const handleDeleteTransaction = (id) => {
    let addTransactionRecall = [...addTransaction];
    const filteredTransaction = addTransactionRecall.filter((transaction) => transaction.id !== id);
    if (filteredTransaction.length > 1) {
      addTransactionRecall = filteredTransaction.map((transaction, index) => {
        if (index) transaction.addButton = true;
        return transaction
      })
    } else {
      addTransactionRecall = [{ ...filteredTransaction[0], addButton: true }]
    }
    setAddTransaction([...addTransactionRecall])
  }

  const handleProceed = async ()=>{
    setProceedLoading(true);
    const order = getSubStageOrder(propertyData.sub_current_stage);
    const templateSub = order === 7 ? defaultValues.doc_sub_types.mou : defaultValues.doc_sub_types.agreement;
    const payload = {
      templateType : defaultValues.doc_types.document,
      templateSubType : templateSub,
      propertyId : propertyId
    };    

    await generateDocWithPayment(payload)
      .then(async (res) => {
        if(res.status){
          if(route === 'token'){
            await getSubStageUpdated(propertyId,getSubStage(8), Routes.SIGN_MOU);            
          }else{
            await getSubStageUpdated(propertyId,getSubStage(13), Routes.SIGN_AGREEMENT);
          }
        }
      }).catch(error => {
        addToast(error.response.data.message, { appearance: ERROR_KEY, autoDismiss: true });
      })
      setProceedLoading(false);

    
  }

  const getSubStageUpdated = async (propertyId, substage , path) => {
    try{
      const response = await updateSubStage({propertyId, substageId: substage, loggedInUserId:userId});
        if(response.status === 200){
          dispatch(updateSelectedProperty(response.data.propertyData));
          addToast(getSuccessMessage('S-10019'), { appearance: SUCCESS_KEY, autoDismiss: true });
          history.push(path)
        }
        else addToast(getErrorMessage('E-10070'), { appearance: ERROR_KEY, autoDismiss: true });
      }catch(error){
        addToast(error.response.data.message || getErrorMessage('E-10070'), { appearance: ERROR_KEY, autoDismiss: true });
      }  
  }

  const getPropertyPaymentDetail = async (propertyId) => {
    try {
      const response = await getPropPaymentDetails(propertyId, {
        paymentType:route.toUpperCase()
      })
      if (response.status === 200) {
        const transactionData = response?.data?.data?.transactionDetails || [];
        const remaningAmount = response?.data?.data?.remaningAmount;
        if (remaningAmount === 0) setProceed(true)
        let history = []
        transactionData.forEach((transaction) => {
          const { date, amount, id: transactionId } = transaction
          const data = { date, amount, transactionId };
          history.push(data)
        })
        setHistorySteps([...history])
        setToken({ ...response.data.data });
      }
    } catch (error) {
      addToast(getErrorMessage('E-10139'), { appearance: ERROR_KEY, autoDismiss: true });

    }
  }

  const handleClick = async () => {
    setVerifyLoading(true);
    const data = addTransaction.map((transaction) => {
      let data = {};
      data.transactionId = transaction.transactionId;
      data.amount = parseInt(transaction.amount);
      return data;
    })
    await verifyTransaction(propertyId, data);
    setVerifyLoading(false);
    setAddTransaction(initialAddTransactionState)
  }

  const verifyTransaction = async (propertyId, data) => {
    let payload = { userId, transactionDetails: data, propertyId , paymentType:route.toUpperCase()};
    try {
      const response = await verifyPropPaymentStatus(payload);
      if (response?.status === 200) {
        await getPropertyPaymentDetail(propertyId);
        const transactionVerify = response?.data?.data || [];
        transactionVerify.forEach((transaction) => {
          if (transaction.status === 'Failed') {
            addToast(transaction.message, { appearance: ERROR_KEY, autoDismiss: true });
          }
          if (transaction.status === 'Verified') {
            addToast(transaction.message, { appearance: SUCCESS_KEY, autoDismiss: true });
          }
        })
      }
    } catch (error) {
      addToast(getErrorMessage('E-10140'), { appearance: ERROR_KEY, autoDismiss: true });
    }
  }

  const handleChange = (e, id) => {
    const recallTransaction = addTransaction
    const foundTransaction = recallTransaction.find((transaction) => transaction.id === id);
    foundTransaction[e.target.name] = e.target.value;
    setAddTransaction([...recallTransaction])
  }

  const createData = (name, value = '') => {
    return { name, value };
  }

  const amountRow = [
    createData('Payment Type', token.type),
    createData('Total Amount', numberFormat(token.totalAmount)),
    createData('Paid Amount', numberFormat(token.paidAmount)),
    createData('Remaining Amount', numberFormat(token.remaningAmount)),
  ];

  const transactionCredRow = [
    createData('Account Name', token?.transferCred?.name),
    createData('Account No', token?.transferCred?.accountId),
    createData('IFSC Code', token?.transferCred?.ifsc),
    createData('Bank Name', token?.transferCred?.bank),

  ];

  const amountStyle = {
    'Total Amount': "bold",
    'Paid Amount': "paid",
    'Remaining Amount': "remaining"
  }

  const tableAttributes = [
    {
      key: "amountDetails",
      heading: ["Amount", 'Details'],
      rowDetails: amountRow,
      containerStyle: { width: '49%', overflowX: "hidden" },
      tableStyle: { minWidth: '40%', marginRight: '5px' },
      headStyle: { backgroundColor: "var(--cyanBlue)" },
    },
    {
      key: "credDetails",
      heading: ["Escrow Account",'Details'],
      rowDetails: transactionCredRow,
      containerStyle: { width: '49%', overflowX: "hidden" },
      tableStyle: { minWidth: '40%' },
      headStyle: { backgroundColor: "var(--cyanBlue)" },
    }
  ]

  const inputAttributes = [
    {
      key: 'transactionId',
      label: "Transaction Id(UTR)",
    },
    {
      key: 'amount',
      label: "Amount",
    }
  ];

  const routeChange = (value) => {
    setShowPopup(false);
    if(value){      
      history.push(`${Routes.BACK_OUT}?pid=${propertyId}`);
    }
  };



  return (
    <>
      <div>
        <Main
          showUserNavbar={!props?.showUserNavbar}
          showUser={!props?.showUser}
        >
          <View
            label={pageLabel}
            tableAttributes={tableAttributes}
            addTransaction={addTransaction}
            inputAttributes={inputAttributes}
            handleChange={handleChange}
            proceed={proceed}
            handleClick={handleClick}
            verifyLoading={verifyLoading}
            proceedLoading={proceedLoading}
            handleAddTransaction={handleAddTransaction}
            handleDeleteTransaction={handleDeleteTransaction}
            historySteps={historySteps}
            handleProceed={handleProceed}
            handleBackout={()=> setShowPopup(true)}
          />
        </Main>
        {showPopup && 
        <MessageModel title="Warning" 
          message="Are you sure you want to Backout ?" 
          callback={routeChange} showPopup={showPopup}>
        </MessageModel>
      }
      </div>
    </>
  );
};
export default Token;
