import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useToasts } from "react-toast-notifications";
import { postAddProperty } from "../../../pages/super-admin-manageProperties/service";
import {
  addPropertySchema1,
  addPropertySchema2,
} from "../../../utils/validationSchemas";
import { useFormik } from "formik";
import {
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { getBuyerPropData } from "../../../pages/user-home/service";
import Loader from "../../Loader/Loader";
import { convertNumberToWord } from "../../../utils/HelperFunction";
import { historyFormatter } from "../../../utils/historyFormatter";
import { addPropertyHistory } from "../../../services/api";
import { ERROR_KEY, ROLE_KEY, SUCCESS_KEY, defaultValues } from "../../../constants";
import { primaryButtonStyle } from "../../../styles/commonstyles";

const today = new Date();
const todayString = today.toISOString().split('T')[0];

const initialValues = {
  property_type: "",
  flat_no: "",
  super_built_up_area: "",
  door_facing_direction: "",
  car_park: "",
  carParkType: "",
  common_area: "",
  bhk: "",
  furnishing_status: "",
  floor_no: "",
  no_of_floor: "",
  society_name: "",
  address_line1: "",
  address_line2: "",
  city: "",
  zipCode: "",
  terrace_area: "",
  additional_room: "",
  tower: "",
  percentage_share: 0,
};

const initialValues2 = {
  price: "",
  khata_number: "",
  registration_number: "",
  no_of_owners: "",
  no_of_buyers: "",
  survey_number: "",
  usp: "",
  schedule_number: "",
  cd_number: "",
  rented: "No",
  tenant_name: "",
  tenant_email: "",
  tenant_phone: "",
  sale_deed_number: "",
  sale_deed_date: null,
  previous_owner: "",
};

const AddPropertyModal = ({ isDisplay, setIsDisplay, getUpdatedProperty }) => {
  const loggedInUser = useSelector((state) => state.loggedInUser);
  const propertyTypeRef = useRef(null);
  const flatNoRef = useRef(null);
  const super_built_up_area = useRef(null);
  const door_facing_direction = useRef(null);
  const plotAreaRef = useRef(null)
  const car_park = useRef(null);
  const carParkType = useRef(null);
  const previous_owner = useRef(null);
  const common_area = useRef(null);
  const sale_deed_number = useRef(null);
  const bhk = useRef(null);
  const furnishing_status = useRef(null);
  const floor_no = useRef(null);
  const no_of_floor = useRef(null);
  const society_name = useRef(null);
  const address_line1 = useRef(null);
  const city = useRef(null);
  const zipCode = useRef(null);
  const khataNoRef = useRef(null);
  const propPriceRef = useRef(null);
  const noOfSellerRef = useRef(null);
  const noOfBuyerRef = useRef(null);
  const tower = useRef(null);
  const [showText, setShowText] = useState(true);
  const percentAgeRef = useRef(null);

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [modal, setModal] = useState(2);
  const [isSubmit, setIsSubmit] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [userData, setUserData] = useState(null);
  const role = useSelector((state) => state.loggedInUser.preferredRole);
  const allRole = useSelector((state) => state?.roles);
  const stages = useSelector((state) => state?.stages?.stages) || [];
  const subStages = useSelector((state) => state?.stages?.subStages) || [];
  const isLoading = useSelector(
    (state) => state?.superAdminProperties.isLoading
  );
  const [data, setData] = useState({
    user_id:
      role === defaultValues.role_names.super_admin || role === defaultValues.role_names.admin
        ? null
        : loggedInUser.data?.data?.userByEmail?._id,
  });
  const showPercentageField = userRoles.length > 1;

  const {
    resetForm,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: addPropertySchema1,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      setModal(2);
      setData({ ...data, ...values });
    },
  });

  const Formik2 = useFormik({
    initialValues: initialValues2,
    validationSchema: addPropertySchema2,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      const getSubStage = (order) =>
        subStages.filter((substage) => substage.order === order)[0]._id;
      const payload = { ...data, ...values };
      const initialStage = stages[0]._id;
      const initialSubStage = getSubStage(1);
      payload.current_stage = initialStage;
      payload.sub_current_stage = initialSubStage;
      payload.PropertyType = data.property_type;
      payload.super_built_up_area = parseFloat(data.super_built_up_area);
      payload.plotArea = parseInt(data.plotArea)
      payload.rented = payload.rented === "Yes" ? true : false;
      payload.address = [
        {
          address_line1: data.address_line1,
          address_line2: data.address_line2,
          city: data.city,
          zipCode: data.zipCode,
        },
      ];
       if (data.property_type === defaultValues.property_types.villa) {
      payload.floor_no = undefined;
      payload.no_of_floor = undefined;
    }
      payload.tenant = {
        name: values.tenant_name,
        email: values.tenant_email,
        phone: values.tenant_phone,
      };

      const selectRole = userRoles.filter((role) => payload.role === role._id);
      const isSeller = payload.role === defaultValues.role_names.seller;
      const isBuyer = payload.role === defaultValues.role_names.buyer;

      if (isSeller) {
        payload.sellers_share = [
          {
            seller_id: loggedInUser.data?.data?.userByEmail?._id,
            percentage_share: parseFloat(values.percentage_share),
          },
        ];
      } else if (isBuyer) {
        payload.buyers_share = [
          {
            buyer_id: loggedInUser.data?.data?.userByEmail?._id,
            percentage_share: parseFloat(values.percentage_share),
          },
        ];
      }

      if (role !== defaultValues.role_names.super_admin && role !== defaultValues.role_names.admin) {
        payload.property_parties_details = [
          {
            user_id: loggedInUser.data?.data?.userByEmail?._id,
            role: selectRole[0]._id,
            role_side: selectRole[0].role_name,
            assigned_date: new Date(),
            assigned_by: loggedInUser.data?.data?.userByEmail?._id,
          },
        ];
      }
      dispatch(postAddProperty(payload));
      setIsSubmit(true);
      action.resetForm();
      resetForm();
    },
  });
const [formattedSaleDeedDate, setFormattedSaleDeedDate] = useState(
  Formik2.values.sale_deed_date
    ? new Date(Formik2.values.sale_deed_date).toISOString().split("T")[0]
    : ""
);

  useEffect(() => {
    if(loggedInUser) {
      if (role === defaultValues.role_names.super_admin) {
        setUserData(loggedInUser?.data?.data?.superAdminByEmail);
      } else if (role === defaultValues.role_names.admin){
        setUserData(loggedInUser?.data?.data?.adminByEmail);
      } else {
        setUserData(loggedInUser?.data?.data?.userByEmail);
      }
    }
  }, [loggedInUser, role]);

  useEffect(() => {
    if (role !== defaultValues.role_names.super_admin && role !== defaultValues.role_names.admin) {
      if (allRole?.roles.length) {
        const userRole = allRole.roles.filter(
          (role) => role.role_name === defaultValues.role_names.buyer || role.role_name === defaultValues.role_names.seller
        );
        setUserRoles(userRole);
      }
      setData({ user_id: loggedInUser.data?.data?.userByEmail?._id });
    }
  }, [role, loggedInUser, allRole]);

  const fetchAddPropertyData = useSelector(
    (state) => state.superAdminProperties
  );
  useEffect(() => {
    if (fetchAddPropertyData?.addPropStatus && isSubmit) {
      if (fetchAddPropertyData?.addPropStatus === "Success") {
        const historyData = historyFormatter(userData, userData._id, fetchAddPropertyData.addPropertyData?._id, 'has created this property');
        addPropertyHistory(historyData)
        addToast(fetchAddPropertyData.addPropMessage, {
          appearance: SUCCESS_KEY,
          autoDismiss: true,
        });
        // let historyData = historyFormatter(userData, userData._id, prope)
        setIsDisplay(!isDisplay);
        getUpdatedProperty();
        setIsSubmit(false);
        dispatch(getBuyerPropData(data?.user_id));
      } else {
        addToast(fetchAddPropertyData.addPropMessage, {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
      }
    }
  }, [fetchAddPropertyData]);

  useEffect(() => {
    setModal(1);
  }, [isDisplay]);

  const onSubmitPriceDetails = (event) => {
    event.preventDefault();
    let hasError = false;

    if (hasError) {
      return;
    }
  };

  const onPrev = () => {
    setModal(1);
  };
  const onPrevDetails = () => {
    setModal(2);
  };

  return (
    <>
      {modal === 1 ? (
        <Modal
          size="lg"
          show={isDisplay}
          onHide={() => setIsDisplay(false)}
          className="addProperty"
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton className="add-modal">
            <Modal.Title
              id="example-modal-sizes-title-lg"
              className="text-center w-100 text-light"
            >
              Add Property Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="add-modal">
            <Form onSubmit={handleSubmit}>
              {data?.user_id && (
                <Row>
                  <Col>
                    <FormControl
                      variant="outlined"
                      className="dropdownProp mb-4 fieldWidth"
                    >
                      <InputLabel id="property-type-label" required>
                        Select Your Role on Property
                      </InputLabel>
                      <Select
                        required
                        ref={propertyTypeRef}
                        name="role"
                        label="Select Property Type"
                        onChange={(value) => {
                          setFieldValue(ROLE_KEY, value.target.value);
                        }}
                        value={values.role}
                        onBlur={handleBlur}
                      >
                        {userRoles.map((role) => (
                          <MenuItem value={role._id}>{role.role_name}</MenuItem>
                        ))}
                      </Select>
                      {touched.role && errors.role ? (
                        <p className="formError">{errors.role}</p>
                      ) : null}
                    </FormControl>
                  </Col>
                  {showPercentageField && (
                    <Col>
                      <Form.Group className="mb-3" controlId="Percentage">
                        <TextField
                          name="percentage_share"
                          id="Percentage"
                          label="Self Property Percentage Share"
                          variant="outlined"
                          type="number"
                          required
                          autoComplete="off"
                          ref={percentAgeRef}
                          value={values.percentage_share}
                          onBlur={handleBlur}
                          InputProps={{
                            inputProps: {
                              max: 100,
                              min: 0,
                            },
                          }}
                          onChange={handleChange}
                          className="inputBox fieldWidth"
                        />
                        {touched.percentage_share &&
                        errors.percentage_share ? (
                          <p className="formError">
                            {errors.percentage_share}
                          </p>
                        ) : null}
                      </Form.Group>
                    </Col>
                  )}
                </Row>
              )}
              <FormControl
                fullWidth
                variant="outlined"
                className="dropdownProp mb-4 customWidth"
              >
                <InputLabel id="property-type-label" required>
                  Select Property Type
                </InputLabel>
                <Select
                  required
                  ref={propertyTypeRef}
                  name="property_type"
                  label="Select Property Type"
                  onChange={(value) => {
                    setFieldValue("property_type", value.target.value);
                    setShowText(
                      value.target.value !== defaultValues.property_types.villa
                    );
                  }}
                  value={values.property_type}
                  onBlur={handleBlur}
                >
                  <MenuItem value={defaultValues.property_types.flat}>Flat/Apartment</MenuItem>
                  <MenuItem value={defaultValues.property_types.pent_house}>Pent House</MenuItem>
                  <MenuItem value={defaultValues.property_types.villa}>
                    Villa/Independent House/Row House
                  </MenuItem>
                </Select>
                {touched.property_type && errors.property_type ? (
                  <p className="formError">{errors.property_type}</p>
                ) : null}
              </FormControl>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="FlatNo">
                    {showText && (
                      <TextField
                        required
                        ref={flatNoRef}
                        name="flat_no"
                        id="Flat No "
                        label={
                          values.property_type === "Pent_house"
                            ? "Pent House"
                            : values.property_type === "Flat_Apartment"
                            ? "Flat No"
                            : "Villa No./House"
                        }
                        variant="outlined"
                        type="text"
                        autoComplete="off"
                        value={values.flat_no}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className="InputBox fieldWidth"
                      />
                    )}
                    {showText && touched.flat_no && errors.flat_no ? (
                      <p className="formError">{errors.flat_no}</p>
                    ) : null}
                    {!showText && (
                      <TextField
                        required
                        ref={flatNoRef}
                        name="flat_no"
                        id="Flat No "
                        label="Villa No./House"
                        variant="outlined"
                        type="text"
                        autoComplete="off"
                        value={values.flat_no}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className="InputBox fieldWidth"
                      />
                    )}
                    {!showText && touched.flat_no && errors.flat_no ? (
                      <p className="formError">{errors.flat_no}</p>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="SuperBuiltUpArea">
                    <TextField
                      required
                      ref={super_built_up_area}
                      name="super_built_up_area"
                      id="Super Built Up Area"
                      label="Super Built Up Area"
                      variant="outlined"
                      type="number"
                      value={values.super_built_up_area}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <span className="whiteText">Sq.ft</span>
                          </InputAdornment>
                        ),
                      }}
                      className="InputBox fieldWidth"
                    />
                    {touched.super_built_up_area &&
                    errors.super_built_up_area ? (
                      <p className="formError">{errors.super_built_up_area}</p>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="Door Facing Direction"
                  >
                    <FormControl
                      variant="outlined"
                      className="InputBox fieldWidth"
                    >
                      <InputLabel id="Door Facing Direction">
                        Door Facing Direction *
                      </InputLabel>
                      <Select
                        required
                        ref={door_facing_direction}
                        name="door_facing_direction"
                        labelId="Door-Facing-Direction"
                        id="Door-Facing-Direction"
                        label="Door Facing Direction"
                        value={values.door_facing_direction}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <MenuItem value="South">South</MenuItem>
                        <MenuItem value="North">North</MenuItem>
                        <MenuItem value="East">East</MenuItem>
                        <MenuItem value="West">West</MenuItem>
                      </Select>
                      {touched.door_facing_direction &&
                      errors.door_facing_direction ? (
                        <p className="formError">
                          {errors.door_facing_direction}
                        </p>
                      ) : null}
                    </FormControl>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="FloorNo">
                    {showText && (
                      <TextField
                        required
                        ref={floor_no}
                        name="floor_no"
                        id="Floor No "
                        label="Floor Number "
                        variant="outlined"
                        type="number"
                        autoComplete="off"
                        value={values.floor_no}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="InputBox fieldWidth"
                      />
                    )}
                    {showText && touched.floor_no && errors.floor_no ? (
                      <p className="formError">{errors.floor_no}</p>
                    ) : null}
                    {!showText && (
                      <TextField
                        required
                        ref={plotAreaRef}
                        name="plotArea"
                        id="Plot/ Land Area"
                        label="Plot/Land Area"
                        variant="outlined"
                        type="number"
                        autoComplete="off"
                        value={values.plotArea}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <span className="whiteText">Sq.ft</span>
                            </InputAdornment>
                          ),
                        }}
                        className="InputBox fieldWidth"
                      />
                    )}
                    {!showText && touched.plotArea && errors.plotArea ? (
                      <p className="formError">{errors.plotArea}</p>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="Bhk">
                    <FormControl
                      variant="outlined"
                      className="InputBox fieldWidth"
                    >
                      <InputLabel id="bhk-label">BHK *</InputLabel>
                      <Select
                        required
                        ref={bhk}
                        name="bhk"
                        labelId="bhk-label"
                        id="bhk-label"
                        label="BHK"
                        autoComplete="off"
                        value={values.bhk}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                        <MenuItem value="4">4</MenuItem>
                      </Select>
                      {touched.bhk && errors.bhk ? (
                        <p className="formError">{errors.bhk}</p>
                      ) : null}
                    </FormControl>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="FurnishingStatus">
                    <FormControl
                      variant="outlined"
                      className="InputBox fieldWidth"
                    >
                      <InputLabel id="furnishing-status-label">
                        Status *
                      </InputLabel>
                      <Select
                        required
                        ref={furnishing_status}
                        name="furnishing_status"
                        labelId="furnishing-status-label"
                        id="furnishing-status"
                        label="Status"
                        value={values.furnishing_status}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <MenuItem value="Furnished">Furnished</MenuItem>
                        <MenuItem value="Unfurnished">Unfurnished</MenuItem>
                        <MenuItem value="Semi Furnished">
                          Semi Furnished
                        </MenuItem>
                      </Select>
                      {touched.furnishing_status && errors.furnishing_status ? (
                        <p className="formError">{errors.furnishing_status}</p>
                      ) : null}
                    </FormControl>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="Tower">
                    {showText && (
                      <TextField
                        required
                        ref={tower}
                        name="tower"
                        id="Tower"
                        label="Tower"
                        variant="outlined"
                        type="text"
                        autoComplete="off"
                        value={values.tower}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="InputBox fieldWidth"
                      />
                    )}
                    {showText && touched.floor_no && errors.tower ? (
                      <p className="formError">{errors.tower}</p>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="Nooffloor">
                    {showText && (
                      <TextField
                        required
                        ref={no_of_floor}
                        name="no_of_floor"
                        id="No of floor"
                        label="Maximum Floor in Society"
                        variant="outlined"
                        type="number"
                        autoComplete="off"
                        value={values.no_of_floor}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="inputBox fieldWidth"
                      />
                    )}
                    {showText && touched.no_of_floor && errors.no_of_floor ? (
                      <p className="formError">{errors.no_of_floor}</p>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="CarParkType">
                    <TextField
                      required
                      ref={carParkType}
                      name="carParkType"
                      id="carParkType"
                      label="Car Park Type"
                      variant="outlined"
                      type="text"
                      autoComplete="off"
                      value={values.carParkType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="inputBox fieldWidth"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="CarParking">
                    <FormControl
                      variant="outlined"
                      className="InputBox fieldWidth"
                    >
                      <InputLabel id="car-parking-label">
                        Car Parking *
                      </InputLabel>
                      <Select
                        required
                        ref={car_park}
                        name="car_park"
                        labelId="car-parking-label"
                        id="car-parking-label"
                        label="Car Parking"
                        autoComplete="off"
                        value={values.car_park}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                        <MenuItem value="4">4</MenuItem>
                      </Select>
                      {touched.car_park && errors.car_park ? (
                        <p className="formError">{errors.car_park}</p>
                      ) : null}
                    </FormControl>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="common_area">
                    {showText && (
                      <TextField
                        required
                        ref={common_area}
                        name="common_area"
                        id="common_area"
                        label="Common area"
                        variant="outlined"
                        type="number"
                        autoComplete="off"
                        value={values.common_area}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <span className="whiteText">Sq.ft</span>
                            </InputAdornment>
                          ),
                        }}
                        className="inputBox fieldWidth"
                      />
                    )}
                    {showText && touched.common_area && errors.common_area ? (
                      <p className="formError">{errors.common_area}</p>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-center">
                    <p className="text-white">----Society Details----</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="SocietyName">
                    <TextField
                      required
                      ref={society_name}
                      name="society_name"
                      id="Society Name"
                      label="Society Name"
                      variant="outlined"
                      type="text"
                      autoComplete="off"
                      value={values.society_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="inputBox fieldWidth"
                    />
                    {touched.society_name && errors.society_name ? (
                      <p className="formError">{errors.society_name}</p>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="BuilderName">
                    <TextField
                      name="developer_name"
                      id="Builder Name"
                      label="Builder Name"
                      variant="outlined"
                      type="text"
                      autoComplete="off"
                      value={values.developer_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="InputBox fieldWidth"
                    />
                    {touched.developer_name && errors.developer_name ? (
                      <p className="formError">{errors.developer_name}</p>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="Address1">
                    <TextField
                      required
                      ref={address_line1}
                      name="address_line1"
                      id="Address 1 "
                      label="Address 1 "
                      variant="outlined"
                      type="text"
                      autoComplete="off"
                      className="inputBox fieldWidth "
                      value={values.address_line1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.address_line1 && errors.address_line1 ? (
                      <p className="formError">{errors.address_line1}</p>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="Address2">
                    <TextField
                      name="address_line2"
                      id="Address 2 "
                      label="Address 2 "
                      variant="outlined"
                      type="text"
                      autoComplete="off"
                      className="inputBox  fieldWidth"
                      value={values.address_line2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.address_line2 && errors.address_line2 ? (
                      <p className="formError">{errors.address_line2}</p>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="city">
                    <FormControl
                      variant="outlined"
                      className="InputBox fieldWidth"
                    >
                      <InputLabel id="city">City *</InputLabel>
                      <Select
                        required
                        ref={city}
                        name="city"
                        id="City "
                        label="Select City"
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <MenuItem value="bangalore">Bangalore</MenuItem>
                      </Select>
                      {touched.city && errors.city ? (
                        <p className="formError">{errors.city}</p>
                      ) : null}
                    </FormControl>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="PinCode">
                    <TextField
                      required
                      maxlength="6"
                      ref={zipCode}
                      name="zipCode"
                      id="Pin Code"
                      label="Pin Code"
                      variant="outlined"
                      type="number"
                      autoComplete="off"
                      className="inputBox fieldWidth"
                      value={values.zipCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.zipCode && errors.zipCode ? (
                      <p className="formError">{errors.zipCode}</p>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
              {values.property_type === "Pent_house" && (
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="TerraceArea">
                      <TextField
                        name="terrace_area"
                        id="terrace_area "
                        label="Terrace Area"
                        variant="outlined"
                        type="number"
                        autoComplete="off"
                        className="inputBox  fieldWidth"
                        value={values.terrace_area}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <span className="whiteText">Sq.ft</span>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {touched.terrace_area && errors.terrace_area ? (
                        <p className="formError">{errors.terrace_area}</p>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="AdditionalRoom">
                      <TextField
                        name="additional_room"
                        id="additional_room"
                        label="Additional Room"
                        variant="outlined"
                        type="number"
                        autoComplete="off"
                        className="inputBox fieldWidth"
                        value={values.additional_room}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.additional_room && errors.additional_room ? (
                        <p className="formError">{errors.additional_room}</p>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
              )}

              <Row>
                <Col className="text-center pt-5">
                  <Button style={primaryButtonStyle} type="submit">
                    Next
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
      {modal === 2 ? (
        <Modal
          size="lg"
          show={isDisplay}
          onHide={() => setIsDisplay(false)}
          className="addProperty"
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton className="add-modal">
            <Modal.Title
              id="example-modal-sizes-title-lg"
              className="text-center w-100 text-light"
            >
              Other Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="add-modal">
            {isLoading ? (
              <Loader />
            ) : (
              <Form onSubmit={Formik2.handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="price" fullWidth>
                      <TextField
                        name="price"
                        id="price"
                        label="Property Price"
                        variant="outlined"
                        type="number"
                        required
                        ref={propPriceRef}
                        value={parseFloat(Formik2.values.price)}
                        onBlur={Formik2.handleBlur}
                        onChange={Formik2.handleChange}
                        className="inputBox fieldWidth"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <strong className="text-center w-50 text-white">
                                ₹
                              </strong>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {Formik2.touched.price && Formik2.errors.price ? (
                        <p className="formError">{Formik2.errors.price}</p>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col>
                    <p
                      className="small-text pl-4"
                      style={{ color: "white", fontWeight: "bold" }}
                    >
                      {convertNumberToWord(parseInt(Formik2.values.price))} Rupees
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="No. Of Seller">
                      <TextField
                        name="no_of_owners"
                        id="No. Of Seller"
                        label="No. Of Seller"
                        variant="outlined"
                        type="number"
                        required
                        autoComplete="off"
                        ref={noOfSellerRef}
                        value={Formik2.values.no_of_owners}
                        onBlur={Formik2.handleBlur}
                        onChange={Formik2.handleChange}
                        InputProps={{
                          inputProps: {
                            max: 4,
                            min: 1,
                          },
                        }}
                        className="inputBox fieldWidth"
                      />
                      {Formik2.touched.no_of_owners &&
                      Formik2.errors.no_of_owners ? (
                        <p className="formError">
                          {Formik2.errors.no_of_owners}
                        </p>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="No. Of Buyer">
                      <TextField
                        name="no_of_buyers"
                        id="No. Of Buyer"
                        label="No. Of Buyer"
                        variant="outlined"
                        type="number"
                        required
                        autoComplete="off"
                        ref={noOfBuyerRef}
                        value={Formik2.values.no_of_buyers}
                        onBlur={Formik2.handleBlur}
                        InputProps={{
                          inputProps: {
                            max: 4,
                            min: 1,
                          },
                        }}
                        onChange={Formik2.handleChange}
                        className="inputBox fieldWidth"
                      />
                      {Formik2.touched.no_of_buyers &&
                      Formik2.errors.no_of_buyers ? (
                        <p className="formError">
                          {Formik2.errors.no_of_buyers}
                        </p>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="khata No. ">
                      <TextField
                        name="khata_number"
                        id="khata No."
                        label="Khata Number"
                        variant="outlined"
                        type="text"
                        required
                        ref={khataNoRef}
                        value={Formik2.values.khata_number}
                        onChange={Formik2.handleChange}
                        onBlur={Formik2.handleBlur}
                        className="inputBox fieldWidth"
                      />
                      {Formik2.touched.khata_number &&
                      Formik2.errors.khata_number ? (
                        <p className="formError">
                          {Formik2.errors.khata_number}
                        </p>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="Registration No.">
                      <TextField
                        name="registration_number"
                        id="Registration No."
                        label="Registration Number"
                        variant="outlined"
                        type="text"
                        autoComplete="off"
                        value={Formik2.values.registration_number}
                        onBlur={Formik2.handleBlur}
                        onChange={Formik2.handleChange}
                        className="inputBox fieldWidth"
                      />
                      {Formik2.touched.registration_number &&
                      Formik2.errors.registration_number ? (
                        <p className="formError">
                          {Formik2.errors.registration_number}
                        </p>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="No. Of Survey">
                      <TextField
                        required
                        name="survey_number"
                        id="No.Of Survey"
                        label="Survey Number"
                        variant="outlined"
                        type="text"
                        autoComplete="off"
                        value={Formik2.values.survey_number}
                        onBlur={Formik2.handleBlur}
                        onChange={Formik2.handleChange}
                        className="inputBox fieldWidth"
                      />
                      {Formik2.touched.survey_number &&
                      Formik2.errors.survey_number ? (
                        <p className="formError">
                          {Formik2.errors.survey_number}
                        </p>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="USP">
                      <TextField
                        name="usp"
                        id="USP"
                        label="Unique Selling Proposition"
                        variant="outlined"
                        type="text"
                        autoComplete="off"
                        value={Formik2.values.usp}
                        onBlur={Formik2.handleBlur}
                        onChange={Formik2.handleChange}
                        className="inputBox fieldWidth"
                      />
                      {Formik2.touched.usp && Formik2.errors.usp ? (
                        <p className="formError">{Formik2.errors.usp}</p>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="Schedule No.">
                      <TextField
                        required
                        name="schedule_number"
                        id="Schedule No."
                        label="Schedule Number."
                        variant="outlined"
                        type="text"
                        autoComplete="off"
                        value={Formik2.values.schedule_number}
                        onBlur={Formik2.handleBlur}
                        onChange={Formik2.handleChange}
                        className="inputBox fieldWidth"
                      />
                      {Formik2.touched.schedule_number &&
                      Formik2.errors.schedule_number ? (
                        <p className="formError">
                          {Formik2.errors.schedule_number}
                        </p>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="  CD Number">
                      <TextField
                        required
                        name="cd_number"
                        id="CD Number"
                        label="CD Number"
                        variant="outlined"
                        autoComplete="off"
                        type="text"
                        value={Formik2.values.cd_number}
                        onBlur={Formik2.handleBlur}
                        onChange={Formik2.handleChange}
                        className="inputBox fieldWidth"
                      />
                      {Formik2.touched.cd_number && Formik2.errors.cd_number ? (
                        <p className="formError">{Formik2.errors.cd_number}</p>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="sale_deed_number">
                      <TextField
                        required
                        ref={sale_deed_number}
                        name="sale_deed_number"
                        id="sale_deed_number"
                        label="Sale Deed Number"
                        variant="outlined"
                        type="text"
                        autoComplete="off"
                        value={Formik2.values.sale_deed_number}
                        onBlur={Formik2.handleBlur}
                        onChange={Formik2.handleChange}
                        className="InputBox fieldWidth"
                      />

                      {Formik2.touched.sale_deed_number && Formik2.errors.sale_deed_number ? (
                        <p className="formError">{Formik2.errors.sale_deed_number}</p>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col>
                      <Form.Group className="mb-3" controlId="saleDeedDate">
                        <TextField
                          type="date"
                          variant="outlined"
                          label="Sale Deed Date"
                          className="col-12 custom-border "
                          name="sale_deed_date"
                          defaultValue={formattedSaleDeedDate} // Use defaultValue instead of value
                          onBlur={handleBlur}
                          onChange={(event) => {
                            const selectedDate = event.target.value;
                            setFormattedSaleDeedDate(selectedDate);
                            Formik2.setFieldValue("sale_deed_date", selectedDate);
                          }}
                          inputProps={{ max: todayString, placeholder: '' }}
                          InputLabelProps={{ shrink: true }}
                        />
                        {Formik2.touched.sale_deed_date && Formik2.errors.sale_deed_date ? (
                          <p className="formError">{Formik2.errors.sale_deed_date}</p>
                        ) : null}
                      </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="previous_owner">
                      <TextField
                        required
                        ref={previous_owner}
                        name="previous_owner"
                        id="previous_owner"
                        label="Previous Owner"
                        variant="outlined"
                        type="text"
                        autoComplete="off"
                        value={Formik2.values.previous_owner}
                        onBlur={Formik2.handleBlur}
                        onChange={Formik2.handleChange}
                        className="InputBox fieldWidth"
                      />

                      {Formik2.touched.previous_owner &&
                      Formik2.errors.previous_owner ? (
                        <p className="formError">
                          {Formik2.errors.previous_owner}
                        </p>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Is Rented *
                      </InputLabel>
                      <Select
                        name="rented"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="IsRented"
                        className="inputBox fieldWidth"
                        value={Formik2.values.rented}
                        onBlur={Formik2.handleBlur}
                        onChange={Formik2.handleChange}
                      >
                        <MenuItem value={"Yes"}>Yes</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Col>
                  {Formik2.values.rented === "Yes" && (
                    <Col>
                      <Form.Group className="mb-3" controlId="TenantName">
                        <TextField
                          name="tenant_name"
                          id="Tenant Name"
                          label="Tenant Name"
                          variant="outlined"
                          type="text"
                          autoComplete="off"
                          value={Formik2.values.tenant_name}
                          onBlur={Formik2.handleBlur}
                          onChange={Formik2.handleChange}
                          className="inputBox fieldWidth"
                        />
                        {Formik2.touched.tenant_name &&
                        Formik2.errors.tenant_name ? (
                          <p className="formError">
                            {Formik2.errors.tenant_name}
                          </p>
                        ) : null}
                      </Form.Group>
                    </Col>
                  )}
                </Row>
                {Formik2.values.rented === "Yes" && (
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="TenantMail">
                        <TextField
                          name="tenant_email"
                          id="Tenant Mail"
                          label="Tenant Mail"
                          variant="outlined"
                          type="email"
                          autoComplete="off"
                          value={Formik2.values.tenant_email}
                          onBlur={Formik2.handleBlur}
                          onChange={Formik2.handleChange}
                          className="inputBox fieldWidth"
                        />
                        {Formik2.touched.tenant_email &&
                        Formik2.errors.tenant_email ? (
                          <p className="formError">
                            {Formik2.errors.tenant_email}
                          </p>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="TenantPhone">
                        <TextField
                          name="tenant_phone"
                          id="Tenant Phone"
                          label="Tenant Phone"
                          variant="outlined"
                          autoComplete="off"
                          type="text"
                          value={Formik2.values.tenant_phone}
                          onBlur={Formik2.handleBlur}
                          onChange={Formik2.handleChange}
                          className="inputBox fieldWidth"
                        />
                        {Formik2.touched.tenant_phone &&
                        Formik2.errors.tenant_phone ? (
                          <p className="formError">
                            {Formik2.errors.tenant_phone}
                          </p>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col className="text-center  pt-5">
                    <Button
                      style={primaryButtonStyle}
                      type="submit"
                      onClick={(e) => onPrev(e)}
                    >
                      Prev
                    </Button>
                  </Col>
                  <Col className="text-center pt-5">
                    <Button
                      style={primaryButtonStyle}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
      {modal === 3 ? (
        <Modal
          size="lg"
          show={isDisplay}
          onHide={() => setIsDisplay(false)}
          className="addProperty"
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton className="add-modal">
            <Modal.Title
              id="example-modal-sizes-title-lg"
              className="text-center w-100 text-light"
            >
              Price Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="add-modal">
            {isLoading ? (
              <Loader />
            ) : (
              <Form>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="Platform Fee ">
                      <TextField
                        id="Platform Fee"
                        label="Platform Fee"
                        variant="outlined"
                        type="text"
                        value={data?.Platform_Fee}
                        onChange={(e) =>
                          setData({ ...data, Platform_Fee: e.target.value })
                        }
                        className="InputBox fieldWidth"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="Price">
                      <TextField
                        id="Price"
                        label="Price"
                        variant="outlined"
                        type="text"
                        value={data?.price}
                        onChange={(e) =>
                          setData({ ...data, price: parseInt(e.target.value) })
                        }
                        className="InputBox fieldWidth"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="Token amount">
                      <TextField
                        id="Token amount"
                        label="Token amount"
                        variant="outlined"
                        type="text"
                        value={data?.token_amount}
                        onChange={(e) =>
                          setData({ ...data, token_amount: e.target.value })
                        }
                        className="InputBox fieldWidth"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="TDS">
                      <TextField
                        id="TDS"
                        label="TDS"
                        variant="outlined"
                        type="text"
                        value={data?.tds}
                        onChange={(e) =>
                          setData({ ...data, tds: e.target.value })
                        }
                        className="InputBox fieldWidth"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <Form.Group className="mb-3" controlId="Agreement amount">
                      <TextField
                        id="Agreement amount"
                        label="Agreement amount"
                        variant="outlined"
                        type="text"
                        value={data?.agreement_amount}
                        onChange={(e) =>
                          setData({ ...data, agreement_amount: e.target.value })
                        }
                        className="InputBox fieldWidth"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center pt-5">
                    <Button
                      className="button"
                      variant="primary px-5"
                      type="submit"
                      onClick={(e) => onPrevDetails(e)}
                    >
                      Prev
                    </Button>
                  </Col>
                  <Col className="text-center pt-5">
                    <Button
                      className="button"
                      variant="light px-5"
                      type="submit"
                      onClick={(e) => onSubmitPriceDetails(e)}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default AddPropertyModal;
