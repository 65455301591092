import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

import './deleteModalStyles.scss';
import { IconButton } from "@mui/material";
import { DeleteRounded } from "@mui/icons-material";
import { propertyImageGet, updatePropertyImages } from "../../../services/api";
import { useToasts } from "react-toast-notifications";
import Loader from "../../Loader/Loader";
import { getErrorMessage, getSuccessMessage } from "../../../constants/getMessages";
import { ERROR_KEY, SUCCESS_KEY, defaultValues } from "../../../constants";

const DeletePropertyImages = ({
  showModal,
  setSHowModal,
  propertyId,
  imageCallback,
}) => {
    const {addToast} = useToasts();
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getPropertyImages = useCallback(async (propertyId)=>{
        const payload = { propertyId : propertyId };
        try {
          const response = await propertyImageGet(payload);
          if(response.status===200 && response.data?.success){
            setImages([...response.data.data]);
            setIsLoading(false);
          }
        } catch (error) {
          addToast(getErrorMessage('E-10149'), { appearance: ERROR_KEY, autoDismiss: true });
        }
         
      }, [addToast])

    useEffect(() => {
        getPropertyImages(propertyId);
    }, [getPropertyImages, propertyId]);


    const handleDelete = (val) => {
        setIsLoading(true);
        let payload = {
            propertyId: propertyId,
            fileName: val,
        }
        updatePropertyImages(payload).then(res => {
            addToast(getSuccessMessage("S-10016"), {appearance: SUCCESS_KEY, autoDismiss: true})
            getPropertyImages(propertyId);
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
            addToast((err.response.data.message), {appearance: ERROR_KEY, autoDismiss: true});
        })
    }

  return (
    <>
      <Modal
        size="lg"
        show={showModal}
        onHide={() => {
            setSHowModal(false);
            imageCallback();
        }}
        aria-labelledby="example-modal-sizes-title-lg"
        className="editProperty"
      >
        <Modal.Header closeButton className="edit-modal">
          <Modal.Title
            id="example-modal-sizes-title-lg"
            className="text-center w-100 text-light"
          >
            {defaultValues.modalTitles.delete_property_image}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="edit-modal">
            {isLoading ? <Loader /> 
            : 
                images?.map(item => {
                    return (
                        <div className="outer">
                            <img src={item.url} className="image" alt="prop-img"/>
                            <IconButton onClick={() => handleDelete(item.fileName)}>
                                <DeleteRounded className="deleteBtn" />
                            </IconButton>
                        </div>
                    )
                })
            }             
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeletePropertyImages;
