import React, { useCallback, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, InputAdornment, IconButton, OutlinedInput, InputLabel } from "@mui/material";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./style.scss";
import { updateUser } from "../../services/api";
import { useToasts } from "react-toast-notifications";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { store } from "../../redux/store";
import { Routes } from "../../constants/routes";

const styles={
  background:'white'
}

const UpdatePassword = ({ openModal,setOpenModal }) => {
  const history=useHistory()
  const { addToast } = useToasts();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const loggedInUser = useSelector((state) => state.loggedInUser);
  const [state, setState] = useState({
    id: `${loggedInUser.data?.data?.userByEmail?._id}`,
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  const passwordSchema = yup.object({
    password: yup.string().required("Enter new password"),
    newPassword: yup
      .string()
      .required("Enter new password")
      .required()
      .matches(/^(?=.*[a-z])/, "Must contain one lowercase character")
      .matches(/^(?=.*[A-Z])/, "Must contain one uppercase character")
      .matches(/^(?=.*\d)/, "Must contain one number character")
      .matches(
        /^(?=.*[!@#$%^&*])/,
        "Must contain one special character"
      )
      .min(8, "Must contain 8 characters"),
    confirmPassword: yup
      .string()
      .required("Enter confirm password")
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(passwordSchema),
    mode: "all" 
  });
    
  const onSubmit = (data) => {
    const { password, newPassword } = data;
      const updatedData = {
        id: state.id,
        password: newPassword,
        current_password: password,
      };
      updateUser(updatedData).then(res => {
        addToast('Password changed successfully! Please login again.', {
          appearance: "success",
          autoDismiss: true,
        });
        setOpenModal(false);
        setTimeout(() => {
          sessionStorage.clear();
          store.dispatch({type: 'USER_LOGOUT'});
          history.push(Routes.HOME);
        }, 500);
      }).catch(err => {
        addToast(err?.response?.data?.message || 'oops! something went wrong', {
          appearance: "error",
          autoDismiss: true,
        });
      })
  };

  const handleClickShowPassword = useCallback((type) => {
    if(type === 'pass') {
      setShowPassword((show) => !show)
    } else if(type === 'confPass') {
      setShowConfPassword((show) => !show)
    }
  },[]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
    

  return (
    <Modal
    size="md"
    show={openModal}
    onHide={() => setOpenModal(false)}
    aria-labelledby="example-modal-sizes-title-lg"
    className="editProperty"
  >
    <Modal.Header closeButton className="edit-modal">
        <Modal.Title
          id="example-modal-sizes-title-lg"
          className="text-center w-100 text-light"
        >
          Change Password
        </Modal.Title>
        </Modal.Header>
        <Modal.Body className="edit-modal">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3">
              <InputLabel htmlFor="outlined-adornment-password">
                Enter Current Password
              </InputLabel>
              <OutlinedInput
                id="Enter Current Password"
                label="Enter Current Password"
                variant="outlined"
                type="password"
                className="form-input"
                size="small"
                name="password"
                {...register("password")}
                error={Boolean(errors.password)}
                fullWidth
              />
            </Form.Group>
            {errors.password ? (
              <div className="formErrors">{errors.password.message}</div>
            ): null }           
            <Form.Group className="mb-3">
              <InputLabel htmlFor="outlined-adornment-password">
                Enter New Password
              </InputLabel>
              <OutlinedInput
                id="newPassword"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                className="form-input"
                size="small"
                name="newPassword"
                {...register("newPassword")}
                error={Boolean(errors.newPassword)}
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleClickShowPassword('pass')}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Form.Group>
            {errors.newPassword ? (
              <div className="formErrors">{errors.newPassword.message}</div>
            ): null }

            <Form.Group className="mb-3">
              <InputLabel htmlFor="outlined-adornment-password">
               Enter Confirm Password
              </InputLabel>
              <OutlinedInput
                id="confirmPassword"
                variant="outlined"
                type={showConfPassword ? "text" : "password"}
                className="form-input"
                size="small"
                name="confirmPassword"
                {...register("confirmPassword")}
                error={Boolean(errors.confirmPassword)}
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleClickShowPassword('confPass')}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Form.Group>
            {errors.confirmPassword ? (
              <div className="formErrors">{errors.confirmPassword.message}</div>
            ): null }
            <Row>
                <Col>
             <Button 
            variant="outlined" 
            type="submit"
            className="formBtn" 
            fullWidth
            sx={{styles}}
            >
              Ok
            </Button>
            </Col>
            <Col>
            <Button 
            variant="outlined" 
            type="submit"
            className="formBtn"
            fullWidth
            onClick={()=>{setOpenModal(false)}}
            >
            Cancel
            </Button>
            </Col>
            </Row>
            </Form>
        </Modal.Body>
    </Modal>
  );
};
export default UpdatePassword;
