import React, { useEffect, useState } from "react";
import dash from "../../../assets/dash.svg";
import AddUsers from "../../super-admin-addUsers";
import ownerName from '../../../assets/Icons/Owner Name.svg';
import ownerHighlight from '../../../assets/Icons/Owner Name1.svg';
import POA from '../../../assets/Icons/POA.svg';
import POAHighlight from '../../../assets/Icons/POA1.svg';
import Broker from '../../../assets/Icons/Broker Name.svg';
import BrokerHighlight from '../../../assets/Icons/Broker Name1.svg';
import Lawyer from '../../../assets/Icons/Lawyer Name.svg';
import LaywerHighlight from '../../../assets/Icons/Lawyer Name1.svg';
import BankAgent from '../../../assets/Icons/Bank AGent Name.svg';
import BankAgentHighlight from '../../../assets/Icons/Bank AGent Name1.svg';
import CA from '../../../assets/Icons/CA Name.svg';
import CAHighlight from '../../../assets/Icons/CA Name1.svg';
import '../style.scss';
import { Button } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import { useDispatch, useSelector } from "react-redux";
import { addPropertyHistory, deleteUser} from "../../../services/api";
import { getPropertiesData } from "../service";
import { useToasts } from "react-toast-notifications";
import { getUserId } from "../../../utils/HelperFunction";
import { historyFormatter } from "../../../utils/historyFormatter";
import { ERROR_KEY, SUCCESS_KEY, defaultValues, getErrorMessage, getSuccessMessage } from "../../../constants";
import { primaryButtonStyle } from "../../../styles/commonstyles";
import EditUser from "../../super-admin-addUsers/editUser";


const SellerDetailsData = ({ data, user, viewProperty, callBack }) => {
    const dispatch = useDispatch();
    const [showSellerDelete, setShowSellerDelete] = useState(false);
    const [showBuyerDelete, setShowBuyerDelete] = useState(false);
    const [addUserModal, setAddUserModal] = useState(false);
    const [editUserModal, setEditUserModal] = useState(false);
    const [addUserType, setAddUserType] = useState("");
    const [editUserType, setEditUserType] = useState("");
    const [seller, setSeller] = useState([]);
    const [buyer, setBuyer] = useState([]);
    const propertyUserRole = useSelector((state) => state.loggedInUser?.preferredRole);
    const roleId = useSelector((state) => state.loggedInUser?.data?.data?.userByEmail);
    const loggedUser = useSelector((state) => state.loggedInUser);
    const { addToast } = useToasts();
    const [editUserId, setEditUserId] = useState(null)
    const [editUserRole, setEditUserRole] = useState("");
    useEffect(() => {
        let isSubscribed = true;
        var buyers = data?.filter(function (obj) {
            return obj.role_side === defaultValues.role_names.buyer;
        });
        isSubscribed && setBuyer(buyers);
        var sellers = isSubscribed && data?.filter(function (obj) {
            return obj.role_side === defaultValues.role_names.seller;
        });
        isSubscribed && setSeller(sellers);
        return () => (isSubscribed = false)
    }, [data]);

    const onDeleteUser = (user_id) => {
        const payload = {
            property_id: viewProperty._id,
            id:  user_id._id
        }
        deleteUser( payload) 
        .then(async(response) => {
            addToast(getSuccessMessage('S-10008'),{appearance: SUCCESS_KEY, autoDismiss: true})
            dispatch(getPropertiesData(viewProperty._id));
            callBack();
            const user = user_id;
            let historyData = historyFormatter(user, getUserId(loggedUser), viewProperty._id, `has been deleted from the property by ${propertyUserRole}`);
            await addPropertyHistory(historyData);
        })
        .catch((error) => {
            addToast(getErrorMessage('E-10136'),{appearance: ERROR_KEY, autoDismiss: true})
        });
    };

  

    return (
      <div className="details-container">
        <div className="details">
          <div className="seller-details fw-bold">
            <h4>Seller Details</h4>
            <Button
              className={
                propertyUserRole === defaultValues.role_names.lawyer ||
                propertyUserRole === defaultValues.role_names.ca ||
                propertyUserRole === defaultValues.role_names.bank_agent ||
                propertyUserRole === defaultValues.role_names.bank_agent ||
                propertyUserRole === defaultValues.role_names.buyer_poa ||
                propertyUserRole === defaultValues.role_names.seller_poa
                  ? "not-allowed"
                  : ""
              }
              disabled={
                propertyUserRole === defaultValues.role_names.lawyer ||
                propertyUserRole === defaultValues.role_names.ca ||
                propertyUserRole === defaultValues.role_names.bank_agent ||
                propertyUserRole === defaultValues.role_names.bank_agent ||
                propertyUserRole === defaultValues.role_names.buyer_poa ||
                propertyUserRole === defaultValues.role_names.seller_poa
              }
              style={primaryButtonStyle}
              onClick={() => {
                setAddUserModal(!addUserModal);
                setAddUserType(defaultValues.role_names.seller);
              }}
            >
              Add User
            </Button>

            {propertyUserRole === defaultValues.role_names.super_admin ||
            propertyUserRole === defaultValues.role_names.admin ? (
              <img
                src="/imgs/delete-icon.svg"
                className={`editIcons ${
                  propertyUserRole === defaultValues.role_names.seller ||
                  propertyUserRole === defaultValues.role_names.admin ||
                  propertyUserRole === defaultValues.role_names.super_admin
                    ? ""
                    : "not-allowed"
                }`}
                onClick={() => setShowSellerDelete(!showSellerDelete)}
                alt="Delete User"
              />
            ) : (
              ""
            )}
          </div>
          <img src={dash} alt="dash" />
          <div className="buyer-details fw-bold">
            <h4>Buyer Details</h4>
            <Button
              className={
                propertyUserRole === defaultValues.role_names.lawyer ||
                propertyUserRole === defaultValues.role_names.ca ||
                propertyUserRole === defaultValues.role_names.seller_agent ||
                propertyUserRole === defaultValues.role_names.bank_agent ||
                propertyUserRole === defaultValues.role_names.buyer_poa ||
                propertyUserRole === defaultValues.role_names.seller_poa
                  ? "not-allowed"
                  : ""
              }
              disabled={
                propertyUserRole === defaultValues.role_names.lawyer ||
                propertyUserRole === defaultValues.role_names.ca ||
                propertyUserRole === defaultValues.role_names.seller_agent ||
                propertyUserRole === defaultValues.role_names.bank_agent ||
                propertyUserRole === defaultValues.role_names.buyer_poa ||
                propertyUserRole === defaultValues.role_names.seller_poa
              }
              style={primaryButtonStyle}
              onClick={() => {
                setAddUserModal(!addUserModal);
                setAddUserType(defaultValues.role_names.buyer);
              }}
            >
              Add User
            </Button>
            {propertyUserRole === defaultValues.role_names.super_admin ||
            propertyUserRole === defaultValues.role_names.admin ? (
              <img
                src="/imgs/delete-icon.svg"
                className={`editIcons ${
                  propertyUserRole === defaultValues.role_names.buyer ||
                  propertyUserRole === defaultValues.role_names.admin ||
                  propertyUserRole === defaultValues.role_names.super_admin
                    ? ""
                    : "not-allowed"
                }`}
                onClick={() => setShowBuyerDelete(!showBuyerDelete)}
                alt="Delete User"
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="users-details-left">
          <div className="userslist">
            {seller?.length ? (
              seller?.map((item) => {
                return (
                  <div style={{ position: "relative", padding: "1.5rem" }}>
                    <div
                      className="hover-text"
                      style={{
                        backgroundColor:
                          item?.user_id?._id === roleId?._id
                            ? "#0062ae"
                            : "white",
                        height: "6rem",
                        width: "6rem",
                        borderRadius: "50%",
                        padding: "1.4rem",
                      }}
                      onClick={() => {if (
                        item?.role?.role_name === defaultValues.role_names.seller ||
                        item?.role?.role_name === defaultValues.role_names.buyer
                      ) {
                        setEditUserModal(!editUserModal);
                        setEditUserType(defaultValues.role_names.buyer);
                        setEditUserId(item?.user_id?._id);
                        setEditUserRole(item?.role?.role_name);
                      }
                    }}
                    >
                      {showSellerDelete && (
                        <div className="delete-icon">
                          <Button onClick={() => onDeleteUser(item?.user_id)}>
                            <CancelIcon sx={{ color: "var(--red)" }} />
                          </Button>
                        </div>
                      )}
                      <img
                        src={
                          item?.role?.role_name ===
                            defaultValues.role_names.seller ||
                          item?.role?.role_name ===
                            defaultValues.role_names.buyer
                            ? item?.user_id?._id === roleId?._id
                              ? ownerHighlight
                              : ownerName
                            : item?.role?.role_name.includes(
                                defaultValues.role_names.ca
                              )
                            ? item?.user_id?._id === roleId?._id
                              ? CAHighlight
                              : CA
                            : item?.role?.role_name.includes(
                                defaultValues.role_names.lawyer
                              )
                            ? item?.user_id?._id === roleId?._id
                              ? LaywerHighlight
                              : Lawyer
                            : item?.role?.role_name.includes(
                                defaultValues.role_names.poa
                              )
                            ? item?.user_id?._id === roleId?._id
                              ? POAHighlight
                              : POA
                            : item?.role?.role_name.includes(
                                defaultValues.role_names.agent
                              )
                            ? item?.user_id?._id === roleId?._id
                              ? BrokerHighlight
                              : Broker
                            : item?.role?.role_name.includes(
                                defaultValues.role_names.bank_agent
                              ) &&
                              (item?.user_id?._id === roleId?._id
                                ? BankAgentHighlight
                                : BankAgent)
                        }
                        alt="owner-name"
                      />
                      <span className="seller-name">
                        {item?.role?.role_name}
                        <br />
                        <span
                          className={
                            `${item?.user_id?.first_name} ${item?.user_id?.last_name}`
                              .length > 10
                              ? "marquee"
                              : ""
                          }
                        >
                          <p>{`${item?.user_id?.first_name} ${item?.user_id?.last_name}`}</p>
                        </span>
                      </span>
                    </div>
                    <div className="hover-content">
                      <p>
                        <span>Email Id: </span>
                        {item?.user_id?.email_id}
                      </p>
                      <p>
                        <span>Phone no.: </span>
                        {item?.user_id?.phone}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              <span className="no-record">No User Found</span>
            )}
          </div>
        </div>
        <div className="users-details-right">
          <div className="userslist">
            {buyer?.length ? (
              buyer?.map((item, index) => {
                return (
                  <div style={{ position: "relative", padding: "1.5rem" }}>
                    <div
                      className="hover-text"
                      style={{
                        alignItems: "center",
                        backgroundColor:
                          item?.user_id?._id === roleId?._id
                            ? "#0062ae"
                            : "white",
                        height: "6rem",
                        width: "6rem",
                        borderRadius: "3rem",
                        padding: "1.4rem",
                      }}
                      onClick={() => {if (
                        item?.role?.role_name === defaultValues.role_names.seller ||
                        item?.role?.role_name === defaultValues.role_names.buyer
                      ) {
                        setEditUserModal(!editUserModal);
                        setEditUserType(defaultValues.role_names.buyer);
                        setEditUserId(item?.user_id?._id);
                        setEditUserRole(item?.role?.role_name);
                      }
                      }}
                    >
                      {showBuyerDelete && (
                        <div className="delete-icon">
                          <Button onClick={() => onDeleteUser(item?.user_id)}>
                            <CancelIcon sx={{ color: "var(--red)" }} />
                          </Button>
                        </div>
                      )}
                      <img
                        src={
                          item?.role?.role_name ===
                            defaultValues.role_names.seller ||
                          item?.role?.role_name ===
                            defaultValues.role_names.buyer
                            ? item?.user_id?._id === roleId?._id
                              ? ownerHighlight
                              : ownerName
                            : item?.role?.role_name.includes(
                                defaultValues.role_names.ca
                              )
                            ? item?.user_id?._id === roleId?._id
                              ? CAHighlight
                              : CA
                            : item?.role?.role_name.includes(
                                defaultValues.role_names.lawyer
                              )
                            ? item?.user_id?._id === roleId?._id
                              ? LaywerHighlight
                              : Lawyer
                            : item?.role?.role_name.includes(
                                defaultValues.role_names.poa
                              )
                            ? item?.user_id?._id === roleId?._id
                              ? POAHighlight
                              : POA
                            : item?.role?.role_name.includes(
                                defaultValues.role_names.agent
                              )
                            ? item?.user_id?._id === roleId?._id
                              ? BrokerHighlight
                              : Broker
                            : item?.role?.role_name.includes(
                                defaultValues.role_names.bank_agent
                              ) &&
                              (item?.user_id?._id === roleId?._id
                                ? BankAgentHighlight
                                : BankAgent)
                        }
                        alt="owner-name"
                      />

                      <span className="seller-name">
                        {item?.role?.role_name}
                        <br />
                        <span
                          className={
                            `${item?.user_id?.first_name} ${item?.user_id?.last_name}`
                              .length > 10
                              ? "marquee"
                              : ""
                          }
                        >
                          <p>{`${item?.user_id?.first_name} ${item?.user_id?.last_name}`}</p>
                        </span>
                      </span>
                    </div>
                    <div className="hover-content">
                      <p>
                        <span>Email Id: </span>
                        {item?.user_id?.email_id}
                      </p>
                      <p>
                        <span>Phone no.: </span>
                        {item?.user_id?.phone}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              <span className="no-record">No User Found</span>
            )}
          </div>
        </div>
        <AddUsers
          setIsDisplay={() => setAddUserModal(!addUserModal)}
          isDisplay={addUserModal}
          userType={addUserType}
          name={user}
          roleName={propertyUserRole}
          viewProperty={viewProperty}
          data={data}
          origin={defaultValues.add_user_origins.seller_details}
        />
        <EditUser
        setIsDisplay={() => setEditUserModal(!editUserModal)}
        isDisplay={editUserModal}
        userType={editUserType}
        userId={editUserId}
        name={user}
        roleName={propertyUserRole}
        viewProperty={viewProperty}
        data={data}
        origin={defaultValues.add_user_origins.seller_details}
        editUserRole={editUserRole}
        />
      </div>
    );

}
export default SellerDetailsData;