import React from 'react'
import './style.scss'

const TooltipError = (props) => {
    const { errors = [] } = props
    return (
        <div className='errorContainer'>
            <div className='errorWrapper'>
                <ul className='list'>
                    {
                        errors.map((error, index) =>
                            <li key={index} className='listItem'>
                                {error}
                            </li>
                        )
                    }
                </ul>
            </div>
        </div>
    )
}

export default TooltipError