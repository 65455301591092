import React, { useState, useEffect } from "react";
import { Row, Col, Image } from "react-bootstrap";
import "./style.scss";
import { BsSearch } from "react-icons/bs";
import ellipse from "../../assets/Ellipse-green.svg";
import circlePlus from "../../assets/green_circle-plus.svg";
import { useDispatch } from "react-redux";
import { removeSelectedProperty } from "./slices";
import { removeDocs } from "../initiate-offer/slices";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import { updatePreferredRole } from "../login/slices";
import { Routes } from "../../constants/routes";
import { getMyRentalAgreement } from "../rental-agreement-new/service";
import { resetSelectedRental } from "../rental-agreement-new/slices";
import { defaultValues, getErrorMessage } from "../../constants";
import { customStyles } from "./customStyles.js";

const MyRentals = (props) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();
  const accessToken = useSelector(
    (state) => state.loggedInUser?.data?.data?.accessToken
  );
  const actAgreement = useSelector((state) => state.rentalAgreement.agreements);

  const userId = useSelector(
    (state) => state?.loggedInUser?.data?.data?.userByEmail?._id || state?.loggedInUser?.data?.superAdminByEmail?._id
  );

  useEffect(() => {
    if (!accessToken) {
      history.push(Routes.HOME);
    } else {
      window.scrollTo(0, 0);
      dispatch(removeSelectedProperty());
      dispatch(removeDocs());
      dispatch(updatePreferredRole(""));
    }
  }, [accessToken, dispatch, history]);

  useEffect(() => {
    getMyRentals();
  }, []);

  const getMyRentals = () => {
    dispatch(resetSelectedRental());
    dispatch(getMyRentalAgreement());
  };

  const getRole = (argData) => {
    const li = argData.rental_parties.filter(
      (party) => party.userId === userId
    );
    if (li.length) {
      return li[0].type === defaultValues.rental_roles.owner
        ? "Owner"
        : "Tenant";
    } else {
      return argData.createdBy?._id === userId ? "Initiator" : "NA";
    }
  };

  const getPartiesName = (partyType, list) => {
    const names = list
      .map((user) => {
        return user.type === partyType
          ? `${user?.first_name} ${user?.last_name}, `
          : null;
      })
      .join("")
      .slice(0, -2);
    return names === "" ? "Not Added" : names;
  };

  useEffect(() => {
    dispatch(getMyRentalAgreement({ refId: searchValue }));
  }, [searchValue]);

  return (
    <div>
      <div className="search-box">
        <input
          type="text"
          className="input"
          placeholder="Search for rental properties with rental ID No"
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <img src={ellipse} alt="ellipse" className="search-circle" />
        <BsSearch className="bs-search" />
      </div>
      <div className="plusCircle">
        <img
          alt="property Add button"
          src={circlePlus}
          className="manage-btn-img"
          onClick={() => history.push(Routes.SUBMIT_RENTAL_AGREEMENT)}
        />
        <p>Add Rental Agreement</p>
      </div>

      <div>
        <div className="assigned">
          <p style={{ color: "var(--primaryColor)" }}>My Rentals</p>
        </div>
        <div>
          {actAgreement && actAgreement.length > 0 ? (
            actAgreement.map((rental, index) => (
              <Row style={customStyles.contentContainerStyle} key={index}>
                <Col xs={12} md={2} className="imageContainer">
                  <Image
                    src="/imgs/interior.jpg"
                    alt="addProp"
                    style={customStyles.defaultImage}
                  />
                </Col>
                <Col xs={12} md={4} className="fieldsContainer">
                  <p>
                    <span className="labelArea">Reference No:</span>{" "}
                    <span>{rental.refId}</span>
                  </p>
                  <p>
                    <span className="labelArea">Owner name: </span>
                    <span>
                      {getPartiesName(
                        defaultValues.rental_roles.owner,
                        rental?.rental_parties
                      )}
                    </span>
                  </p>
                  <p>
                    <span className="labelArea">City:</span>{" "}
                    <span>{rental?.city}</span>
                  </p>
                </Col>
                <Col xs={12} md={4} className="fieldsContainer">
                  <p>
                    <span className="labelArea">Agreement Type :</span>{" "}
                    <span>
                      {rental?.type === defaultValues.rental_agreement_type.new
                        ? "New"
                        : "Renew"}
                    </span>
                  </p>
                  <p>
                    <span className="labelArea">Tenant name: </span>
                    <span>
                      {getPartiesName(
                        defaultValues.rental_roles.tenant,
                        rental?.rental_parties
                      )}
                    </span>
                  </p>
                  <span className="labelArea">Sign date:</span>{" "}
                  <span className="text-dark">
                    {rental.signDate ? rental.signDate.split("T")[0] : "N/A"}
                  </span>
                </Col>
                <Col xs={12} md={2} className="fieldsContainer">
                  <p>
                    <span className="labelArea">Role:</span>{" "}
                    <span>{getRole(rental)}</span>
                  </p>
                  <p>
                    <span className="labelArea">Signature Via:</span>{" "}
                    <span>{(rental?.signatureVia || "").toUpperCase()}</span>
                  </p>
                  <div>
                    {rental.stageNo === 5 ? (
                      <p>
                        <span className="labelArea badge badge-info text-wrap text-white">
                          Agreement generated
                        </span>
                      </p>
                    ) : (
                      <Button
                        style={customStyles.editBtn}
                        className="button px-4 mx-auto"
                        variant="primary"
                        onClick={() => {
                          history.push({
                            pathname: Routes.SUBMIT_RENTAL_AGREEMENT,
                            state: { argId: rental._id },
                          });
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            ))
          ) : (
            <div className="">
              <span className="no-record-found">
                {getErrorMessage("E-10278")}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default MyRentals;
