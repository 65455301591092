import React,{useState,useEffect} from "react";
import Main from "../../layout/Main";
import { useSelector , useDispatch} from "react-redux";
import { useHistory , useLocation} from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  Box,
  Button,
  Typography,
  FormControl,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormLabel
} from "@mui/material";
import {updateSubStage} from '../../utils/HelperFunction';
import {updateSelectedProperty} from "../user-home/slices"
import { addPropertyHistory, updateProperty } from "../../services/api";
import { Routes } from "../../constants/routes";
import { ERROR_KEY, PID_KEY, SUCCESS_KEY, defaultValues, getErrorMessage, getSuccessMessage } from "../../constants";
import Loader from "../../layout/Loader/Loader";
import { historyFormatter } from "../../utils/historyFormatter";

const LoanAgreement = (props) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const propertyId = useSelector((state)=> state?.buyerHomeData?.selectedProperty?._id) || '';
  const selectedProperty = useSelector((state)=> state?.buyerHomeData?.selectedProperty) || {};
  const roleName = useSelector((state) => state.loggedInUser.preferredRole);
  const user = useSelector((state) => state.loggedInUser.data.data.userByEmail);
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const [status, setStatus] = useState(null);
  const history = useHistory();

  const accessToken = useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";
  const subStages = useSelector((state)=> state?.stages?.subStages)
  
  const getSubStage = (order) => subStages.filter((substage)=> substage.order === order)[0]._id;
  const [loader, setLoader] = useState(null);

  useEffect(() => {
    const pid = searchParams.get(PID_KEY);
    if (!accessToken) {
      history.push(`${Routes.HOME}`);
    } else {
      if(roleName === defaultValues.role_names.bank_agent){   
        if (!propertyId || pid !== propertyId) {
          history.push(`${Routes.PROPERTIES}`);
        } else {
          history.push(`${Routes.LOAN_SANCTION}?pid=${propertyId}`);
        }
      } else { history.push(`${Routes.VIEW_PROPERTY}?pid=${propertyId}`); }
    }
  }, []);


  const getSubStageUpdated = async (propertyId, substage)=>{
    try{
      const response = await updateSubStage({propertyId, substageId: substage, loggedInUserId:user._id});
        if(response.status === 200){
          dispatch(updateSelectedProperty(response.data.propertyData));
          setLoader(false);
          addToast(getSuccessMessage('S-10006'), { appearance: SUCCESS_KEY, autoDismiss: true });
          history.push(`${Routes.VIEW_PROPERTY}?pid=${propertyId}`);
        }
      else { addToast(getErrorMessage('E-10070'), { appearance: ERROR_KEY, autoDismiss: true }); }
  
      } catch(error){
        setLoader(false);
        addToast(getErrorMessage('E-10111'), { appearance: ERROR_KEY, autoDismiss: true });
      }  
  }

  const handleSave = (propertyId) => {
    if (status === "") {
      addToast(getErrorMessage("E-10251"), {
        appearance: ERROR_KEY,
        autoDismiss: true,
      });
      return;
    }
    setLoader(true);
    const payload = {
      id: propertyId,
      bank: {
        inspection_time_date: selectedProperty?.bank?.inspection_time_date,
        loan_sanctioned: status,
      },
    };

    updateProperty(payload)
      .then((data) => {
        const historyData = historyFormatter(user, user._id, propertyId, 'has updated the loan status.');
        addPropertyHistory(historyData);
        if (getSubStage(15.1) === selectedProperty.sub_current_stage) {
          getSubStageUpdated(propertyId, getSubStage(16));
        }
      })
      .catch((error) => {
        addToast(error.response.data?.message || getErrorMessage("E-10070"), {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
        setLoader(false);
      });
  };
  
  

  return (
    <>{loader ? <Loader /> : 
      <div>
        <Main showUserNavbar={!props?.showUserNavbar} showUser={!props?.showUser}>
          <Box
              display="flex"
              flexDirection="column"
              justifyContent="start"
              alignItems="start"
              minHeight="70vh"
              bgcolor="var(--white)"
            >
              <div className="container">
                  <div className="inner-container">
                    <div className="fee-heading">
                      <Typography style={{color:"black"}} variant="h5" component="h3" sx={{ fontWeight: 100 }}>
                        Loan Sanction
                      </Typography>
                      <div className="picker">
                        <FormControl
                          required
                          component="fieldset"
                          variant="standard"
                        >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={status === true}
                                    onChange={() => setStatus(true)}
                                    name="inprogress"/>
                                }
                                label="Loan Sanction Accepted"
                                className="black-text"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                  checked={status === false}
                                  onChange={() => setStatus(false)}
                                    name="sanctioned"/>
                                }
                                label="Loan Sanction Rejected"
                                className="black-text"
                              />
                            </FormGroup>
                            <Button variant="contained" style={{background: 'var(--primaryButton)'}}
                              disabled={status === null} onClick={()=>{handleSave(propertyId)}}>
                                {status ? "Proceed" : "Save"}
                            </Button>
                        </FormControl>
                      </div>
                    </div>
                  </div>
              </div>
          </Box>
        </Main>
      </div>
    }</>
  );
};
export default LoanAgreement;
