import React, { useState, useEffect } from "react";
import Main from "../../layout/Main";
import "./style.scss";
import { BsSearch } from "react-icons/bs";
import ellipse from "../../assets/Ellipse 58.svg";
import circlePlus from "../../assets/circle-plus.svg";
import AddPropertyModal from "../../layout/PropertyModal/AddPropertyModal/addPropertyModal";
import { useDispatch } from "react-redux";
import { getManagePropData } from "../super-admin-manageProperties/service";
import { removeSelectedProperty } from "./slices";
import { removeDocs } from '../initiate-offer/slices'
import BuyerHomeData from "./buyer-data";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Tab } from "@mui/material";
import { Link } from "react-router-dom";
import { updatePreferredRole } from "../login/slices";
import { Routes } from "../../constants/routes";
import { ROLE_KEY, defaultValues } from "../../constants";
import { API } from "../../utils/api";
import MyRentals from "./rental-dashboard";
import { customStyles } from "./customStyles";

const BuyerHome = (props) => {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState("properties");
  const [searchValue, setSearchValue] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const role = sessionStorage.getItem(ROLE_KEY);
  const loggedInUser = useSelector((state) => state.loggedInUser);
  const userData = useSelector(
    (state) =>state.loggedInUser?.data?.data?.userByEmail
  );
  const history = useHistory();
  const [showAddBtn, setShowAddBtn] = useState(true);

  const accessToken = useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";
  const checkUser = loggedInUser?.data?.data?.userByEmail;
  useEffect(() => {
    if (!accessToken) {
      history.push(Routes.HOME);
    } else {
      window.scrollTo(0, 0);
      dispatch(removeSelectedProperty());
      dispatch(removeDocs())
      dispatch(updatePreferredRole(""));
    }
  }, [accessToken, dispatch, history]);

  useEffect(() => {
    if (props.location.state && props.location.state.tab) {
      setTabValue(props.location.state.tab);
    }
  }, [props.location]);

  useEffect(() => {
    if (userData && defaultValues.rental_role_name
        .includes(userData?.primary_role?.role_name)
    ) {
      setTabValue("rental");
    }
  }, [userData]);

  const getProperties = () => {
    dispatch(getManagePropData());
  }
  const checkUserProfile = () => {
    return !!checkUser?.gender
  };

  const changeHandle = (event, newValue) => {
    setTabValue(newValue);    
  };

  return (
    <div>
      <Main
        showWelcomePage={!props?.showWelcomePage}
        currentScreen={"user_home"}
        showChildren={!props?.showChildren}
      >
        <div className="buyer-container">
          <div className="headings">
            {(role === defaultValues.role_names.seller ||
              role === defaultValues.role_names.buyer ||
              role === defaultValues.role_names.bank_agent ||
              role === defaultValues.role_names.seller_agent ||
              role === defaultValues.role_names.buyer_agent ||
              role === defaultValues.role_names.seller_poa ||
              role === defaultValues.role_names.buyer_poa ||
              role === defaultValues.role_names.lawyer ||
              role === defaultValues.role_names.ca) && (
                <>
                  <p className="welcomeText">Welcome, {loggedInUser.data?.data?.userByEmail?.first_name}!</p>
                  {!checkUserProfile() && (
                    <p className="userCheckSet" >
                      Please complete user profile.{" "}
                      <Link to={Routes.PROFILE_SETTINGS}>Go to Profile Settings</Link>
                    </p>
                  )}
                </>
              )}
          </div> 
          <TabContext value={tabValue}>
            <Box sx={customStyles.tabBorder}>
              <TabList  value={tabValue} aria-label="basic tabs example"  
                centered onChange={changeHandle} >
                <Tab label="Properties" className="r-menu-text" value="properties"/>
                <Tab label="Rental" className="r-menu-text" value="rental"/>                
              </TabList>
            </Box>
            <TabPanel value="properties">
              <div>
                <div className="search-box">
                  <input type="text" className="input" placeholder="Search for properties with property ID No" onChange={(e) => setSearchValue(e.target.value)} />
                  <img src={ellipse} alt="ellipse" className="search-circle" />
                  <BsSearch className="bs-search" />
                </div>
                {showAddBtn && (
                  <div className="plusCircle">
                    <img
                      alt="circlePlus"
                      src={circlePlus}
                      className="manage-btn-img"
                      onClick={() => setOpenModal(!openModal)}
                    />
                    <p>Add Property</p>
                  </div>
                )}
                <div className="assigned">
                  <p style={{ color: 'var(--primaryColor)' }}>Properties Assigned</p>
                </div>
                <BuyerHomeData searchData={searchValue} callBack={getProperties} />
              </div> 
            </TabPanel>
            <TabPanel value="rental">
               <MyRentals /> 
            </TabPanel>
          </TabContext>          
        </div>
        <div className="desktop-footer">
          <footer>
            <Button className="text-light" variant='text' component={Link} href={API.footerUrl.terms_condition} target="_blank">Terms & Conditions</Button>
            <Button className="text-light" variant='text' component={Link} href={API.footerUrl.privacy} target="_blank">Privacy Policy</Button>
          </footer>
          <p>{defaultValues.copyRight}</p>
        </div>
      </Main>
      <AddPropertyModal
        setIsDisplay={() => setOpenModal(!openModal)}
        isDisplay={openModal}
        getUpdatedProperty = {getProperties}
      />
    </div>
  );
};
export default BuyerHome;