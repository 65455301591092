import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { TextField, Button } from "@mui/material";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom"
import { useToasts } from "react-toast-notifications";

import "./style.scss";
import { updateAdmin, updateSuperAdmin } from "../../services/api";
import { store } from "../../redux/store";
import { ERROR_KEY, SUCCESS_KEY, defaultValues, getErrorMessage, getSuccessMessage } from "../../constants";
import { Routes } from "../../constants/routes";


const ChangePassword = ({ openModal, setOpenModal }) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const loggedInUser = useSelector((state) => state.loggedInUser);
  const role = useSelector((state) => state.loggedInUser.preferredRole);
  const [state, setState] = useState({
    [role === defaultValues.role_names.admin ? "_id" : "id"]:
      role === defaultValues.role_names.super_admin
        ? loggedInUser.data?.data?.superAdminByEmail?._id || ""
        : loggedInUser.data?.data?.adminByEmail?._id || "",
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  const passwordSchema = yup.object({
    newPassword: yup
      .string()
      .required(getErrorMessage('E-10064'))
      .oneOf([yup.ref("confirmPassword"), null], getErrorMessage('E-10016'))
      .required()
      .matches(/^(?=.*[a-z])/, defaultValues.password_validation[0])
      .matches(/^(?=.*[A-Z])/, defaultValues.password_validation[1])
      .matches(/^(?=.*\d)/, defaultValues.password_validation[2])
      .matches(/^(?=.*[!@#$%^&*])/, defaultValues.password_validation[3])
      .min(8, defaultValues.password_validation[4]),
    confirmPassword: yup
      .string()
      .required(getErrorMessage('E-10221'))
      .oneOf([yup.ref("newPassword"), null],  getErrorMessage('E-10016')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(passwordSchema),
    mode: "onBlur",
  });
  const onSubmit = (data) => {
    const { password, newPassword } = data;

    const userId = loggedInUser?.data.data.superAdminByEmail?._id;

    if (role === defaultValues.role_names.super_admin) {
      const updatedSuperAdminData = {
        id: userId,

        password: newPassword,
        current_password: password,
      };
      updateSuperAdmin(updatedSuperAdminData).then((response) => {
          addToast(getSuccessMessage('S-10003'), {
            appearance: SUCCESS_KEY,
            autoDismiss: true,
          });
          setTimeout(() => {
            sessionStorage.clear();
            store.dispatch({type: defaultValues.dispatch_actions.logout});
            history.push(Routes.SUPER_ADMIN_LOGIN);
          }, 500);
        })
        .catch((e) => {
          const errorMessage = e?.response?.data?.error;
          if (errorMessage) {
            addToast(errorMessage, { appearance: ERROR_KEY, autoDismiss: true });
          } else {
            addToast(getErrorMessage('E-10070'), {
              appearance: ERROR_KEY,
              autoDismiss: true,
            });
          }
        });
    }

    if (role === defaultValues.role_names.admin) {
      const updatedAdminData = {
        current_password: password,
        password: newPassword,
        _id: state._id,
      };
      updateAdmin(updatedAdminData).then(() => {
          addToast(getSuccessMessage('S-10003'), {
            appearance: SUCCESS_KEY,
            autoDismiss: true,
          });
          setTimeout(() => {
            sessionStorage.clear();
            store.dispatch({type: defaultValues.dispatch_actions.logout});
            history.push(Routes.ADMIN_LOGIN);
          }, 500);
        })
        .catch((e) => {
          const errorMessage = e?.response?.data?.error;
          if (errorMessage) {
            addToast(errorMessage, { appearance: ERROR_KEY, autoDismiss: true });
          } else {
            addToast(getErrorMessage('E-10070'), {
              appearance: ERROR_KEY,
              autoDismiss: true,
            });
          }
        });
    }
    setOpenModal(true);
  };

  return (
    <Modal
      size="md"
      show={openModal}
      onHide={() => setOpenModal(false)}
      aria-labelledby="example-modal-sizes-title-lg"
      className="editProperty"
    >
      <Modal.Header closeButton className="edit-modal">
        <Modal.Title
          id="example-modal-sizes-title-lg"
          className="text-center w-100 text-light"
        >
          Change Password
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="edit-modal">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3">
            <TextField
              id="Enter Current Password"
              label="Enter Current Password"
              variant="outlined"
              type="text"
              className="form-input"
              size="small"
              name="password"
              autoComplete="off"
              {...register("password")}
              error={Boolean(errors.password)}
              fullWidth
            />
          </Form.Group>
          {errors.password ? (
            <div className="formErrors">{errors.password.message}</div>
          ) : null}

          <Form.Group className="mb-3">
            <TextField
              id="Enter New Password"
              label="Enter New Password"
              variant="outlined"
              type="text"
              autoComplete="off"
              className="form-input"
              size="small"
              name="newPassword"
              {...register("newPassword")}
              error={Boolean(errors.newPassword)}
              fullWidth
            />
          </Form.Group>
          {errors.newPassword ? (
            <div className="formErrors">{errors.newPassword.message}</div>
          ) : null}

          <Form.Group className="mb-3">
            <TextField
              id="Enter Confirm Password"
              label="Enter Confirm Password"
              variant="outlined"
              type="text"
              className="form-input"
              autoComplete="off"
              size="small"
              name="confirmPassword"
              {...register("confirmPassword")}
              error={Boolean(errors.confirmPassword)}
              fullWidth
            />
          </Form.Group>
          {errors.confirmPassword ? (
            <div className="formErrors">{errors.confirmPassword.message}</div>
          ) : null}
          <Row>
            <Col>
              <Button
                variant="outlined"
                type="submit"
                className="formBtn"
                fullWidth
              >
                Ok
              </Button>
            </Col>
            <Col>
              <Button
                variant="outlined"
                type="submit"
                className="formBtn"
                fullWidth
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export default ChangePassword;
