import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Button from "../../layout/Button";
import "./style.scss";

import { TextField } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { useToasts } from "react-toast-notifications";

import { addData, getData } from "./service";
import { addAdminSchema  } from "../../utils/validationSchemas";
import { useFormik } from "formik";
import { validatePhone } from "../../utils/HelperFunction";
import { ERROR_KEY, SUCCESS_KEY } from "../../constants";


const initialValues = {
  first_name: "",
  last_name: "",
  email_id: "",
  phone: ""
};



const AddAdminModal = ({ isOpen, onClose, ...props }) => {
  const [isDiscard, setIsDiscard] = useState(false);
  const { addToast } = useToasts();
  const adminList = useSelector((state) => state.admins);
  const dispatch = useDispatch();

  const {
    resetForm,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    errors,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: addAdminSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {},
  });

  useEffect(() => {
    if (adminList.message) {
      if (adminList.status === SUCCESS_KEY) {
        addToast(adminList.message, { appearance: SUCCESS_KEY, autoDismiss: true });
        onClose();
        resetForm();
        dispatch(getData());
      } else {
        addToast(adminList.message, { appearance: ERROR_KEY, autoDismiss: true });
      }
    }
  }, [adminList.message, adminList.status]);

  const handleAddAdmin = values => {
    values.middle_name = "";
    values.is_admin = true;
    values.phone= values.phone.toString();
    dispatch(addData(values));     
  }


  const onDoClose = (e) => {
    e.preventDefault();
    resetForm();
    onClose()
  };

  return (
    <Modal
      size="lg"
      show={isOpen}
      aria-labelledby="example-modal-sizes-title-lg"
      className="editProperty"
    >
      <div className="blueBg form-area form-area-full">
        {isDiscard ? (
          <div className="flex">
            <img className="mt-50" src="/imgs/discard.png" alt="discard" />
            <div className="mt-50"></div>
            <Button
              type="button"
              variant="secondary"
              className="btn-lg pull-left"
              text="Discard"
              onClick={(e) => onClose()}
            />
            <Button
              type="button"
              variant="secondary"
              className="btn-lg pull-right"
              text="No"
              onClick={(e) => setIsDiscard(false)}
            />
          </div>
        ) : (
          <Form className="container-fluid" onSubmit={handleSubmit}>
            <h1 className="mb-50">Add Admin</h1>
            <div class="row">
              <div class="col-3"></div>
              <div class="col-7">
              <div className="custom-offset">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <TextField
                    name="first_name"
                    type="text"
                    label="Enter First Name"
                    autoComplete="off"
                    fullWidth
                    value = {values.first_name} onBlur={handleBlur} onChange={handleChange}
                    
                  />
                  {touched.first_name && errors.first_name ? (
                    <p className="formError">{errors.first_name}</p>
                  ) : null }
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <TextField
                    name="last_name"
                    type="text"
                    label="Enter Last Name"
                    autoComplete="off"
                    fullWidth
                    value = {values.last_name} onBlur={handleBlur} onChange={handleChange}                    
                  />
                  {touched.last_name && errors.last_name ? (
                    <p className="formError">{errors.last_name}</p>
                  ) : null }
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <TextField
                    name="email_id"
                    type="text"
                    label="Enter Email ID"
                    autoComplete="off"
                    fullWidth
                    value = {values.email_id} onBlur={handleBlur} onChange={handleChange}                    
                  />
                  {touched.email_id && errors.email_id ? (
                    <p className="formError">{errors.email_id}</p>
                  ) : null }
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <TextField
                    name="phone"
                    type="number"
                    label="Phone Number As Per Aadhaar"
                    autoComplete="off"
                    fullWidth
                    value = {values.phone} onBlur={handleBlur} 
                    onChange={(e) => {
                      if(validatePhone(e.target.value))
                      {handleChange(e)}
                    }}
                  />
                  {touched.phone && errors.phone ? (
                    <p className="formError">{errors.phone}</p>
                  ) : null }
                </Form.Group>
                </div>

                <br />
                <div className="mt-50"></div>
                <Button
                  type="submit"
                  onClick={() => handleAddAdmin(values)}
                  variant="secondary"
                  className="btn-lg pull-left custom-offset"
                  text="Add"
                />
                <Button
                  type="button"
                  variant="secondary"
                  className="btn-lg pull-right custom-offset"
                  text="Cancel"
                  onClick={(e) => onDoClose(e)}
                />
              </div>
            </div>
          </Form>
        )}
      </div>
    </Modal>
  );
};
export default AddAdminModal;
