import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "../../layout/Main";
import AddUsers from "../super-admin-addUsers";
import EditUsers from "../super-admin-editUsers";
import Pagination from '@mui/material/Pagination';
import { getData, deleteUser } from "./service";
import { Button, Dialog, DialogTitle, DialogActions } from "@mui/material";
import { useToasts } from "react-toast-notifications";
import "./style.scss";
import circlePlus from "../../assets/circle-plus.svg";
import { Table } from "react-bootstrap";
import Loader from "../../layout/Loader/Loader";
import { useHistory } from "react-router-dom";
import { Routes } from "../../constants/routes";
import { ERROR_KEY, SUCCESS_KEY, defaultValues, getErrorMessage } from "../../constants";

const Page = (props) => {
  const [showConfirmation, setShowConfirmation] = useState({show: false, message: "", canDelete: true});
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  const [userIdToEdit, setUserIdToEdit] = useState(null);
  const [userFirstNameToEdit, setUserFirstNameToEdit] = useState(null);
  const [userLastNameToEdit, setUserLastNameToEdit] = useState(null);
  const [userEmailIdToEdit, setUserEmailIdToEdit] = useState(null);
  const [userPhoneToEdit, setUserPhoneToEdit] = useState(null);
  const [userStatusToEdit, setUserStatusToEdit] = useState(false);



  const { addToast } = useToasts();
  const [openModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const manageUsers = useSelector((state) => state.manageUsers.userData);
  const countPerPage = 10;
  const dispatch = useDispatch();
  const history = useHistory();
  const roleName = useSelector((state) => state.loggedInUser?.selectedRole?.role_name);

  const accessToken =
    useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";

  useEffect(() => {
    if (!accessToken) {
      history.push(Routes.ADMIN_LOGIN);
    }
    if(roleName !== defaultValues.role_names.super_admin && roleName !== defaultValues.role_names.admin)
    {
      history.push(Routes.HOME)
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getData()).then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (manageUsers?.length) {
      let data1 = [...manageUsers];
      data1.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setTotalCount(data1?.length);
      setData(data1?.slice(0, countPerPage));
    }
  }, [manageUsers]);

  const updatePage = (event, page) => {
    setCurrentPage(page);
    let cloneData = [...manageUsers];
    const to = countPerPage * page;
    const from = to - countPerPage;
    setData(cloneData?.slice(from, to));
    setTotalCount(cloneData?.length);
  };
  
  const handleSearch = (e) => {
    const searchString = e.toLowerCase();
    let res = [];
    res = manageUsers?.filter((item) => {
      const firstName = item?.first_name?.toLowerCase();
      const createdAt = item?.created_at?.toLowerCase();
      return (
        firstName.includes(searchString) ||
        createdAt.includes(searchString)
      );
    });
    res = res.sort((a, b) => {
      const createdAtA = new Date(a.created_at);
      const createdAtB = new Date(b.created_at);
      return createdAtB - createdAtA;
    });
    setTotalCount(res?.length);
    setData(res?.slice(0, countPerPage));
  };

  const onDelete = (id, canDelete) => {
    setShowConfirmation({ show: false, canDelete: true, message: ""});
    deleteUser(id).then((res) => {
      if(res.message){
        addToast(res.message, { appearance: SUCCESS_KEY, autoDismiss: true });
        dispatch(getData());
      }else {
        canDelete && addToast(res.message, { appearance: ERROR_KEY, autoDismiss: true });
      }      
    	setIsLoading(false);
    });
  };

  const handleDeleteClick = (id, status) => {
    if (status === true) {
      setShowConfirmation({
        show: true,
        message: "Are you sure you want to delete this user?",
        canDelete: true,
      });
      setUserIdToDelete(id);
    } else {
      setShowConfirmation({
        ...showConfirmation,
        show: true,
        message: getErrorMessage("E-10133"),
        canDelete: false,
      });
    }
  };

  const handleClose = () => {
    setShowConfirmation({ ...showConfirmation, show: false, canDelete: true});
  };

  const handleEditClick = (id,first_name,last_name,phone,email_id,status) => {
    setEditModal(true);
    setUserIdToEdit(id);
    setUserFirstNameToEdit(first_name)
    setUserLastNameToEdit(last_name)
    setUserPhoneToEdit(phone)
    setUserEmailIdToEdit(email_id)
    setUserStatusToEdit(status);
  };


  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Main
          showNav={!props?.showNav}
          showFooter={!props?.showFooter}
          showLogo={!props?.showLogo}
          showAdminFooter={!props?.showAdminFooter}
          showAdminNavbar={!props?.showAdminNavbar}
          onChange={handleSearch}
        >
          <div className="plus-area">
            <div className="plus-btn-wrapper">
              <img
                src={circlePlus}
                className="plus-btn-img"
                onClick={() => setOpenModal(true)}
                alt="plus"
              />
            </div>
            <div>
              {data?.length > 0 ? (
                <div className="manage-users-container">
                  {editModal && (
                    <EditUsers
                      editModal={editModal}
                      setEditModal={setEditModal}
                      id={userIdToEdit}
                      first_name={userFirstNameToEdit}
                      last_name={userLastNameToEdit}
                      email_id={userEmailIdToEdit}
                      phone={userPhoneToEdit}
                      status={userStatusToEdit}
                    />
                  )}
                  <Table>
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Created Date</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((user) => (
                        <tr>
                          <td>{`${user.first_name} ${user.last_name}`}</td>
                          <td>
                            {new Date(user.created_at).toLocaleDateString()}
                          </td>
                          <td>{user?.primary_role?.role_name}</td>
                          <td>{user?.status ? "Active" : "Inactive"}</td>
                          <td>
                            <img 
                              src="/imgs/edit.svg" 
                              className="editIcon" 
                              alt="edit"
                              onClick={() => handleEditClick(user._id, user.first_name, user.last_name, user.phone, user.email_id, user.status !== undefined ? user.status : false)} 
                             />
                            <img
                              src="/imgs/delete-icon.svg"
                              className="editIcon"
                              onClick={() => handleDeleteClick(user._id, user.canDelete)}
                              alt="editicon"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div>
                  <span className="no-record-found">No Record Found</span>
                </div>
              )}
            </div>
            <Pagination
              className="mPage"
              count={Math.ceil(totalCount / countPerPage)}
              color="primary"
              onChange={updatePage}
            />

          </div>
          {openModal && (
            <AddUsers
              isAssignDisplay={false}
              isDisplay={openModal}
              setIsDisplay={setOpenModal}
              roleName={roleName}
              origin={'manage-users'}
            />
          )}
          <Dialog open={showConfirmation.show} onClose={handleClose}>
            <DialogTitle
              sx={{ background: "var(--primaryColor)", color: "var(--white)" }}
            >
            { showConfirmation.message}
            </DialogTitle>
            <DialogActions sx={{ background: "var(--primaryColor)" }}>
              {showConfirmation.canDelete &&(
                  <Button
                    onClick={handleClose}
                    sx={{
                      color: "var(--white)",
                      "&:hover": {
                        backgroundColor: "var(--white)",
                        color: "var(--drakBlack)",
                      },
                    }}
                  >
                    Cancel
                  </Button>)}
              <Button
                onClick={() => onDelete(userIdToDelete, showConfirmation.canDelete)}
                sx={{
                  color: "var(--white)",
                  "&:hover": {
                    backgroundColor: "var(--white)",
                    color: "var(--red)",
                  },
                }}> 
              { showConfirmation.canDelete ? "Delete" : "Ok"}
              </Button>
            </DialogActions>
          </Dialog>
        </Main>
      )}
    </>
  );
};
export default Page;
