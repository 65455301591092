import { createAsyncThunk } from "@reduxjs/toolkit";
import { addProperty, checkListFolderGet, deleteProperty, getAllChecklistFiles, getAllProperties, getChecklistFiles, getChecklistType, getPropertyChecklistFiles, getPropertyDetails, getRoles, getStages, updateProperty, uploadChecklistDoc, validateStage } from "../../services/api";

export const getPropertiesData = createAsyncThunk(
  "adminManagePropertiesSlice/getPropertiesData", async (propertyId, { rejectWithValue }) => {
    try {
      const { data } = await getPropertyDetails(propertyId);
      return data;
    } catch (e) {
      rejectWithValue(e.response.data);
    }
});

export const getManagePropData = createAsyncThunk(
  "adminManagePropertiesSlice/getManagePropData", async (args, { rejectWithValue }) => {
    try {
      const { data } = await getAllProperties(args);
      return data;
    } catch (e) {
      rejectWithValue(e.response.data);
    }
  });

export const postAddProperty = createAsyncThunk(
  "adminManagePropertiesSlice/postAddProperty", async (payload, { rejectWithValue }) => {
    try {
      const { data } = await addProperty(payload);
      return data;
    } catch (e) {
      return(e.response.data);
    }
  });

export const updateManagePropData = createAsyncThunk(
  "adminManagePropertiesSlice/updateManagePropData", async (args, { rejectWithValue }) => {
    try {
      const { data } = await updateProperty(args);
      return data;
    } catch (e) {
      rejectWithValue(e.response.data);
    }
  }
);

export const updateCheckListType = createAsyncThunk(
  "adminManagePropertiesSlice/updateCheckListType", async (checklistType, { rejectWithValue }) => {
    try {
      const {data} = await getChecklistType(checklistType.type);
      return data;
    } catch (e) {
      rejectWithValue(e.response.data);
    }
  }
);

export const getStagesData = createAsyncThunk(
  "adminManagePropertiesSlice/getStagesData", async (args, { rejectWithValue }) => {
    try {
      const { data } = await getStages(args);
      return data;
    } catch (e) {
      rejectWithValue(e.response.data);
    }
  });

export const getRoleId = createAsyncThunk(
  "adminManagePropertiesSlice/getRoleId", async (args, { rejectWithValue }) => {
    try {
      const { data } = await getRoles(args);
      return data;
    } catch (e) {
      rejectWithValue(e.response.data);
    }
  }
);

export const getCheckListFolder = createAsyncThunk(
  "adminManagePropertiesSlice/getCheckListFolder", async (propertyId, { rejectWithValue }) => {
    try {
      const { data } = await checkListFolderGet(propertyId);
      return data;
    } catch (e) {
      rejectWithValue(e.response.data);
    }
  }
);

export const updateCheckListDocument = createAsyncThunk(
  "adminManagePropertiesSlice/updateCheckListDocument", async (args, { rejectWithValue }) => {
    try {
      const { data } = await uploadChecklistDoc(args);
      return data;
    } catch (e) {
      rejectWithValue(e.response.data);
    }
  }
);

export const getAllFilesInsideChecklist = createAsyncThunk(
  "adminManagePropertiesSlice/getAllFilesInsideChecklist", async (args, { rejectWithValue }) => {
    try {
      const { data } = await getPropertyChecklistFiles(args);
      return data;
    } catch (e) {
      rejectWithValue(e.response.data);
    }
  });

  export const getFileFromChecklist = createAsyncThunk(
    "adminManagePropertiesSlice/getFileFromChecklist", async (args, { rejectWithValue }) => {
      try {
        const { data } = await getChecklistFiles(args);
        return data;
      } catch (e) {
        rejectWithValue(e.response.data);
      }
    });


    export const deletePropertiesData = createAsyncThunk(
      "adminManagePropertiesSlice/deletePropertiesData", async (propertyId, { rejectWithValue }) => {
        try {
          const { data } = await deleteProperty(propertyId);
          return data;
        } catch (e) {
          rejectWithValue(e.response.data);
        }
      });

    export const stageValidation = createAsyncThunk(
      "adminManagePropertiesSlice/validateStageChange", async (payload, {rejectWithValue})=>{
          try {
            const {data} = await validateStage(payload)
            return data
          } catch (e) {
            rejectWithValue(e.response.data);
          }
        }
    );

    export const paymentRequest= createAsyncThunk(
      "adminManagePropertiesSlice/paymentRequest", async (payload, {rejectWithValue})=>{
        try {
          const { data } = await paymentRequest(payload);
          return data;
        } catch (e) {
          rejectWithValue(e.response.data);
        }
      }
    )
