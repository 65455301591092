import React, { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";

import ScreenWrapper from "./utils/ScreenWrapper";

import ManageTemplates from "./pages/manage-templates";
import ManageChecklist from "./pages/manage-checklist";
import ManageAdmins from "./pages/manage-admins";
import UserTypeSelectionModal from "./layout/UserTypeSelection";
import Login from "./pages/login";
import ForgotPassword from "./pages/super-admin-forgot-pswrd.js";
import ResetPassword from "./pages/super-admin-reset-pswrd.js";
import Dashboard from "./pages/super-admin-dashboard";
import SuperAdminSettings from "./pages/super-admin-settings";
import ManageUsers from "./pages/manage-users";
import ViewProperty from "./pages/super-admin-manageProperties/viewProperty";
import ManageProperties from "./pages/super-admin-manageProperties";
import ManageProfile from "./pages/manage-profile";
import UserHome from "./pages/user-home";
import ProfileSettings from "./pages/buyer-profileSettings";
import PlatformFee from "./pages/platform-fee";
import PaymentVerify from "./pages/payment_verify";
import InitiateOffer from "./pages/initiate-offer";
import ReviewMou from "./pages/review-mou";
import ReviewSaleAgreement from "./pages/review-saleAgreement";
import ReviewSaleDeed from "./pages/review-saleDeed";
import EditMou from "./pages/review-mou/editMou";
import ReviewDocuments from "./pages/review-documents";
import PropertPayment from "./pages/property-payment";
import UpdateTdsDetails from "./pages/update-tds-details";
import Backout from "./pages/backout";
import Fallback from "./pages/fallback-404";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/swiper.min.css";
import "swiper/modules/free-mode/free-mode.min.css";
import SetRegistrationDate from "./pages/set-registration-date";
import LegalVerification from "./pages/legal-verification";
import PropertyEvaluation from "./pages/property-evaluation";
import LoanAgreement from "./pages/loan-agreement";

import AgentProfile from "./pages/agent-profile";

import About from "../src/pages/general-landing-page/About";
import ContactUs from "../src/pages/general-landing-page/ContactUs";
import PrivacyPolicy from "../src/pages/general-landing-page/PrivacyPolicy";
import TermsOfUse from "../src/pages/general-landing-page/TermsOfUse";

import { store } from "./redux/store";
import "./main.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import OfferLetter from "./pages/offer-letter";

import SignUp from "./pages/signup";
import ApplyLoan from "./pages/apply-loan";
import SignSaleDeed from "./pages/sign-saleDeed";
import PayTds from "./pages/pay-tds";

import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Routes } from "./constants/routes";
import { SubmitRentalAgreement } from "./pages/rental-agreement-new";

const persistor = persistStore(store);
class App extends Component {
  render() {
    return (
      <ToastProvider>
        <ScreenWrapper>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <Router basename={process.env.PUBLIC_URL}>
                <UserTypeSelectionModal />
                <Switch>
                  <Route exact path={Routes.HOME} component={Login} />
                  <Route exact path={Routes.SUBMIT_RENTAL_AGREEMENT} component={SubmitRentalAgreement} />
                  <Route
                    exact
                    path={Routes.MANAGE_TEMPLATES}
                    component={ManageTemplates}
                  />
                  <Route
                    exact
                    path={Routes.MANAGE_CHECKLISTS}
                    component={ManageChecklist}
                  />
                  <Route
                    exact
                    path={Routes.MANAGE_ADMINS}
                    component={ManageAdmins}
                  />
                  <Route
                    exact
                    path={Routes.MANAGE_USERS}
                    component={ManageUsers}
                  />
                  <Route
                    exact
                    path={Routes.MANAGE_PROPERTIES}
                    component={ManageProperties}
                  />
                  <Route
                    exact
                    path={Routes.VIEW_PROPERTY}
                    component={ViewProperty}
                  />

                  <Route
                    exact
                    path={Routes.SUPER_ADMIN_LOGIN}
                    component={Login}
                  />
                  <Route exact path={Routes.SIGN_UP} component={SignUp} />
                  <Route exact path={Routes.RENTAL_AGREEMENT} component={SignUp} />
                  <Route exact path={Routes.ADMIN_LOGIN} component={Login} />
                  <Route
                    exact
                    path={Routes.FORGOT_PASSWORD}
                    component={ForgotPassword}
                  />
                  <Route
                    exact
                    path={Routes.RESET_PASSWORD}
                    component={ResetPassword}
                  />
                  <Route exact path={Routes.DASHBOARD} component={Dashboard} />
                  <Route
                    exact
                    path={Routes.SUPER_ADMIN_SETTINGS}
                    component={SuperAdminSettings}
                  />
                  <Route
                    exact
                    path={Routes.MANAGE_PROFILE}
                    component={ManageProfile}
                  />

                  <Route exact path={Routes.PROPERTIES} component={UserHome} />
                  <Route
                    exact
                    path={Routes.PROFILE_SETTINGS}
                    component={ProfileSettings}
                  />
                  <Route
                    exact
                    path={Routes.INITIATE_OFFER}
                    component={InitiateOffer}
                  />
                  <Route
                    exact
                    path={Routes.SIGN_OFFER}
                    component={OfferLetter}
                  />
                  <Route
                    exact
                    path={Routes.OFFER_LETTER}
                    component={OfferLetter}
                  />
                  <Route
                    exact
                    path={Routes.PLATFORM_FEE}
                    component={PlatformFee}
                  />
                  <Route
                    exact
                    path={Routes.VERIFY_PAYMENT}
                    component={PaymentVerify}
                  />
                  <Route
                    exact
                    path={Routes.TOKEN_PAYMENT}
                    component={PropertPayment}
                  />
                  <Route
                    exact
                    path={Routes.REGISTRATION}
                    component={PropertPayment}
                  />
                  <Route exact path={Routes.REVIEW_MOU} component={ReviewMou} />
                  <Route
                    exact
                    path={Routes.REVIEW_SALE_AGREEMENT}
                    component={ReviewSaleAgreement}
                  />
                  <Route
                    exact
                    path={Routes.REVIEW_SALE_DEED}
                    component={ReviewSaleDeed}
                  />
                  <Route
                    exact
                    path={Routes.REVIEW_DOCUMENTS}
                    component={ReviewDocuments}
                  />
                  <Route exact path={Routes.EDIT_MOU} component={EditMou} />
                  <Route
                    exact
                    path={Routes.UPDATE_TDS_DETAILS}
                    component={UpdateTdsDetails}
                  />
                  <Route
                    exact
                    path={Routes.SET_REGISTRATION_DATE}
                    component={SetRegistrationDate}
                  />
                  <Route exact path={Routes.APPLY_LOAN} component={ApplyLoan} />
                  <Route exact path={Routes.SIGN_MOU} component={ReviewMou} />
                  <Route
                    exact
                    path={Routes.SIGN_AGREEMENT}
                    component={ReviewSaleAgreement}
                  />
                  <Route
                    exact
                    path={Routes.SIGN_DEED}
                    component={SignSaleDeed}
                  />
                  <Route exact path={Routes.PAY_TDS} component={PayTds} />

                  <Route
                    exact
                    path={Routes.LEGAL_VERIFICATION}
                    component={LegalVerification}
                  />
                  <Route
                    exact
                    path={Routes.PROPERTY_INSPECTION}
                    component={PropertyEvaluation}
                  />
                  <Route
                    exact
                    path={Routes.LOAN_SANCTION}
                    component={LoanAgreement}
                  />
                  <Route
                    exact
                    path={Routes.AGENT_PROFILE}
                    component={AgentProfile}
                  />
                  <Route exact path={Routes.BACK_OUT} component={Backout} />
                  <Route exact path={Routes.FALLBACK} component={Fallback} />
                </Switch>
              </Router>
            </PersistGate>
          </Provider>
        </ScreenWrapper>
      </ToastProvider>
    );
  }
}

export default App;