import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { useToasts } from "react-toast-notifications";
import "./style.scss";
import { r_getAgreementDocument } from "../../../services/api";
import { JoditTextEditor } from "../../../utils/JoditTextEditor";
import { JsStyles } from "./jsStyle";
import TooltipErrorComponent from "../../../commonComponent/TooltipError";

export const ReviewRentalAgreement = ({ onSave }) => {
  const { addToast } = useToasts();
  const [pdfUrl, setPdfUrl] = useState("");
  const [editData, setEditData] = useState("");
  const [showEditor, setShowEditor] = useState(false);
  const [argData, setArgData] = useState({});
  const [approvalStatus, setApprovalStatus] = useState(false);
  const [pendingParty, setPendingParty] = useState([]);

  const selectedRental = useSelector(
    (state) => state.rentalAgreement.selectedRental
  );
  const userId =
    useSelector((state) => state?.loggedInUser?.data?.data?.userByEmail?._id || state?.loggedInUser?.data?.superAdminByEmail?._id);

  useEffect(() => {
    if (selectedRental) {
      setArgData(selectedRental);
      const party = selectedRental.document_approval.filter(
        (item) => item.userId === userId
      );
      if (party.length === 1) {
        setApprovalStatus(party[0].approval);
      }

      const pList = selectedRental.rental_parties.filter((item) => {
        return (
          selectedRental.document_approval.filter(
            (v) => v.userId === item.userId
          ).length === 0
        );
      });
      setPendingParty(pList);
    }
  }, [selectedRental]);

  useEffect(() => {
    if (argData) {
      setEditData(argData.document_data && argData.document_data);
      const li = argData.document_approval?.filter(
        (item) => item.userId === userId
      );
      setShowEditor(argData.stageNo === 3);
      if (argData.stageNo === 3.1 || li?.length > 0) {
        r_getAgreementDocument(argData._id)
          .then((res) => {
            if (res.data.status) setPdfUrl(res.data.url);
          })
          .catch((err) => {
            addToast(err?.response?.data?.message, {
              appearance: "error",
              autoDismiss: true,
            });
          });
      }
    }
  }, [argData]);

  const getPendingList = () => {
    return [
      "Rental agreement review is pending from other parties.",
      ...pendingParty.map((party, i) => (
        `${i + 1}. ${party.first_name + " " + party.last_name}`                          
      ))
    ];
  }

  return (
    <>
      <div className="row mb-4" style={JsStyles.mainHight}>
        <div className="col-md-12 m-auto text-center">
          {showEditor && !approvalStatus ? (
            <div className="editor-box">
              <JoditTextEditor
                height="85vh"
                data={editData}
                onChangedData={(val) => val && setEditData(val)}
              />
            </div>
          ) : (
            <embed src={pdfUrl} style={JsStyles.pdfViewer} />
          )}
        </div>
        {showEditor && !approvalStatus ? (
          <div className="col-md-12 mt-3">
            <div className="proceed-button text-center">
                <Button
                  className="btn-outline-secondary px-5 mx-3 r-editBtn"
                  variant="outlined"
                  onClick={() => onSave({ stageNo: 1, document_data : "", })}
                >
                  Back to Edit 
              </Button>
              <Button
                className="button px-5 my-3 mx-3 "
                variant="primary"
                onClick={() => {
                  onSave({ stageNo: 3.1, document_data: `${editData}` });
                }}
              >
                Save
              </Button>
            </div>
          </div>
        ) : (
          <div className="col-md-12 mt-3">
            {approvalStatus ? (
              argData.stageNo === 4 ? (
                <div className="col-md-12 mt-3">
                  <div className="proceed-button text-center">
                    <Button
                      className="button px-5 my-3 mx-3 "
                      variant="primary"
                      onClick={() => onSave({ stageNo: 4 })}
                    >
                      Get E-stamp
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="col-md-12 mt-3">
                  <div className="proceed-button text-center">
                    <Tooltip
                      className="my-3 mx-3"
                      title={<TooltipErrorComponent errors={getPendingList()}/>}
                      placement="top"
                      arrow
                    >
                      <Button
                        className="button px-5 text-light bg-secondary"
                        variant="primary"
                      >
                        Get E-stamp
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              )
            ) : (
              <div className="proceed-button text-center">
                <Button
                  className="btn-outline-secondary px-5 mx-3 r-editBtn"
                  variant="outlined"
                  onClick={() => setShowEditor(true)}
                >
                  Edit document
                </Button>
                <Button
                  className="button px-5 my-3 mx-3 "
                  variant="primary"
                  onClick={() => onSave({ stageNo: 3, approval: true })}
                >
                  Accept
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};