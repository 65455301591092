import React, { useEffect , useState} from "react";
import { Button } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Main from "../../layout/Main";
import { getPropertiesData, stageValidation } from "../super-admin-manageProperties/service";
import {updateSubStage} from "../../utils/HelperFunction";
import {updateSelectedProperty} from "../user-home/slices"
import "./style.scss";
import Loader from "../../layout/Loader/Loader";
import { addPropertyHistory, getDocByPropId,  getSignedDoc, updateVerifyStatus } from "../../services/api";
import { API } from "../../utils/api";
import { initiateSigning } from "../../utils/signed";
import MessageModel from "../../layout/MessageModel";
import { ERROR_KEY, SUCCESS_KEY, defaultValues, getErrorMessage, getSuccessMessage } from "../../constants";
import { historyFormatter } from "../../utils/historyFormatter";
import { Routes } from "../../constants/routes";

const OfferLetter = (props) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const { addToast } = useToasts();

  const accessToken = useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";
  const location = useLocation();

  const propertyId = useSelector((state) => state?.buyerHomeData?.selectedProperty?._id) || '';
  const pid = location.search.split("=")[1];
  const userId = useSelector(
    (state) => state.loggedInUser.data.data?.userByEmail._id
  );
  const user = useSelector(state => state.loggedInUser.data.data?.userByEmail);
  const selectedProperty = useSelector((state) => state?.buyerHomeData?.selectedProperty)
  const roleId = useSelector((state)=> state.loggedInUser?.selectedRole?._id); 
  const propertyRole = useSelector((state)=> state.loggedInUser?.preferredRole);
  const subStages = useSelector((state)=> state?.stages?.subStages)
  const roles = useSelector((state)=> state?.roles?.roles);
  const getSubStage = (order) => subStages.filter((substage)=> substage.order === order)[0]?._id;
  const getSubStageOrder = (id) => subStages.filter((substage)=> substage._id === id)[0].order;

  const [loader, setLoader] = useState(false);
  const [pdfUrl , setPdfUrl] = useState(null);
  const [isDocumentSigned , setIsDocumentSigned] = useState(false);
  const [showSignType, setShowSignType] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAccept, setShowAccept] = useState(false);
  const [title, setTitle] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [selectedSigningOption, setSelectedSigningOption] = useState("aadhaar");
  useEffect(()=>{
    history.location.pathname === Routes.SIGN_OFFER
        ? setTitle("Sign")
        : setTitle("Review");
  },[roles]);

  useEffect(() => {

    if (!accessToken) {
      history.push(Routes.HOME);
    } else {
      if (!propertyId) history.push(Routes.PROPERTIES);
      else if (pid !== propertyId) history.push(`${Routes.INITIATE_OFFER}?pid=${propertyId}`);
      else{
        getFinalizedOffer();
        getPropertyDocument();
        if(selectedProperty.sub_current_stage === getSubStage(4.1) && propertyRole === defaultValues.role_names.seller){
          setShowAccept(true);
        }
      }
    }
  }, []);

  const routeChange = (value) => {
    setShowPopup(false);
    if(value){      
      history.push(`${Routes.BACK_OUT}?pid=${propertyId}`);
    }
  };

  const getSubStageUpdated = async (propertyId, substage, status) => {
    try {
      const response = await updateSubStage({propertyId, substageId:substage, loggedInUserId:userId, status});
      if (response.status === 200) {
        dispatch(updateSelectedProperty(response.data.propertyData));
        addToast(getSuccessMessage("S-10006"), {
          appearance: SUCCESS_KEY,
          autoDismiss: true,
        });
      } else
        addToast(getErrorMessage("E-10070"), {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
    } catch (error) {
      addToast(error.response.data.message || getErrorMessage("E-10070"), {
        appearance: ERROR_KEY,
        autoDismiss: true,
      });
    }
  };

  const getPropertyDocument = async () =>  {
    let data = {
      property_id: propertyId,
      documentType: defaultValues.doc_types.document,
      subType: defaultValues.doc_sub_types.offer_letter,
    };
    const response = await getDocByPropId(data);

    if (response.status === 200) {
      setShowSignType(response.data.data.e_sign?.reference_id ? false : true);
    }
  }

  const getSigneduserInfo = async () => {
    const { property_parties_details = [] } = selectedProperty;
    const buyerSellers = property_parties_details.filter((party) =>
      [
        defaultValues.role_names.buyer,
        defaultValues.role_names.seller,
      ].includes(party.role.role_name)
    );
    const userIds = buyerSellers.map((user) => {
      return user.user_id._id || user.user_id;
    });

    let data = {
      property_id: propertyId,
      documentType: defaultValues.doc_types.document,
      subType: defaultValues.doc_sub_types.offer_letter,
    };
    const response = await getDocByPropId(data);

    if (response.status === 200) {
      const signedUserArray = response.data.data.signing || [];
      const signedUserIdArray = signedUserArray.map((user) => user.signed_by);
      const unsignedUser = userIds.filter(
        (id) => !signedUserIdArray.includes(id)
      );
      if (!unsignedUser.length) {
        dispatch(stageValidation({ propertyId, roleId })).then(async () => {
          await getSubStageUpdated(propertyId, getSubStage(6), true);
        });
      }
    }
  };

  const callbackFunction = async (res, docketId) => {
    setIsLoading(true);
    getSignedDoc({
      docket_id: docketId,
      document_id: res?.document_id,
      user_id: userId,
      template_type: defaultValues.doc_types.document,
      template_subtype: defaultValues.doc_sub_types.offer_letter,
    }).then(async (res) => {
      if (res.data.status === "signed") {
        addToast(getSuccessMessage("S-10013"), {
          appearance: SUCCESS_KEY,
          autoDismiss: true,
        });
        dispatch(getPropertiesData(propertyId));
        getSigneduserInfo();
        setIsLoading(true);
        const historyData = historyFormatter(
          user,
          userId,
          propertyId,
          `has signed the ${defaultValues.doc_sub_types.offer_letter}.`
        );
        addPropertyHistory(historyData);
        history.push(Routes.VIEW_PROPERTY);
      } else {
        addToast(getErrorMessage("E-10118"), {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
        setIsLoading(false);
      }
    });
  };

  const getFinalizedOffer = () => {
    setLoader(true);
    const payload = {
      propertyId,
      templateSubType: defaultValues.doc_sub_types.offer_letter,
      templateType: defaultValues.doc_types.document,
    };
    const getToken = () => sessionStorage.getItem("token");
    const options = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(payload),
    };
    fetch(`${API.baseURL[API.currenEnv]}property/getDocumentFromS3`, options)
      .then((res) => res.blob())
      .then((data) => {
        if (data.type === "application/pdf") {
          const file = new File([data], "offer_letter", { type: data.type });
          const url = URL.createObjectURL(file);
          setPdfUrl(url);
          addToast(getSuccessMessage("S-10021"), {
            appearance: SUCCESS_KEY,
            autoDismiss: true,
          });
        } else {
          addToast(getErrorMessage("E-10137"), {
            appearance: ERROR_KEY,
            autoDismiss: true,
          });
        }
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
        addToast(getErrorMessage("E-10113"), {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
      });
  };

  const handleVerify = async (status) => {
    const payload = {
      propertyId: propertyId,
      documentType: defaultValues.doc_types.document,
      subType: defaultValues.doc_sub_types.offer_letter,
      userId: userId,
      status: status,
    };

    let message = "has accepted the offer.";

    await updateVerifyStatus(payload)
      .then((res) => {
        if (status) {
          const userList = res.data.data.verify.map((data) => data.user_id);
          const { property_parties_details = [] } = selectedProperty;
          const sellers = property_parties_details.filter((party) =>
            [defaultValues.role_names.seller].includes(party.role.role_name)
          );
          const userIds = sellers.map((user) => {
            return user.user_id._id || user.user_id;
          });

          const unVerifyUser = userIds.filter((id) => !userList.includes(id));
          const currentOrder = getSubStageOrder(
            selectedProperty.sub_current_stage
          );
          if (unVerifyUser.length === 0 && status) {
            if (currentOrder < 5 && currentOrder >= 3) {
              getSubStageUpdated(propertyId, getSubStage(5), true);
            }
          }
          history.push(Routes.PROPERTIES);
        } else {
          message = "has rejected the offer";
          getSubStageUpdated(propertyId, getSubStage(3), status);
          history.push(Routes.VIEW_PROPERTY);
        }
        const historyData = historyFormatter(user, userId, propertyId, message);
        addPropertyHistory(historyData);
      })
      .catch((error) => {
        addToast(getErrorMessage("E-10193"), {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
      });
  };

  if(isLoading) {
    return <Loader />
  }

  return (
    <div style={{ marginTop: "2%" }}>
      {loader ? (
        <Loader />
      ) : (
        <div>
          <Main
            showUserNavbar={!props?.showUserNavbar}
            showUser={!props?.showUser}
          >
            <div className="fee-container fee-containers">
              <div className="fee-heading">
                <p>{title} Offer Letter</p>
                <button
                  onClick={() => setShowPopup(true)}
                  style={{ background: "var(--primaryButton)" }}
                >
                  {defaultValues.actions.backout}
                </button>
              </div>

              <div className="platform-fee-container">
                <>
                  <div className="platform-fee-ck-container">
                    <object
                      data={pdfUrl}
                      type="application/pdf"
                      width="100%"
                      height="100%"
                    >
                      <p>
                        Alternative text - include a link{" "}
                        <a href={pdfUrl}>to the PDF!</a>
                      </p>
                    </object>
                  </div>
                  <div className="offer-button">
                    {showAccept && (
                      <Button
                        className="m-4 button"
                        onClick={() => handleVerify(false)}
                        disabled={isDocumentSigned}
                      >
                        Reject
                      </Button>
                    )}
                    {showAccept && (
                      <Button
                        className="m-4 button"
                        onClick={() => handleVerify(true)}
                        disabled={isDocumentSigned}
                      >
                        Accept
                      </Button>
                        )}
                        {!showAccept && showSignType && (
                        <>
                          <div>
                            <label style={{color : 'black'}}>
                              <input
                                type="radio"
                                value="aadhaar"
                                className="textColor"
                                checked={selectedSigningOption === defaultValues.signing_option.aadhaar}
                                onChange={() => setSelectedSigningOption(defaultValues.signing_option.aadhaar)}
                              />
                              Aadhaar
                            </label>
                          </div>
                          <div>
                          <label style={{color : 'black'}}>
                              <input
                                type="radio"
                                value="electronic"
                                className="textColor"
                                checked={selectedSigningOption === defaultValues.signing_option.electronic}
                                onChange={() => setSelectedSigningOption(defaultValues.signing_option.electronic)}
                              />
                              Electronic Sign
                            </label>
                          </div>
                        </>
                        )}
                    {!showAccept && (
                      <Button
                        className="m-4 button"
                        disabled={isDocumentSigned}
                        onClick={() => {
                          setLoader(true);
                          initiateSigning({
                            propertyId: sessionStorage.getItem("propertyID"),
                            userId,
                            templateType: defaultValues.doc_types.document,
                            templateSubType:
                              defaultValues.doc_sub_types.offer_letter,
                            callbackFunction: callbackFunction,
                            addToastMessage: (message) =>
                              addToast(message, {
                                appearance: "error",
                                autoDismiss: true,
                              }),
                            loaderFalse: setLoader,
                            signType: selectedSigningOption,
                          });
                        }}
                      >
                        {" "}
                        Sign Here
                      </Button>
                    )}
                  </div>
                </>
              </div>
            </div>
          </Main>
          {showPopup && (
            <MessageModel
              title="Warning"
              message="Are you sure you want to Backout ?"
              callback={routeChange}
              showPopup={showPopup}
            ></MessageModel>
          )}
        </div>
      )}
    </div>
  );
};
export default OfferLetter;