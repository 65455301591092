import React, { useState, useEffect, useCallback } from "react";
import Main from "../../layout/Main/index";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedProperty } from "../user-home/slices";
import {
  getPropertiesData,
  getRoleId,
  stageValidation,
  updateCheckListDocument,
} from "./service";
import { useLocation, useHistory } from "react-router-dom";
import Loader from "../../layout/Loader/Loader";
import { useToasts } from "react-toast-notifications";
import PropertyDetailsData from "./propertyDetails/propertyData";
import OtherDetailsData from "./propertyDetails/otherDetails";
import PriceDetailsData from "./propertyDetails/priceDetails";
import AgreementsData from "./propertyDetails/agreements";
import PropertyDocumentData from "./propertyDetails/PropertyDocument";
import SellerDetailsData from "./propertyDetails/sellersDetails";
import PropertyHistoryData from "./propertyDetails/propertyHistory";
import { addSelectedProperty } from "../user-home/slices";
import { updateSubStage } from "../../utils/HelperFunction";
import {
  addPropertyHistory,
  getAllUploadedDocumentBySeller,
  getDocByPropId,
  getPropertyDetails,
  getSignedDoc,
  propertyImageGet,
} from "../../services/api";
import dayjs from "dayjs";
import {
  ERROR_KEY,
  PID_KEY,
  SUCCESS_KEY,
  defaultValues,
  getErrorMessage,
  getSuccessMessage,
} from "../../constants";
import { Routes } from "../../constants/routes";
import { historyFormatter } from "../../utils/historyFormatter";

const ViewProperty = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const pid = searchParams.get(PID_KEY);

  const [isLoading, setIsLoading] = useState(false);
  const [propertyImageLoader, setPropertyImageLoader] = useState(false);
  const [propertyImages, setPropertyImages] = useState([]);
  const [action, setAction] = useState({});
  const [hasLawyer, setHasLawyer] = useState(false);
  const [hasCA, setHasCA] = useState(false);
  const [hasBankAgent, setBankAgent] = useState(false);
  const [userId, setUserId] = useState("");
  const user = useSelector((state) => state.loggedInUser.data.data?.userByEmail);
  const [updatedStatus, setUpdatedStatus] = useState(true);
  const [stampStatus, setStampStatus] = useState(false);
  const [isReinstated, setIsReinstated] = useState(false);
  const [currentUserSigned, setCurrentUserSigned] = useState(false);
  const [showSignType, setShowSignType] = useState(false);
  const [proceed , setProceed] = useState(false)
  const [isDocumentSigned , setIsDocumentSigned] = useState(false);
  const roleId = useSelector((state)=> state.loggedInUser?.selectedRole?._id);
  const dispatch = useDispatch();
  const viewPropertiesData = useSelector(
    (state) => state.superAdminProperties.propertyData
  );
  const role = useSelector((state) => state.loggedInUser.preferredRole);
  const userName = useSelector(
    (state) => state.loggedInUser.data.data?.userByEmail?.first_name
  );
  const accessToken =
    useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";
  const propertyId =
    useSelector((state) => state?.buyerHomeData?.selectedProperty?._id) || "";
  const selectedProperty = useSelector(
    (state) => state?.buyerHomeData?.selectedProperty
  );
  const subStages = useSelector((state) => state?.stages?.subStages) || [];
  const user_id =
    useSelector((state) => state.loggedInUser.data.data?.userByEmail?._id) ||
    "";
  const super_admin_id =
    useSelector(
      (state) => state.loggedInUser.data?.data?.superAdminByEmail?._id
    ) || "";
  const admin_id =
    useSelector((state) => state.loggedInUser.data?.data?.adminByEmail?._id) ||
    "";

  const getSubStage = (order) =>
    subStages.filter((substage) => substage.order === order)[0]?._id;
  const getSubStageOrder = (id) =>
    subStages.filter((substage) => substage._id === id)[0]?.order;

  //To update the data on reload
  const getSelectPropertyData = useCallback(() => {
    if (pid) {
      getPropertyDetails(pid).then((response) => {
        if (response.data.propertyData) {
          dispatch(addSelectedProperty(response.data.propertyData));
        }
      });
    }
  }, [dispatch, pid]);

  useEffect(() => {
    getSelectPropertyData();
  }, [getSelectPropertyData]);

  useEffect(() => {
    if (role === defaultValues.role_names.super_admin) {
      setUserId(super_admin_id);
    } else if (role === defaultValues.role_names.admin) {
      setUserId(admin_id);
    } else {
      setUserId(user_id); 
    }
  }, []);
  const getSigneduserInfo = async () => {
    const { sub_current_stage } = selectedProperty;
    const currentOrder = getSubStageOrder(sub_current_stage);
    const subtype = getSubType(sub_current_stage);
    const { property_parties_details = [] } = selectedProperty;
    const buyerSellers = property_parties_details.filter((party) =>
      [
        defaultValues.role_names.buyer,
        defaultValues.role_names.seller,
      ].includes(party.role.role_name)
    );
    const userIds = buyerSellers.map((user) => {
      return user.user_id._id || user.user_id;
    });
    
    
    const response = await getDocByPropId({
      property_id: propertyId,
      documentType: defaultValues.doc_types.document,
      subType: subtype,
    });
    if (response.status === 200) {
      setShowSignType(response.data.data.e_sign?.reference_id ? false : true);
      const signedUserArray = response.data.data.signing || [];
      const signedUserIdArray = signedUserArray.map((user) => user.signed_by);
      const unsignedUser = userIds.filter(
        (id) => !signedUserIdArray.includes(id)
      );

      if (!unsignedUser.length) {
        getPropertyDetails(selectedProperty?._id).then((response) => {
          if (response?.data?.propertyData?.sub_current_stage === getSubStage(5)) {
            getSubStageUpdated(propertyId, getSubStage(6), true);
            dispatch(stageValidation({ propertyId, roleId }))
          }
          if (response?.data?.propertyData?.sub_current_stage === getSubStage(8)) {
            getSubStageUpdated(propertyId, getSubStage(9), true);
            dispatch(stageValidation({ propertyId, roleId }))
          }
          if (response?.data?.propertyData?.sub_current_stage === getSubStage(13)) {
            getSubStageUpdated(propertyId, getSubStage(14), true);
            dispatch(stageValidation({ propertyId, roleId }))
          }
          setProceed(true);
          setIsDocumentSigned(true);
          let message = `has signed the ${subtype}`;
          let historyData = historyFormatter(user, userId, propertyId, message);
          addPropertyHistory(historyData);   
        });
      }
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      if(selectedProperty.sub_current_stage === getSubStage(8) || getSubStage(5) || getSubStage(13)){
      const { sub_current_stage } = selectedProperty;
      const currentOrder = getSubStageOrder(sub_current_stage);
      const subtype = getSubType(sub_current_stage);
      try {
        const res = await getDocByPropId({
          property_id: propertyId,
          documentType: defaultValues.doc_types.document,
          subType: subtype,
        });

        const signerInfoArray = res?.data?.data?.e_sign?.signer_info;
        const documentId = signerInfoArray[0]?.document_id;
  
        const signedDocResponse = await getSignedDoc({
          docket_id: res?.data?.data?.e_sign?.docket_id,
          document_id: documentId,
          user_id: user_id,
          template_type: defaultValues.template_types.document,
          template_subtype: defaultValues.template_types.offer_letter,
        });
        
        if (signedDocResponse.data.status === "signed") {
          await getSigneduserInfo();
          getPropertyDetails(propertyId).then(response => {
            if(response.data) {
             dispatch(addSelectedProperty(response.data.propertyData));
            }
          });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };}
  
    fetchData();
  }, []);
  

  const getUserId = () => {
    if (role === defaultValues.role_names.super_admin) {
      setUserId(super_admin_id);
      return super_admin_id
    } else if (role === defaultValues.role_names.admin) {
      setUserId(admin_id);
      return admin_id
    } else {
      setUserId(user_id);
      return user_id;
    }
  }

  useEffect(() => {
    if (
      selectedProperty &&
      selectedProperty?.property_parties_details?.length
    ) {
      selectedProperty.property_parties_details.map((item) => {
        if (item.role.role_name === defaultValues.role_names.lawyer) {
          setHasLawyer(true);
        }
        if (item.role.role_name === defaultValues.role_names.ca) {
          setHasCA(true);
        }
        if (item.role.role_name === defaultValues.role_names.bank_agent) {
          setBankAgent(true);
        }
        return true;
      });
    }
  }, [selectedProperty]);

  useEffect(() => {
    if (!accessToken) {
      history.push(Routes.HOME);
    } else {
      dispatch(getRoleId());
      if (!propertyId) history.push(Routes.PROPERTIES);
      else if (pid !== propertyId) {
        getPropertyImages(propertyId);
        history.push(`${Routes.VIEW_PROPERTY}?pid=${propertyId}`);
      } else {
        getData();
        if (selectedProperty.sub_current_stage === getSubStage(9)) {
          checkAllDocsUploadedFromSeller();
        }
      }
    }
  }, []);

  const checkAllDocsUploadedFromSeller = async () => {
    try {
      //logic to get all docs
      const {
        data: { checklistAsperType, checklistValidationArray },
      } = await getAllUploadedDocumentBySeller({
        documentType: defaultValues.doc_types.checklist,
        propertyId: propertyId,
        propertyType: selectedProperty.PropertyType,
      });
      const ckListPerType = [ ...checklistAsperType.filter(
        (item) => !defaultValues.exclude_folder_lawyer.includes(item.title)
      )];
        
      if (checklistValidationArray.length >= ckListPerType.length) {
        const invalidArray = checklistValidationArray.filter(
          (data) => !data.docFound
        );
        if (!invalidArray.length) {
          getPropertyDetails(selectedProperty?._id).then((response) => {
            if (response?.data?.propertyData?.sub_current_stage === getSubStage(9)) {
          getSubStageUpdated(propertyId, getSubStage(10));
        }
      })
      }
    } }catch (error) {
      addToast(getErrorMessage("E-10145"), { autoDismiss: true, error: true });
    }
  };

  const getActionErrorFlow = useCallback(() => {
    const { sub_current_stage } = selectedProperty;
    let errorArray = [];
    let disabledRole = [];
    let path = "";
    let action = "";

    if (
      sub_current_stage === getSubStage(1) ||
      sub_current_stage === getSubStage(2)
    ) {
      const { no_of_owners, no_of_buyers, property_parties_details } =
        selectedProperty;
      const addedBuyers = property_parties_details?.filter(
        (party) => party.role_side === defaultValues.role_names.buyer
      );
      const addedSellers = property_parties_details?.filter(
        (party) => party.role_side === defaultValues.role_names.seller
      );

      if (no_of_owners > addedSellers?.length) {
        errorArray.push(
          `${no_of_owners - addedSellers?.length} ${
            defaultValues.action_info.add_seller
          }`
        );
        disabledRole = defaultValues.all_roles;
      }
      if (no_of_buyers > addedBuyers?.length) {
        errorArray.push(
          `${no_of_buyers - addedBuyers?.length} ${
            defaultValues.action_info.add_buyer
          }`
        );
        disabledRole = defaultValues.all_roles;
      }

      if (errorArray.length === 0) {
        errorArray.push(defaultValues.action_info.platform_fee_pending);
        disabledRole = defaultValues.all_roles.filter(
          (role) => role !== defaultValues.role_names.buyer
        );
        path = Routes.PLATFORM_FEE;
        action = defaultValues.actions.pay_platform_fees;
      }
    } else if (sub_current_stage === getSubStage(3)) {
      errorArray.push(
        isReinstated
          ? defaultValues.action_info.offer_reinitiate_pending
          : defaultValues.action_info.offer_initiate_pending
      );
      disabledRole = defaultValues.all_roles.filter(
        (role) =>
          ![
            defaultValues.role_names.buyer,
            defaultValues.role_names.buyer_poa,
            defaultValues.role_names.buyer_agent,
          ].includes(role)
      );
      path = Routes.INITIATE_OFFER;
      action = isReinstated
        ? defaultValues.actions.reinitiate_offer
        : defaultValues.actions.initiate_offer;
    } else if (sub_current_stage === getSubStage(4)) {
      errorArray.push(defaultValues.action_info.offer_finalize_pending);
      disabledRole = defaultValues.all_roles.filter(
        (role) =>
          ![
            defaultValues.role_names.buyer,
            defaultValues.role_names.buyer_poa,
            defaultValues.role_names.buyer_agent,
          ].includes(role)
      );
      path = Routes.INITIATE_OFFER;
      action = defaultValues.actions.finalize_offer;
    } else if (sub_current_stage === getSubStage(4.1)) {
      if (updatedStatus) {
        errorArray.push(
          defaultValues.action_info.review_offer_pending_multiple_seller
        );
        disabledRole = defaultValues.all_roles;
      } else {
        errorArray.push(
          defaultValues.action_info.review_offer_pending_single_seller
        );
        disabledRole = defaultValues.all_roles.filter(
          (role) => ![defaultValues.role_names.seller].includes(role)
        );
      }

      path = Routes.OFFER_LETTER;
      action = defaultValues.actions.review_offer;
    } else if (sub_current_stage === getSubStage(5)) {
      if (currentUserSigned) {
        errorArray.push(defaultValues.action_info.offer_signed_current_user);
        disabledRole = defaultValues.all_roles;
      } else {
        errorArray.push(defaultValues.action_info.offer_sign_pending_all);
        disabledRole = defaultValues.all_roles.filter(
          (role) =>
            ![
              defaultValues.role_names.buyer,
              defaultValues.role_names.seller,
            ].includes(role)
        );
      }

      path = Routes.SIGN_OFFER;
      action = defaultValues.actions.sign_offer;
    } else if (sub_current_stage === getSubStage(6)) {
      let message = isReinstated
        ? defaultValues.action_info.mou_reinitiate_pending
        : defaultValues.action_info.mou_initiate_pending;

      if (!hasLawyer && role === defaultValues.role_names.buyer) {
        message = defaultValues.action_info.add_lawyer;
      }
      errorArray.push(message);
      disabledRole = defaultValues.all_roles.filter(
        (role) =>
          ![
            defaultValues.role_names.buyer,
            defaultValues.role_names.buyer_poa,
            defaultValues.role_names.buyer_agent,
          ].includes(role)
      );
      if (!hasLawyer) {
        disabledRole = defaultValues.all_roles;
      }
      path = Routes.REVIEW_MOU;
      action = isReinstated
        ? defaultValues.actions.reinitiate_mou
        : defaultValues.actions.initiate_mou;
    } else if (sub_current_stage === getSubStage(6.1)) {
      errorArray.push(defaultValues.action_info.review_mou_pending_lawyer);
      disabledRole = defaultValues.all_roles.filter(
        (role) => ![defaultValues.role_names.lawyer].includes(role)
      );
      path = Routes.REVIEW_MOU;
      action = defaultValues.actions.review_mou;
    } else if (sub_current_stage === getSubStage(6.2)) {
      if (updatedStatus) {
        errorArray.push(defaultValues.action_info.mou_reviewed_current_seller);
        disabledRole = defaultValues.all_roles;
      } else {
        errorArray.push(
          defaultValues.action_info.review_mou_pending_single_seller
        );
        disabledRole = defaultValues.all_roles.filter(
          (role) => ![defaultValues.role_names.seller].includes(role)
        );
      }

      path = Routes.REVIEW_MOU;
      action = defaultValues.actions.review_mou;
    } else if (sub_current_stage === getSubStage(7)) {
      errorArray.push(defaultValues.action_info.token_payment_pending);
      disabledRole = defaultValues.all_roles.filter(
        (role) => ![defaultValues.role_names.buyer].includes(role)
      );
      if (role === defaultValues.role_names.buyer) {
        path = Routes.TOKEN_PAYMENT;
      }

      action = defaultValues.actions.pay_token;
    } else if (sub_current_stage === getSubStage(8)) {
      if (stampStatus) {
        if (currentUserSigned) {
          errorArray.push(
            defaultValues.action_info.mou_sign_pending_other_party
          );
          disabledRole = defaultValues.all_roles;
        } else {
          errorArray.push(defaultValues.action_info.mou_sign_pending_all);
          disabledRole = defaultValues.all_roles.filter(
            (role) =>
              ![
                defaultValues.role_names.buyer,
                defaultValues.role_names.seller,
              ].includes(role)
          );
        }
      } else {
        errorArray.push(defaultValues.action_info.generate_stamp_pending);
        disabledRole = defaultValues.all_roles.filter(
          (role) => ![defaultValues.role_names.buyer].includes(role)
        );
      }

      path = Routes.SIGN_MOU;
      action = defaultValues.actions.sign_mou;
    } else if (sub_current_stage === getSubStage(9)) {
      let message = defaultValues.action_info.document_upload_pending;
      if (role === defaultValues.role_names.seller) {
        message = defaultValues.action_info.upload_documents;
      }
      errorArray.push(message);
      disabledRole = [...defaultValues.all_roles];
      path = "";
      action = defaultValues.actions.upload_docs;
    } else if (sub_current_stage === getSubStage(10)) {
      errorArray.push(defaultValues.action_info.document_review_pending);
      disabledRole = defaultValues.all_roles.filter(
        (role) => ![defaultValues.role_names.lawyer].includes(role)
      );
      path = Routes.REVIEW_DOCUMENTS;
      action = defaultValues.actions.verify_docs;
    } else if (sub_current_stage === getSubStage(11)) {
      errorArray.push(
        isReinstated
          ? defaultValues.action_info.sale_agreement_reinitate_pending
          : defaultValues.action_info.sale_agreement_initate_pending
      );
      disabledRole = defaultValues.all_roles.filter(
        (role) =>
          ![
            defaultValues.role_names.buyer,
            defaultValues.role_names.buyer_poa,
            defaultValues.role_names.buyer_agent,
          ].includes(role)
      );

      path = Routes.REVIEW_SALE_AGREEMENT;
      action = isReinstated
        ? defaultValues.actions.reinitiate_sale_agreement
        : defaultValues.actions.initiate_sale_agreement;
    } else if (sub_current_stage === getSubStage(11.1)) {
      errorArray.push(
        defaultValues.action_info.review_sale_agreement_pending_lawyer
      );
      disabledRole = defaultValues.all_roles.filter(
        (role) => ![defaultValues.role_names.lawyer].includes(role)
      );
      path = Routes.REVIEW_SALE_AGREEMENT;
      action = defaultValues.actions.review_sale_agreement;
    } else if (sub_current_stage === getSubStage(11.2)) {
      if (updatedStatus) {
        errorArray.push(
          defaultValues.action_info.review_sale_agreement_pending_other_seller
        );
        disabledRole = defaultValues.all_roles;
      } else {
        errorArray.push(
          defaultValues.action_info.review_sale_agreement_pending_seller
        );
        disabledRole = defaultValues.all_roles.filter(
          (role) => ![defaultValues.role_names.seller].includes(role)
        );
      }

      path = Routes.REVIEW_SALE_AGREEMENT;
      action = defaultValues.actions.review_sale_agreement;
    } else if (sub_current_stage === getSubStage(12)) {
      errorArray.push(defaultValues.action_info.agreement_fee_pending);
      disabledRole = defaultValues.all_roles.filter(
        (role) => ![defaultValues.role_names.buyer].includes(role)
      );

      path = Routes.REGISTRATION;
      action = defaultValues.actions.pay_agreement_fee;
    } else if (sub_current_stage === getSubStage(13)) {
      if (stampStatus) {
        if (currentUserSigned) {
          errorArray.push(
            defaultValues.action_info.agreement_sign_pending_other_party
          );
          disabledRole = defaultValues.all_roles;
        } else {
          errorArray.push(defaultValues.action_info.agreement_sign_pending_all);
          disabledRole = defaultValues.all_roles.filter(
            (role) =>
              ![
                defaultValues.role_names.buyer,
                defaultValues.role_names.seller,
              ].includes(role)
          );
        }
      } else {
        errorArray.push(defaultValues.action_info.agreement_stamp_pending);
        disabledRole = defaultValues.all_roles.filter(
          (role) => ![defaultValues.role_names.buyer].includes(role)
        );
      }

      path = Routes.SIGN_AGREEMENT;
      action = defaultValues.actions.sign_agreement;
    } else if (sub_current_stage === getSubStage(14)) {
      errorArray.push(defaultValues.action_info.loan_status_pending);
      disabledRole = defaultValues.all_roles.filter(
        (role) => ![defaultValues.role_names.buyer].includes(role)
      );
      path = Routes.APPLY_LOAN;
      action = defaultValues.actions.apply_loan;
    } else if (sub_current_stage === getSubStage(15)) {
      if (role === defaultValues.role_names.buyer && !hasBankAgent) {
        errorArray.push(defaultValues.action_info.add_bank_agent);
        disabledRole = defaultValues.all_roles;
      } else {
        errorArray.push(defaultValues.action_info.loan_confirmation_pending);
        disabledRole = defaultValues.all_roles.filter(
          (role) => ![defaultValues.role_names.bank_agent].includes(role)
        );
      }
      path = Routes.PROPERTY_INSPECTION;
      action = defaultValues.actions.set_inspection_Date;
    } else if (sub_current_stage === getSubStage(15.1)) {
      errorArray.push(defaultValues.action_info.loan_confirmation_pending);
      disabledRole = defaultValues.all_roles.filter(
        (role) => ![defaultValues.role_names.bank_agent].includes(role)
      );

      path = Routes.LOAN_SANCTION;
      action = defaultValues.actions.set_loan_section_status;
    } else if (sub_current_stage === getSubStage(16)) {
      if (role === defaultValues.role_names.buyer && !hasCA) {
        errorArray.push(defaultValues.action_info.add_ca);
        disabledRole = defaultValues.all_roles;
      } else {
        errorArray.push(defaultValues.action_info.tds_confirmation_pending);
        disabledRole = defaultValues.all_roles.filter(
          (role) => ![defaultValues.role_names.ca].includes(role)
        );
      }

      path = Routes.UPDATE_TDS_DETAILS;
      action = defaultValues.actions.confirm_tds;
    } else if (sub_current_stage === getSubStage(17)) {
      errorArray.push(defaultValues.action_info.tds_challan_pending);
      disabledRole = defaultValues.all_roles.filter(
        (role) => ![defaultValues.role_names.buyer].includes(role)
      );

      path = Routes.PAY_TDS;
      action = defaultValues.actions.upload_challan;
    } else if (sub_current_stage === getSubStage(18)) {
      errorArray.push(defaultValues.action_info.final_sale_deed_pending);
      disabledRole = defaultValues.all_roles.filter(
        (role) => ![defaultValues.role_names.buyer].includes(role)
      );

      path = Routes.REVIEW_SALE_DEED;
      action = defaultValues.actions.initiate_sale_deed;
    } else if (sub_current_stage === getSubStage(18.1)) {
      if (updatedStatus) {
        errorArray.push(
          defaultValues.action_info.review_sale_deed_pending_other_seller
        );
        disabledRole = defaultValues.all_roles;
      } else {
        errorArray.push(
          defaultValues.action_info.review_sale_deed_pending_single_seller
        );
        disabledRole = defaultValues.all_roles.filter(
          (role) => ![defaultValues.role_names.seller].includes(role)
        );
      }

      path = Routes.REVIEW_SALE_DEED;
      action = defaultValues.actions.review_sale_deed;
    } else if (sub_current_stage === getSubStage(19)) {
      errorArray.push(defaultValues.action_info.registration_date_pending);
      disabledRole = defaultValues.all_roles.filter(
        (role) => ![defaultValues.role_names.lawyer].includes(role)
      );

      path = Routes.SET_REGISTRATION_DATE;
      action = defaultValues.actions.set_registration_date;
    } else if (sub_current_stage === getSubStage(20)) {
      let regDate = selectedProperty?.lawyer?.registration_date;
      if (regDate) {
        regDate = dayjs(regDate).format(defaultValues.date_format);
      }
      disabledRole = defaultValues.all_roles;
      path = "";
      action = regDate
        ? `${defaultValues.action_info.registration_date} ${regDate}`
        : defaultValues.action_info.registration_details;
      if (selectedProperty?.current_stage?.order_number === 6) {
        errorArray.push(defaultValues.action_info.registration_completed);
        disabledRole = defaultValues.all_roles;
        path = "";
        action = "";
      }
    } else if (sub_current_stage === getSubStage(21)) {
      errorArray.push(defaultValues.action_info.backout_stage);
      disabledRole = defaultValues.all_roles;
      path = "";
      action = "";
    }

    return { errorArray, disabledRole, path, action };
  }, [getSubStage, role, selectedProperty]);

  useEffect(() => {
    getActionErrorFlow();
  }, [getActionErrorFlow]);

  const getSubType = (subStageId) => {
    const currentOrder = getSubStageOrder(subStageId);
    if (currentOrder >= 3 && currentOrder < 6) {
      return defaultValues.doc_sub_types.offer_letter;
    }
    if (currentOrder >= 6 && currentOrder < 9) {
      return defaultValues.doc_sub_types.mou;
    } else if (currentOrder >= 11 && currentOrder < 14) {
      return defaultValues.doc_sub_types.agreement;
    } else if (currentOrder >= 18 && currentOrder < 19) {
      return defaultValues.doc_sub_types.sale_deed;
    } else {
      return "";
    }
  };

  useEffect(() => {
    const { sub_current_stage } = selectedProperty;
    const subtype = getSubType(sub_current_stage);
    if (
      (getSubStageOrder(sub_current_stage) >= 4.1 ||
        getSubStageOrder(sub_current_stage) >= 3) &&
      subtype !== ""
    ) {
      setIsLoading(true);
      getDocByPropId({
        property_id: propertyId,
        documentType: defaultValues.doc_types.document,
        subType: subtype,
      })
        .then((res) => {
          const docData = res.data?.data;
          if (
            sub_current_stage === getSubStage(4.1) ||
            sub_current_stage === getSubStage(6.2) ||
            sub_current_stage === getSubStage(11.2) ||
            sub_current_stage === getSubStage(18.1)
          ) {
            const userList = docData.verify.map((data) => {
              if (data.status) return data.user_id;
            });
            setUpdatedStatus(userList.includes(userId));
          }
          if (
            sub_current_stage === getSubStage(8) ||
            sub_current_stage === getSubStage(5) ||
            sub_current_stage === getSubStage(13)
          ) {
            setStampStatus(docData.e_stamp?.stamp_paper_number?.length > 0);
            const signUserId = docData.signing.map((data) => data.signed_by);
            setCurrentUserSigned(signUserId.includes(userId));
          }

          if (
            docData?.document_data?.length &&
            (sub_current_stage === getSubStage(3) ||
              sub_current_stage === getSubStage(6) ||
              sub_current_stage === getSubStage(11))
          ) {
            setIsReinstated(true);
          } else {
            setIsReinstated(false);
          }

          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  }, [selectedProperty]);

  const getSubStageUpdated = async (propertyId, substage) => {
    try {
      const loggedInUserId = userId === "" ? getUserId() : userId;
      const response = await updateSubStage({propertyId, substageId: substage, loggedInUserId });
      if (response.status === 200) {
        dispatch(updateSelectedProperty(response.data.propertyData));
        addToast(getSuccessMessage("S-10006"), {
          appearance: SUCCESS_KEY,
          autoDismiss: true,
        });
      } else
        addToast(getErrorMessage("E-10070"), {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
    } catch (error) {
      addToast(getErrorMessage("E-10111"), {
        appearance: ERROR_KEY,
        autoDismiss: true,
      });
    }
  };

  const getData = async () => {
    setIsLoading(true);
    dispatch(getPropertiesData(propertyId));
    await getPropertyImages(propertyId);
    await getPropertyDetails(pid).then((response) => {
      if (response.data.propertyData) {
        dispatch(addSelectedProperty(response.data.propertyData));
      }
    });
    setIsLoading(false);
  };

  const addpropertyImages = (selectedFile) => {
    const formData = new FormData();
    formData.append("propertydoc", selectedFile);
    formData.append("property_id", propertyId);
    formData.append("checklist_name", "PropertyImages");
    setPropertyImageLoader(true);
    dispatch(updateCheckListDocument(formData)).then(async () => {
      addToast(getSuccessMessage("S-10007"), {
        appearance: SUCCESS_KEY,
        autoDismiss: true,
      });
      await getPropertyImages(propertyId);
      setPropertyImageLoader(false);
    });
  };

  const getPropertyImages = async (propertyId) => {
    try {
      const payload = { propertyId: propertyId };
      const response = await propertyImageGet(payload);
      if (response.status === 200 && response.data?.success) {
        setPropertyImages([...response.data.data]);
        dispatch(
          addSelectedProperty({
            ...selectedProperty,
            propertyImages: [...response.data.data],
          })
        );
      }
    } catch (error) {
      addToast(getErrorMessage("E-10149"), {
        appearance: ERROR_KEY,
        autoDismiss: true,
      });
    }
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <Main
          showNav={!props?.showNav}
          showFooter={!props?.showFooter}
          showLogo={
            !props?.showLogo &&
            role !== defaultValues.role_names.seller &&
            role !== defaultValues.role_names.buyer &&
            role !== defaultValues.role_names.bank_agent &&
            role !== defaultValues.role_names.lawyer &&
            role !== defaultValues.role_names.ca &&
            role !== defaultValues.role_names.agent
          }
          showAdminFooter={!props?.showAdminFooter}
          showAdminNavbar={!props?.showAdminNavbar}
          showWelcomePage={
            !props?.showWelcomePage &&
            role !== defaultValues.role_names.super_admin &&
            role !== defaultValues.role_names.admin
          }
          currentScreen={"user_home"}
        >
          {viewPropertiesData && (
            <div className="add-property">
              <PropertyDetailsData
                user={userName}
                propertyImageAttribute={propertyImages}
                addpropertyImages={addpropertyImages}
                propertyImageLoader={propertyImageLoader}
                callBack={getSelectPropertyData}
                imageCallBack={() => getPropertyImages(propertyId)}
              />
              <OtherDetailsData
                user={userName}
                callBack={getSelectPropertyData}
              />
              <PriceDetailsData
                user={userName}
                role={role}
                actionData={action}
                data={getActionErrorFlow()}
              />
              <AgreementsData propertyId={searchParams.get(PID_KEY)} />
              <PropertyDocumentData
                updateAction={checkAllDocsUploadedFromSeller}
              />
              <SellerDetailsData
                user={userName}
                data={viewPropertiesData?.property_parties_details}
                viewProperty={viewPropertiesData}
                callBack={getSelectPropertyData}
              />
              <PropertyHistoryData />
            </div>
          )}
        </Main>
      )}
    </div>
  );
};
export default ViewProperty;
