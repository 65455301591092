import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { updateManagePropData } from "../../../pages/super-admin-manageProperties/service";
import { useToasts } from "react-toast-notifications";
import { TextField } from "@mui/material";
import { addPropertyHistory } from "../../../services/api";
import { getUserId } from "../../../utils/HelperFunction";
import { ERROR_KEY, SUCCESS_KEY, defaultValues } from "../../../constants";

const PriceDetailEditProperty = ({
  isPriceEditModal,
  setIsPriceEditModal,
  data,
  callBack,
  name
}) => {
  const { addToast } = useToasts();
  const [editPriceData, setEditPriceData] = useState(data);
  const [isPriceSubmit, setIsPriceSubmit] = useState(false);
  const dispatch = useDispatch();
  const editPropResp = useSelector((state) => state.superAdminProperties);
  const loggedInUser = useSelector((state) => state.loggedInUser);
  const role = useSelector((state) => state.loggedInUser.preferredRole);

  const getLoggedInUserName = () => {
    if (role === defaultValues.role_names.super_admin) {
      return loggedInUser.data?.data?.superAdminByEmail?.first_name;
    } else if (role === defaultValues.role_names.admin) {
      return loggedInUser.data?.data?.adminByEmail?.first_name;
    } else if (role === defaultValues.role_names.seller || role === defaultValues.role_names.buyer || role === defaultValues.role_names.lawyer || 
      role === defaultValues.role_names.bank_agent ||
      role === defaultValues.role_names.buyer_agent ||
      role === defaultValues.role_names.seller_agent ||
      role === defaultValues.role_names.buyer_poa ||
      role === defaultValues.role_names.seller_poa ||
      role === defaultValues.role_names.ca) {
      return loggedInUser.data?.data?.userByEmail?.first_name;
    }
    return "";
  };


  useEffect(() => {
    setEditPriceData(data);
  }, [data]);
  const submitPriceEdit = async(e) => {
    e.preventDefault();
    setIsPriceSubmit(true);
    const updateData = {};
  let his = "";
    for (const item in data) {
      if (data.hasOwnProperty(item)) {
        if (data[item] !== editPriceData[item]) {
          updateData.id = editPriceData._id;
          updateData[item] = editPriceData[item];
          his = ` ${his} ${(item).replaceAll("_"," ")} updated to ${editPriceData[item]}`;
        }
      }
    }
    
    const historyData = {
      activity: his,
      name : getLoggedInUserName(),
      activity_by : getUserId(loggedInUser),
      activity_date : new Date(),
      property_id : data._id,
    }
    await addPropertyHistory(historyData);

    if (role === defaultValues.role_names.super_admin) {
      return loggedInUser.data?.data?.superAdminByEmail?.first_name;
    } else if (role === defaultValues.role_names.admin) {
      return loggedInUser.data?.data?.adminByEmail?.first_name;
    } else if (role === defaultValues.role_names.seller || role === defaultValues.role_names.buyer || role === defaultValues.role_names.lawyer || 
      role === defaultValues.role_names.bank_agent ||
      role === defaultValues.role_names.buyer_agent ||
      role === defaultValues.role_names.seller_agent ||
      role === defaultValues.role_names.buyer_poa ||
      role === defaultValues.role_names.seller_poa ||
      role === defaultValues.role_names.ca) {
      updateData.user_id = `${loggedInUser.data?.data?.userByEmail?._id}`;
    }
    dispatch(updateManagePropData(updateData));
  };

  useEffect(() => {
    if (editPropResp.updateManageStatus && isPriceSubmit) {
      if (editPropResp.updateManageStatus === "Success") {
        addToast(editPropResp.updateManagePropMessage, {
          appearance:SUCCESS_KEY, autoDismiss: true
        });
        setIsPriceEditModal(!isPriceEditModal);
        callBack();
        setIsPriceSubmit(false);
      } else {
        addToast(editPropResp.updateManagePropMessage, { appearance: ERROR_KEY, autoDismiss: true });
      }
    }
  }, [editPropResp]);

  return (
    <>
      <Modal
        size="lg"
        show={isPriceEditModal}
        onHide={() => setIsPriceEditModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="editProperty"
      >
        <Modal.Header closeButton className="edit-modal">
          <Modal.Title
            id="example-modal-sizes-title-lg"
            className="text-center w-100 text-light"
          >
            Edit Price Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="edit-modal">
          <Form onSubmit={(e) => submitPriceEdit(e)}>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="PlatformFee">
                  <TextField
                    id="Platform Fee"
                    label="Platform Fee"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    className="inputBox"
                    disabled
                    onChange={(e) =>
                      setEditPriceData({
                        ...editPriceData,
                        tds: parseInt(e.target.value),
                      })
                    }
                    value={editPriceData?.payment_amount || defaultValues.actions.to_be_paid}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="TDS">
                  <TextField
                    id="TDS"
                    label="TDS"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    className="inputBox"
                    disabled
                    onChange={(e) =>
                      setEditPriceData({
                        ...editPriceData,
                        tds: parseInt(e.target.value),
                      })
                    }
                    value={editPriceData?.tds || defaultValues.actions.to_be_paid}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="Price">
                  <TextField
                    id="Price"
                    label="Price"
                    variant="outlined"
                    type="number"
                    autoComplete="off"
                    className="inputBox"
                    onChange={(e) =>
                      setEditPriceData({
                        ...editPriceData,
                        tds: parseInt(e.target.value),
                      })
                    }
                    value={editPriceData?.price || defaultValues.actions.to_be_paid}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="AgreementAmount">
                  <TextField
                    id="Agreement Amount"
                    label="Agreement Amount"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    className="inputBox fieldWidth"
                    disabled
                    onChange={(e) =>
                      setEditPriceData({
                        ...editPriceData,
                        tds: parseInt(e.target.value),
                      })
                    }
                    value={editPriceData?.agreement_payment || defaultValues.actions.to_be_paid}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="TokenAmount">
                  <TextField
                    id="Token Amount"
                    label="Token Amount"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    className="inputBox fieldWidth"
                    disabled
                    onChange={(e) =>
                      setEditPriceData({
                        ...editPriceData,
                        tds: parseInt(e.target.value),
                      })
                    }
                    value={editPriceData?.token_payment || defaultValues.actions.to_be_paid}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="text-center pt-5">
                <Button variant="light px-5" type="submit" className="button">
                  Update
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PriceDetailEditProperty;
