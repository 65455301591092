export const customStyles = {
  contentContainerStyle: {
    border: 3,
    borderStyle: "solid",
    borderColor: "var(--primaryColorLight)",
    backgroundColor: "var(--secondaryColorLight)",
    borderRadius: 10,
    display: "flex",
    alignItems:'center',
    width: "96%",
    marginLeft: "2%",
    marginRight: "2%",
    marginBottom: "20px",
    paddingTop: "8px",
    paddingBottom: "8px",
    marginTop: 4,
  },
  tabBorder: { borderBottom: 1, borderColor: "divider" },
  defaultImage: {
    height: 120,
    width: "100%",
    borderRadius: 25,
  },
  editBtn: {
    background: "var(--secondaryColor)",
    color : "var(--white)",    
  }
};
