import Form from "react-bootstrap/Form";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import { useFormik } from "formik";
import "./style.scss";
import { STATES } from "../../../constants/states";
import { rentalParty } from "../../../utils/validationSchemas";
import { Modal } from "react-bootstrap";
import { defaultValues, ERROR_KEY, getErrorMessage, SUCCESS_KEY } from "../../../constants";
import { updateRentalParty } from "../../../pages/rental-agreement-new/service";
import { useToasts } from "react-toast-notifications";
import { COUNTRY_CODE } from "../../../constants/countryCode";
import { Dropdown } from "../../Dropdown";


export const AddPartyDetails = ({
  type,
  onCancel,
  onError,
  editData,
  showModel,
  role
}) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { values, handleBlur, handleChange, handleSubmit, errors, touched, resetForm, setValues } =
    useFormik({
      initialValues: {
        first_name: editData?.first_name || "",
        last_name: editData?.last_name || "",
        age: editData?.age || "",
        mobile: editData?.mobile || "",
        country_code: editData?.country_code || "+91",
        pan: editData?.pan || "",
        email: editData?.email || "",
        address: editData?.address || "",
        state: editData?.state || "",
        city: editData?.city || "",
        pin: editData?.pin || "",
      },
      validationSchema: rentalParty,
      validateOnChange: true,
      validateOnBlur: true,
      enableReinitialize: true,
      role:role,
      onSubmit: (values, action) => handleSave(),
    });
  const [argData, setArgData] = useState({});

  const selectedRental = useSelector(
    (state) => state.rentalAgreement.selectedRental
  );

  useEffect(() => {
    if (selectedRental) {
      setArgData(selectedRental);
    }
  }, [selectedRental]);


  const handleSave = () => {
    const payload = { 
      ...values, 
      argId: argData._id,
      action: editData?._id 
        ? defaultValues.rental_parties_action.edit 
        : defaultValues.rental_parties_action.add,
      type,
      role
    };
    payload.state_code =  STATES.find(
      (item) => item.label === values.state
    ).value;
    payload.age = parseInt((payload.age).toString())
    if(editData?._id){
      payload.userId = editData.userId;
    }

    dispatch(updateRentalParty(payload))
    .then((res) => {
      if(!res.payload?.data || !res.payload?.data.status){
        addToast(
          res.payload?.data?.message || getErrorMessage("E-10070"),
        {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
      } else {
        addToast(res.payload.data?.message, {
          appearance: SUCCESS_KEY,
          autoDismiss: true,
        });
        resetForm();
        onCancel();
      }          
    })
  }


  return (
    <Modal
      size="lg"
      show={showModel}
      onHide={onCancel}
      className="addParty"
      centered
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton className="m-header" closeVariant="white">
        <Modal.Title id="example-modal-sizes-title-lg" className="m-h-title text-dark">
          Add New {type === defaultValues.rental_roles.owner ? "Owner" : "Tenant"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="m-body">
        <div>
          <Form className="py-2 px-4" onSubmit={handleSubmit}>
            <div className="row">
              <Form.Group className="mb-3 col-md-6">
                <Form.Label className="fee-label text-dark">
                  First Name
                </Form.Label>
                <Form.Control
                  name="first_name"
                  id="first_name"
                  type="text"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.first_name}
                  className="fee-inputs-multiple mb-1"
                />
                {touched.first_name && errors.first_name && (
                  <p className="r-formError">{errors.first_name}</p>
                )}
              </Form.Group>
              <Form.Group className="mb-3 col-md-6">
                <Form.Label className="fee-label text-dark">
                  Last Name
                </Form.Label>
                <Form.Control
                  name="last_name"
                  id="last_name"
                  type="text"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.last_name}
                  className="fee-inputs-multiple mb-1"
                />
                {touched.last_name && errors.last_name && (
                  <p className="r-formError">{errors.last_name}</p>
                )}
              </Form.Group>
              <Form.Group className="mb-3 col-md-6">
                <Form.Label className="fee-label text-dark">Age</Form.Label>
                <Form.Control
                  name="age"
                  id="age"
                  type="number"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.age}
                  className="fee-inputs-multiple mb-1"
                />
                {touched.age && errors.age && (
                  <p className="r-formError">{errors.age}</p>
                )}
              </Form.Group>
              <div className="mb-3 col-md-6">
                <div className="row">
                  <Form.Group className="mb-3 col-md-4">
                    <Form.Label className="fee-label text-dark">
                      Country code
                    </Form.Label>
                    <FormControl size="small">
                      <Select
                        required
                        sx={{ maxHeight: "37px", width: '90px', backgroundColor: "var(--white)" }}
                        className="fee-inputs-multiple mb-1"
                        name="country_code"
                        id="country_code"
                        value={values.country_code}
                        displayEmpty={!editData?.country_code}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          const selectedCountryCode = e.target.value;
                          setValues({
                            ...values,
                            country_code : selectedCountryCode
                          });
                        }}
                        renderValue={(selected) => {
                          const selectedCountry = COUNTRY_CODE.find(country => country.value === selected);
                          return selectedCountry ? selectedCountry.value : '';
                        }}
                        
                      >
                        {COUNTRY_CODE.map((country, index) => (
                          <MenuItem key={index} value={country.value}>
                            {`${country.label} (${country.value})`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Form.Group>
                  <Form.Group className="mb-3 col-md-8">
                    <Form.Label className="fee-label text-dark">
                      Contact Number
                    </Form.Label>
                    <Form.Control
                      name="mobile"
                      id="mobile"
                      type="text"
                      required
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.mobile}
                      className="fee-inputs-multiple mb-1"
                    />
                    {touched.mobile && errors.mobile && (
                      <p className="r-formError">{errors.mobile}</p>
                    )}
                  </Form.Group>
                </div>
              </div>
              
              <Form.Group className="mb-3 col-md-6">
                <Form.Label className="fee-label text-dark">
                  Email Address
                </Form.Label>
                <Form.Control
                  name="email"
                  id="email"
                  type="text"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  className="fee-inputs-multiple mb-1"
                />
                {touched.email && errors.email && (
                  <p className="r-formError">{errors.email}</p>
                )}
              </Form.Group>
              <Form.Group className="mb-3 col-md-6">
                <Form.Label className="fee-label text-dark">
                  PAN Number
                </Form.Label>
                <Form.Control
                  name="pan"
                  id="pan"
                  type="text"
                  required
                  onBlur={handleBlur}
                  onChange={(e) => setValues({
                      ...values,
                      pan : (e.target.value).toUpperCase()
                    })
                  }
                  value={values.pan}
                  className="fee-inputs-multiple mb-1 text-uppercase"                  
                />
                {touched.pan && errors.pan && (
                  <p className="r-formError">{errors.pan}</p>
                )}
              </Form.Group>
              
            </div>
            <div className="row">
              <Form.Group className="mb-3 col-md-12">
                <Form.Label className="fee-label text-dark">Permanent Address</Form.Label>
                <Form.Control
                  name="address"
                  id="address"
                  type="text"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address}
                  className="fee-inputs-multiple mb-1"
                />
                {touched.address && errors.address && (
                  <p className="r-formError">{errors.address}</p>
                )}
              </Form.Group>
              <Form.Group className="mb-3 col-md-6 pr-1 d-flex flex-column">
                <Form.Label className="fee-label text-dark">State</Form.Label>
                <FormControl size="small">
                  <Dropdown
                    required
                    className="fee-inputs-multiple mb-1"
                    id="state"
                    name="state"
                    value={values.state}
                    placeholder="Select state"
                    options={STATES.map((state) => state.label)}
                    handleBlur={handleBlur}
                    onChange={(event, newValue) => {
                      handleChange({
                        target: { name: "state", value: newValue },
                      });
                    }}
                  />
                </FormControl>
                {touched.state && errors.state && (
                  <p className="r-formError">{errors.state}</p>
                )}
              </Form.Group>
              <Form.Group className="mb-3 col-md-6">
                <Form.Label className="fee-label text-dark">City</Form.Label>
                <Form.Control
                  name="city"
                  id="city"
                  type="text"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.city}
                  className="fee-inputs-multiple mb-1"
                />
                {touched.city && errors.city && (
                  <p className="r-formError">{errors.city}</p>
                )}
              </Form.Group>
              <Form.Group className="mb-3 col-md-6">
                <Form.Label className="fee-label text-dark">Pin Code</Form.Label>
                <Form.Control
                  name="pin"
                  id="pin"
                  type="number"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.pin}
                  className="fee-inputs-multiple mb-1"
                />
                {touched.pin && errors.pin && (
                  <p className="r-formError">{errors.pin}</p>
                )}
              </Form.Group>
            </div>
            {onError !== "" && (
              <p className="r-formError mb-0 ml-5">{onError}</p>
            )}
            <div className="row my-3 px-3 proceed-button">
              <Button
                className="col-md-3 button px-4 mr-5"
                variant="primary"
                type="submit"
              >
                {editData?._id ? "Update" : "Save"}
              </Button>
              <Button
                className="col-md-3 px-4 btn-outline-secondary r-cancelBtn"
                variant="outlined"
                onClick={onCancel}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};
