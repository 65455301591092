import React, { useState, useEffect } from "react";
import Main from "../../layout/Main";
import {
  Box, Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {updateSubStage} from '../../utils/HelperFunction';
import {updateSelectedProperty} from "../../pages/user-home/slices"
import { updateProperty } from "../../services/api";
import { ERROR_KEY, PID_KEY, SUCCESS_KEY, defaultValues, getErrorMessage, getSuccessMessage } from "../../constants";
import { Routes } from "../../constants/routes";
import Loader from "../../layout/Loader/Loader";


const ApplyLoan = ({ props }) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  const [status, setStatus] = useState(null);
  const [loader, setLoader] = useState(null);

  const BuyerPropData = useSelector((state) => state.buyerHomeData.buyerData?.properties);
  const accessToken = useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";
  const userId = useSelector((state) => state.loggedInUser.data.data?.userByEmail._id);
  const propertyId = useSelector((state) => state?.buyerHomeData?.selectedProperty?._id) || "";
  const subStages = useSelector((state)=> state?.stages?.subStages)
  
  const getSubStage = (order) => subStages.filter((substage)=> substage.order === order)[0]._id

  const handleProceed = async(event) => {
    event.preventDefault();
    setLoader(true);
    const updatedState = {
      id: propertyId,
      "loan_applied": status,
    };
    await updateProperty(updatedState)
      .then(async (data) => {
        if(status){
          await getSubStageUpdated(propertyId, getSubStage(15));
        }else {
          await getSubStageUpdated(propertyId, getSubStage(16));
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        addToast(getErrorMessage('E-10110'), {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
      });
    
  };

  const getSubStageUpdated = async (propertyId, substage)=>{
    try{
        const response = await updateSubStage({propertyId, substageId: substage, loggedInUserId:userId});
        if(response.status === 200){
          dispatch(updateSelectedProperty(response.data.propertyData));
          addToast(getSuccessMessage("S-10006"), { appearance: SUCCESS_KEY, autoDismiss: true });
          history.push(Routes.VIEW_PROPERTY);
        }
      else addToast(getErrorMessage('E-10070'), { appearance: ERROR_KEY, autoDismiss: true });
  
      }catch(error){
        addToast(getErrorMessage('E-10111'), { appearance: ERROR_KEY, autoDismiss: true });
      }  
  }

  useEffect(() => {
    const pid = searchParams.get(PID_KEY);
    if (!accessToken) {
      history.push(Routes.HOME);
    } else {
      if (!propertyId) {
        history.push(Routes.USER_HOME);
      } else if (pid !== propertyId) {
        history.push(`${Routes.APPLY_LOAN}?pid=${propertyId}`);
      }
    }
  }, [BuyerPropData]);

  return (
    <>{loader ? <Loader /> : 
      <div>
        <Main showUserNavbar={!props?.showUserNavbar} showUser={!props?.showUser}>
          <Box display="flex" flexDirection="column" justifyContent="start" 
            alignItems="start" minHeight="70vh" bgcolor="var(--white)">
            <div className="container">
              <div className="inner-container">
                <Typography variant="h4" component="h4" sx={{ fontWeight: 100 }} color={"black"} >
                {defaultValues.screenTitles.apply_loan}
                </Typography>
                <Typography variant="body2" component="p" mt={5} color={"black"}>
                  {defaultValues.screenSubTitles.apply_loan}
                </Typography>
                <form onSubmit={handleProceed}>
                  <div className="d-flex mt-4">
                    <FormControlLabel className="mr-5"
                      control={
                        <Checkbox
                          color="primary"
                          checked={status}
                          onChange={(e) => setStatus(true)}
                          value="yes"
                        />
                      }
                      label={<span style={{ color:"black" }}>Yes</span>}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={status === false}
                          onChange={(e) => setStatus(false)}
                          value="no"
                        />
                      }
                      label={<span style={{ color:"black" }}>No</span>} 
                    />

                  </div>
                  <div className="col-3 mt-4">
                    <Button type="submit" variant="contained" fullWidth
                      style={{ backgroundColor: "var(--primaryButton)" }} disabled={status === null}>
                      Proceed
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Main>
      </div>
    }</>
  );
};

export default ApplyLoan;
