import React ,{useEffect} from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import InitiateOffers from "../../commonComponent/initiateOffers";
import { defaultValues } from "../../constants";
import { Routes } from "../../constants/routes";

const ReviewSaleAgreement = () => {
  const history = useHistory();
  const location = useLocation();
  const accessToken = useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";
  const propertyId = useSelector((state)=> state?.buyerHomeData?.selectedProperty?._id) || '';
  const pid = location.search.split("=")[1]
  const role = useSelector((state) => state.loggedInUser.preferredRole);
  let heading = "";
  let text = "";

  useEffect(() => {
    if (!accessToken) {
      history.push(Routes.HOME);
    }else{
      if(!propertyId) history.push(Routes.PROPERTIES);
      else if(pid !== propertyId){
        history.push(`${history.location.pathname}?pid=${propertyId}`);
      } 
    }
  }, []);

  if(history.location.pathname === Routes.SIGN_AGREEMENT){
    heading = defaultValues.actions.sign_agreement;
    text = <span style={{ color: "Black" }}>Please choose the stamp value{text}</span>
  }else {
    heading = defaultValues.actions.initiate_sale_agreement;
    if (role === defaultValues.role_names.seller || role === defaultValues.role_names.lawyer) {
      heading = defaultValues.actions.review_sale_agreement;
    }
  }

  return (
    <InitiateOffers
      templateSubType={defaultValues.doc_sub_types.agreement}
      templateType={defaultValues.template_types.document}
      heading={heading}
      subheading={text}
      buttonText={"Finalize Sale Agreement"}
      proceedUrl={Routes.REGISTRATION}
    />
  );
};

export default ReviewSaleAgreement