import { createAsyncThunk } from "@reduxjs/toolkit";
import { addUser } from "../../services/api/index";


export const singUpUser = createAsyncThunk(
  "superAdminAddUserSlice/addUser", async (payload, { rejectWithValue }) => {
    try {
      const { data } = await addUser(payload);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
});

export const clearStore = createAsyncThunk("CLEAR_STORE", async (_, { dispatch }) => {
  dispatch({ type: "CLEAR_STORE" });
});
  