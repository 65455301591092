import { createAsyncThunk } from "@reduxjs/toolkit";
import { getProfilePic } from "../../services/api";

export const getProfileImg = createAsyncThunk(
	"profileSlice/getProfileImg",
	async (userId, { rejectWithValue }) => {
		try {
			const { data } = await getProfilePic(userId);
			return data;
		} catch (e) {
			rejectWithValue(e.response.data);
		}
	}
);
