import { Routes } from '../../constants/routes';
import { createAxiosInstance } from './axiosConfig';

const endpointMap = {
    '/super-admin-login' : `superadmin/login`,
    '/admin-login' :  `admin/login`,
    [Routes.HOME] :  `user/login`
  }

const defaultHeaders = {
  'Content-Type': 'application/json',
};

const multipartHeaders = {
    'Content-Type': 'multipart/form-data',
  };

const removeToken = () => {
    sessionStorage.removeItem('token');
}
const setToken = (val) => {
    sessionStorage.setItem('token', val);
    // setAuthHeader();
}

const getToken = () => sessionStorage.getItem("token");

const getAuthorizationHeader = () => {
  const token = getToken();
  if(token)
  return `Bearer ${token}`;
  else{
    sessionStorage.clear();
    window.location.href = Routes.HOME;
    return;
  }

}

export function signIn(path, data) {
    return createAxiosInstance({
      url: endpointMap[path],
      method: 'POST',
      headers: { ...defaultHeaders },
      data: data,
    }).then(response => {
      setToken(response.data.data.accessToken);
      return response;
    });
}

export function getAllProperties(values) {
    return createAxiosInstance({
      url: 'property',
      method: 'GET',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      params: values
    });
}

export function getPropertyDetails(propertyId) {
    return createAxiosInstance({
      url: `property/${propertyId}`,
      method: 'GET',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    });
}

export function addProperty(values) {
    return createAxiosInstance({
      url: 'property',
      method: 'POST',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function updateProperty(values) {
    return createAxiosInstance({
      url: 'property',
      method: 'PATCH',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function deleteProperty(propertyId) {
    return createAxiosInstance({
      url: `property/${propertyId}`,
      method: 'DELETE',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    });
}

export function getUserProperty(userId) {
    return createAxiosInstance({
      url: `users/getProperties/${userId}`,
      method: 'GET',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    });
}

export function getStages(values) {
    return createAxiosInstance({
      url: 'stages',
      method: 'GET',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      params: values
    });
}

export function getRoles(values) {
    return createAxiosInstance({
      url: 'roles',
      method: 'GET',
      params: values
    });
}

export function getSubStages() {
    return createAxiosInstance({
      url: 'subStages',
      method: 'GET',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    });
}

export function checkListFolderGet(propertyId) {
    return createAxiosInstance({
      url: `property/createChecklistFolder/${propertyId}`,
      method: 'GET',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    });
}

export function uploadChecklistDoc(values) {
    return createAxiosInstance({
      url: 'property/uploadChecklistDocument',
      method: 'POST',
      headers: { ...multipartHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function getAllChecklistFiles(values) {
    return createAxiosInstance({
      url: 'getAllFilesInsideChecklist',
      method: 'PUT',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function getPropertyChecklistFiles(values) {
    return createAxiosInstance({
      url: 'property/getAllFilesInsideChecklist',
      method: 'PUT',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function getChecklistFiles(values) {
    return createAxiosInstance({
      url: 'property/getFileFromChecklist',
      method: 'PUT',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function validateStage(values) {
    return createAxiosInstance({
      url: 'rules/initiateStageChange',
      method: 'PUT',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function requestPayments(values) {
    return createAxiosInstance({
      url: 'payment/platform/createPaymentRequest',
      method: 'POST',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function checkPaymentStatus(values) {
    return createAxiosInstance({
      url: 'payment/platform/paymentRequestStatus',
      method: 'POST',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function getPropPaymentDetails(propertyId, values) {
    return createAxiosInstance({
      url: `payment/getPropertyPaymentDetails/${propertyId}`,
      method: 'GET',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      params: values,
    });
}

export function verifyPropPaymentStatus(values) {
    return createAxiosInstance({
      url: 'payment/verifyPropertyPaymentDetails',
      method: 'POST',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}


export function getChecklistType(type) {
    return createAxiosInstance({
      url: `checklist/type/${type}`,
      method: 'GET',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    });
}

export function getUsers(values) {
    return createAxiosInstance({
      url: 'users',
      method: 'GET',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      params: values
    });
}

export function getUsersByEmail(email) {
  return createAxiosInstance({
    url: `users/findByEmail/${email.email}`,
    method: 'GET',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    // params: values
  });
}

export function addUser(values) {
    return createAxiosInstance({
      url: 'users',
      method: 'POST',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function updateUser(values) {
    return createAxiosInstance({
      url: 'users',
      method: 'PATCH',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function deleteUserbyId(userId) {
    return createAxiosInstance({
      url: `users/${userId}`,
      method: 'DELETE',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    });
}

export function getSuperAdmin(values) {
    return createAxiosInstance({
      url: 'superadmin',
      method: 'GET',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      params: values,
    });
}

export function getSuperAdminById(id) {
    return createAxiosInstance({
      url: `superadmin/${id}`,
      method: 'GET',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    });
}

export function updateSuperAdmin(values) {
    return createAxiosInstance({
      url: 'superadmin',
      method: 'PATCH',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function getAdmin() {
    return createAxiosInstance({
      url: 'admin',
      method: 'GET',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    });
}

export function addAdmin(values) {
    return createAxiosInstance({
      url: 'admin',
      method: 'POST',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function updateAdmin(values) {
    return createAxiosInstance({
      url: 'admin',
      method: 'PATCH',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function addTemplate(values) {
    return createAxiosInstance({
      url: 'template/',
      method: 'POST',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function getTemplate(values) {
    return createAxiosInstance({
      url: 'template/',
      method: 'GET',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    });
}

export function getTemplateByName(name) {
  return createAxiosInstance({
    url: `template/templateName/${name}`,
    method: 'GET',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
  });
}

export function updateTemplate(values) {
    return createAxiosInstance({
      url: 'template/',
      method: 'PATCH',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function uploadImage(values) {
    return createAxiosInstance({
      url: 'images',
      method: 'POST',
      headers: { ...multipartHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function addChecklistData(values) {
    return createAxiosInstance({
      url: 'checklist/',
      method: 'POST',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function getChecklistData() {
    return createAxiosInstance({
      url: 'checklist/',
      method: 'GET',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    });
}

export function updateChecklistData(values) {
    return createAxiosInstance({
      url: 'checklist',
      method: 'PATCH',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function deleteChecklist(checklistId) {
    return createAxiosInstance({
      url: `checklist/${checklistId}`,
      method: 'DELETE',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    });
}

export function generateDoc(values) {
    return createAxiosInstance({
      url: 'property/generateDocument',
      method: 'POST',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function storeDoc(values) {
    return createAxiosInstance({
      url: 'property/storeDocument',
      method: 'PUT',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function getDocByPropId(values) {
    return createAxiosInstance({
      url: 'propertydocument/getDocumentByPropertyId',
      method: 'PUT',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function waveFee(values) {
    return createAxiosInstance({
      url: 'property/waivePlatFormFee',
      method: 'PATCH',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function uploadProfilePic(values) {
    return createAxiosInstance({
      url: 'profileImage/upload',
      method: 'POST',
      headers: { ...multipartHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function getProfilePic(userId) {
    return createAxiosInstance({
      url: `profileImage/${userId}`,
      method: 'GET',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    });
}

export function superAdminForgotPass(values) {
    return createAxiosInstance({
      url: 'superadmin/forgot',
      method: 'POST',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function propertyImageGet(payload) {
    return createAxiosInstance({
      url: `property/images`,
      method: 'POST',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: payload
    });
}

export function getSignedDoc(values) {
    return createAxiosInstance({
      url: 'property/getsigndoc',
      method: 'POST',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function initiateSignDoc(values) {
    return createAxiosInstance({
      url: 'property/signdoc',
      method: 'POST',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function initiateStampDoc(values) {
    return createAxiosInstance({
      url: 'property/stampDoc',
      method: 'POST',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function updateSUbStages(values) {
    return createAxiosInstance({
      url: 'property/substages',
      method: 'PATCH',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function approveorRejectDoc(values) {
    return createAxiosInstance({
      url: 'propertydocument/approvereject',
      method: 'PUT',
      headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
      data: values,
    });
}

export function addCommentToDoc(values) {
  return createAxiosInstance({
    url: 'propertydocument/addNewComment',
    method: 'PUT',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    data: values,
  });
}


export function getPlatformFeeDetails(propertyId) {
  return createAxiosInstance({
    url: `payment/getPropertyPlatformFessDetails/${propertyId}`,
    method: 'GET',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
  });
}

export function deleteUser(values) {
  return createAxiosInstance({
    url: `property/user`,
    method: 'PATCH',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    data: values,
  });
}

export function userSignUp(values) {
  return createAxiosInstance({
    url: `users/signUp`,
    method: 'POST',
    headers: { ...defaultHeaders },
    data: values,
  });
}

export function addAllDocumentByPropertyIdAndType(values) {
  return createAxiosInstance({
    url: 'propertydocument/getAllDocumentByPropIDAndType',
    method: 'PUT',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    data: values,
  });
}

export function checkPartyDetails(propertyId) {
  return createAxiosInstance({
    url: `property/party_details/${propertyId}`,
    method: 'GET',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
  });
}

export function getAllUploadedDocumentBySeller(value) {
  return createAxiosInstance({
    url: `propertydocument/getAllDocumentByPropIDAndType`,
    method: 'PUT',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    data : value
  });
}


export function lawyerApproval(value) {
  return createAxiosInstance({
    url: `property/lawyerApproval`,
    method: 'POST',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    data : value
  });
}

export function addPropertyHistory(value) {
  return createAxiosInstance({
    url: 'history',
    method: 'POST',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    data : value
  });
}

export function generateDocWithPayment(value) {
  return createAxiosInstance({
    url: 'property/generateDocumentWithPayment',
    method: 'POST',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    data : value
  });
}

export function updateVerifyStatus(value) {
  return createAxiosInstance({
    url: 'propertydocument/updateVerifyDocument',
    method: 'POST',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    data : value
  });
}

export function updatePropertyImages(values) {
  return createAxiosInstance({
    url: `property/delete_property_image`,
    method: 'PATCH',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    data: values
  });
}
export function updatePropertyDocument(values) {
  return createAxiosInstance({
    url: `property/delete_property_document`,
    method: 'PATCH',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    data: values
  });
}

export function totalPlatformFee() {
  return createAxiosInstance({
    url: `payment/getPaidPlatformFee`,
    method: 'GET',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
  });
}

export function backOut(values) {
  return createAxiosInstance({
    url: 'property/backout',
    method: 'POST',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    data: values,
  });
}

export function createRentalAgreement(values) {
  return createAxiosInstance({
    url: 'rentalAgreement/newApplications',
    method: 'POST',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    data: values,
  });
}

export function updateRentalAgreement(values) {
  return createAxiosInstance({
    url: 'rentalAgreement/updateApplications',
    method: 'POST',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    data: values,
  });
}

export function rentalPayRequest(values) {
  return createAxiosInstance({
    url: 'rentalAgreement/createPayRequest',
    method: 'POST',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    data: values,
  });
}

export function r_updatePaymentDetailsAPI(values) {
  return createAxiosInstance({
    url: 'rentalAgreement/updatePaymentDetails',
    method: 'POST',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    data: values,
  });
}

export function r_getAgreementDocument(id) {
  return createAxiosInstance({
    url: `rentalAgreement/getAgreementDocument/${id}`,
    method: 'GET',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() }
  });
}

export function submitRentalAgreementAPI(values) {
  return createAxiosInstance({
    url: 'rentalAgreement/submitApplications',
    method: 'POST',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    data: values,
  });
}

export function getNewAgreement(id) {
  return createAxiosInstance({
    url: `rentalAgreement/getNewAgreement?id=${id}`,
    method: 'GET',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
  });
}

export function updateRentalPartyAPI(values) {
  return createAxiosInstance({
    url: 'rentalAgreement/updateRentalParty',
    method: 'POST',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    data: values,
  });
}

export function updateRentalPartyApiOld(data) {
  return createAxiosInstance({
    url: `rentalAgreement/updateRentalParty`,
    method: 'POST',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    data
  });
}

export function getMyRentalAgreementAPI(values) {
  return createAxiosInstance({
    url: `rentalAgreement/getMyRentalAgreement`,
    method: 'GET',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    params: values
  });
}

export function getAllRentalAgreementAPI(values) {
  return createAxiosInstance({
    url: `rentalAgreement/getAllRental`,
    method: 'GET',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    params: values
  });
}

export function updatePercentage(values) {
  return createAxiosInstance({
    url: 'property/update_percentage',
    method: 'PATCH',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    data: values,
  });
}

export function getUserById(id) {
  return createAxiosInstance({
    url: `users/${id}`,
    method: 'GET',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() }
  });
}

export function getRentalByRefid(refId) {
  return createAxiosInstance({
    url: `rentalAgreement/getRentalByRefId`,
    method: 'POST',
    headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() },
    data: refId,
  });
}


