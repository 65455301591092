import React from "react";
import { useSelector } from "react-redux";

const PropertyHistoryData = () => {
    const history = useSelector(
        (state) => state.buyerHomeData?.selectedProperty?.activity_history
      );
    return (
        <div>
            <div className="property-doc">
                <h4 className="fw-bold">Property History</h4>
                <div className="history-box">
                    <ul className="property-history-font">
                        {history?.map((item) => {
                            return (
                                <li>{`${new Date(item.activity_date).toDateString()} ${new Date(item.activity_date).getHours() + ":" + new Date(item.activity_date).getMinutes()} ${item.name
                                    } ${item.activity}`}</li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>

    )

}
export default PropertyHistoryData;