/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Main from "../../layout/Main";
import "./style.scss";
import { Box, Button, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useHistory, useLocation } from "react-router-dom";
import { updateProperty } from "../../services/api";
import dayjs from "dayjs";
import { Routes } from "../../constants/routes";
import { updateSubStage } from "../../utils/HelperFunction";
import { updateSelectedProperty } from "../user-home/slices";
import Loader from "../../layout/Loader/Loader";
import {
  ERROR_KEY,
  PID_KEY,
  SUCCESS_KEY,
  defaultValues,
  getErrorMessage,
  getSuccessMessage,
} from "../../constants";

const PropertyEvaluation = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const propertyId =
    useSelector((state) => state?.buyerHomeData?.selectedProperty?._id) || "";
  const propertyData = useSelector(
    (state) => state?.buyerHomeData?.selectedProperty
  );
  const roleName = useSelector((state) => state.loggedInUser?.preferredRole);
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const accessToken =
    useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";
  const [selectDate, setSelectDate] = useState(null);
  const [loader, setLoader] = useState(false);
  const subStages = useSelector((state) => state?.stages?.subStages);
  const getSubStage = (order) =>
    subStages.filter((substage) => substage.order === order)[0]?._id;
  const userId = useSelector((state) => state.loggedInUser.data.data?.userByEmail._id);

  useEffect(() => {
    const pid = searchParams.get(PID_KEY);
    if (!accessToken) {
      history.push(Routes.HOME);
    } else {
      if (roleName === defaultValues.role_names.bank_agent) {
        if (!propertyId || pid !== propertyId) {
          history.push(Routes.USER_HOME);
        } else {
          history.push(`${Routes.PROPERTY_INSPECTION}?pid=${propertyId}`);
        }
      } else {
        history.push(`${Routes.VIEW_PROPERTY}?pid=${propertyId}`);
      }
    }
  }, [accessToken, propertyId, roleName]);

  useEffect(() => {
    const getDate = new Date(propertyData?.bank?.inspection_time_date);
    setSelectDate(dayjs(getDate));
  }, [propertyData]);

  const onSubmit = async (propertyId) => {
    setLoader(true);
    if (selectDate === "") {
      addToast(getErrorMessage("E-10229"), {
        appearance: ERROR_KEY,
        autoDismiss: true,
      });
      return;
    }

    const payload = {
      id: propertyId,
      bank: {
        legal_verification: false,
        loan_sanctioned: false,
        inspection_time_date: selectDate,
      },
      notify_inspection: true,
    };

    await updateProperty(payload)
      .then(async (data) => {
        await getSubStageUpdated(propertyId, getSubStage(15.1));
        history.push(`${Routes.VIEW_PROPERTY}?pid=${propertyId}`);
      })
      .catch((error) => {
        setLoader(false);
        addToast(error.response.data?.message || getErrorMessage("E-10070"), {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
      });

    setLoader(false);
  };

  const getSubStageUpdated = async (propertyId, substage) => {
    try {
      const response = await updateSubStage({propertyId, substageId: substage, loggedInUserId:userId});
      if (response.status === 200) {
        dispatch(updateSelectedProperty(response.data.propertyData));
        addToast(getSuccessMessage("S-10006"), {
          appearance: SUCCESS_KEY,
          autoDismiss: true,
        });
      } else
        addToast(getErrorMessage("E-10070"), {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
    } catch (error) {
      addToast(getErrorMessage("E-10111"), {
        appearance: ERROR_KEY,
        autoDismiss: true,
      });
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div>
          <Main
            showUserNavbar={!props?.showUserNavbar}
            showUser={!props?.showUser}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="start"
              alignItems="start"
              minHeight="70vh"
              bgcolor="var(--white)"
            >
              <div className="container">
                <div className="inner-container">
                  <Typography
                    variant="h5"
                    component="h3"
                    sx={{ fontWeight: 100 }}
                    style={{color:"black"}}
                  >
                    Property Inspection
                  </Typography>
                  <Typography variant="body2" component="p" mt={5}>
                    Pick the date & time on which Bank will conduct the property
                    evaluation to publish notification:
                  </Typography>
                  <div className="fees-heading">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        disablePast
                        value={selectDate}
                        label="Select Date & Time"
                        className="dateWidth"
                        onChange={(date) =>
                          setSelectDate(
                            date ? date.toLocaleString("en-US") : ""
                          )
                        }
                      />
                    </LocalizationProvider>
                  </div>
                  <br />
                  {propertyData?.tenant?.name !== "" && (
                    <>
                      <div className="fee-body">
                        <h4>Tenant Details</h4>
                        <div className="col">
                          <p>
                            <strong>Name </strong>: {propertyData?.tenant?.name}
                          </p>
                          <p>
                            <strong>Email Id</strong> :{" "}
                            {propertyData?.tenant?.email}
                          </p>
                          <p>
                            <strong>Phone No</strong> :{" "}
                            {propertyData?.tenant?.phone}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="evaluation">
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "var(--primaryButton)" }}
                      disabled={!selectDate || selectDate === ""}
                      onClick={() => {
                        onSubmit(propertyId);
                      }}
                    >
                      Notify Inspection Date
                    </Button>
                  </div>
                </div>
              </div>
            </Box>
          </Main>
        </div>
      )}
    </>
  );
};
export default PropertyEvaluation;
