import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import Form from "react-bootstrap/Form";
import "./style.scss";
import { rPropertyDetails } from "../../../utils/validationSchemas";
import { STATES } from "../../../constants/states";
import { Dropdown } from "../../Dropdown";

export const PropertyDetails = ({
  onBack,
  onSave,
  agreementData,
  readOnly,
  isRenew,
}) => {
  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues: {
        propertyAddress: agreementData?.propertyAddress || "",
        bhk: agreementData?.bhk || "",
        carPark: agreementData?.carPark || "",
        area: agreementData?.area || "",
        furnishing: agreementData?.furnishing || "",
        propertyState: agreementData?.propertyState || "",
        propertyCity: agreementData?.propertyCity || "",
        propertyZip: agreementData?.propertyZip || "",
      },
      validationSchema: rPropertyDetails,
      validateOnChange: true,
      validateOnBlur: true,
      enableReinitialize: true,
      onSubmit: (values) =>
        onSave(
          {
            ...values,
            propertyZip: values.propertyZip.toString(),
            stageNo: agreementData.stageNo === 3.1 ? 3.1 : 1.4,
          },
          true
        ),
    });

  return (
    <Form className="pl-3 mt-1 container-fluid" onSubmit={handleSubmit}>
      <div className="row">
        <Form.Group className="mb-3 col-md-6">
          <Form.Label className="fee-label">House Address</Form.Label>
          <Form.Control
            disabled={readOnly}
            type="text"
            name="propertyAddress"
            id="propertyAddress"
            required
            placeholder="property full address"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.propertyAddress}
            className="fee-inputs-multiple mb-1"
          />
          {touched.propertyAddress && errors.propertyAddress && (
            <p className="r-formError">{errors.propertyAddress}</p>
          )}
        </Form.Group>
        <Form.Group className="mb-3 col-md-3">
          <Form.Label className="fee-label">State</Form.Label>
          <FormControl size="small" sx={{ minWidth: 260 }}>
            <Dropdown
              required
              disabled={readOnly}
              id="propertyState"
              name="propertyState"
              value={values.propertyState}
              placeholder="Select state"
              options={STATES.map((state) => state.label)}
              handleBlur={handleBlur}
              onChange={(event, newValue) => {
                handleChange({
                  target: { name: "propertyState", value: newValue },
                });
              }}
            />
          </FormControl>
          {(touched.propertyState || touched.city) && errors.propertyState && (
            <p className="r-formError">{errors.propertyState}</p>
          )}
        </Form.Group>
        <Form.Group className="mb-3 col-md-4">
          <Form.Label className="fee-label">City</Form.Label>
          <Form.Control
            required
            disabled={readOnly}
            type="text"
            name="propertyCity"
            id="propertyCity"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.propertyCity}
            placeholder="city name"
            className="fee-inputs-multiple mb-1"
          />
          {touched.propertyCity && errors.propertyCity && (
            <p className="r-formError">{errors.propertyCity}</p>
          )}
        </Form.Group>
        <Form.Group className="mb-3 col-md-2">
          <Form.Label className="fee-label">Pin Code</Form.Label>
          <Form.Control
            required
            disabled={readOnly}
            type="number"
            name="propertyZip"
            id="propertyZip"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.propertyZip}
            placeholder="pin code"
            className="fee-inputs-multiple mb-1"
          />
          {touched.propertyZip && errors.propertyZip && (
            <p className="r-formError">{errors.propertyZip}</p>
          )}
        </Form.Group>
      </div>
      <div className="row">
        <Form.Group className="mb-3 col-md-4">
          <Form.Label className="fee-label">
            Super Built-up Area in sq.ft.(Approximately)
          </Form.Label>
          <Form.Control
            disabled={readOnly}
            type="number"
            name="area"
            id="area"
            required
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="area in sq.ft."
            value={values.area}
            className="fee-inputs-multiple mb-1"
          />
          {touched.area && errors.area && (
            <p className="r-formError">{errors.area}</p>
          )}
        </Form.Group>
        <Form.Group className="mb-3 col-md-2">
          <Form.Label className="fee-label">Number of BHK</Form.Label>
          <Form.Control
            disabled={readOnly}
            type="number"
            name="bhk"
            id="bhk"
            required
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.bhk}
            placeholder="BHK"
            className="fee-inputs-multiple mb-1"
          />
          {touched.bhk && errors.bhk && (
            <p className="r-formError">{errors.bhk}</p>
          )}
        </Form.Group>
        <Form.Group className="mb-3 col-md-2">
          <Form.Label className="fee-label">Number of Car Park/s</Form.Label>
          <Form.Control
            disabled={readOnly}
            type="number"
            name="carPark"
            id="carPark"
            required
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.carPark}
            placeholder="number of car park/s"
            className="fee-inputs-multiple mb-1"
          />
          {touched.carPark && errors.carPark && (
            <p className="r-formError">{errors.carPark}</p>
          )}
        </Form.Group>
        <Form.Group className="mb-3 col-md-2" style={{ maxWidth: "160px" }}>
          <Form.Label className="fee-label">Furnishing</Form.Label>
          <FormControl size="small" sx={{ minWidth: 220 }}>
            <Select
              className="fee-inputs-multiple mb-1"
              disabled={readOnly}
              displayEmpty
              required
              name="furnishing"
              id="furnishing"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.furnishing}
            >
              <MenuItem value={"Semi-Furnished"}>Semi-Furnished</MenuItem>
              <MenuItem value={"Fully Furnished"}>Fully Furnished</MenuItem>
              <MenuItem value={"Unfurnished"}>Unfurnished</MenuItem>
            </Select>
          </FormControl>
          {touched.furnishing && errors.furnishing && (
            <p className="r-formError">{errors.furnishing}</p>
          )}
        </Form.Group>
      </div>
      {!readOnly && (
        <div className="proceed-button my-3 text-center">
          <Button
            className="px-5 mr-3 mt-3 btn-outline-secondary"
            variant="outlined"
            onClick={onBack}
          >
            Back
          </Button>
          <Button className="button px-5 mt-3" variant="primary" type="submit">
            Save & Proceed
          </Button>
        </div>
      )}
    </Form>
  );
};
