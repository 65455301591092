import { createAsyncThunk } from "@reduxjs/toolkit";
import { superAdminForgotPass } from "../../services/api";


export const postData = createAsyncThunk('superAdminForgotSlice/postData', async (args, {rejectWithValue}) =>{
  try {
     const {data}  = await superAdminForgotPass(args);
     return data
  } catch(e) {
      rejectWithValue(e.response.data)
  }
})