import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { FormControl, InputLabel, MenuItem, Select, TextField, Button } from "@mui/material";

import "./style.scss";

import { useDispatch, useSelector } from "react-redux";
import { updateUserData, getData } from "../manage-users/service";
import { useToasts } from "react-toast-notifications";
import { loginSchema } from "../../utils/validationEditSchemas";
import { yupResolver } from "@hookform/resolvers/yup";
import { SUCCESS_KEY } from "../../constants";

const EditUsers = ({ editModal, setEditModal, id, first_name, last_name, email_id, phone, status }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false)
  const [dataValue, setDataValue] = useState({
    id: id,
    first_name: first_name,
    last_name: last_name,
    phone: phone,
    email_id: email_id,
    status: status,
  });


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
    mode: "onBlur",
  });


  const onSubmit = async (data, e) => {
    e.preventDefault();
    dispatch(updateUserData(dataValue)).then((res) => {
      setIsLoading(true)
      dispatch(getData()).then(() => setIsLoading(false));
      addToast(res.payload.message, { appearance: SUCCESS_KEY, autoDismiss: true });
    });
    setEditModal(false);
  };


  return (
    <Modal
      size="md"
      show={editModal}
      onHide={() => setEditModal(false)}
      aria-labelledby="example-modal-sizes-title-lg"
      className="editProperty"
      centered
    >
      <div className="add-form">
        <div className="add-user-form">
          <div className="close-btn"></div>
          <h1 className="assignAdds">Edit User</h1>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div class="add-admin-column">
              <TextField
                id="First Name"
                label="First Name"
                variant="outlined"
                type="text"
                value={dataValue.first_name}
                {...register("first_name")}
                onChange={(e) =>
                  setDataValue({ ...dataValue, first_name: e.target.value })
                }
              />
              {errors.firstname ? (
                <div className="form-errors">{errors.firstname.message}</div>
              ) : null}
              <TextField
                id="ast name"
                label="Last name"
                variant="outlined"
                type="text"
                value={dataValue.last_name}
                {...register("last_name")}
                onChange={(e) =>
                  setDataValue({ ...dataValue, last_name: e.target.value })
                }
              />
              {errors.lastname ? (
                <div className="form-errors">{errors.lastname.message}</div>
              ) : null}
            </div>
            <TextField
              id="Phone no"
              label="Phone No"
              variant="outlined"
              type="number"
              value={dataValue.phone}
              {...register("phone")}
              onChange={(e) =>
                setDataValue({ ...dataValue, phone: e.target.value })
              }
              helperText={
                <span className={errors.phone ? "errorText" : ""}>
                  {errors.phone?.message}
                </span>
              }
            />

            <div class="add-admin-column">
              <TextField
                id="email_id"
                label="Email-ID"
                variant="outlined"
                type="text"
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "var(--white)",
                    cursor: "not-allowed"
                  },
                }}
                disabled
                value={dataValue.email_id}
                {...register("email_id")}
                onChange={(e) =>
                  setDataValue({ ...dataValue, email_id: e.target.value })
                }
              />
              {errors.email_id ? (
                <div className="form-errors">{errors.email_id.message}</div>
              ) : null}

              <FormControl fullWidth>
                <InputLabel id="status-select-label">Status</InputLabel>
                <Select
                  labelId="status-select-label"
                  id="status-select"
                  value={dataValue.status}
                  label="Status"
                  onChange={(e) => setDataValue((prevDataValue) => ({
                    ...prevDataValue,
                    status: e.target.value
                  }))}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="edit-btn">
              <Button type="submit" className="add-admin-btn" variant="contained">
                Update
              </Button>
              <Button type="close" className="add-admin-btn" onClick={() => { setEditModal(false); }} variant="contained">
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};
export default EditUsers;