import successMessages from './successMessages.json';
import errors from './errors.json';

/**
 * Fetch the message using the code.
 */

export function getSuccessMessage(code, varNameArr) {
  const successMsg = successMessages[code] && successMessages[code].message;
  if (successMsg && successMsg.indexOf('<') !== -1) {
    let updatedSuccessMsg = successMsg;
    if (varNameArr) {
      varNameArr.map((val, index) => {
        updatedSuccessMsg = updatedSuccessMsg.replace(
          '<' + parseInt(parseInt(index, 10) + 1, 10) + '>',
          val,
        );
      });
    }
    return updatedSuccessMsg;
  } else {
    return successMsg;
  }
}


export function getErrorMessage(code, varNameArr) {
  const errorMsg = errors[code] && errors[code].error_msg;
  if (errorMsg && errorMsg.indexOf('<') !== -1) {
    let updatedMsg = errorMsg;
    if (varNameArr) {
      varNameArr.map((val, index) => {
        updatedMsg = updatedMsg.replace(
          '<' + parseInt(parseInt(index, 10) + 1, 10) + '>',
          val,
        );
      });
    }
    return updatedMsg;
  } else {
    return errorMsg;
  }
}
