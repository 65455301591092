import React , {useEffect} from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import InitiateOffers from "../../commonComponent/initiateOffers";
import { Routes } from "../../constants/routes";
import { defaultValues } from "../../constants";

const ReviewSaleDeed = () => {
  const history = useHistory();
  const location = useLocation();
  const accessToken = useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";
  const propertyId = useSelector((state)=> state?.buyerHomeData?.selectedProperty?._id) || '';
  const pid = location.search.split("=")[1]
  const role = useSelector((state) => state.loggedInUser.preferredRole);

  useEffect(() => {
    if (!accessToken) {
      history.push(Routes.HOME);
    }else{
      if(!propertyId) history.push(Routes.PROPERTIES);
      else if(pid !== propertyId) history.push(`${Routes.REVIEW_SALE_DEED}?pid=${propertyId}`)
    }
  }, []);

  let heading = defaultValues.flow_list.initiate_sale_deed;
  if (role === defaultValues.role_names.seller || role === defaultValues.role_names.lawyer) {
    heading = defaultValues.actions.review_sale_deed;
  }
  
    return (
      <InitiateOffers
        templateSubType={defaultValues.doc_sub_types.sale_deed}
        templateType={defaultValues.template_types.document}
        heading={heading}
        subheading={""}
        type={"review"}
        buttonText={"Finalize SALE DEED"}
        // proceedUrl={""}
      />
    );
  
};

export default ReviewSaleDeed
