import Axios from "axios";
import { initiateSignDoc, initiateStampDoc } from "../services/api";
import { API } from "./api";
import {SUCCESS_KEY, getErrorMessage} from '../constants';

let documentId = '';
let signerid = '';
let docketid = '';
const width = '1200px'
const height = '600px'
var clientData = {
  document_id: documentId,
  signer_id: signerid,
  docket_id: docketid,
  id: API.signDeskAPIId[API.currenEnv],
  widget_width: width,
  widget_height: height,
  "x-parse-rest-api-key": API.signDeskAPIKey[API.currenEnv],
  "x-parse-application-id": API.signDeskAPIId[API.currenEnv],

  getWidgetToken: function (result) {
    widgetCall({ "widget_token": result })
  },
  signatureStatus: function (result) {
    clientData.callback2(result, clientData.docket_id);
  },
  errorLog: function (result) {
  },
};

const getToken = () => sessionStorage.getItem("token");

const getAuthorizationHeader = () => `Bearer ${getToken()}`;

export const initiateSigning = ({ 
  propertyId,userId, templateType, templateSubType, callbackFunction, addToastMessage, loaderFalse, signType }) => {
  initiateSignDoc({
    propertyId,
    templateType,
    templateSubType,
    userId,
    signType
  }).then((res) => {
    clientData.docket_id = res.data.data.docket_id;
    clientData.document_id = res.data.data.signer_info.document_id;
    clientData.signer_id = res.data.data.signer_info.signer_id;
    clientData.callback2 = callbackFunction;
    clientData.addToastMessage = addToastMessage;
    clientData.loaderFalse = loaderFalse;
    window.esignWidgetCall(clientData);
    loaderFalse(false)


  }).catch((error) => {
    loaderFalse(false);
    addToastMessage(error.response.data.message);
  });
}
export const initiateE_Stamp = ({ propertyId,userId, templateType, templateSubType, callbackFunction, loaderFalse, signType, addToastMessage  }) => {
  initiateStampDoc({
    propertyId,
    templateType,
    templateSubType,
    userId,
    loaderFalse,
    signType
  }).then((res) => {
   callbackFunction(res);
   
  }).catch((error) => {
    addToastMessage(error.response.data.message);
    loaderFalse(false)
  });
}



function widgetCall(payloadData) {
  Axios({
    method: "post",
    headers: {
      Authorization: getAuthorizationHeader(),
    },
    url:`${API.baseURL[API.currenEnv]}property/widgetCall`,
    data: payloadData,
  }).then((rsp) => { 
    if(rsp.data.data.status !== SUCCESS_KEY) {
      clientData.addToastMessage(rsp.data.data.error)
    }
    return rsp.data.data;
  }).catch((e) => {
    clientData.addToastMessage(getErrorMessage("E-10248"));
    clientData.loaderFalse(false);
  });
}