/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import "./style.scss";
import { GiHamburgerMenu } from "react-icons/gi";

import { NavLink , useHistory} from "react-router-dom";

// import logo from "../../assets/menu.svg";
import logo from '../../assets/logo_new.png';
import ava from "../../assets/ava.svg";
import logoutIcon from "../../assets/logout-2.svg";
import homeIcon from "../../assets/house-solid.svg";
import settingsIcon from "../../assets/settings-gear.svg"
import rentalIcon from "../../assets/Icons/rental.svg"

import { useDispatch, useSelector } from "react-redux";
import '../../styles/colors.scss';
import { getProfileImg } from "../../redux/extra-services/profile.services";
import { store } from "../../redux/store";
import { ROLE_KEY, defaultValues } from "../../constants";
import { Routes } from "../../constants/routes";


const UserNavbar = ({props, text}) => {
  const role = sessionStorage.getItem(ROLE_KEY)
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const profileImage = useSelector(state => state.profileImage.image);
  const history = useHistory();


  const [showBigImage,setShowBigImage]=useState(false);

  const dispatch = useDispatch()
  const loggedInUser = useSelector((state) => state.loggedInUser);
  const userFirstName=loggedInUser.data?.data?.userByEmail?.first_name;

  useEffect(() => {
	let user = loggedInUser.data?.data?.userByEmail;
	if(user) {
		dispatch(getProfileImg(user._id))
	}
  }, [dispatch, loggedInUser]);

  const handleClick = ()=>{
      sessionStorage.clear();
      store.dispatch({type: defaultValues.dispatch_actions.logout});
      history.push(Routes.HOME)
  }

  useEffect(()=>{
    if(props?.currentScreen && props?.currentScreen === "user_home"){
      setShowBigImage(true)
    }
  },[props])

  return (
		<>
			<nav className={showBigImage ? "home-nav" : "main-nav"}>
				<div className="logo">
         			<img src={logo} alt="Logo" />
        		</div>
				<div
					className={
						showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
					}
				>
					<ul >
					<li className="home" style={{ padding: "0% 1vh 0% 0%" }}>
					    {role === defaultValues.role_names.super_admin ? (
					        <NavLink to="/manage-properties">
					            <img
					                style={{ marginLeft: "10px" }}
					                width="25px"
					                src={homeIcon}
					            />
					            <br />
					            <a style={{ fontSize: "15px" }}>Home</a>
					        </NavLink>
					    ) : role === defaultValues.role_names.admin ? (
							<NavLink to="/manage-properties">
								<img
									style={{ marginLeft: "10px" }}
									width="25px"
									src={homeIcon}
									alt="Home Icon"
								/>
								<br />
								<span style={{ fontSize: "15px" }}>Home</span>
							</NavLink>
						) :
						 (
					        <NavLink to="/properties">
					            <img
					                style={{ marginLeft: "10px" }}
					                width="25px"
					                src={homeIcon}
					            />
					            <br />
					            <a style={{ fontSize: "15px" }}>Home</a>
					        </NavLink>
					    )}
					</li>
					<li className="home" style={{ padding: "0% 1vh 0% 0%" }}>
						    {role === defaultValues.role_names.super_admin ? (
						        <NavLink to="/super-admin-settings">
						            <img
						                style={{ marginLeft: "15px" }}
						                width="25px"
						                src={settingsIcon}
						            />
						            <br />
						            <a style={{ fontSize: "15px" }}>Settings</a>
						        </NavLink>
						    ) : role === defaultValues.role_names.admin ? (
								<NavLink to="/manage-profile">
									<img
										style={{ marginLeft: "10px" }}
										width="25px"
										src={homeIcon}
										alt="Home Icon"
									/>
									<br />
									<span style={{ fontSize: "15px" }}>Settings</span>
								</NavLink>
							) : (
						        <>
						            {role === "BANK AGENT" ? (
						                <NavLink to="/agent-profile">
						                    <img
						                        style={{ marginLeft: "15px" }}
						                        width="25px"
						                        src={settingsIcon}
						                    />
						                    <br />
						                    <a style={{ fontSize: "15px" }}>Settings</a>
						                </NavLink>
						            ) : (
						                <NavLink to="/profile-settings">
						                    <img
						                        style={{ marginLeft: "15px" }}
						                        width="25px"
						                        src={settingsIcon}
						                    />
						                    <br />
						                    <a style={{ fontSize: "15px" }}>Settings</a>
						                </NavLink>
						            )}
						        </>
						    )}
						</li>
						<li
							className="home"
							style={{
								color: "white",
								borderRight: "10px",
								borderColor: "white",
								padding: "0% 1vh 0% 0%",
							}}
						>
							<NavLink to= {window.location}>
								<a
									onClick={handleClick}
									style={{ borderRight: "10px", borderColor: "white" }}
								>
									<img
										style={{ marginLeft: "15px" }}
										width="25px"
										src={logoutIcon}
										alt="logout"
									/>
									<br />
									<a style={{ fontSize: "15px" }}>Logout</a>
								</a>
							</NavLink>
						</li>
						<li style={{ padding: "0% 0% 0% 0%", marginLeft: "10px"}}>
							<NavLink to="/properties">
							<div className="user-name-container">
								<img src={profileImage ? profileImage : ava} className="buyer-image" />
								
      								<a className="user-name" title={userFirstName}>
      								  {userFirstName}
      								</a>
    							</div>
							</NavLink>

						</li>
					</ul>
				</div>
				<div className="social-media">
					<ul></ul>
					<div className="hamburger-menu">
						<a href="#" onClick={() => setShowMediaIcons(!showMediaIcons)}>
							<GiHamburgerMenu className="ham" />
						</a>
					</div>
				</div>
			</nav>
		</>
	);
};

export default UserNavbar;
