import { createAsyncThunk } from "@reduxjs/toolkit";
import { getStages, getSubStages } from "../../services/api";

export const getAllStages = createAsyncThunk(
    "stagesSlice/getAllStages", async (args, { rejectWithValue }) => {
        try {
            const { data } = await getStages(args);
            return data;
        } catch (e) {
            rejectWithValue(e.response.data);
        }
    });

    export const getAllSubStages = createAsyncThunk(
        "stagesSlice/getAllSubStages", async (args, { rejectWithValue }) => {
            try {
                const { data } = await getSubStages();
                return data;
            } catch (e) {
                rejectWithValue(e.response.data);
            }
        });