import React,{useState, useEffect, useCallback} from "react";
import Main from "../../layout/Main";
import { Col, Form } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import {useDispatch, useSelector} from 'react-redux'
import "./style.scss";
import { Typography, Button } from "@mui/material";
import { addressOptions, zoneOptions } from "../../utils/addressOptions";
import {useHistory,useLocation} from 'react-router-dom'
import {updateSubStage} from '../../utils/HelperFunction';
import {updateSelectedProperty} from "../user-home/slices"
import { addPropertyHistory, getPropertyDetails, updateProperty } from "../../services/api";
import Loader from "../../layout/Loader/Loader";
import { historyFormatter } from "../../utils/historyFormatter";
import { Routes } from "../../constants/routes";
import { ERROR_KEY, SUCCESS_KEY, getErrorMessage, getSuccessMessage } from "../../constants";

function SetRegistrationDate() {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory()
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const role = useSelector((state) => state.loggedInUser.prefferedRole);
  const user = useSelector((state) => state.loggedInUser.data.data.userByEmail);
  const userId = useSelector((state) => state.loggedInUser?.data?.data?.userByEmail._id)
  const accessToken = useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";
  const propertyId = useSelector((state)=> state?.buyerHomeData?.selectedProperty?._id) || '';
  const selectedProperty = useSelector((state)=> state?.buyerHomeData?.selectedProperty) || {};
  const subStages = useSelector((state)=> state?.stages?.subStages)
  const getSubStage = (order) => subStages.filter((substage)=> substage.order === order)[0]._id
  const [isLoading, setIsLoading] = useState(true);
  const [city, setCity] = useState("");
  const [zone, setZone] = useState("");
  const [address, setAddress] = useState("");
  const [time,setTime]=useState("");
  const [date,setDate]=useState("");
  const today = new Date().toLocaleDateString("en-CA");


  useEffect(() => {
    const pid = searchParams.get("pid");
    if (!accessToken) {
      history.push(Routes.HOME);
    } else {
      if (!propertyId) {
        history.push(Routes.PROPERTIES);
      } else if (pid !== propertyId) {
        history.push(`${Routes.SET_REGISTRATION_DATE}?pid=${propertyId}`);
      }
    }
  }, [accessToken, history, propertyId, searchParams]);

  const getCurrentValues = useCallback((values) => {
    getPropertyDetails(propertyId).then(res => {
      setIsLoading(false);
      if(res?.data?.propertyData?.lawyer) {
        let lawyerObj = res?.data?.propertyData?.lawyer;
        setCity(lawyerObj?.registration_city || '');
        setZone(lawyerObj?.registration_zone || '');
        setAddress(lawyerObj?.registration_address || '');
        let dateFormatted = '';
        if(lawyerObj?.registration_date) {
          dateFormatted = new Date(lawyerObj?.registration_date).toISOString().split('T')[0];
        }
        setDate(dateFormatted);
        setTime(lawyerObj?.registration_time || '');
      }
    }).catch(err => {
      setIsLoading(false);
    })
  }, [propertyId])

  useEffect(() => {
    if(propertyId) {
     getCurrentValues();
    }
  }, [getCurrentValues, propertyId]);


  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const handleZoneChange = (e) => {
    setZone(e.target.value);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const submit = async() => {
    let final_date_with_day=`${new Date(date).toLocaleDateString("en-CA",{ weekday: 'short' })},${date}`;
    let payload ={
      id:propertyId,
      lawyer: {
        registration_date: final_date_with_day,
        registration_time: time,
        registration_city: city,
        registration_zone: zone,
        registration_address: address
      }
    };
    let response = await updateProperty(payload)
    if (response.status === 200) {
      getCurrentValues();
			addToast(getSuccessMessage('S-10027'), {
				appearance: SUCCESS_KEY,
				autoDismiss: true,
			});
      const historyData = historyFormatter(user, user._id, propertyId, 'has updated the registration date.');
      addPropertyHistory(historyData);
			if (getSubStage(19) === selectedProperty.sub_current_stage)
				await getSubStageUpdated(propertyId, getSubStage(20));
		}else{
      addToast(`${response.data.message}`, {
				appearance: ERROR_KEY,
				autoDismiss: true,
			});

    }
  };

  const getSubStageUpdated = async (propertyId, substage)=>{
    try{
      const response = await updateSubStage({propertyId, substageId: substage, loggedInUserId:userId});
        if(response.status == 200){
          dispatch(updateSelectedProperty(response.data.propertyData));
          addToast(getSuccessMessage('S-10006'), { appearance: SUCCESS_KEY, autoDismiss: true });
          history.push(Routes.PROPERTIES)
        }
      else addToast(getErrorMessage('E-10070'), { appearance: ERROR_KEY, autoDismiss: true });

      }catch(error){
        addToast(getErrorMessage('E-10111'), { appearance: ERROR_KEY, autoDismiss: true });
      }
  }

  if(isLoading) {
    return <Loader />
  }

  return (
    <div>
      <Main showUserNavbar={true} role={role}>
        <div className="container">
          <div className="fee-container">
            <div className="inner-container mr-5 pr-5">
              <Typography
                variant="h5"
                component="h5"
                align="center"
                sx={{ color: "var(--primaryColor)", mb: 10 }}
              >
                Set Registration Date
              </Typography>
              <div className="row mb-3">
                <Col className=" col-md-4">
                  <Form.Label className="black-text">City</Form.Label>
                  <Form.Select
                    onChange={handleCityChange}
                    value={city}
                    className="theme-border"
                  >
                    <option value="">Select City</option>
                    <option value="Bangalore">Bangalore</option>
                  </Form.Select>
                </Col>
                <Col className=" col-md-4">
                  <Form.Label className="black-text">Zone</Form.Label>
                  <Form.Select
                    onChange={handleZoneChange}
                    value={zone}
                    className="theme-border"
                  >
                    {zoneOptions.map((option,index) => (
                      <option key={index} value={option.label}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col className=" col-md-4">
                  <Form.Label className="black-text">Address</Form.Label>
                  <Form.Select
                    onChange={handleAddressChange}
                    value={address}
                    className="theme-border"
                  >
                    {addressOptions.map((option,index) => (
                      <option key={index} value={option.label}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </div>
              <div className="row mb-3">
                <Col>
                  <Form.Label className="black-text">Date</Form.Label>
                  <Form.Control
                    onChange={(e)=>setDate(e.target.value)}
                    type="date"
                    placeholder="Select date..."
                    className="theme-border"
                    min={today}
                    value={date}
                  />
                </Col>
                <Col>
                  <Form.Label className="black-text">Time</Form.Label>
                  <Form.Control
                    onChange={(e)=>setTime(e.target.value)}
                    type="time"
                    placeholder="Select time..."
                    className="theme-border"
                    value={time}
                  />
                </Col>
              </div>
              <Button
                variant="contained"
                type="submit"
                style={{
                  backgroundColor: "var(--primaryButton)",
                  color: "var(--white)",
                  textTransform: "capitalize",
                }}
                onClick={() => submit()}
              >
                Notify All Users
              </Button>
            </div>
          </div>
        </div>
      </Main>
    </div>
  );
}

export default SetRegistrationDate;
