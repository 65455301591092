export const SUCCESS_KEY = "success";
export const ERROR_KEY = 'error';
export const INFO_KEY = 'info';
export const ACCESS_TOKEN_KEY ='token';
export const PID_KEY = 'pid'; //to get the key from search params
export const ROLE_KEY = 'role';
export const USER_KEY = 'userByEmail';
export const ADMIN_KEY = 'adminByEmail';
export const SUPER_ADMIN_KEY = 'superAdminByEmail';
export const PROPERTY_ID_KEY = 'propertyID'; // to get the id from property data
export const RENTAL_AGREEMENT_KEY = 'rentalAgreement';  // to get session data from local storage
