import React,{ useRef, useState, useEffect } from "react";
import MOU from "../../../../src/assets/mou 2.png"
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { FreeMode, Navigation, Pagination, A11y } from "swiper";
import { getTemplateByName, propertyImageGet } from "../../../services/api";
import { useToasts } from "react-toast-notifications";

import './../../super-admin-manageProperties/style.scss';
import { Modal } from "react-bootstrap";
import Loader from "../../../layout/Loader/Loader";
import { ERROR_KEY, defaultValues } from "../../../constants";

SwiperCore.use([FreeMode, Navigation, Pagination, A11y]);

const AgreementsData = ({propertyId}) => {
    const swiperRefAgreement = useRef(null);
    const [openDialog , setOpenDialog] = useState(false);
    const [document , setDocument] = useState(false);
    const [documentList, setDocumentList] = useState([]);
    const [ selectPDF , setSelectPDF ] = useState("");
    const [ isLoading , setIsLoading ] = useState(false);
    
    const { addToast } = useToasts();
    const logoPath = 
        [ MOU , "/imgs/saleAg.png", "/imgs/saleDe.png", "/imgs/undrawreg.png"];
    
    const showDocument = async (docName) => {
        setDocument(docName.template_name);
        const name = `${docName.template_type}_${docName.template_subtype}`;
        const payload = { 
            propertyId : propertyId, docName : name 
        }; 
        setIsLoading(true);
        propertyImageGet(payload)
        .then(value => {
            setOpenDialog(true);
            setSelectPDF(value.data.data[0].url)
            setIsLoading(false);
        })
        .catch(error => {
            setIsLoading(false);
            addToast(error.response.data.message, { appearance:ERROR_KEY, autoDismiss: true });
        });
    }

    useEffect(() => {
        getTemplateByName(defaultValues.doc_types.document).then(list => {
            let docLi = list.data.data;
            // below two line code for exchange position of Offer letter and MOU
            const elementToMove = docLi.splice(1, 1)[0];
            docLi.splice(0, 0, elementToMove);

            setDocumentList(docLi);
        })
        .catch(error => {
            addToast(error.response.data.message, {appearance: ERROR_KEY, autoDismiss: true});            
        });
                
    },[propertyId]);

    const displayHeight=window.innerHeight*0.8;
    const displayWidth="100%";
    const dimensions={height:displayHeight,width:displayWidth}; 
    return (
        <>
          <div>
            <div className="property-doc">
                <h4>Document Agreements</h4>
            </div>
            <div className="template-addProp">
                <div className="property-button">
                </div>
                <Swiper
                    freeMode={true}
                    spaceBetween={50}
                    slidesPerView={4}
                    modules={[FreeMode, Navigation, Pagination, A11y]}
                    onSwiper={(swiper) => {
                        swiperRefAgreement.current = swiper;
                    }}>
                    {documentList &&(
                        documentList?.map((doc,index) => (
                            <SwiperSlide onClick={() => showDocument(doc)} className="agreement-spacing">
                                <div className="templateItems swiper-slide-color" >
                                    <img className="mb-2" src={logoPath[index]} alt={doc.template_name} />
                                    <h4>{doc.template_name}</h4>
                                </div>
                            </SwiperSlide>
                         ))
                       )}
                </Swiper>
            </div>
          </div>
        <Modal size="lg" show={openDialog}
            onHide={() => {setOpenDialog(false); setDocument("")}}
            aria-labelledby="example-modal-sizes-title-lg"
            className='propertyData'>
            <Modal.Header closeButton className='property-modal'>                
            <span className="black-text fw-bold">{document}</span>
            </Modal.Header>         
            <Modal.Body className=''>
                { isLoading
                    ? <Loader />
                    : <object style={dimensions} data={selectPDF}></object>
                }
            </Modal.Body>
            
        </Modal>
  
        </>
    )

}
export default AgreementsData;