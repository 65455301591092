import React from "react";
import "./style.scss";
import { Button, Box } from "@mui/material";
import { LinkedIn, Instagram, Facebook } from "@mui/icons-material";
import { API } from "../../utils/api";
import logo from "../../assets/logo_new_removebg.png";

function LandingFooter() {
  return (
    <footer className="foot-section">
      <div className="col-xl-10 col-lg-12 mx-auto row">
        <Box className = "box-container">
          <img
            width="240rem"
            height="60rem"
            src={logo}
            className="attachment-full size-full wp-image-1786"
            alt=""
            loading="lazy"
            srcSet={API.footerUrl.logo_srcset}
            sizes="(max-width: 400px) 100vw, 471px"
          ></img>
          <span className="text-line">
            A Hassle-Free Experience in Your Real Estate Journey
          </span>
          <div className="socialmedia-button-container">
            <Button href={API.footerUrl.linkedin}>
              <LinkedIn color="primary" />
            </Button>
            <Button href={API.footerUrl.instagram}>
              <Instagram color="primary" />
            </Button>
            <Button href={API.footerUrl.facebook}>
              <Facebook color="primary" />
            </Button>
          </div>
        </Box>

        <Box>
          <ul className="footer-menu-title">
            <li>Support</li>
          </ul>
          <ul className="footer-menu-links">
            <li>
              <a href={API.footerUrl.home}>
                Home
              </a>
            </li>
            <li>
              <a href={API.footerUrl.about_us}>
                About Us
              </a>
            </li>
            <li>
              <a href={API.footerUrl.FAQs}>
                FAQs
              </a>
            </li>
            <li>
              <a href={API.footerUrl.contact}>
                Contact
              </a>
            </li>
            <li>
              <a href={API.footerUrl.userGuide}>
                User Guide
              </a>
            </li>
          </ul>
        </Box>

        <Box>
          <ul className="footer-menu-title">
            <li>Explore</li>
          </ul>
          <ul className="footer-menu-links">
            <li>
              <a href={API.footerUrl.privacy}>
                Privacy Policy
              </a>
            </li>
            <li>
              <a href={API.footerUrl.terms_condition}>
                Terms & Conditions
              </a>
            </li>
          </ul>
        </Box>

        <Box>
          <ul className="footer-menu-title">Visit Us</ul>
          <ul className="footer-menu-links">
            <li>
              {" "}
              <i className="fa fa-phone" aria-hidden="true"></i>
              <a href={API.footerUrl.telephone_url}>Phone: {API.footerUrl.telephone_number}</a>
            </li>
            <li>
              <i className="fa fa-envelope" aria-hidden="true"></i>
              <a href={API.footerUrl.email_url}>{API.footerUrl.email}</a>
            </li>
            <li></li>
          </ul>
        </Box>
      </div>
      <div className="copyright-notice">
        &copy; Zerochaos 2023 | All Rights Reserved
      </div>
    </footer>
  );
}

export default LandingFooter;
