import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useToasts } from "react-toast-notifications";
import { useFormik } from "formik";
import {
  TextField,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  InputAdornment,
} from "@mui/material";
import { editPropertySchema1 } from "../../../utils/validationSchemas";
import Loader from "../../Loader/Loader";
import { addPropertyHistory, updateProperty } from "../../../services/api";
import { addSelectedProperty } from "../../../pages/user-home/slices";
import { getUserId } from "../../../utils/HelperFunction";
import { ERROR_KEY, SUCCESS_KEY, defaultValues, getSuccessMessage } from "../../../constants";

const EditProperty = ({
  isEditModal,
  setIsEditModal,
  data,
  callBack,
  name,
}) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.loggedInUser);
  const role = useSelector((state) => state.loggedInUser.preferredRole);
  const [loading, setLoading] = useState(true);
  const getLoggedInUserName = () => {
    if (role === defaultValues.role_names.super_admin) {
      return loggedInUser.data?.data?.superAdminByEmail?.first_name;
    } else if (role === defaultValues.role_names.admin) {
      return loggedInUser.data?.data?.adminByEmail?.first_name;
    } else if (
      role === defaultValues.role_names.seller ||
      role === defaultValues.role_names.buyer ||
      role === defaultValues.role_names.lawyer ||
      role === defaultValues.role_names.bank_agent ||
      role === defaultValues.role_names.buyer_agent ||
      role === defaultValues.role_names.seller_agent ||
      role === defaultValues.role_names.buyer_poa ||
      role === defaultValues.role_names.seller_poa ||
      role === defaultValues.role_names.ca
    ) {
      return loggedInUser.data?.data?.userByEmail?.first_name;
    }
    return "";
  };

  useEffect(() => {
    setLoading(false);
  }, [data]);

  const {
    resetForm,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isValid,
  } = useFormik({
    initialValues: data,
    validationSchema: editPropertySchema1,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      submitEdit(values);
      action.resetForm();
    },
  });

  const submitEdit = async (values) => {
      let his = [];
      for (const item in data) {
        if (data.hasOwnProperty(item)) {
          if (data[item] !== values[item]) {
            his.push(`${item} updated to ${values[item]}`);
          }
        }
      }
      const addressData = [{
        "address_line1": values.address_line1,
        "address_line2": values.address_line2,
        "city": values.city,
        "zipCode": values.zipCode,
      }];
      values.address = addressData;
      delete values.address_line1;
      delete values.address_line2;
      delete values.city;
      delete values.zipCode;
      values.car_park = values.car_park.toString();
      values.flat_no = values.flat_no.toString();
      values.bhk = values.bhk.toString();
      values.id = values._id;
      values.floor_no = values?.floor_no;
      values.no_of_floor = values?.no_of_floor;
      delete values._id;
      const historyData = {
        activity: his.join(", "),
        name : getLoggedInUserName(),
        activity_by : getUserId(loggedInUser),
        property_id : values.id,
      }
      await addPropertyHistory(historyData);
      
      if (role === defaultValues.role_names.super_admin) {
        values.user_id = `${loggedInUser.data?.data?.superAdminByEmail?._id}`;
      } else if (role === defaultValues.role_names.admin) {
        values.user_id = `${loggedInUser.data?.data?.adminByEmail?._id}`;
      } else if (role === defaultValues.role_names.seller || role === defaultValues.role_names.buyer || role === defaultValues.role_names.lawyer || 
      role === defaultValues.role_names.bank_agent ||
      role === defaultValues.role_names.buyer_agent ||
      role === defaultValues.role_names.seller_agent ||
      role === defaultValues.role_names.buyer_poa ||
      role === defaultValues.role_names.seller_poa ||
      role === defaultValues.role_names.ca) {
        values.user_id = `${loggedInUser.data?.data?.userByEmail?._id}`;
      }
      updateProperty(values).then(res => {
        dispatch(addSelectedProperty(res.data.propertyData));
        addToast(getSuccessMessage('S-10006'), {
          appearance: SUCCESS_KEY,
          autoDismiss: true,
        });
        setIsEditModal(!isEditModal);
        callBack();
        resetForm()
      }).catch(err => {
        addToast(errors.response.data.message, { appearance: ERROR_KEY, autoDismiss: true });
        setIsEditModal(!isEditModal);
        callBack();
      })
  };

  const isPentHouse = data?.property_type === defaultValues.property_types.pent_house;
  const villa = data?.property_type === defaultValues.property_types.villa

  return (
    <>
      <Modal
        size="lg"
        show={isEditModal}
        onHide={() => setIsEditModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="editProperty"
      >
        <Modal.Header closeButton className="edit-modal">
          <Modal.Title
            id="example-modal-sizes-title-lg"
            className="text-center w-100 text-light"
          >
            Edit Property Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="edit-modal">
          {
            loading ? <Loader /> : (
            <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="Id">
                      <TextField
                        id="ID no"
                        label="ID no"
                        variant="outlined"
                        type="text"
                        className={"inputBox fieldWidth"}
                        disabled
                        value={values?.re_id}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="FlatNo">
                      {!villa &&(
                      <TextField
                        id="flat_no"
                        name="flat_no"
                        label="Flat No"
                        variant="outlined"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.flat_no}
                        className={"inputBox fieldWidth"}
                      />
                      )}
                       {villa &&(
                      <TextField
                        id="villNo"
                        name="villNo"
                        label="Villa No./House No"
                        variant="outlined"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.flat_no}
                        className={"inputBox fieldWidth"}
                      />
                      )}
                      {touched.flat_no && errors.flat_no && (
                        <p className="formError">{errors.flat_no}</p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="FloorNo">
                    {!villa &&(
                      <TextField
                        id="floor_no"
                        name="floor_no"
                        label="Floor No"
                        variant="outlined"
                        type="number"
                        className="inputBox fieldWidth"

                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.floor_no}
                      />
                    )}
                       {villa &&(
                      <TextField
                        id="plotArea"
                        name="plotArea"
                        label="Plot/Land Area."
                        variant="outlined"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.plotArea}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <span className="whiteText">Sq.ft</span>
                            </InputAdornment>
                          ),
                        }}
                        className={"inputBox fieldWidth"}
                      />
                      )}
                      {touched.plotArea && errors.plotArea && (
                        <p className="formError">{errors.plotArea}</p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="Door Facing Direction">
                      <FormControl
                        variant="outlined"
                        className="InputBox fieldWidth"
                      >
                        <InputLabel id="Door Facing Direction">
                          Door Facing Direction
                        </InputLabel>
                        <Select
                          labelId="Door-Facing-Direction"
                          id="Door-Facing-Direction"
                          name="door_facing_direction"
                          label="Door Facing Direction"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.door_facing_direction}
                        >
                          <MenuItem value="South">South</MenuItem>
                          <MenuItem value="North">North</MenuItem>
                          <MenuItem value="East">East</MenuItem>
                          <MenuItem value="West">West</MenuItem>
                        </Select>
                      </FormControl>
                      {touched.door_facing_direction && errors.door_facing_direction && (
                        <p className="formError">{errors.door_facing_direction}</p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="SuperBuiltUpArea">
                      <TextField
                        id="Super BuiltUp Area "
                        label="Super Built Up Area "
                        variant="outlined"
                        type="number"
                        name="super_built_up_area"
                        className="InputBox fieldWidth"
                        value={values?.super_built_up_area}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <span className="whiteText">Sq.ft</span>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {touched.super_built_up_area && errors.super_built_up_area && (
                        <p className="formError">{errors.super_built_up_area}</p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="Bhk">
                      <FormControl
                        variant="outlined"
                        className="InputBox fieldWidth"
                      >
                        <InputLabel id="bhk-label">Bhk</InputLabel>
                        <Select
                          labelId="bhk-label"
                          id="bhk-label"
                          label="Bhk"
                          name="bhk"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.bhk}
                        >
                          <MenuItem value="1">1</MenuItem>
                          <MenuItem value="2">2</MenuItem>
                          <MenuItem value="3">3</MenuItem>
                          <MenuItem value="4">4</MenuItem>
                        </Select>
                      </FormControl>
                      {touched.bhk && errors.bhk && (
                        <p className="formError">{errors.bhk}</p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="CarParking">
                      <FormControl
                        variant="outlined"
                        className="InputBox fieldWidth"
                      >
                        <InputLabel id="car-parking-label">Car Parking</InputLabel>
                        <Select
                          labelId="car-parking-label"
                          id="car-parking-label"
                          label="Car Parking"
                          name="car_park"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.car_park}
                        >
                          <MenuItem value="1">1</MenuItem>
                          <MenuItem value="2">2</MenuItem>
                          <MenuItem value="3">3</MenuItem>
                          <MenuItem value="4">4</MenuItem>
                        </Select>
                      </FormControl>
                      {touched.car_park && errors.car_park && (
                        <p className="formError">{errors.car_park}</p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group className="mb-3" controlId="CarParkType">
                    <TextField
                      name="carParkType"
                      id="carParkType"
                      label="Car Park Type"
                      variant="outlined"
                      type="text"
                      autoComplete="off"
                      value={values.carParkType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="inputBox fieldWidth"
                    />
                  </Form.Group>
                  </Col>
                </Row>
                <Row>
                 {!villa && (
                    <Col>
                      <Form.Group className="mb-3" controlId="No of Floor">
                        <TextField
                          id="No of Floor"
                          label="No of Floor"
                          variant="outlined"
                          type="number"
                          className="inputBox fieldWidth"
                          name="no_of_floor"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.no_of_floor}
                        />
                        {touched.no_of_floor && errors.no_of_floor && (
                          <p className="formError">{errors.no_of_floor}</p>
                        )}
                      </Form.Group>
                    </Col>
                  )}
                  <Col>
                    <Form.Group className="mb-3" controlId="Status">
                      <FormControl
                        variant="outlined"
                        className="InputBox fieldWidth"
                      >
                        <InputLabel id="furnishing-status-label">Status</InputLabel>
                        <Select
                          labelId="furnishing-status-label"
                          id="furnishing-status"
                          label="Status"
                          name="furnishing_status"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.furnishing_status}
                        >
                          <MenuItem value="Furnished">Furnished</MenuItem>
                          <MenuItem value="Unfurnished">Unfurnished</MenuItem>
                          <MenuItem value="Semi Furnished">Semi Furnished</MenuItem>
                        </Select>
                      </FormControl>
                      {touched.furnishing_status && errors.furnishing_status && (
                        <p className="formError">{errors.furnishing_status}</p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="SocietyName">
                      <TextField
                        id="Society Name "
                        label="Society Name "
                        variant="outlined"
                        type="text"
                        className="inputBox fieldWidth"
                        name="society_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.society_name}
                      />
                      {touched.society_name && errors.society_name && (
                        <p className="formError">{errors.society_name}</p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="BuilderName">
                      <TextField
                        id="Builder Name "
                        label="Builder Name "
                        variant="outlined"
                        type="text"
                        className="InputBox fieldWidth"
                        name="developer_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.developer_name}
                      />
                      {touched.developer_name && errors.developer_name && (
                        <p className="formError">{errors.developer_name}</p>
                      )}
                    </Form.Group>
                  </Col>
                  
                </Row>
                <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="Address1">
                      <TextField
                        id="Address 1 "
                        label="Address 1 "
                        variant="outlined"
                        type="text"
                        className="InputBox fieldWidth"
                        name="address_line1"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.address_line1}
                      />
                      {touched.address_line1 && errors.address_line1 && (
                        <p className="formError">{errors.address_line1}</p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="Address2">
                      <TextField
                        id="Address 2 "
                        label="Address 2 "
                        variant="outlined"
                        type="text"
                        className="InputBox fieldWidth"
                        name="address_line2"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.address_line2}
                      />
                      {touched.address_line2 && errors.address_line2 && (
                        <p className="formError">{errors.address_line2}</p>
                      )}
                    </Form.Group>
                  </Col>
                  
                </Row>
                <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="City">
                    <Select
                        name="city"
                        id="City "
                        label="Select City"
                        variant="outlined"
                        className="InputBox fieldWidth"
                        value={values?.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <MenuItem value="bangalore">Bangalore</MenuItem>
                      </Select>
                      {touched.city && errors.city && (
                        <p className="formError">{errors.city}</p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="PinCode">
                      <TextField
                        id="Pin Code"
                        label="Pin Code"
                        variant="outlined"
                        type="number"
                        className="InputBox fieldWidth"
                        name="zipCode"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.zipCode}
                      />
                      {touched.zipCode && errors.zipCode && (
                        <p className="formError">{errors.zipCode}</p>
                      )}
                    </Form.Group>
                  </Col>
                  
                </Row>
                
                {isPentHouse && (
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="Id">
                        <TextField
                          id="terrace_area"
                          label="Terrace Area"
                          variant="outlined"
                          type="number"
                          className={"inputBox fieldWidth"}
                          name="terrace_area"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.terrace_area}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span className="whiteText">Sq.ft</span>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {touched.terrace_area && errors.terrace_area && (
                          <p className="formError">{errors.terrace_area}</p>
                        )}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="FlatNo">
                        <TextField
                          id="additional_room"
                          label="Additional Room"
                          variant="outlined"
                          type="number"
                          className={"inputBox fieldWidth"}
                          name="additional_room"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.additional_room}
                        />
                        {touched.additional_room && errors.additional_room && (
                          <p className="formError">{errors.additional_room}</p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col className="text-center pt-5">
                    <Button 
                    variant="px-5" 
                    onClick={() => submitEdit(values)} 
                    disabled={!isValid}
                    className="button"
                    type="submit"
                    style={{background: 'var(--primaryButton)'}}
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </Form>
            )
          }
          
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditProperty;
