import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/material";

export const Dropdown = ({ 
  options, handleBlur, value, onChange, placeholder, id, disabled, rest
 }) => {
  
  return (
    <Autocomplete
      disabled={disabled}
      className="fee-inputs-multiple mb-1"
      name={id}
      id={id}
      size="small"
      sx={{
        minWidth: "200px",
        maxHeight: "37px",
        backgroundColor: "white"
      }}
      options={options}
      onBlur={handleBlur}
      onChange={onChange}
      value={value}
      autoHighlight
      getOptionLabel={(option) => option}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option}
        </Box>
      )}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            placeholder={placeholder}
            inputProps={{ ...params.inputProps }}
          />
        );
      }}
      {...rest}
    />
  );
}
