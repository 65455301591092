import React, { useCallback } from "react";
import Navigation from '../Navigation';
// import logo from '../../assets/logo.svg';
import logo from '../../assets/logo_new.png';
import picIcon from '../../assets/pic-icon.svg';
import { useSelector } from "react-redux";
import './style.scss';
import StepperComponenet from "../../commonComponent/Stepper";
import { useHistory } from "react-router-dom";
import { Routes } from "../../constants/routes";

function Page(props) {
  const history = useHistory();

  const {
    role, allAccessibleRoles, handleSwitch, showStages
  } = props;

  const stages = useSelector((state) => state?.stages?.stages) || [];
  const propertyCurrentStage = useSelector((state) => state?.buyerHomeData?.selectedProperty?.current_stage);
  const profileImage = useSelector((state) => state?.profileImage?.image);
  const propertyData = useSelector((state) => state?.buyerHomeData?.selectedProperty);
  const getStageId = (id) => stages.filter(stage => stage._id === id)[0]?.order_number;
  
  const getStages = useCallback(()=> {
	let li =[];
	const backStage = getStageId(propertyData?.backout?.stageId) || 7;
	stages.forEach((item, index) => {
		if(item.order_number === 7 && getStageId(propertyCurrentStage?._id) === 7){
			li.push(item);
		}
		else if(item.order_number < 7 && item.order_number <= backStage) {
			li.push(item);			
		}
	});
	return li;	

  },[propertyCurrentStage]);
  
  const getActiveStep = useCallback(() => {
    let activeStep = 0;
    stages.forEach((stage, index) => {
      if (stage._id === propertyCurrentStage || stage._id === propertyCurrentStage?._id) {
        activeStep = index
      }
    });
    return activeStep
  },[propertyCurrentStage, stages]);

  const dashboard = () => {
    history.push(Routes.DASHBOARD);
    window.scrollTo(0, 0);
  }

  const profileImageInlineStyle = {
    backgroundImage: `url(${profileImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '150px',
    height: '150px',
    borderRadius:'50%'
  };

  return (
		<div className="sidebar">
			{props?.showLogo && (
				<>
					<img src={logo} alt="Logo" style={{width: '100%'}} onClick={dashboard} />

					<div className="imagecontainer"  >
						{profileImage ? (
							<div className="pic_wrapper" style={profileImageInlineStyle} onClick={dashboard}/>
						) : (
							<div className="pic_wrapper">
								<img  src={picIcon} alt="Profile Icon" onClick={dashboard} />
							</div>
						)}
					</div>

					<div className="name-text" onClick={dashboard}>
						<div>{role}</div>
						{allAccessibleRoles && allAccessibleRoles.length > 1 && (
							<div className="switch" onClick={handleSwitch}>
								Switch Role
							</div>
						)}
					</div>
				</>
			)}
			{showStages ? (
				<div className="stageContainer">
					<div className="stageWrapper">
						<div className="stageHead">Property Stage</div>
						<div className="stepperWrapper">
							<StepperComponenet
								boxStyle={{ width: "100%" }}
								steps={getStages()}
								activeStep={getActiveStep()}
								component={"stages"}
							/>
						</div>
					</div>
				</div>
			) : (
				<Navigation {...props} />
			)}
		</div>
	);
}

export default Page;
