import * as Yup from "yup";
import { defaultValues, getErrorMessage } from "../constants";

let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
let patternDigitsAndComma = /^[\d,]+$/;
let onlyNumber = /^[0-9]+$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;

export const loginSchema = Yup.object({
    selectRole: Yup.string().required(getErrorMessage('E-10219')),
    first_name: Yup.string().required(getErrorMessage('E-10034')),
    last_name: Yup.string().required(getErrorMessage('E-10035')),
    email: Yup
      .string()
      .email(getErrorMessage('E-10008'))
      .required(getErrorMessage('E-10029')),
      phone: Yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .test(
      "phone",
      getErrorMessage('E-10220'),
      (value) => value && value.toString().length === 10)
      .nullable()
      .required(getErrorMessage('E-10026')),
    password: Yup.string().required(getErrorMessage('E-10063')).min(6),
  });

  export const settingsSchema = Yup.object({
    first_name: Yup.string().required(getErrorMessage('E-10034')).matches(/^[aA-zZ\s]+$/, getErrorMessage("E-10074")),
    last_name: Yup.string().required(getErrorMessage('E-10035')).matches(/^[aA-zZ\s]+$/, getErrorMessage("E-10074")),
    address: Yup.string().required(getErrorMessage("E-10041")),
    address2: Yup.string().required(getErrorMessage("E-10041")),
    pin_code: Yup.string().required(getErrorMessage("E-10042")).matches( /^(\d{6})$/, getErrorMessage("E-10018")),
    city: Yup.string().required(getErrorMessage("E-10043")),
    state: Yup.string().required(getErrorMessage("E-10045")),
    account_name: Yup.string().required(getErrorMessage("E-10254")),
    branch_name: Yup.string().required(getErrorMessage("E-10255")),
    bank_name: Yup.string().required(getErrorMessage("E-10049")),
    father_name: Yup.string().required(getErrorMessage("E-10256")).matches(/^[aA-zZ\s]+$/, getErrorMessage("E-10074")),
    gender: Yup.string().required(getErrorMessage("E-10036")),
    // aadhaar: Yup.string().matches(/^[0-9]{12}$/, getErrorMessage("E-10257")).required(getErrorMessage("E-10058")),
    aadhaar: Yup.string().matches(/^([0-9]{4}[0-9]{4}[0-9]{4}$)|([0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|([0-9]{4}-[0-9]{4}-[0-9]{4}$)/, getErrorMessage("E-10257")).required(getErrorMessage("E-10058")),
    account_number: Yup.string()
    .matches(/^[0-9]{9,18}$/, "Account number must be between 9 to 18 digits")
    .required("Enter your Account No."),
    ifsc_code: Yup.string()
    .matches(/^([A-Z]{4}[0][A-Z0-9]{6})$/, "Invalid IFSC code").required("IFSC code is required"), 
     pan: Yup.string().matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, "Invalid PAN number"),
     phone: Yup
     .number()
     .transform((value) => (isNaN(value) ? undefined : value))
     .test(
     "phone",
     getErrorMessage('E-10220'),
     (value) => value && value.toString().length === 10)
     .nullable()
     .required(getErrorMessage('E-10026')),
    
  dob: Yup.string()
  .required("Date of birth is required")
  .test("minimumAge", "Person should be 18 years or above", function (value) {
    const selectedDate = new Date(value);
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 18);
    
    return selectedDate <= minDate;
  }),

  });

  export const manageProfile = Yup.object({
    first_name: Yup.string().required(getErrorMessage('E-10034')),
    last_name: Yup.string().required(getErrorMessage('E-10035')),
    phone: Yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .test(
      "phone",
      getErrorMessage('E-10220'),
      (value) => value && value.toString().length === 10)
      .nullable()
      .required(getErrorMessage('E-10026')),    
  });

export const addPropertySchema1 = Yup.object().shape({
  role : Yup.string(),
  property_type: Yup.string().required("Property Type required"),
  flat_no: Yup.string().required("Flat No required"),
  super_built_up_area: Yup
    .string()
    .matches(patternTwoDigisAfterComma, "Area can have max of 2 two digits after decimal point")
    .required("Super Built-Up Area required"),
    
  door_facing_direction: Yup
    .string()
    .required("Door Facing Direction required"),
  car_park: Yup.string().required("Car Parking required"),
  bhk: Yup.string().required("BHK required"),
  furnishing_status: Yup.string().required("Furnishing Status required"),
  floor_no: Yup
      .string()
      .when("property_type", {
        is : value => value !== defaultValues.property_types.villa,
        then: () => Yup.string()
          .matches(onlyNumber, getErrorMessage("E-10084"))
          .required("Floor No required"),        
      }),
  no_of_floor: Yup
    .string()
    .when("property_type", {
      is : value => value !== defaultValues.property_types.villa,
      then: () => Yup.string()
        .matches(onlyNumber, getErrorMessage("E-10084"))
        .required("Maximum Floor in Society required")
        .test(
          "isGreaterThan",
          "Floor No. can not exceed Max Floors in Society",
          function (value) {
            const floorNo = Number(this.resolve(Yup.ref("floor_no")));    
            if (!isNaN(floorNo) && !isNaN(Number(value))) {
              return Number(value) >= floorNo;
            }    
            return true;
          }
        ),        
    }),
  society_name: Yup.string().required("Society Name required"),
  address_line1: Yup.string().required("Address Line-1 required"),
  city: Yup.string().required('City Name required'),
  zipCode: Yup
    .string()
    .required(getErrorMessage("E-10042"))
    .matches(onlyNumber, getErrorMessage("E-10084"))
    .test(
      "Pin Code",
      getErrorMessage("E-10259"),
      (val) => val && val.toString().length === 6
    ),
});

export const editPropertySchema1 = Yup.object().shape({
  role : Yup.string(),
  property_type: Yup.string().required("Property Type required"),
  flat_no: Yup.string().required("Flat No required"),
  super_built_up_area: Yup
    .string()
    .matches(patternTwoDigisAfterComma, "Area can have max of 2 two digits after decimal point")
    .required("Super Built-Up Area required"),
    
  door_facing_direction: Yup
    .string()
    .required("Door Facing Direction required"),
  car_park: Yup.string().required("Car Parking required"),
  bhk: Yup.string().required("BHK required"),
  furnishing_status: Yup.string().required("Furnishing Status required"),
  floor_no: Yup
      .string()
      .when("property_type", {
        is : value => value !== defaultValues.property_types.villa,
        then: () => Yup.string()
          .matches(onlyNumber, getErrorMessage("E-10084"))
          .required("Floor No required"),        
      }),
  no_of_floor: Yup
    .string()
    .when("property_type", {
      is : value => value !== defaultValues.property_types.villa,
      then: () => Yup.string()
        .matches(onlyNumber, getErrorMessage("E-10084"))
        .required("Maximum Floor in Society required")
        .test(
          "isGreaterThan",
          "Floor No. can not exceed Max Floors in Society",
          function (value) {
            const floorNo = Number(this.resolve(Yup.ref("floor_no")));    
            if (!isNaN(floorNo) && !isNaN(Number(value))) {
              return Number(value) >= floorNo;
            }    
            return true;
          }
        ),        
    }),
  society_name: Yup.string().required("Society Name required"),
  address_line1: Yup.string().required("Address Line-1 required"),
  city: Yup.string().required('City Name required'),
  zipCode: Yup
    .string()
    .required("Pin Code required")
    .matches(onlyNumber, getErrorMessage("E-10084"))
    .test(
      "Pin Code",
      getErrorMessage("E-10259"),
      (val) => val && val.toString().length === 6
    ),
    price : Yup.string().required("Property Price required").matches(onlyNumber, "It much be number only"),
});

export const addPropertySchema2 = Yup.object().shape({
  price : Yup.string().required("Property Price required").matches(patternTwoDigisAfterComma, "It must be number only"),
  khata_number : Yup.string().required("Khata Number required"),
  registration_number : Yup.string(),
  no_of_owners: Yup.string()
  .matches(onlyNumber, getErrorMessage("E-10084"))
  .test('maxSellers', 'Maximum 4 sellers allowed', value => parseInt(value, 10) <= 4)
  .required("No. Of Sellers is required"),
  no_of_buyers: Yup.string()
  .matches(onlyNumber, getErrorMessage("E-10084"))
  .test('maxBuyers', 'Maximum 4 buyers allowed', value => parseInt(value, 10) <= 4)
  .required("No. Of Buyers is required"),
  survey_number : Yup.string(),
  usp : Yup.string(),
  schedule_number : Yup.string(),
  cd_number : Yup.string(),
  sale_deed_number: Yup.string("Is Sale Deed Number required"),
  rented : Yup.string().required("Is Rented required"),
  tenant_name : Yup.string().when("rented", 
    { is : "Yes", then: () => Yup.string().required(getErrorMessage("E-10099")) }
  ),
  tenant_email : Yup.string().when('rented', {
    is: 'Yes',
    then: () => Yup.string()
      .matches(emailRegex, getErrorMessage("E-10008"))    
      .required(getErrorMessage("E-10100")),
  }),
  tenant_phone : Yup.string().matches(onlyNumber, getErrorMessage("E-10084"))
    .when('rented', {
    is: 'Yes',
    then: () => Yup.string().test( "phone", getErrorMessage("E-10220"),
      (val) => val && val.toString().length === 10
    )
    .required(getErrorMessage("E-10101")),
  }),
});

export const editPropertySchema2 = Yup.object().shape({
  khata_number : Yup.string().required("Khata Number required"),
  registration_number : Yup.string(),
  no_of_owners: Yup.string()
  .matches(onlyNumber, getErrorMessage("E-10084"))
  .test('maxSellers', 'Maximum 4 sellers allowed', value => parseInt(value, 10) <= 4)
  .test('greaterThanZero', 'Number of Sellers must be greater than 0', value => parseInt(value, 10) > 0)
  .required("No. Of Sellers is required"),
  no_of_buyers: Yup.string()
  .matches(onlyNumber, getErrorMessage("E-10084"))
  .test('maxBuyers', 'Maximum 4 buyers allowed', value => parseInt(value, 10) <= 4)
  .test('greaterThanZero', 'Number of Buyers must be greater than 0', value => parseInt(value, 10) > 0)
  .required("No. Of Buyers is required"),
  usp : Yup.string(),
  schedule_number : Yup.string(),
  cd_number : Yup.string(),
  rented : Yup.string().required(getErrorMessage("E-10098")),
  tenant_name : Yup.string().when("rented", 
    { is : "Yes", then: () => Yup.string().required(getErrorMessage("E-10099")) }
  ),
  tenant_email : Yup.string().when('rented', {
    is: 'Yes',
    then: () => Yup.string()
      .matches(emailRegex, getErrorMessage("E-10008"))    
      .required(getErrorMessage("E-10100")),
  }),
  tenant_phone : Yup.string()
    .when('rented', {
    is: 'Yes',
    then: () => Yup.string().test( "phone", getErrorMessage("E-10220"),
      (val) => val && val.toString().length === 10
    ).matches(onlyNumber, getErrorMessage("E-10084"))
    .required(getErrorMessage("E-10101")),
  }),
});


export const addAdminSchema = (balShareValue) => Yup.object().shape({
  first_name : Yup.string().required("First Name Type required"),
  last_name : Yup.string().required(getErrorMessage('E-10035')),
  email_id :  Yup.string().matches(emailRegex, getErrorMessage("E-10008")).required(getErrorMessage("E-10258")),
  phone: Yup.string()
  .required(getErrorMessage(getErrorMessage('E-10026')))
  .when(
    "country_code", {
      is: (country_code) => country_code === "+91",
      then: () => Yup.number()
        .test("phone",
          getErrorMessage('E-10220'),
          (value) => value && value.toString().length === 10
        ),
      otherwise: () => Yup.number()
        .test("phone",
        getErrorMessage('E-10220'),
        (value) => value && value.toString().length > 5
      )
    }),
  select_role : Yup.string().required(getErrorMessage("E-10102")),
  percent_share : Yup.string()
  .when('select_role', {
    is: defaultValues.role_names.buyer || defaultValues.role_names.seller,
    then: () => Yup.number()
      .test("balance share", `You did not have balance share for add User`, val => balShareValue > 0)
      .test("percent share", `Must be less than ${balShareValue}`,
      (val) => {
        const numericValue = Number(val);
        return numericValue >= 0 && numericValue <= balShareValue;
      }
    )})
});


export const addUserSchema = Yup.object().shape({
  first_name : Yup.string().required(getErrorMessage('E-10034')),
  last_name : Yup.string().required(getErrorMessage('E-10035')),
  email_id :  Yup.string().matches(emailRegex, getErrorMessage("E-10008")).required(getErrorMessage("E-10258")),
  phone : Yup.string().matches(onlyNumber, getErrorMessage("E-10084"))
    .test( "phone", getErrorMessage("E-10220"),
    (val) => val && val.toString().length === 10
  ),
  primary_role : Yup.string().required(getErrorMessage("E-10102")),
});

export const addRentalParty = Yup.object().shape({
  name: Yup.string().required(getErrorMessage("E-10260")),
  age: Yup.string().matches(onlyNumber, getErrorMessage("E-10084"))
    .required("Age required"),
  mobile: Yup.string().matches(onlyNumber, getErrorMessage("E-10084"))
    .test( "phone", getErrorMessage("E-10220"),
    (val) => val && val.toString().length === 10
  ),
  pan: Yup.string()
  .matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, getErrorMessage("E-10038")),
  email: Yup.string().matches(emailRegex, getErrorMessage("E-10008")).required(getErrorMessage("E-10008")),
  address: Yup.string().required(getErrorMessage("E-10102")),
  state: Yup.object().shape({
    value: Yup.string().required(getErrorMessage("E-10045")),
    label: Yup.string().required(getErrorMessage("E-10045")),
  }).required(getErrorMessage("E-10045")),
  city: Yup.string().required(getErrorMessage("E-10088")),
});

export const newRental = Yup.object().shape({
  name: Yup.string().required(getErrorMessage("E-10260")),
  email: Yup.string().matches(emailRegex, getErrorMessage("E-10008")).required(getErrorMessage("E-10008")),  
});


export const agreementDetails = Yup.object().shape({
  stampValue: Yup.string()
    .matches(onlyNumber, getErrorMessage("E-10084"))
    .required(getErrorMessage("E-10261")),
  city: Yup.string().required(getErrorMessage("E-10043")),
  signDate: Yup.date()
    .required(getErrorMessage("E-10262"))
    .test({
      name: 'notPastDate',
      message: getErrorMessage("E-10263"),
      test: function(value) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        value.setHours(0, 0, 0, 0);
        return value >= today;
      },
    }),
  signatureVia: Yup.string().required(getErrorMessage("E-10275")),
});

export const partyDetails =  Yup.object().shape({
  rental_parties : Yup.array()
    .min(1, getErrorMessage("E-10264"))
    .required(getErrorMessage("E-10264")),
});

export const rentalParty = Yup.object().shape({
  first_name: Yup.string().required(getErrorMessage("E-10034")),
  last_name: Yup.string().required(getErrorMessage("E-10035")),  
  age: Yup.string()
    .matches(onlyNumber, getErrorMessage("E-10084"))
    .required(getErrorMessage("E-10084")),
  country_code: Yup.string(),
  mobile: Yup.string()
    .when("country_code", 
    {
      is: (country_code) => country_code === "+91",
      then: () => Yup.string()
        .matches(onlyNumber, getErrorMessage("E-10039"))
        .test("mobile", getErrorMessage("E-10220"),
          (val) => val && val.toString().length === 10
        ),
      otherwise: () => Yup.string()
      .matches(onlyNumber, getErrorMessage("E-10039"))
      .test("mobile", getErrorMessage("E-10220"),
        (val) => val && val.toString().length > 5
      ),
    },
  ),
  pan: Yup.string().required(getErrorMessage("E-10038"))
    .matches(panRegex, getErrorMessage("E-10038")),
  email: Yup.string().matches(
    emailRegex, getErrorMessage("E-10008")).required(getErrorMessage("E-10008")),
  address: Yup.string().required(getErrorMessage("E-10041")),
  state: Yup.string().required(getErrorMessage("E-10045")),    
  city: Yup.string().required(getErrorMessage("E-10043")),
  pin: Yup.string()
    .required(getErrorMessage("E-10042"))
    .matches( /^(\d{6})$/, getErrorMessage("E-10018")),
});

export const rPropertyDetails = Yup.object().shape({
  propertyAddress: Yup.string().required("Property Address required"),
  bhk : Yup.string().required("BHK required"),
  carPark : Yup.string()
    .required(getErrorMessage("E-10080"))
    .matches(onlyNumber, getErrorMessage("E-10084")),
  area : Yup.string()
    .required(getErrorMessage("E-10265"))
    .matches(patternTwoDigisAfterComma, getErrorMessage("E-10077")),
  furnishing : Yup.string().required(getErrorMessage("E-10082")),
  propertyState: Yup.string().required(getErrorMessage("E-10045")),    
  propertyCity: Yup.string().required(getErrorMessage("E-10043")),
  propertyZip : Yup.string()
    .required(getErrorMessage("E-10018"))
    .matches( /^(\d{6})$/, getErrorMessage("E-10018")),
});

export const rRentDetails = Yup.object().shape({
  securityDeposit : Yup.string()
    .matches(patternDigitsAndComma, getErrorMessage("E-10084"))
    .required(getErrorMessage("E-10268")),
  payDetails : Yup.string(),
  rentAmount : Yup.string()
    .matches(patternDigitsAndComma, getErrorMessage("E-10084"))
    .required(getErrorMessage("E-10269")),
  maintenance : Yup.string().required(getErrorMessage("E-10267")),
  maintenanceAmt : Yup.string()
    .matches(patternDigitsAndComma, getErrorMessage("E-10084")),
  hikePer : Yup.string()
    .matches(patternTwoDigisAfterComma, getErrorMessage("E-10084"))
    .test("isGreaterThan", getErrorMessage("E-10271"),
      value => {
        if(value) {
          const numberValue = parseFloat(value);
          return numberValue <= 100;
        }
        return true;
    })
    .required(getErrorMessage("E-10272")),
  licenseDate : Yup.date()
    .required("License Commencement Date required"),
  duration : Yup.string()
    .required("Duration of License required")
    .matches(onlyNumber, getErrorMessage("E-10084")),
  noticePeriod : Yup.string()
    .when('duration', {
      is: (duration, schema) => duration && parseInt(duration, 10) > 0, 
      then: () => Yup.string()
        .matches(onlyNumber, getErrorMessage("E-10084"))
        .required("Notice Period required")
      .test(
        'is-less-than-duration',
        'Notice Period must be less than Duration',
        function (value) {
          const { duration } = this.parent;
          if (value && duration) {
            const numericNoticePeriod = parseInt(value, 10);
            const numericDuration = parseInt(duration, 10);
            return numericNoticePeriod <= numericDuration;
          }
          return true; 
        }),
    }),
  lockPeriod : Yup.string()
    .when('duration', {
      is: (duration, schema) => duration && parseInt(duration, 10) > 0, 
      then: () => Yup.string()
        .matches(onlyNumber, getErrorMessage("E-10084"))
        .required("Lock Period required")
      .test(
        'is-less-than-duration',
        'Lock Period must be less than Duration',
        function (value) {
          const { duration } = this.parent;
          if (value && duration) {
            const numericLockPeriod = parseInt(value, 10);
            const numericDuration = parseInt(duration, 10);
            return numericLockPeriod <= numericDuration;
          }
          return true; 
        }),
    }),
  stayModel : Yup.string().required(getErrorMessage("E-10276")),
});

export const rentalAgreementInvitation = Yup.object().shape({
  first_name: Yup.string().required(getErrorMessage("E-10034")),
  last_name: Yup.string().required(getErrorMessage("E-10035")),  
  email: Yup.string()
    .matches(emailRegex, getErrorMessage("E-10008"))
    .required(getErrorMessage("E-10008")),
});