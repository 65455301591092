import { defaultValues } from "../constants";
import { updateSUbStages } from "../services/api";

export const regexExpression = {
   emailRegex : /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
}
 

export const updateSubStage = async (data)=>{
    try{
      const response = await updateSUbStages(data);  
      return response
    }catch(error){
      return error
    }
    
  };

  export const validatePhone = (value) => {
    return Number(value) >= 9999999999 ? false : true;
  }

export const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 0
  }).format(value);

export const validateZipCode = (value) => {
  return Number(value) >= 999999 ? false : true;
}

export const getRoleNameInProperty = (propertyData, userId) => {
  if(propertyData?.property_parties_details){
    const filtered = propertyData?.property_parties_details
    .filter(user => user?.user_id?._id === userId);    
    return filtered[0]?.role.role_name;
  }
}

export const convertNumberToWord = (amount) => {
  
    const single = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
    const double = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    const tens = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
    const formatTenth = (digit, prev) => {
       return 0 === digit ? "" : " " + (1 === digit ? double[prev] : tens[digit])
    };
    const formatOther = (digit, next, denom) => {
       return (0 !== digit && 1 !== next ? " " + single[digit] : "") + (0 !== next || digit > 0 ? " " + denom : "")
    };
    let res = "";
    let index = 0;
    let digit = 0;
    let next = 0;
    let words = [];
    if (amount += "", isNaN(parseInt(amount))){
       res = "";
    }
    else if (parseInt(amount) > 0 && amount.length <= 10) {
       for (index = amount.length - 1; index >= 0; index--) switch (digit = amount[index] - 0, next = index > 0 ? amount[index - 1] - 0 : 0, amount.length - index - 1) {
          case 0:
             words.push(formatOther(digit, next, ""));
          break;
          case 1:
             words.push(formatTenth(digit, amount[index + 1]));
             break;
          case 2:
             words.push(0 != digit ? " " + single[digit] + " Hundred" + (0 != amount[index + 1] && 0 != amount[index + 2] ? " and" : "") : "");
             break;
          case 3:
             words.push(formatOther(digit, next, "Thousand"));
             break;
          case 4:
             words.push(formatTenth(digit, amount[index + 1]));
             break;
          case 5:
             words.push(formatOther(digit, next, "Lakh"));
             break;
          case 6:
             words.push(formatTenth(digit, amount[index + 1]));
             break;
          case 7:
             words.push(formatOther(digit, next, "Crore"));
             break;
          case 8:
             words.push(formatTenth(digit, amount[index + 1]));
             break;
          case 9:
             words.push(0 != digit ? " " + single[digit] + " Hundred" + (0 != amount[index + 1] || 0 != amount[index + 2] ? " and" : " Crore") : "")
       };
       res = words.reverse().join("")
    } else res = "";
    return res;

}

export const getUserId = (value) => {
   if(value?.data?.data){
     if(value?.preferredRole === defaultValues.role_names.super_admin){
       return value.data.data.superAdminByEmail._id;
     }else if(value.preferredRole === defaultValues.role_names.admin){
       return value.data.data.adminByEmail._id;
     }else {
       return value.data.data.userByEmail._id;
     }
 }
}