import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Backdrop } from "@mui/material";
import { Routes } from "../../constants/routes";
import {
  ERROR_KEY,
  SUCCESS_KEY,
  defaultValues,
  getErrorMessage,
} from "../../constants";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { GenerateRentalAgreement } from "./generate-rental-agreement";
import { PaymentRentalAgreement } from "./payment-rental-agreement";
import { ReviewRentalAgreement } from "./review-rental-agreement";
import { StatusRentalAgreement } from "./status-rental-agreement";
import Loader from "../../layout/Loader/Loader";
import "./style.scss";
import { SteppeBar } from "../../commonComponent/rental-agreement";
import { useDispatch, useSelector } from "react-redux";
import Main from "../../layout/Main";
import {
  getNewRentalAgreement,
  r_updatePaymentDetails,
  submitNewRentalAgreement,
} from "./service";
import { API } from "../../utils/api";
import { JsStyles } from "./jsStyle";

export const SubmitRentalAgreement = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const search = useLocation().search;
  const { addToast } = useToasts();

  const userId = useSelector(
    (state) => {
      const user = state.loggedInUser?.data?.data?.userByEmail?._id || state.loggedInUser?.data?.data?.superAdminByEmail?._id
      return user
    }
  );

  const token = useSelector(
    (state) => state.loggedInUser?.data?.data?.accessToken
  );

  const selectedRental = useSelector(
    (state) => state.rentalAgreement.selectedRental
  );
  const isLoading = useSelector((state) => state.rentalAgreement.isLoading);

  const [activeStep, setActiveStep] = useState(1);
  const [agrData, setAgrData] = useState(null);
  const searchParams = new URLSearchParams(search);
  const [stepsScreen, setStepsScreen] = useState([]);

  useEffect(() => {
    if (selectedRental) {
      dispatch(getNewRentalAgreement(selectedRental._id));
    }
  }, []);

  useEffect(() => {
    if (searchParams.get("id")) {
      // Token passing If user already logged then saving token in session store,
      // If user coming from email link
      sessionStorage.setItem('token', token);
      dispatch(getNewRentalAgreement(searchParams.get("id")))
        .then((res) => {
          history.push(Routes.SUBMIT_RENTAL_AGREEMENT);
        });
    } else if (props.location.state && props.location.state.argId) {
      dispatch(getNewRentalAgreement(props.location.state.argId));
    }
  }, [props.location]);

  useEffect(() => {
    if (selectedRental) {
      setAgrData(selectedRental);
      if (selectedRental.stageNo === 1.5) window.scrollTo(0, 0);
      if (selectedRental.stageNo >= 5) setActiveStep(4);
      else if (selectedRental.stageNo >= 3 && selectedRental.stageNo <= 4) {
        setActiveStep(3);
      } else if (selectedRental.stageNo >= 2 && selectedRental.stageNo < 3)
        setActiveStep(2);
      else if (selectedRental.stageNo >= 1 && selectedRental.stageNo < 2)
        setActiveStep(1);
    } else {
      setActiveStep(1);
    }
  }, [selectedRental]);

  // this useEffect for after back payment getaway
  useEffect(() => {
    if (searchParams.get("refId") && searchParams.get("payment_id")) {
      const payload = {
        id: searchParams.get("refId"),
        payment_id: searchParams.get("payment_id"),
        payment_request_id: searchParams.get("payment_request_id"),
      };
      dispatch(r_updatePaymentDetails(payload))
        .then((res) => {
          if (res?.data?.status) {
            addToast(res?.data?.message, {
              appearance: SUCCESS_KEY,
              autoDismiss: true,
            });
          }
          history.push(Routes.SUBMIT_RENTAL_AGREEMENT);
        })
        .catch((err) => {
          addToast(err?.response?.data?.message || getErrorMessage("E-10070"), {
            appearance: "error",
            autoDismiss: true,
          });
        });
    }
  }, []);

  useEffect(() => {
    if (searchParams.get("refId") && !searchParams.get("payment_id")) {
      getRAgreementData(searchParams.get("refId"));
    }
  }, []);

  const getRAgreementData = (agreementId) => {
    dispatch(getNewRentalAgreement(agreementId)).catch((err) => {
      addToast(getErrorMessage(err?.response?.data?.message), {
        appearance: ERROR_KEY,
        autoDismiss: true,
      });
    });
  };

  const onSave = useCallback(
    (data) => {
      const _id = agrData?._id || null;
      let payload = {};
      if (_id) {
        payload = { _id, ...data };
      } else {
        payload = { ...data, createdBy: userId };
      }
      dispatch(submitNewRentalAgreement(payload))
        .then((res) => {
          if (res.payload.data?.data?.status) {
            addToast(res.payload.data?.message, {
              appearance: SUCCESS_KEY,
              autoDismiss: true,
            });
          }
        })
        .catch((err) => {
          addToast(err?.response?.data?.message || getErrorMessage("E-10070"), {
            appearance: ERROR_KEY,
            autoDismiss: false,
          });
        });
    },
    [agrData, addToast]
  );

  useEffect(() => {
    setStepsScreen([
      // index = 0
      <GenerateRentalAgreement agreementData={agrData} />,
      // index = 1
      <PaymentRentalAgreement onSave={onSave} />,
      // index = 2
      <ReviewRentalAgreement onSave={onSave} />,
      // index = 3
      <StatusRentalAgreement />,
    ]);
  }, [agrData]);

  return (
    <div>
      {isLoading && (
        <Backdrop open={isLoading} sx={JsStyles.backDrop}>
          <Loader />
        </Backdrop>
      )}
      <div>
        <Main
          showWelcomePage={!props?.showWelcomePage}
          currentScreen={"user_home"}
          showChildren={!props?.showChildren}
        >
          <div className="buyer-container">
            <div className="container-fluid nr-container-fluid">
              <div className="nr-main-box">
                {activeStep !== 4 && (
                  <div className="r-title">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h4>Rental Agreement</h4>
                        <p>( Digitally Signed & legally vetted )</p>
                      </div>
                      {agrData?.refId && (
                        <p className="refNo">Reference No. : {agrData.refId}</p>
                      )}
                    </div>
                  </div>
                )}
                {activeStep > 0 && activeStep < 4 && (
                  <SteppeBar activeStep={activeStep - 1} />
                )}
                {stepsScreen[activeStep - 1]}
              </div>
            </div>
          </div>
          <div className="desktop-footer">
            <footer>
              <Button
                className="text-light"
                variant="text"
                component={Link}
                href={API.footerUrl.terms_condition}
                target="_blank"
              >
                Terms & Conditions
              </Button>
              <Button
                className="text-light"
                variant="text"
                component={Link}
                href={API.footerUrl.privacy}
                target="_blank"
              >
                Privacy Policy
              </Button>
            </footer>
            <p>{defaultValues.copyRight}</p>
          </div>
        </Main>
      </div>
    </div>
  );
};
