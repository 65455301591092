/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { AddTemplateData, GetTemplateData, updateTemplateData } from './service'
import Main from '../../layout/Main';
import './style.scss'
import AddTemplate from './AddTemplate';
import Tooltip from '@mui/material/Tooltip';
import { CustomEditor } from "../../utils/CustomEditor";
import { Routes } from "../../constants/routes";
import { ERROR_KEY, ROLE_KEY, SUCCESS_KEY, defaultValues } from "../../constants";

function Page(props) {
	const Dispatch = useDispatch();
	const role = sessionStorage.getItem(ROLE_KEY);
	const adminTemplate = useSelector((state) => state.adminTemplate);
	const { addToast } = useToasts();
	const [currentTemplate, setCurrentTemplate] = useState();
	const [email_subject, setEmailSubject] = useState();
	const [state, setState] = useState({
		template_cat: 'document',
		index : null,
		template_type:'document'
	});
	const history = useHistory();

	const accessToken = useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";

	useEffect(() => {
		if (!accessToken) {
		history.push(Routes.SUPER_ADMIN_LOGIN);
		}
		if (role !== defaultValues.role_names.super_admin && role !== defaultValues.role_names.admin) {
			props.history.push(Routes.HOME);
		  }
	}, [accessToken, history]);

	useEffect(() => {
		Dispatch(GetTemplateData())
	}, [Dispatch])
	
	useEffect(() => {
		if (adminTemplate?.templates?.length) {
			setCurrentTemplate(adminTemplate.templates[state.index])
			if( adminTemplate.templates[state.index]?.file){
			setState(() => ({ ...state, currentFile: adminTemplate.templates[state.index]?.file }))
			setEmailSubject(adminTemplate.templates[state.index].email_subject);
			}
			else{
			setState(() => ({ ...state, currentTemplate: '', currentFile: '' }))
			}
		} else {
			setState(() => ({ ...state, currentTemplate: '', currentFile: '' }))
		}
	}, [state.template_type, state.index]);


	useEffect(() => {
		if (adminTemplate.message) {
		if (adminTemplate.status === SUCCESS_KEY) {
			addToast(adminTemplate.message, { appearance: SUCCESS_KEY, autoDismiss: true });
		} else {
			addToast(adminTemplate.message, { appearance: ERROR_KEY, autoDismiss: true });
		}
		}
	}, [adminTemplate.message, adminTemplate.status]);

  	return (
		<Main
			showLoader={false}
			showNav={!props?.showNav}
			showFooter={!props?.showFooter}
			showLogo={!props?.showLogo}
			showAdminFooter={!props?.showAdminFooter}
			showAdminNavbar={!props?.showAdminNavbar}
		>
			<div className="content row manage-home">
				{state.template_cat?.toLowerCase() === "document" ? (
					<div className="template-list col-2">
						{adminTemplate.templates?.map((ele, index) => {
							return (
								<>
									{(ele.template_type === defaultValues.template_types.mou ||
										ele.template_type === defaultValues.template_types.sale_deed ||
										ele.template_type === defaultValues.template_types.offer_letter ||
										ele.template_type === defaultValues.template_types.agreement ||
										ele.template_type === defaultValues.template_types.document) && (
										<div
											className={`template-item ${
												state.template_type === defaultValues.template_types.document &&
												state.index === index
													? "active"
													: ""
											}`}
											onClick={() => {
												setState({
													...state,
													template_type: defaultValues.template_types.document,
													index,
												});
											}}
										>
											<img src="/imgs/document.png" className="doc-Image" />
											<p className="pt-2">{ele.template_name.toUpperCase()}</p>
										</div>
									)}
								</>
							);
						})}
					</div>
				) : null}

				{state.template_cat?.toLowerCase() === "email" ? (
					<div className="template-list col-2">
						{adminTemplate.templates?.map((ele, index) => {
							return (
								<>
									{ele.template_type === defaultValues.template_types.email && (
										<div
											className={`template-item ${
												state.template_type === defaultValues.template_types.email && state.index === index
													? "active"
													: ""
											}`}
											onClick={() => {
												setState({
													...state,
													template_type: defaultValues.template_types.email,
													index,
												});
											}}
										>
											<img src="/imgs/Email.svg" />
											<Tooltip
												placement="right-start"
												arrow
												title={ele.template_name}
											>
												<p>{ele.template_name}</p>
											</Tooltip>
										</div>
									)}
								</>
							);
						})}
					</div>
				) : null}

				{state.template_cat?.toLowerCase() === "sms" ? (
					<div className="template-list col-2">
						{adminTemplate.templates?.map((ele, index) => {
							return (
								<>
									{ele.template_type === defaultValues.template_types.sms && (
										<div
											className={`template-item ${
												state.template_type === defaultValues.template_types.sms && state.index === index
													? "active"
													: ""
											}`}
											onClick={() => {
												setState({
													...state,
													template_type: defaultValues.template_types.sms,
													index,
												});
											}}
										>
											<img src="/imgs/SMS.svg" />
											<p>{ele.template_name}</p>
										</div>
									)}
								</>
							);
						})}
					</div>
				) : null}

            <div className="template-area col-10">
                <div className="row">
                    <div className="col-9">
                        <div className="template-btns">
                            <div style={{ textAlign: "left" }}>
                                <select
                                    style={{ width: window.innerWidth * 0.48, backgroundColor: "white", color: "black" }}
                                    className="form-select select-menu"
                                    onChange={(e) =>
                                        setState({
                                            ...state,
                                            index: null,
                                            template_cat: String(e.target.value).toLowerCase(),
                                            template_type:
                                                String(e.target.value).toLowerCase() !== "document"
                                                    ? String(e.target.value).toLowerCase()
                                                    : null,
                                        })
                                    }
                                >
										<option value="document">DOCUMENT</option>
										<option value="sms">SMS</option>
										<option value="email">EMAIL</option>
									</select>
								</div>
							</div>
							<div>
							    {state.template_type === defaultValues.template_types.email && (
							        <div>
							            <label style={{ width: "150px", marginRight: "1rem", color:"black" }}>Email Subject:</label>
							            <input
							                type="text"
							                id="emailSubject"
							                placeholder="Enter email subject"
							                value={email_subject}
							                onChange={(e) => setEmailSubject(e.target.value)}
							                style={{ width: "50rem", marginBottom: "1rem" }}
							            />
							        </div>
							    )}
							</div>
							<div className="editorWrapper">
								<CustomEditor 
									data={state.index!=null?state.currentFile:"Choose a file to continue..."} 
									onChange={(text) => {
										state.currentFile = text;
										state.updateTemplate = true;
									}}
									readonly={state.template_type === defaultValues.template_types.sms || state.index==null}
								/>
							</div>
							<div className="template-btns">
								<div className="btn-group-right">
									{(state.template_type !== defaultValues.template_types.sms &&  state.currentFile) ? (
										<button
											disabled={state.template_type === defaultValues.template_types.sms}
											onClick={async () => {
												await Dispatch(
													updateTemplateData({
														...currentTemplate,
														templateId: currentTemplate._id,
														file: state.currentFile,
														email_subject: email_subject
													})
												);
												Dispatch(GetTemplateData());
											}}
											className={`templateBtn ${
												(state.template_type === defaultValues.template_types.sms)  ? "disabledButton" : ""
											}`}
										>
											Save
										</button>
									) : (
										<></>
									)}
								</div>
							</div>
						</div>
						{/* as per client requirement, not needed now */}
						 {/* <div className="spotlist-area col-3">
							<div className="spotlist-item">
								<div className="title-bar">
									<p className="title">Offer Letter</p>
									<div className="icon-group">
										<img className="icon" src={addIcon} />
										<img className="icon" src={deleteIcon} />
										<img className="icon" src={editIcon} />
									</div>
								</div>
								<div className="description-area">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
									sem elit
								</div>
							</div>
							<div className="plus-btn-wrap">
								<img style={{opacity:"30%"}} src={circlePlus} className="plus-btn" />
							</div>
							{currentTemplate?.spotlight.map((item) => {
								return (
									<div className="spotlist-item">
										<div className="title-bar">
											<p className="title">{item.name}</p>

											<div className="icon-group">
												<img className="icon" src={addIcon} />
												<img className="icon" src={deleteIcon} />
												<img className="icon" src={editIcon} />
											</div>
										</div>
										<div className="description-area">Lorem ipsum dolor sit amet, consectetur adipiscing elit. asdddddddddNunc sem elit</div>
									</div>
								);
							})}
						</div> */}
					</div>
				</div>
			</div>
			<AddTemplate
				isOpen={state.isAddOpen}
				onClose={(e) => {
					setState({ ...state, isAddOpen: false });
				}}
				onSubmit={(data) => {
					Dispatch(AddTemplateData(data));
				}}
			/>
		</Main>
	);

}

export default Page