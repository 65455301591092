import React, { useRef } from "react";
import "../styles/editorStyle.css";

import JoditEditor from "jodit-react"; //https://www.npmjs.com/package/jodit-react

export const JoditTextEditor = ({
  data,
  onChangedData,
  readonly = false, //default value is false, pass true from parent if needed readonly editor
  placeholder = "",
  height = "80vh",
}) => {
  const editor = useRef(null);

  const editorConfig = {
    height: height,
    readonly: readonly, // all options from https://xdsoft.net/jodit/docs/,
    placeholder: placeholder || "Start typings...",
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "ul",
      "ol",
      "|",
      "outdent",
      "indent",
      "|",
      "font",
      "fontsize",
      "brush",
      "paragraph",
      "|",
      "image",
      "table",
      "link",
      "|",
      "align",
      "|",
      "hr",
      "eraser",
      "spellcheck",
      "|",
      "fullsize",
    ],
    statusbar: false,
  };

  return (
    <JoditEditor
      ref={editor}
      config={editorConfig}
      value={data}
      onBlur={(val) => {
        onChangedData && onChangedData(val);
      }}
    />
  );
};
