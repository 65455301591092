import React, { useEffect, useState } from "react";
import Main from "../../layout/Main";
import { updateSelectedProperty } from "../user-home/slices";
import { useSelector, useDispatch } from "react-redux";

import { useHistory, useLocation } from "react-router-dom";
import { updateSubStage } from "../../utils/HelperFunction";
import "./style.scss";
import { Button } from "@mui/material";
import { useToasts } from "react-toast-notifications";
import Loader from "../../layout/Loader/Loader";
import {
  addPropertyHistory,
  checkPaymentStatus,
  getPlatformFeeDetails,
  requestPayments,
  validateStage,
} from "../../services/api";
import { API } from "../../utils/api";
import { historyFormatter } from "../../utils/historyFormatter";
import { Routes } from "../../constants/routes";
import { ERROR_KEY, SUCCESS_KEY, getSuccessMessage } from "../../constants";
import { primaryButtonStyle } from "../../styles/commonstyles";

const PlatformFee = (props) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const accessToken =
    useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";
  const propertyId =
    useSelector((state) => state?.buyerHomeData?.selectedProperty?._id) || "";
  const buyer_id =
    useSelector((state) => state?.loggedInUser?.data?.data?.userByEmail?._id) ||
    "";
  const user =
    useSelector((state) => state?.loggedInUser?.data?.data?.userByEmail) || "";
  const subStages = useSelector((state) => state?.stages?.subStages) || [];
  const roleId = useSelector((state) => state.loggedInUser?.selectedRole?._id);
  const pid = location.search.split("=")[1];
  const [paymentDetails, setPaymentDetails] = useState({ paid: false });
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const [isLoading, setIsLoading] = useState(false);
  const getSubStage = (order) =>
    subStages.filter((substage) => substage.order === order)[0]._id;

  useEffect(() => {
    if (!accessToken) {
      history.push(Routes.HOME);
    } else {
      if (!propertyId) history.push(Routes.PROPERTIES);
      else if (pid !== propertyId)
        history.push(`${Routes.PLATFORM_FEE}?pid=${propertyId}`);
    }
  }, [accessToken, history, pid, propertyId]);

  useEffect(() => {
    if (searchParams.get("payment_id")) {
      setIsLoading(true);
      var payload = {};
      payload.payment_id = searchParams.get("payment_id");
      payload.property_id = propertyId;
      payload.payee_id = buyer_id;
      payload.payment_id = searchParams.get("payment_id");
      payload.payment_status = searchParams.get("payment_status");
      payload.payment_request_id = searchParams.get("payment_request_id");
      updatePaymentStatus(payload).then((data) => {
        if(data.payment_status){
          window.location.reload()
        }
        setIsLoading(false);
      });
    }
  }, [buyer_id, propertyId, searchParams]);

  useEffect(() => {
    setIsLoading(true);
    getPlatformFeeDetails(propertyId)
      .then((response) => {
        setPaymentDetails(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        addToast(error.response.data.message, {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
        setIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickPayNow = async () => {
    const historyData = historyFormatter(
      user,
      buyer_id,
      propertyId,
      "has paid the platform fee."
    );
    if (paymentDetails.payFess === 0) {
      await validateStage({ propertyId, roleId });
      await updateSubStage({
        propertyId,
        substageId: getSubStage(3),
        loggedInUserId: buyer_id,
      })
        .then((response) => {
          dispatch(updateSelectedProperty(response.data.propertyData));
          addToast(getSuccessMessage("S-10019"), {
            appearance: SUCCESS_KEY,
            autoDismiss: true,
          });
          history.push(Routes.INITIATE_OFFER);
          addPropertyHistory(historyData);
        })
        .catch((error) => {
          addToast(error.response.data.message, {
            appearance: ERROR_KEY,
            autoDismiss: true,
          });
        });
    } else {
      var redirectUrl = `${API.redirectUrl[API.currenEnv]}${
        Routes.PLATFORM_FEE
      }?pid=${propertyId}`;
      setIsLoading(true);
      requestPayments({
        propertyId: propertyId,
        buyerId: buyer_id,
        redirectUrl: redirectUrl,
      })
        .then((res) => {
          window.location.href = res.data;
          setIsLoading(false);
          addPropertyHistory(historyData);
        })
        .catch((error) => {
          setIsLoading(false);
          addToast(error.response.data.message, {
            appearance: ERROR_KEY,
            autoDismiss: true,
          });
        });
    }
  };

  const updatePaymentStatus = async (payload) => {
    try {
      const { data } = await checkPaymentStatus(payload);
      return data;
    } catch (e) {}
  };

  const handleClick = async () => {
    validateStage({ propertyId, roleId })
      .then(async (res) => {
        await updateSubStage({
          propertyId,
          substageId: getSubStage(3),
          loggedInUserId: buyer_id,
        })
          .then((response) => {
            dispatch(updateSelectedProperty(response.data.propertyData));
            addToast(getSuccessMessage("S-10019"), {
              appearance: SUCCESS_KEY,
              autoDismiss: true,
            });
            history.push(Routes.INITIATE_OFFER);
          })
          .catch((error) => {
            addToast(error.response.data.message, {
              appearance: ERROR_KEY,
              autoDismiss: true,
            });
          });
      })
      .catch((err) => {
        addToast(err.response.data.message, {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
      });
  };

  return (
    <div>
      <Main showUserNavbar={!props?.showUserNavbar} showUser={!props?.showUser}>
        {isLoading ? (
          <div className="fee-container">
            <Loader />
          </div>
        ) : (
          <div className="fee-container">
            <div className="fee-heading">
              <p>Pay Platform Fee</p>
            </div>
            <div className="inner-container">
              {paymentDetails.paid === false ? (
                <div className="fee-body">
                  <div className="col">
                    <h4>Pay Platform Fees of Rs. {paymentDetails.payFess}</h4>
                    <br />
                    <Button
                      variant="contained"
                      style={primaryButtonStyle}
                      onClick={onClickPayNow}
                    >
                      {paymentDetails.payFess > 0 ? "Pay Now" : "Proceed"}
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="fee-body">
                  <h2>Your payment transaction is successful.</h2>
                  <br />
                  <div className="col">
                    <p>
                      <strong>Transaction Id </strong>:{" "}
                      {paymentDetails.paymentDetails.transaction_id}
                    </p>
                    <p>
                      <strong>Paid Amount</strong> :{" "}
                      {paymentDetails.paymentDetails.payment_amount}
                    </p>
                    <p>
                      <strong>Payment Date</strong> :{" "}
                      {paymentDetails.paymentDetails.payment_date}
                    </p>
                    <br />
                    <br />
                    <Button
                      variant="contained"
                      style={primaryButtonStyle}
                      onClick={handleClick}
                    >
                      Proceed
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Main>
    </div>
    //<SignPage pageTitle="Pay Platform Fee" />
  );
};
export default PlatformFee;
