import React, { useEffect, useState } from "react";
import Main from "../../layout/Main";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import "./style.scss";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useToasts } from "react-toast-notifications";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addPropertyHistory, backOut } from "../../services/api";
import Loader from "../../layout/Loader/Loader";
import { historyFormatter } from "../../utils/historyFormatter";
import { ERROR_KEY, SUCCESS_KEY, getErrorMessage, getSuccessMessage } from "../../constants";
import { Routes } from "../../constants/routes";

const initialValues = {
  reason: "",
  agreeCondition: false  
};

export const backoutSchema = (balShareValue) =>
  Yup.object().shape({
    reason: Yup.string().required(getErrorMessage('E-10222')),
    agreeCondition: Yup.boolean().oneOf(
      [true],
      getErrorMessage('E-10223')
    ),
  });

const Backout = (props) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const location = useLocation();
  const accessToken =
    useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";
  const userId = useSelector((state) => state.loggedInUser?.data?.data?.userByEmail?._id);
  const userData = useSelector((state) => state.loggedInUser?.data?.data?.userByEmail);
  const preferredRole = useSelector((state) => state.loggedInUser?.preferredRole);
  const propertyId =
    useSelector((state) => state?.buyerHomeData?.selectedProperty?._id) || "";
  const property = useSelector((state) => state?.buyerHomeData?.selectedProperty);
  const subStages = useSelector((state) => state?.stages?.subStages) || [];
  const stages = useSelector((state) => state?.stages?.stages) || [];
  const getSubStageId = (order) =>
    subStages.filter((substage) => substage.order === order)[0]?._id;

  const getStageId = (order) =>
    stages.filter((stage) => stage.order_number === order)[0]?._id;

  const getSubStageOrder = (id) =>
    subStages.filter((substage) => substage._id === id)[0]?.order;

  const getSubStageName = (order) =>
    subStages.filter((substage) => substage.order === order)[0]?.sub_stage_name;

  const currentSubStage = getSubStageOrder(property?.sub_current_stage);
  const pid = location.search.split("=")[1];
  const [conditionList, setConditionList] = useState([]);
  const [loader, setLoader] = useState(false);

  const { values, handleBlur, handleChange, handleSubmit, touched, errors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: backoutSchema,
      validateOnChange: true,
      validateOnBlur: true,
      onSubmit: async (values, action) => {
        setLoader(true);
        const payload = {
          propertyId : propertyId,
          userId : userId,
          execute : true,
          propertyData : {
            id: propertyId,
            current_stage: getStageId(7),
            sub_current_stage: getSubStageId(21),
            backout: {
              user_id: userId,
              stageId: property.current_stage._id,
              sub_stage: property.sub_current_stage,
              reason: values.reason,
            },
          },
        };

        backOut(payload)
          .then(async(res) => {
            let message = '';
            message = `has initiated backout at ${getSubStageName(currentSubStage)} stage`;
            const historyData = historyFormatter(userData, userId, propertyId, message);
            await addPropertyHistory(historyData);

            addToast(getSuccessMessage('S-10028'), {
              appearance: SUCCESS_KEY,
              autoDismiss: true,
            });
            history.push(Routes.VIEW_PROPERTY);
                       
          })
          .catch((error) => {
            addToast(getErrorMessage('E-10224'), {
              appearance: ERROR_KEY,
              autoDismiss: true,
            });
          }).finally(() => setLoader(false));
      },
    });

  useEffect(() => {
    if (!accessToken) {
      history.push(Routes.HOME);
    } else {
      if (!propertyId) history.push(Routes.PROPERTIES);
      else if (pid !== propertyId) history.push(`${Routes.BACK_OUT}?pid=${propertyId}`);
    }
  }, []);

  useEffect(() => {
    getTermsAndConditions();
  }, [preferredRole, propertyId]);

  const getTermsAndConditions = async () => {
    setLoader(true);
    
    await backOut({ propertyId, userId, execute: false }).then((res) => {
      setConditionList(res.data);
    }).catch(error => {
      addToast(getErrorMessage('E-10070'), {
        appearance: ERROR_KEY,
        autoDismiss: true,
      });
    }).finally(() => setLoader(false));
  };

  const renderTitle = (item, index) => {
    if(item.subTitle.length){
      return (
        <>
          <p>{index}. {item.title}</p>
          {item.subTitle.map((sub, subIndex) => 
            <p className="pl-5 m-3">{index}.{subIndex+1}. {sub}</p>)
          }
        </>
      );

    }else{
      return <p>{index}. {item.title}</p>
    } 
  }


  return (
    <div style={{marginTop: '2%'}}>
      {loader ? (
        <Loader />
      ) : (
        <div>
          <Main
            showUserNavbar={!props?.showUserNavbar}
            showUser={!props?.showUser}
          >
            <div className="fee-container">
              <div className="backout-heading">
                <p>Backout</p>
              </div>

              <div className="backout-subheading">
                <p>Back out penalty details and terms and conditions:</p>
              </div>

              <div className="bullet-point">
                {conditionList.map(
                    (item, index) => renderTitle(item, index + 1)
                )}                
              </div>

              <Form
                onSubmit={handleSubmit}
                className="pl-3 mt-5 container-fluid"
              >
                <Form.Group className="mb-3">
                  <Form.Label className="fee-label">
                    Reason For Backing Out
                  </Form.Label>
                  <Form.Control
                    name="reason"
                    type="text"
                    as="textarea"
                    rows={4}
                    placeholder="Enter what is reason for backing Out"
                    className="fee-inputs-multiple mb-1"
                    value={values.reason}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.reason && errors.reason ? (
                    <p className="formError text-danger">{errors.reason}</p>
                  ) : null}
                </Form.Group>
                <Form.Group>
                  <FormControlLabel
                    className="mr-5 fee-label"
                    control={
                      <Checkbox
                        name="agreeCondition"
                        color="primary"
                        checked={values.agreeCondition}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    }
                    label="I agree to the terms and conditions"
                  />
                  {touched.agreeCondition && errors.agreeCondition ? (
                    <p className="formError text-danger">
                      {errors.agreeCondition}
                    </p>
                  ) : null}
                </Form.Group>
                <div className="proceed-button d-flex flex-row mt-5">
                  <Button
                    className="m-4 button"
                    variant="primary"
                    type="submit"
                  >
                    Proceed
                  </Button>
                  <Button
                    className="m-4 button"
                    variant="primary"
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </Main>
        </div>
      )}
    </div>
  );
};
export default Backout;
