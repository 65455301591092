import { createSlice } from "@reduxjs/toolkit";
import { getProfileImg } from "../extra-services/profile.services";
const initialState = {
	image: null,
};

export const profileSlice = createSlice({
	name: "profileSlice",
	initialState,
	reducers: {
		clearProfileImage: (state, action)=>{
		    return initialState
		},
	},
	extraReducers: {
		[getProfileImg.pending]: (state, action) => ({
			...initialState,
			loading: true,
		}),

		[getProfileImg.fulfilled]: (state, action) => ({
			...state,
			loading: false,
			image: action.payload?.url,
		}),

		[getProfileImg.rejected]: (state, action) => ({
			...initialState,
			loading: false,
		}),
	},
});

export const {  clearProfileImage } = profileSlice.actions;

export default profileSlice.reducer;
