import React, { useEffect, useRef,useState } from "react";
import Button from "react-bootstrap/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import RightArrow from "../../../assets/right-arrow.svg";
import LeftArrow from "../../../assets/left-arrow.svg";
import SwiperCore, { FreeMode, Navigation, Pagination, A11y } from "swiper";
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from "react-redux";
import PropertyDocument from "../../../layout/PropertyModal/PropertyDocumentModal/propertyDocument";
import { defaultValues } from "../../../constants";
SwiperCore.use([FreeMode, Navigation, Pagination, A11y]);

const PropertyDocumentData = ({updateAction}) => {
  const [propertyDocumentModal, setPropertyDocumentModal] = useState(false);
  const [addModal, setAddModal] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const userRole = useSelector((state) => state.loggedInUser.preferredRole);
  const [title, setTitle] = useState({});
  const [filteredFolders, setFilteredFolders] = useState([]);
  const swiperRef = useRef(null);
  const checkListTypeData = useSelector(
    (state) => state.superAdminProperties?.checkListData
  );

  const selectedProperty = useSelector((state) => state.buyerHomeData?.selectedProperty);
  const stageList = useSelector((state) => state.stages?.stages);
  const getStageOrder = (id) => stageList.filter(stage => stage._id === id)[0].order_number;


  useEffect(() => {
    if(checkListTypeData?.length) {
      let tempArr = [...checkListTypeData];
      tempArr = tempArr.filter(
        (item) => !defaultValues.exclude_folder_lawyer.includes(item.title)
        );
      tempArr = tempArr.filter(item => 
          getStageOrder(item.stages) <= selectedProperty.current_stage.order_number);
      setFilteredFolders(tempArr);
    }
  }, [checkListTypeData, selectedProperty]);
  
  const handlePrevClick = () => {
    if (swiperRef.current !== null && activeIndex > 0) {
      swiperRef.current.slidePrev();
      setActiveIndex(activeIndex - 1);
    }
  };
  const handleNextClick = () => {
    if (swiperRef.current !== null && activeIndex < checkListTypeData?.length - 3) {
      swiperRef.current.slideNext();
      setActiveIndex(activeIndex + 1);
    }
  };

  return (
    <div>
      <div className="property-doc fw-bold">
        <h4>Property Documents</h4>
      </div>
      <div className="template-addProp">
        <div className="property-button">
          <Button
            variant="transparent"
            className="arrow"
            onClick={handlePrevClick}
            disabled={activeIndex === 0}
          >
            <img src={LeftArrow} alt="left-arrow" />
          </Button>
          <Button
            variant="transparent"
            className="arrow"
            onClick={handleNextClick}
            disabled={activeIndex === checkListTypeData?.length - 3}
          >
            <img src={RightArrow} alt="right-arrow" />
          </Button>
        </div>
        <Swiper
          freeMode={true}
          spaceBetween={50}
          slidesPerView={4}
          modules={[FreeMode, Navigation, Pagination, A11y]}
          onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {filteredFolders?.map((propertyData) => (
            <SwiperSlide>
              <div className="position-relative" onClick={() => {
                setPropertyDocumentModal(!propertyDocumentModal);
                setAddModal(false);
                setTitle(propertyData);
              }}>
                {userRole === defaultValues.role_names.seller &&(
                <div className="position-absolute end-0">
                  <AddIcon style={{ color: "#0062AE", cursor:'pointer' }} />
                </div>
             )}
                <div className="template-items" >
                  <img src="/imgs/document.png" alt="doc-img" />
                  <p>{propertyData.title}</p>
                </div>
              </div>
            </SwiperSlide>
          )
          )}
        </Swiper>
      </div>
      <PropertyDocument
        setIsPropertyModal={() =>{
          setPropertyDocumentModal(!propertyDocumentModal);
          updateAction();
        }}
        isPropertyModal={propertyDocumentModal}
        openModal={addModal}
        modalInitial = {setAddModal}
        title={title}
        setTitle={setTitle}
      />
    </div>
  );
};

export default PropertyDocumentData;
