export const addressOptions = [
  { value: "", label: "Select address..." },
  {
    value: "Address 1",
    label:
      "3rd and 4th floor, annexue building,Bangalore D.C. office Compound, Bangalore-560009",
  },
  {
    value: "Address 2",
    label:
      "No.11,12, Ist Floor, I & II Main Road,Palace Guttahalli, Bangalore 03",
  },
  {
    value: "Address 3",
    label:
      "No. 70, Ist Floor, above Kanthi Sweets,5th Main, Ganganagar, Bangalore-32.",
  },
];

export const zoneOptions = [
  { value: "", label: "Select zone..." },
  { value: "Zone A", label: "GANDHINAGAR" },
  { value: "Zone B", label: "MALLESHWARA" },
  { value: "Zone C", label: "GANGANAGAR" },
];
