import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import dayjs from "dayjs";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./style.scss";
import { agreementDetails } from "../../../utils/validationSchemas";
import {
  MenuItem,
  Select,
  FormControl,
  Autocomplete,
  createFilterOptions,
  TextField,
} from "@mui/material";
import { STAMP_STATE } from "../../../constants/states";
import { defaultValues } from "../../../constants";
import { JsStyles } from "./jsStyle";

export const AgreementDetails = ({ onSave, agreementData, readOnly }) => {
  const [disabledSD, setDisabledSD] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const filter = createFilterOptions();

  const currentDate = new Date().toISOString().split("T")[0];
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      stampValue:
        agreementData.state === defaultValues.rental_states.karnataka
          ? "0"
          : agreementData.stampValue || "",
      city: agreementData.city || "",
      signDate: agreementData.signDate || "",
      signatureVia: agreementData.signatureVia || "",
      state: agreementData.state || "",
      documentCategory: agreementData.documentCategory || "",
    },
    validationSchema: agreementDetails,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values, action) => {
      const documentCategory = getDocumentCategory(values.state);
      let payload = {
        ...values,
        stageNo: agreementData.stageNo === 3.1 ? 3.1 : 1.1,
        documentCategory: documentCategory,
        stampValue: parseFloat(values.stampValue),
      };
      if(new Date(agreementData.licenseDate) < new Date(values.signDate)){
        payload.licenseDate = "";
      }
      onSave(payload, true);
    },
  });

  const [formattedSignDate, setFormattedSignDate] = useState("");

  const getDocumentCategory = (selectedState) => {
    const selectedStateData = STAMP_STATE.find(
      (state) => state.value === selectedState
    );

    if (selectedStateData) {
      return selectedStateData.documentCategory;
    }
    return "";
  };

  const handleStateChange = (event) => {
    const { name, value } = event.target;

    if (name === "state") {
      const selectedStateData = STAMP_STATE.find(
        (state) => state.value === value
      );
      const stampDuty =
        selectedStateData &&
        selectedStateData.value === defaultValues.rental_states.karnataka
          ? "0"
          : "";
      handleChange({ target: { name: "state", value } });
      handleChange({ target: { name: "stampValue", value: stampDuty } });
      setShowTerms(
        selectedStateData.value === defaultValues.rental_states.karnataka
      );
    } else {
      handleChange(event);
    }
  };

  useEffect(() => {
    setFormattedSignDate(
      agreementData.signDate
        ? dayjs(new Date(agreementData.signDate)).format("YYYY-MM-DD")
        : ""
    );
    if (agreementData?.paymentDetails?.tra_id) {
      setDisabledSD(true);
    }
  }, [agreementData.signDate]);


  return (
    <>
      <Form className="pl-3 mt-1 container-fluid" onSubmit={handleSubmit}>
        <div className="row">
          <Form.Group className="mb-3 col-md-3">
            <Form.Label className="fee-label">Stamp State</Form.Label>
            <FormControl size="small" sx={JsStyles.min200}>
              <Select
                required
                className="fee-inputs-multiple mb-1"
                name="state"
                id="state"
                disabled={agreementData?.paymentDetails?.tra_id || readOnly}
                onBlur={handleBlur}
                onChange={handleStateChange}
                value={values.state}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select State
                </MenuItem>
                {STAMP_STATE.map((state, index) => (
                  <MenuItem key={index} value={state.value}>
                    {state.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {(touched.state || touched.city) && errors.state && (
              <p className="r-formError">{errors.state}</p>
            )}
          </Form.Group>
          <Form.Group className="mb-3 col-md-3">
            <Form.Label className="fee-label">
              Select/Enter Stamp Duty
            </Form.Label>
            <FormControl size="small" sx={JsStyles.min200}>
              <Autocomplete
                sx={JsStyles.min200}
                disabled={
                  agreementData?.paymentDetails?.tra_id ||
                  readOnly ||
                  disabledSD ||
                  values.state === defaultValues.rental_states.karnataka ||
                  values.state === ""
                }
                size="small"
                name="stampValue"
                id="stampValue"
                className="fee-inputs-multiple mb-1 pr-3"
                value={values.stampValue}
                onBlur={handleBlur}
                onChange={(event, newValue) => {
                  handleChange({
                    target: { name: "stampValue", value: newValue },
                  });
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  const isExisting = options.some(
                    (option) => inputValue === option
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push(inputValue);
                  }
                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={defaultValues.rental_stamp_value}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option;
                }}
                renderOption={(props, option) => <li {...props}>{option}</li>}
                freeSolo
                renderInput={(params) => (
                  <div>
                    <TextField type="number" {...params} />
                  </div>
                )}
              />
            </FormControl>
            {touched.stampValue && errors.stampValue && (
              <p className="r-formError">{errors.stampValue}</p>
            )}
          </Form.Group>
          <Form.Group className="mb-3 col-md-4">
            <Form.Label className="fee-label">
              City where the agreement is executed
            </Form.Label>
            <Form.Control
              required
              disabled={readOnly}
              type="text"
              name="city"
              id="city"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.city}
              placeholder="Location of the property"
              className="fee-inputs-multiple mb-1"
            />
            {touched.city && errors.city && (
              <p className="r-formError">{errors.city}</p>
            )}
          </Form.Group>
          <Form.Group className="mb-3 col-md-3 pr-4">
            <Form.Label className="fee-label">Day of Signing</Form.Label>
            <Form.Control
              disabled={readOnly}
              type="date"
              name="signDate"
              id="signDate"
              onBlur={handleBlur}
              defaultValue={formattedSignDate}
              onChange={handleChange}
              placeholder="Enter what is reason for backing Out"
              className="fee-inputs-multiple mb-1"
              min={currentDate}
            />
            {touched.signDate && errors.signDate && (
              <p className="r-formError">{errors.signDate}</p>
            )}
          </Form.Group>
          <Form.Group
            className="d-flex flex-column mb-3 col-md-3"
            style={JsStyles.min160}
          >
            <Form.Label className="fee-label">Signature via</Form.Label>
            <FormControl size="small" sx={JsStyles.min160}>
              <Select
                required
                sx={JsStyles.max37}
                disabled={readOnly}
                className="fee-inputs-multiple mb-1"
                id="signatureVia"
                name="signatureVia"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.signatureVia}
                displayEmpty
              >
                <MenuItem value="aadhaar">Aadhaar</MenuItem>
                <MenuItem value="electronic">Electronic</MenuItem>
              </Select>
            </FormControl>
            {touched.signatureVia && errors.signatureVia && (
              <p className="r-formError">{errors.signatureVia}</p>
            )}
          </Form.Group>
        </div>
        <div className="row">
          <p className="r-formNote">
            Note: Signature via cannot be changed after e-stamp purchase
          </p>
        </div>
        {!readOnly && (
          <div className="proceed-button d-flex flex-row my-3 mr-auto">
          
            <Button className="button px-5" variant="primary" type="submit">
              Save & Proceed
            </Button>
          </div>
        )}
      </Form>
      <Dialog open={showTerms}>
        <DialogTitle id="responsive-dialog-title">
          Important Notice:
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText className="text-justify">
            <p>
              As mandated by the Government of Karnataka, there have been
              changes in stamp duty calculation procedures, specifically
              concerning the issuance of stamp papers. Effective from the 5th of
              February 2024, stamp duty calculation will now be automated during
              stamp generation. This means that the stamp duty amount will be
              automatically calculated based on either the consideration amount
              or the market price, across all article codes.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", marginY: "15px" }}>
          <Button
            variant="contained"
            color="success"
            onClick={() => setShowTerms(false)}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
