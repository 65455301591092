import { createSlice } from "@reduxjs/toolkit";
import { getData, addData , updateData } from "./service";
import { ERROR_KEY, SUCCESS_KEY } from "../../constants";

export const superAdminListSlice = createSlice({
  name: "manageAdminsSlice",
  initialState: {},
  reducers: {
   
  },
  extraReducers: {
    [getData.pending]: (state, action) =>{
      return {
        ...state,
        message : "",
        status : null,
        adminData:[]
      }  
    },

    [getData.fulfilled] : (state, action)=> {
      if(action?.payload?.adminData){
        return {
          ...state,
          adminData: action.payload.adminData
        }  
      } else {
        return {
          ...state,
          status : ERROR_KEY
        }  
      }
    },

    //add data
    [addData.pending]: (state, action) =>{
      return {
        ...state,
        message : "",
        status : null,
      }  
    },

    [addData.fulfilled] : (state, action)=> {
      if(action?.payload?.adminData){
        return {
          ...state,
          message : action.payload.message,  
          status : SUCCESS_KEY,
          adminData: action.payload.adminData
        }
      } 
    },

    [addData.rejected]: (state, action) => {      
			return {
      ...state,
			message : action.payload.message,
      status : ERROR_KEY
      }
		},

    //update data
    [updateData.pending]: (state, action) =>{
      return {
        ...state,
        message : "",
        status : null,
      }  
    },

    [updateData.fulfilled] : (state, action)=> {
      if(action?.payload?.adminData){
        return {
          ...state,
          message : "Successfully Updated",
          status : SUCCESS_KEY,
          adminData: action.payload.adminData
        }  
      } else {
        return {
          ...state,
          message : "Could not Update User",
          status : ERROR_KEY
        }  
      }
    },

    
  },
});

export const { increment } = superAdminListSlice.actions;

export default superAdminListSlice.reducer;
