import React, { useEffect, useState } from "react";
import Main from "../../layout/Main";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import "./style.scss";
import { Button } from "@mui/material";
import { useToasts } from "react-toast-notifications";
import { checkPaymentStatus } from "../../services/api";
import { Routes } from "../../constants/routes";
import { ERROR_KEY, getErrorMessage } from "../../constants";

const PaymentVerify = (props) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const accessToken =
  useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const [paymentId, setPaymentId] = useState("");
  const response = {};
  
  useEffect(() => {
    if (!accessToken) {
      history.push(Routes.HOME);
    } else {
        
      setPaymentId(searchParams.get("payment_id"));      
      response.payment_id = searchParams.get("payment_id");

      if (response.payment_id !== "") {        
        response.property_id = searchParams.get("property_id");
        response.payee_id = searchParams.get("payee_id");
        response.payment_id = searchParams.get("payment_id");
        response.payment_status = searchParams.get("payment_status");
        response.payment_request_id = searchParams.get("payment_request_id");

        updatePaymentStatus(response).then((data) => {});
      }
    }
  }, []);

  const handleClick = async () => {
    try {
      if (response.payment_id !== "") {
        history.push(Routes.INITIATE_OFFER);
      } else
        addToast(getErrorMessage('E-10070'), {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
    } catch (error) {
      addToast(error.response.data.message || getErrorMessage('E-10070'), {
        appearance: ERROR_KEY,
        autoDismiss: true,
      });
    }
  };

  const updatePaymentStatus = async (payload) => {
    try {
      const { data } = await checkPaymentStatus(payload);
      return data;
    } catch (e) {
    }
  };

  return (
    <div>
      <Main showUserNavbar={!props?.showUserNavbar} showUser={!props?.showUser}>
        <div className="fee-container">
          <div className="fee-heading">
            <p>Pay Platform Fee</p>
          </div>
          <div className="fee-body">
            <p>Transaction Number : {paymentId} </p>
          </div>
          <div className="fee-body">
            <div className="proceed">
              <Button variant="contained" color="primary" onClick={handleClick}>
                Procced
              </Button>
            </div>
          </div>
        </div>
      </Main>
    </div>
  );
};
export default PaymentVerify;
