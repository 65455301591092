import { createAsyncThunk } from "@reduxjs/toolkit";
import { addAdmin, getAdmin, updateAdmin } from "../../services/api";

export const getData = createAsyncThunk(
  "checklistSlice/getData", async (args, { rejectWithValue }) => {
    try {
      const { data } = await getAdmin();
      return data;
    } catch (e) {
      rejectWithValue(e.response.data);
    }
  }
);

export const addData = createAsyncThunk(
  "manageAdminsSlice/addData", async (args, { rejectWithValue }) => {
    try {
      const { data } = await addAdmin(args);
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);


export const updateData = createAsyncThunk(
  "checklistSlice/postData", async (args, { rejectWithValue }) => {
    try {
      const { data } = await updateAdmin(args);
      return data;
    } catch (e) {
      rejectWithValue(e.response.data);
    }
  }
);

