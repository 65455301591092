/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, Card, CardHeader, CardContent } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { getPropertyData, getUsersData } from "./service";
import { getAllStages, getAllSubStages } from "../../redux/extra-services/stages.services";
import { getAllRoles } from "../../redux/extra-services/roles.services"
import { useHistory } from "react-router-dom";
import Main from "../../layout/Main";
import "./style.scss";
import { Bar } from "react-chartjs-2";
import { DatePicker } from "antd";
import moment from 'moment';
import { getProfileImg } from "../../redux/extra-services/profile.services";
import { getRoleId } from "../super-admin-manageProperties/service";
import { totalPlatformFee } from "../../services/api";
import { numberFormat } from "../../utils/HelperFunction";
import { ROLE_KEY, defaultValues } from "../../constants";
import { Routes } from "../../constants/routes";
const { RangePicker } = DatePicker;


function Dashboard(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const role = sessionStorage.getItem(ROLE_KEY);
  const chartData = useSelector((state) => state.superAdminDashboard);
  const property = Array.isArray(chartData?.propertyData) ? [...chartData.propertyData] : [];
  const userData = Array.isArray(chartData?.data) ? [...chartData.data] : [];
  const [chart1label, setChart1Label] = useState();
  const [chart2label, setChart2Label] = useState();
  const [chart1Value, setChart1Value] = useState();
  const [chart2Value, setChart2Value] = useState();
  const loggedInUser = useSelector((state) => state.loggedInUser);
  const [registeredCount, setRegisteredCount] = useState(0);
  const [totalFee, setTotalFee] = useState(0);

  const stages = useSelector((state)=> state?.stages?.stages);
  const superAdminId = useSelector((state) => state.loggedInUser.data?.data?.superAdminByEmail?._id) || ''
  const adminId = useSelector((state) => state.loggedInUser.data?.data?.adminByEmail?._id) || ''
  const userId =  role === defaultValues.role_names.super_admin ? superAdminId : adminId ;

  const accessToken = useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";

  useEffect(() => {

    if (!accessToken) {
      history.push(Routes.ADMIN_LOGIN);
    }
  }, [accessToken, history]);
  useEffect(() => {
    if (role !== defaultValues.role_names.super_admin && role !== defaultValues.role_names.admin) {
      props.history.push(Routes.HOME);
    }
  }, [role, props.history]);

  useEffect(() => {
    totalPlatformFee().then(res => {
      if(res?.data?.success) {
        setTotalFee(res.data.total);
      }
    }).catch(err => {
      //
    })
  }, []);

  useEffect(() => {
    dispatch(getProfileImg(userId))
    dispatch(getPropertyData());
    dispatch(getUsersData());
    dispatch(getAllStages());
    dispatch(getAllRoles());
    dispatch(getAllSubStages())
    dispatch(getRoleId());
  }, [dispatch, userId]);

  const isFinalStage = useCallback(stageId => {
    let finalStage = stages.filter(item => item._id === stageId);
    if(finalStage[0]?.order_number === 6) {
      return true;
    } else {
      return false;
    }
  }, [stages]);

  useEffect(() => {
    if(property && property?.length) {
      let registered = 0;
      property.map(item => {
        let isRegistered = isFinalStage(item.current_stage);
        if(isRegistered) {
          registered ++;
        }
        return true;
      });
      setRegisteredCount(registered);
    }
  }, [isFinalStage, property])

  const handleDateRangeChange = (i, date) => {
    let filteredData = [];
    let propertyTypes = [];
    const startDate = date[0];
    const endDate = date[1];
    if (startDate && endDate) {

      filteredData = chartData.propertyData.filter((property) => {
        const createdAt = new Date(property.created_at);
        return createdAt >= new Date(startDate) && createdAt <= new Date(endDate);
      });
    }

    if (filteredData) {
      propertyTypes = filteredData.reduce((types, property) => {
        if (!types.includes(property.PropertyType)) {
          types.push(property.PropertyType);
        }
        return types;
      }, []);
      setChart1Label(propertyTypes);
    }
    const chartDataPoints = propertyTypes?.map((type) => {
      return filteredData.filter(
        (property) => property.PropertyType === type
      ).length;
    });
    setChart1Value(chartDataPoints);
  };

  const Chart1Values = useCallback((propertyTypes) => {
    const chartDataPoints = propertyTypes?.map((type) => {
      return chartData.propertyData.filter(
        (property) => property.PropertyType === type
      ).length;
    });
    setChart1Value(chartDataPoints)
  }, [chartData.propertyData]);

  const Chart1Labels = useCallback(() => {
    if (chartData && chartData.propertyData) {
      let propertyTypes = [];
      propertyTypes = chartData.propertyData.reduce((types, property) => {
        if (!types.includes(property.PropertyType)) {
          types.push(property.PropertyType);
        }
        return types;
      }, []);
      setChart1Label(propertyTypes);
      Chart1Values(propertyTypes)
    }
  },[Chart1Values, chartData]);

  const chartlabel2 = useCallback(() => {
    let roleNames = [];
    const user = chartData.data;
    if (user) {
      roleNames = user.reduce((names, item) => {
        const role = item.primary_role;
        if (role && role.role_name) {
          names.push(role.role_name);
        }
        return names;
      }, []);
    }
    const countByRoleName = roleNames.reduce((count, roleName) => {
      count[roleName] = (count[roleName] || 0) + 1;
      return count;
    }, {});
    setChart2Label(Object.keys(countByRoleName));
    setChart2Value(Object.values(countByRoleName))
  }, [chartData]);

  useEffect(() => {
    Chart1Labels();
    chartlabel2();
  }, [Chart1Labels, chartlabel2])

  const chart1 = {
    labels: chart1label,
    redraw: true,
    datasets: [
      {
        label: "Count of Properties",
        data: chart1Value,
        backgroundColor: "#0062AE",
        barThickness: 50,
        borderWidth: 1,
        hoverBackgroundColor: "#59a9ff",
        hoverBorderColor: "#0062AE",
        hoverBorderWidth: 1,
        clip: { left: 5, top: false, right: -2, bottom: 0 },
        indexAxis: "y",
        options: {
          scales: {
            y: {
              min: 0,
            },
          },
        },
      },
    ],
  };

  const handleUserRangeChange = (i, date) => {
    let roleNames = [];
    let filteredUserData = [];
    const startDate = date[0];
    const endDate = date[1];
    if (startDate && endDate) {

      filteredUserData = chartData.data.filter((user) => {
        const createdAt = new Date(user.created_at);
        return createdAt >= new Date(startDate) && createdAt <= new Date(endDate);
      });
    }

    if (filteredUserData) {
      roleNames = filteredUserData.reduce((names, item) => {
        const role = item.primary_role;
        if (role && role.role_name&& !names.includes(role.role_name)) {
          names.push(role.role_name);
        }
        return names;
      }, []);
      setChart2Label(roleNames);
    }
    const chartDataPoints = roleNames.map((name) => {
      return filteredUserData.filter((item) => item?.primary_role?.role_name === name).length;
    });
    setChart2Value(chartDataPoints);
  }

  const chart2 = {
    labels: chart2label,
    redraw: true,
    datasets: [
      {
        label: "Count of User",
        data: chart2Value,
        backgroundColor: "#0062AE",
        barThickness: 50,
        borderWidth: 1,
        hoverBackgroundColor: "#59a9ff",
        hoverBorderColor: "#0062AE",
        hoverBorderWidth: 1,
        clip: { left: 5, top: false, right: -2, bottom: 0 },
        indexAxis: "y",
        options: {
          scales: {
            y: {
              min: 0,
            },
          },
        },
      },
    ],
  };
  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };
  const onChange = (id) => {
    history.push(`${Routes.VIEW_PROPERTY}?pid=${id}`);
  };

  const allUsers = () => {
    history.push(Routes.MANAGE_USERS);

  }
  const allProperties = () => {
    history.push(Routes.MANAGE_PROPERTIES);

  }
  let compareProperty = (a, b) => {
    const dateA = new Date(a?.created_at);
    const dateB = new Date(b?.created_at);
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  };
  property.sort(compareProperty);

  let compareUser = (a, b) => {
    const date1 = new Date(a?.created_at);
    const date2 = new Date(b?.created_at);
    if (date1 < date2) {
      return -1;
    }
    if (date1 > date2) {
      return 1;
    }
    return 0;
  };
  userData.sort(compareUser);

  return (
    <Main
      showNav={!props?.showNav}
      showFooter={!props?.showFooter}
      showLogo={!props?.showLogo}
      showAdminNavbar={!props?.showAdminNavbar}
    >
      <div className="dashboard-content">
        {role === defaultValues.role_names.super_admin ? (
          <h2>
            Welcome {loggedInUser?.data?.data?.superAdminByEmail?.first_name}!
          </h2>
        ) : (
          <h2>Welcome {loggedInUser?.data?.data?.adminByEmail?.first_name}!</h2>
        )}
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid xs={3} md={3} p={2}>
              <Card sx={{ maxWidth: 345 }}>
                <CardHeader style={{ backgroundColor: '#0474BD', color: '#fff' }}

                  title={chartData?.data?.length}
                  subheader="All users"
                />
              </Card>
            </Grid>
            <Grid xs={3} md={3} p={2}>
              <Card sx={{ maxWidth: 345 }}>
                <CardHeader style={{ backgroundColor: '#B8E5FA', color: '#fff' }}

                  title={chartData?.propertyData?.length}
                  subheader="Total Properties"
                />
              </Card>
            </Grid>
            <Grid xs={3} md={3} p={2}>
              <Card sx={{ maxWidth: 345 }}>
                <CardHeader style={{ backgroundColor: '#0474BD', color: '#fff' }}

                  title={registeredCount}
                  subheader="Registered property"
                />
              </Card>
            </Grid>
            <Grid xs={3} md={3} p={2}>
              <Card sx={{ maxWidth: 345 }}>
                <CardHeader style={{ backgroundColor: '#B8E5FA', color: '#fff' }}

                  title={numberFormat(totalFee)}
                  subheader="Platform fee collected"
                />
              </Card>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid xs={8} md={8} p={2}>
              <Card className='chart'>
                <CardContent p={1}>
                  <RangePicker className="date-picker float-end" onChange={handleDateRangeChange}  disabledDate={disabledDate}/>
                  <div className="chart-box">
                    <Bar data={chart1} />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={4} md={4} p={2}>
              <Card>
                <CardHeader title="Property Activity" />
                <CardContent p={1}>
                  <Grid container>
                    {property.reverse().slice(0, 4).map((data, index) => (
                      <Grid key={index} xs={10} md={10} mb={1}>
                        <p className="m-0">{data.re_id}</p>
                        <p className="m-0" onClick={() => onChange(data?._id)}>{data?.PropertyType}</p>
                        <p className="m-01">{new Date(data?.created_at)?.toLocaleDateString()}</p>
                      </Grid>
                    ))}
                    <p className="m-02" onClick={allProperties}>View all Property</p>


                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid xs={8} md={8} p={2}>
              <Card className="chart">
                <CardContent p={1}>
                  <RangePicker className="date-picker float-end" onChange={handleUserRangeChange} disabledDate={disabledDate}/>
                  <div className="chart-box">
                    <Bar data={chart2} />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={4} md={4} p={2}>
              <Card>
                <CardHeader title="User Activity" />
                <CardContent p={1}>
                  <Grid container>
                    {userData.reverse().slice(0, 4).map((data, index) => (
                      <Grid key={index} xs={10} md={10} mb={1}>
                        <p className="m-0" >{data?.first_name}</p>
                        <p className="m-01">{new Date(data?.created_at)?.toLocaleDateString()}</p>
                      </Grid>
                    ))}
                    <p className="m-02" onClick={allUsers}>View all Users</p>

                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </div>
    </Main>
  );
}
export default Dashboard;
