import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { rentalPayRequest } from "../../../services/api";
import { useToasts } from "react-toast-notifications";
import { Routes } from "../../../constants/routes";
import { numberFormat } from "../../../utils/HelperFunction";
import { API } from "../../../utils/api";
import dayjs from "dayjs";
import "./style.scss";
import { JsStyles } from "./jsStyle";

export const PaymentRentalAgreement = ({ onSave }) => {
  const selectedRental = useSelector(
    (state) => state.rentalAgreement.selectedRental
  );
  const { addToast } = useToasts();
  const [agrData, setAgrData] = useState({});

  useEffect(() => {
    if (selectedRental) {
      setAgrData(selectedRental);
    }
  }, [selectedRental]);

  const onPayment = () => {
    var redirectUrl =
      `${API.redirectUrl[API.currenEnv]}` +
      `${Routes.SUBMIT_RENTAL_AGREEMENT}?refId=${agrData._id}`;

    rentalPayRequest({ id: agrData._id, redirectUrl: redirectUrl })
      .then((res) => {
        if (res.data.status) {
          window.location.href = res.data.payUrl;
        }
      })
      .catch((err) => {
        addToast(err?.response?.data?.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const getTotalPayment = (agrData) => {
    return numberFormat(
      agrData.stampValue +
      agrData.agreementFess +
      (agrData.agreementFess * 18) 
      / 100
    );
  }

  return (
    <div className="row mt-3 text-center">
      {agrData?.paymentDetails?.tra_id ? (
        <div className="col-md-6 m-auto p-4 text-center border rounded">
          <h5 className="mb-3 r-title">Payment received successfully</h5>
          <div className="d-flex flex-column justify-content-center my-2">
            <div className="r-point-text">
              <p className="r-point-name">Transaction Id :</p>
              <p className="r-point-value">{agrData.paymentDetails.tra_id}</p>
            </div>
            <div className="r-point-text">
              <p className="r-point-name">Payment Date :</p>
              <p className="r-point-value">
                {dayjs(new Date(agrData.paymentDetails.pay_date)).format(
                  "DD-MMM-YYYY"
                )}
              </p>
            </div>
            <div className="r-point-text " style={JsStyles.seaColor}>
              <p className="r-point-name">Amount :</p>
              <p className="r-point-value">
                {numberFormat(agrData.paymentDetails.pay_amount)}
              </p>
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <div className="proceed-button text-center">
              <Button
                className="button px-5 my-3 ml-2"
                variant="primary"
                onClick={() => onSave({ stageNo: 3 })}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-md-6 m-auto p-4 text-center border rounded">
          <h4 className="mb-3 r-title">Rental Agreement - Payment</h4>
          <div className="d-flex flex-column justify-content-center my-2">
            <div className="r-point-text">
              <p className="r-point-name">PlatForm Fee :</p>
              <p className="r-point-value">
                {numberFormat(agrData.agreementFess)}
              </p>
            </div>
            <div className="r-point-text">
              <p className="r-point-name">GST 18% :</p>
              <p className="r-point-value">
                {numberFormat((agrData.agreementFess * 18) / 100)}
              </p>
            </div>
            <div className="r-point-text">
              <p className="r-point-name">Stamp Duty :</p>
              <p className="r-point-value">
                {numberFormat(agrData.stampValue)}
              </p>
            </div>
            <div
              className="r-point-text mr-2"
              style={{ backgroundColor: "var(--seaShell)" }}
            >
              <p className="r-point-name">Total Payment :</p>
              <p className="r-point-value">
                {getTotalPayment(agrData)}
              </p>
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <div className="proceed-button text-right">
              <Button
                className="button px-5 my-3 ml-2"
                variant="primary"
                onClick={onPayment}
              >
                Pay Now
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
