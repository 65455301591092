import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from '../../assets/logo_new.png';
import Button from "@mui/material/Button";
import { AccountCircle, PersonAdd } from "@mui/icons-material";
import { Routes } from "../../constants/routes";
import "./GeneralNav.css"; // Import your CSS file for styling
import { API } from "../../utils/api";

function GeneralNav() {
  const history = useHistory();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleLogin = () => {
    history.push(Routes.HOME);
  };
  const handleSignup = () => {
    history.push(Routes.SIGN_UP);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <header className="header">
      <nav>
        <div className="logo">
          <a href={API.footerUrl.home}>
            <img src={logo} alt="Logo"/>
          </a>
        </div>
        <div className="hamburger-menu" onClick={toggleMobileMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <ul className={`menu-landing-general ${mobileMenuOpen ? "mobile-menu-open" : ""}`}>
          <li>
            <Button href={API.footerUrl.home}>Home</Button>
          </li>
          <li>
            <Button href={API.footerUrl.about_us}>About Us</Button>
          </li>
          <li>
            <Button href={API.footerUrl.contact}>Contact Us</Button>
          </li>
              <li>
                <Button
                  variant="contained"
                  style={{background: 'var(--primaryButton)'}}
                  size="large"
                  sx={{ marginRight: "3px" }}
                  onClick={handleLogin}
                >
                  <AccountCircle sx={{ marginRight: "8px", color: 'var(--white)' }} />
                  Sign In
                </Button>
              </li>
              <li>
                <Button
                  variant="contained"
                  style={{background: 'var(--primaryButton)'}}
                  size="large"
                  onClick={handleSignup}
                >
                  <PersonAdd sx={{ marginRight: "8px", color: 'var(--white)' }} />
                  Sign Up
                </Button>
              </li>
        </ul>
      </nav>
    </header>
  );
}

export default GeneralNav;
