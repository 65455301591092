import React , {useEffect} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import InitiateOffers from "../../commonComponent/initiateOffers";
import { defaultValues } from "../../constants";
import { Routes } from "../../constants/routes";

const ReviewMou = () => {

  const history = useHistory();
  const location = useLocation();
  const accessToken = useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";
  const propertyId = useSelector((state)=> state?.buyerHomeData?.selectedProperty?._id) || '';
  const pid = location.search.split("=")[1]
  const role = useSelector((state) => state.loggedInUser.preferredRole);
  let heading = "";
  let text = "";

  useEffect(() => {

    if (!accessToken) {
      history.push(Routes.HOME);
    }else{
      if(!propertyId) history.push(Routes.PROPERTIES);
      else if(pid !== propertyId){
        history.push(`${history.location.pathname}?pid=${propertyId}`);        
      } 
    }
  }, []);

  if(history.location.pathname === Routes.SIGN_MOU){
    heading = "Sign MOU";
  text = <span style={{ color: "Black" }}>Please choose the stamp value{text}</span>
  }else {
    heading = defaultValues.actions.initiate_mou;
    if (role === defaultValues.role_names.seller|| role === defaultValues.role_names.lawyer) {
      heading = defaultValues.actions.review_mou;
    }
  }
  
    return (
      <InitiateOffers
        templateSubType={defaultValues.doc_sub_types.mou}
        templateType={defaultValues.template_types.document}
        heading={heading}
        subheading={text}
        type={"review"}
        buttonText={"Finalize MOU"}
        proceedUrl={ role === defaultValues.role_names.buyer && Routes.TOKEN_PAYMENT}
      />
    );
};

export default ReviewMou