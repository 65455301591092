export const primaryButtonStyle = {
    paddingLeft: '3%', 
    paddingRight: '3%', 
    background: 'var(--primaryButton)'
};

export const paraStyle = {
    marginTop: 15,
};

export const listItemMargin = {
    marginTop: 10,
};