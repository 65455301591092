import React, { useEffect, useState } from "react";
import Main from "../../layout/Main";
import circlePlus from "../../assets/circle-plus.svg";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { getManagePropData, getStagesData } from "./service";
import AddPropertyModal from "../../layout/PropertyModal/AddPropertyModal/addPropertyModal";
import Loader from "../../layout/Loader/Loader";
import { DropdownButton, Dropdown, Button, Col, Image, Row } from "react-bootstrap";
import AddProperties from "./adding-property";
import { useHistory } from "react-router-dom";
import { Routes } from "../../constants/routes";
import { ROLE_KEY, defaultValues, getErrorMessage } from "../../constants";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { customStyles } from "../user-home/customStyles";
import { getAllRentalAgreement, getMyRentalAgreement } from "../rental-agreement-new/service";
import { BsSearch } from "react-icons/bs";
import ellipse from "../../assets/Ellipse-green.svg";
import SearchBar from "../../../src/layout/SearchBar/index";


const ManageProperties = (props) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const role = sessionStorage.getItem(ROLE_KEY);
  const [addModal, setAddModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedTitle, setSelectedTitle] = useState('Select Stage');
  const [search, setSearch] = useState('');
  const accessToken = useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";
  const allStagesData = useSelector((state)=> state?.stages?.stages) || [];
  const [tabValue, setTabValue] = useState("properties");
  const [rentalAgreementData, setRentalAgreementData] = useState(null);
  const userId = useSelector(
    (state) => {
      return state.loggedInUser?.data?.data?.superAdminByEmail?._id || state.loggedInUser?.data?.data?.adminByEmail?._id
    }
  );
  const handleSelect = (eventKey) => {
    setSelectedValue(eventKey);
    eventKey !== 'all'
      ? setSelectedTitle((allStagesData.find(obj => obj._id === eventKey)).stage_name)
      : setSelectedTitle("All Stages");  
  };

   useEffect(() => {
    getProperties();
    dispatch(getStagesData())
  }, []);

  useEffect(() => {
    if (!accessToken) {
      history.push(Routes.SUPER_ADMIN_LOGIN);
    }
    if (role !== defaultValues.role_names.super_admin && role !== defaultValues.role_names.admin) {
      props.history.push(Routes.HOME);
    }
  }, []);

  // function to fetch all the propeties from backend
  const getProperties = () => {
    setIsLoading(true)
    dispatch(getManagePropData()).then(() => setIsLoading(false));
  }

  const changeHandle = (event, newValue) => {
    setTabValue(newValue);    
  };
  useEffect(() => {
    dispatch(getAllRentalAgreement())
      .then(response => {
        setRentalAgreementData(response.payload.data.rentalData);
      })
      .catch(error => {
        return error
      });
  }, []);

  
  const getPartiesName = (partyType, list) => {
    const names = list
      .map((user) => {
        return user.type === partyType
          ? `${user?.first_name} ${user?.last_name}, `
          : null;
      })
      .join("")
      .slice(0, -2);
    return names === "" ? "Not Added" : names;
  };

  const getRole = (argData) => {
    const li = argData.rental_parties.filter(
      (party) => party.userId === userId
    );
    if (li.length) {
      return li[0].type === defaultValues.rental_roles.owner
        ? "Owner"
        : "Tenant";
    } else {
      return argData.createdBy === userId ? "Initiator" : "NA";
    }
  };

  useEffect(() => {
    dispatch(getMyRentalAgreement({ refId: searchValue }));
  }, [searchValue]);

  return (
    <div>
      {isLoading ? <Loader /> :
        <Main
          showNav={!props?.showNav}
          showFooter={!props?.showFooter}
          showLogo={!props?.showLogo}
          showAdminFooter={!props?.showAdminFooter}
          showAdminNavbar={!props?.showAdminNavbar}
          onChange={setSearch}>
          <div className="manage-area">
            <div className="mainCont">
            </div>
          <TabContext value={tabValue}>
            <Box sx={customStyles.tabBorder}>
              <TabList  value={tabValue} aria-label="basic tabs example"  
                centered onChange={changeHandle} >
                <Tab label="Properties" className="r-menu-text" value="properties"/>
                <Tab label="Rental" className="r-menu-text" value="rental"/>                
              </TabList>
            </Box>
            <TabPanel value="properties">
              <div>
              <SearchBar {...props}/>
              <div className="manage-btn-wrapper">
                <img alt="add property btn"
                  src={circlePlus}
                  className="manage-btn-img"
                  onClick={() => setAddModal(!addModal)}
                />
                <h6 className="addPropText">Add Property</h6>
              </div>
                  <div className="selectStageContainer">
                <DropdownButton
                  id="dropdown-basic-button"
                  className="selectStage1"
                  title={selectedTitle}
                  onSelect={handleSelect}
                >
                  <Dropdown.Item eventKey="all">All Stages</Dropdown.Item>
                  {allStagesData?.map((item) => (
                    <Dropdown.Item eventKey={item._id} >
                      {item.stage_name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
                </div>
                <AddProperties event={search} selectedValue={selectedValue}/>
              </div> 
            </TabPanel>
            <TabPanel value="rental">
            <div className="search-box">
        <input
          type="text"
          className="input"
          placeholder="Search for rental properties with rental ID No"
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <img src={ellipse} alt="ellipse" className="search-circle" />
        <BsSearch className="bs-search" />
      </div>
            <div className="plusCircle">
        <img
          alt="property Add button"
          src={circlePlus}
          className="manage-btn-img"
          onClick={() => history.push(Routes.SUBMIT_RENTAL_AGREEMENT)}
        />
        <p>Add Rental Agreement</p>
      </div>
            <div>
          {rentalAgreementData && rentalAgreementData.length > 0 ? (
            rentalAgreementData.map((rental, index) => (
              <Row style={customStyles.contentContainerStyle} key={index}>
                <Col xs={12} md={2} className="imageContainer">
                  <Image
                    src="/imgs/interior.jpg"
                    alt="addProp"
                    style={customStyles.defaultImage}
                  />
                </Col>
                <Col xs={12} md={4} className="fieldsContainer">
                  <p>
                    <span className="labelArea">Reference No:</span>{" "}
                    <span>{rental.refId}</span>
                  </p>
                  <p>
                    <span className="labelArea">Owner name: </span>
                    <span>
                      {getPartiesName(
                        defaultValues.rental_roles.owner,
                        rental?.rental_parties
                      )}
                    </span>
                  </p>
                  <p>
                    <span className="labelArea">City:</span>{" "}
                    <span>{rental?.city}</span>
                  </p>
                </Col>
                <Col xs={12} md={4} className="fieldsContainer">
                  <p>
                    <span className="labelArea">Agreement Type :</span>{" "}
                    <span>
                      {rental?.type === defaultValues.rental_agreement_type.new
                        ? "New"
                        : "Renew"}
                    </span>
                  </p>
                  <p>
                    <span className="labelArea">Tenant name: </span>
                    <span>
                      {getPartiesName(
                        defaultValues.rental_roles.tenant,
                        rental?.rental_parties
                      )}
                    </span>
                  </p>
                  <span className="labelArea">Sign date:</span>{" "}
                  <span className="text-dark">
                    {rental.signDate ? rental.signDate.split("T")[0] : "N/A"}
                  </span>
                </Col>
                <Col xs={12} md={2} className="fieldsContainer">
                  <p>
                    <span className="labelArea">Role:</span>{" "}
                    <span>{getRole(rental)}</span>
                  </p>
                  <p>
                    <span className="labelArea">Signature Via:</span>{" "}
                    <span>{(rental?.signatureVia || "").toUpperCase()}</span>
                  </p>
                  <div>
                  {(getRole(rental) === "Owner" || getRole(rental) === "Seller" || getRole(rental) === "Initiator") && rental.stageNo !== 5 ? (
                <Button
                  style={customStyles.editBtn}
                  className="button px-4 mx-auto"
                  variant="primary"
                  onClick={() => {
                    history.push({
                      pathname: Routes.SUBMIT_RENTAL_AGREEMENT,
                      state: { argId: rental._id },
                    });
                  }}
                >
                  Edit
                </Button>
              ) : (
                <p className="labelArea badge badge-info text-wrap">
                  {rental.stageNo === 5 ? "Agreement generated" : "Agreement in progress"}
                </p>
              )}
                  </div>
                </Col>
              </Row>
            ))
          ) : (
            <div className="">
              <span className="no-record-found">
                {getErrorMessage("E-10278")}
              </span>
            </div>
          )}
        </div>
            </TabPanel>
          </TabContext>    
            
          </div>
        </Main>
      }
      <AddPropertyModal key="addPropertyModel"
        setIsDisplay={() => setAddModal(!addModal)}
        isDisplay={addModal}
        getUpdatedProperty={getProperties}
      />
    </div>
  );
};
export default ManageProperties;
