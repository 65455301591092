import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./style.scss";

const MessageModel = ({ showPopup, title, message, callback}) => {
  const [open, setOpen] = React.useState(showPopup);

  return (
    <Dialog
      open={open}
      onClose={() => { setOpen(false); callback(); }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="pb-2 proceed-button">
        <Button className="mr-2 button" onClick={() => { setOpen(false); callback(); } }>Cancel</Button>
        <Button className="mr-2 button" onClick={callback} autoFocus>Proceed</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MessageModel;
