import { createSlice } from "@reduxjs/toolkit";
import {
  getNewRentalAgreement,
  submitNewRentalAgreement,
  getMyRentalAgreement,
  updateRentalParty,
  r_updatePaymentDetails,
} from "./service";
const initialState = {
  isLoading: false,
  agreements: [],
  selectedRental: null,
};

export const rentalAgreementSlice = createSlice({
  name: "rentalAgreement",
  initialState,
  reducers: {
    resetSelectedRental: (state, action) => {
      return { ...state, selectedRental: null };
    },
    clearAll: (state, action) => {
      const argData = action.payload;
      return {
        ...state,
        selectedRental: {
          _id: argData._id,
          refId: argData.refId,
          stageNo: 1,
          stampValue: "",
          state: "",
          city: "",
          signDate: "",
          signatureVia: "",
          documentCategory: "",
          rental_parties: [],
          propertyAddress: "",
          bhk: "",
          carPark: "",
          area: "",
          furnishing: "",
          securityDeposit: "",
          payDetails: "",
          rentAmount: "",
          maintenance: "",
          maintenanceAmt: "",
          hikePer: "",
          licenseDate: "",
          duration: "",
          noticePeriod: "",
          lockPeriod: "",
          stayModel: "",
        },
      };
    },
  },
  extraReducers: {
    [getMyRentalAgreement.pending]: (state, action) => {
      return { ...state, isLoading: true };
    },
    [getMyRentalAgreement.fulfilled]: (state, action) => {
      if (action.payload?.data?.status) {
        return {
          ...state,
          isLoading: false,
          agreements: action.payload?.data?.data,
        };
      } else {
        return {
          ...state,
          isLoading: false,
        };
      }
    },
    [getMyRentalAgreement.rejected]: (state, action) => {
      return { ...state, isLoading: false };
    },

    [getNewRentalAgreement.pending]: (state, action) => {
      return { ...state, isLoading: true };
    },
    [getNewRentalAgreement.fulfilled]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        selectedRental: action.payload?.data?.status
          ? action.payload?.data?.data
          : state.selectedRental,
      };
    },
    [getNewRentalAgreement.rejected]: (state, action) => {
      return { ...state, isLoading: false };
    },

    [submitNewRentalAgreement.pending]: (state, action) => {
      return { ...state, isLoading: true };
    },
    [submitNewRentalAgreement.fulfilled]: (state, action) => {
      return {
        ...state,
        selectedRental: action.payload?.data?.status
          ? action.payload.data.data
          : state.selectedRental,
        isLoading: false,
      };
    },
    [submitNewRentalAgreement.rejected]: (state, action) => {
      return { ...state, isLoading: false };
    },

    [updateRentalParty.pending]: (state, action) => {
      return { ...state, isLoading: true };
    },
    [updateRentalParty.fulfilled]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        selectedRental: action.payload?.data?.status
          ? action.payload.data.data
          : state.selectedRental,
      };
    },
    [updateRentalParty.rejected]: (state, action) => {
      return { ...state, isLoading: false };
    },

    [r_updatePaymentDetails.pending]: (state, action) => {
      return { ...state, isLoading: true };
    },
    [r_updatePaymentDetails.fulfilled]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        selectedRental: action.payload?.data?.status
          ? action.payload?.data?.data
          : state.selectedRental,
      };
    },
    [r_updatePaymentDetails.rejected]: (state, action) => {
      return { ...state, isLoading: false };
    },
  },
});

export const { resetSelectedRental, clearAll } = rentalAgreementSlice.actions;
export default rentalAgreementSlice.reducer;
