import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import { Button } from "@mui/material";
import editIcon from "../../../assets/edit-icon.svg";
import PriceDetailEditProperty from "../../../layout/PropertyModal/EditPropertyModal/priceDetailEditProperty";
import { getPropertiesData } from "../service";
import Loader from "../../../layout/Loader/Loader";
import TooltipErrorComponent from "../../../commonComponent/TooltipError";
import { numberFormat } from "../../../utils/HelperFunction";
import { PID_KEY, ROLE_KEY, defaultValues } from "../../../constants";
import { useToasts } from "react-toast-notifications";
import { INFO_KEY, getErrorMessage } from "../../../constants";

const PriceDetailsData = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const search = useLocation().search;
  const [editDisabled, setEditDisabled] = useState(false);
  const { role = {}, data = {} } = props;
  const { errorArray, disabledRole, path, action } = data;
  const searchParams = new URLSearchParams(search);

  const [isLoading, setIsLoading] = useState(false);
  const [priceEditModal, setPriceEditModal] = useState(false);

  const viewPropertiesData = useSelector(
    (state) => state.superAdminProperties.propertyData
  );
  const propertyId =
    useSelector((state) => state?.buyerHomeData?.selectedProperty?._id) || "";

  const getData = () => {
    setIsLoading(true);
    dispatch(getPropertiesData(searchParams.get(PID_KEY))).then(() =>
      setIsLoading(false)
    );
  };

  const handleActionClick = (path) => {

    if (propertyId) {
      history.push(`${path}?pid=${propertyId}`)
    }
    sessionStorage.setItem("propertyID", searchParams.get(PID_KEY));
  };
  const currentStageOrder =viewPropertiesData?.current_stage?.order_number;

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="view-property-box">
          <div
            className={`view-plus-area ${sessionStorage.getItem(ROLE_KEY) === defaultValues.role_names.super_admin && "extended"
              }`}
          >
            <>
              <div className="view-left">
              <div className="heading-and-edit">
              <h4 className="fw-bold font-color">Price Details</h4>
                  <div>
                    {["BUYER", "SELLER", "SUPERADMIN", "ADMIN"].includes(role) && (
                      <img
                        src={editIcon}
                        className="editIcons"
                        alt="edit"
                        onClick={() => {
                          if (editDisabled) {
                            addToast(getErrorMessage("E-10143"), {
                              appearance: INFO_KEY,
                              autoDismiss: true,
                            });
                          } else {
                            setPriceEditModal(!priceEditModal);
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
                <table className="property-table">
                  <tbody>
                    <tr>
                      <td className="labelArea">Platform Fee: </td>
                      <td>
                        {currentStageOrder > 1 && viewPropertiesData.platform_fee_details === null
                          ? '0'
                          : viewPropertiesData.platform_fee_details
                            ? numberFormat(viewPropertiesData.platform_fee_details?.payment_amount)
                            : defaultValues.actions.to_be_paid
                        }
                      </td>                    </tr>
                    <tr>
                      <td className="labelArea">TDS: </td>
                      <td>{viewPropertiesData?.ca?.tds_amount ? numberFormat(viewPropertiesData.ca.tds_amount) : defaultValues.actions.to_be_paid}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="view-right">
                <table className="property-table">
                  <tbody>
                    <tr>
                      <td className="labelArea">Agreement Amount: </td>
                      <td>
                        {
                        viewPropertiesData?.agreement_payment ? 
                        numberFormat(viewPropertiesData.agreement_payment) 
                        : 
                        defaultValues.actions.to_be_paid
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="token-right">
                <table className="property-table">
                  <tbody>
                    <tr>
                      <td className="labelArea">Token Amount: </td>
                        <td>
                          {
                            viewPropertiesData?.token_payment ?
                            numberFormat(viewPropertiesData.token_payment)
                            :
                            defaultValues.actions.to_be_paid
                          }
                        </td>           
                    </tr>
                    <tr>
                      <td className="labelArea">Offered Price: </td>
                        <td>
                          {
                            viewPropertiesData?.document_meta_data?.offered_purchase_price?
                            numberFormat(viewPropertiesData?.document_meta_data?.offered_purchase_price)
                            :
                            defaultValues.actions.to_be_paid
                          }
                        </td>           
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          </div>
          {errorArray.length && (disabledRole?.includes(role)) ?
            <Tooltip
              title={
                <TooltipErrorComponent
                  errors={errorArray}
                />
              }
              placement="left"
              arrow
            >
              <Button className= {errorArray.length && disabledRole?.includes(role) ? 'button-area-disabled' : 'button-area'}>
                Action
              </Button>
            </Tooltip>
            :
            <div className="action-button-area" onClick={()=>handleActionClick(path)}>
              <p>{action}</p>
            </div>
            }
        </div>
      )}
      <PriceDetailEditProperty
  setIsPriceEditModal={() => setPriceEditModal(!priceEditModal)}
  isPriceEditModal={priceEditModal}
  data={viewPropertiesData}
  callBack={getData}
/>
    </div>
  );
};
export default PriceDetailsData;
