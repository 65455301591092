import React from "react";
import { useLocation , useHistory} from 'react-router-dom';

import './style.scss'

import man from '../../assets/man.svg';
import manCog from '../../assets/man-cog.svg';
import properties from '../../assets/properties.svg';
import templates from '../../assets/template.svg';
import checklist from '../../assets/checklist.svg';
import cog from '../../assets/cog.svg';

import review from "../../assets/review.svg"
import contract from "../../assets/contract.svg"
import searchData from "../../assets/search-data.svg"
import signature from "../../assets/signature.svg"
import wallet from "../../assets/wallet.svg"
import writing from "../../assets/writing.svg"
import money from "../../assets/money.svg"
import logoutIcon from "../../assets/logout.png"
import { store } from "../../redux/store";
import { defaultValues } from "../../constants";
import { Routes } from "../../constants/routes";

function NavItem({ icon, role, text, path, currentPath }) {
  const history = useHistory()
  let updatedPath = currentPath;
 if(updatedPath?.includes("view-property") && path === Routes.MANAGE_PROPERTIES){
  updatedPath=path;
 }
    const handleClick = (path)=>{
      if(text === defaultValues.actions.logout){
        sessionStorage.clear();
      	store.dispatch({type: defaultValues.dispatch_actions.logout});
        history.push(path)
      }else{
        if([defaultValues.role_names.admin, defaultValues.role_names.super_admin].includes(role)){
          history.push(path)
        }
      }
    }


  return (
    <div className={`nav-item ${updatedPath == path ? 'active' : ''}`} onClick={()=>handleClick(path)}>
      <div className="nav-icon"><img className="pic" src={icon} alt="nav-ic" /> </div>
      <div className="nav-text"><div>{text}</div></div>
    </div>
  )
}



function Page(props) {
  const {role} = props
  const location = useLocation();
  const currentPath = location.pathname;

  return (
		<>
			<>
				{role === defaultValues.role_names.buyer || role === defaultValues.role_names.buyer_agent || role === defaultValues.role_names.buyer_poa ? (
					<>
						<div className="nav inner">
							<NavItem
								icon={wallet}
								role={role}
								text={defaultValues.flow_list.pay_platform_fee}
								currentPath={currentPath}
								path={Routes.PLATFORM_FEE}
							/>
							<NavItem
								icon={searchData}
								role={role}
								text={defaultValues.flow_list.initiate_offer}
								currentPath={currentPath}
								path={Routes.INITIATE_OFFER}
							/>
							<NavItem
								icon={signature}
								role={role}
								text={defaultValues.flow_list.sign_offer}
								currentPath={currentPath}
								path={Routes.SIGN_OFFER}
							/>
							<NavItem
								icon={contract}
								role={role}
								text={defaultValues.flow_list.initiate_mou}
								currentPath={currentPath}
								path={Routes.REVIEW_MOU}
							/>
							<NavItem
								icon={money}
								role={role}
								text={defaultValues.flow_list.pay_token_amount}
								currentPath={currentPath}
								path={Routes.TOKEN_PAYMENT}
							/>
							<NavItem
								icon={signature}
								role={role}
								text={defaultValues.flow_list.sign_mou}
								currentPath={currentPath}
								path={Routes.SIGN_MOU}
							/>
							<NavItem
								icon={contract}
								role={role}
								text={defaultValues.flow_list.initiate_sale_agreement}
								currentPath={currentPath}
								path={Routes.REVIEW_SALE_AGREEMENT}
							/>
							<NavItem
								icon={wallet}
								role={role}
								text={defaultValues.flow_list.pay_agreement_fee}
								currentPath={currentPath}
								path={Routes.REGISTRATION}
							/>
							<NavItem
								icon={writing}
								role={role}
								text={defaultValues.flow_list.sign_sale_agreement}
								currentPath={currentPath}
								path={Routes.SIGN_AGREEMENT}
							/>
							<NavItem
								icon={money}
								role={role}
								text={defaultValues.flow_list.apply_loan}
								currentPath={currentPath}
								path={Routes.APPLY_LOAN}
							/>
							<NavItem
								icon={money}
								role={role}
								text={defaultValues.flow_list.pay_tds}
								currentPath={currentPath}
								path={"/"}
							/>
							<NavItem
								icon={searchData}
								role={role}
								text={defaultValues.flow_list.initiate_sale_deed}
								currentPath={currentPath}
								path={Routes.REVIEW_SALE_DEED}
							/>
						</div>
					</>
				) : role === defaultValues.role_names.seller ||
				  role === defaultValues.role_names.seller_poa ||
				  role === defaultValues.role_names.seller_agent ? (
					<>
						<div className="nav inner">
							<NavItem
								icon={contract}
								role={role}
								text={defaultValues.flow_list.offer_letter}
								currentPath={currentPath}
								path={Routes.OFFER_LETTER}
							/>
							<NavItem
								icon={signature}
								role={role}
								text={defaultValues.flow_list.sign_offer}
								currentPath={currentPath}
								path={Routes.SIGN_OFFER}
							/>
							<NavItem
								icon={review}
								role={role}
								text={defaultValues.actions.review_mou}
								currentPath={currentPath}
								path={Routes.REVIEW_MOU}
							/>
							<NavItem
								icon={signature}
								role={role}
								text={defaultValues.flow_list.sign_mou}
								currentPath={currentPath}
								path={Routes.SIGN_MOU}
							/>
							<NavItem
								icon={writing}
								role={role}
								text={defaultValues.actions.review_sale_agreement}
								currentPath={currentPath}
								path={Routes.SIGN_AGREEMENT}
							/>
							<NavItem
								icon={searchData}
								role={role}
								text={defaultValues.actions.review_sale_deed}
								currentPath={currentPath}
								path={"/"}
							/>
						</div>
					</>
				) : role === defaultValues.role_names.bank_agent ? (
					<>
						<div className="nav inner">
							<NavItem
								icon={searchData}
								role={role}
								text={defaultValues.actions.property_inspection}
								currentPath={currentPath}
								path={Routes.PROPERTY_INSPECTION}
							/>
							<NavItem
								icon={wallet}
								role={role}
								text={defaultValues.actions.loan_sanction}
								currentPath={currentPath}
								path={Routes.LOAN_SANCTION}
							/>
						</div>
					</>
				) : role === defaultValues.role_names.lawyer ? (
					<div className="nav inner">
						<NavItem
							icon={review}
							role={role}
							text={defaultValues.actions.review_mou}
							currentPath={currentPath}
							path={Routes.REVIEW_MOU}
						/>
						<NavItem
							icon={searchData}
							role={role}
							text={defaultValues.actions.review_documents}
							currentPath={currentPath}
							path={Routes.REVIEW_DOCUMENTS}
						/>
						<NavItem
							icon={writing}
							role={role}
							text={defaultValues.actions.review_sale_agreement}
							currentPath={currentPath}
							path={Routes.REVIEW_SALE_AGREEMENT}
						/>
						<NavItem
							icon={contract}
							role={role}
							text={defaultValues.actions.review_sale_deed}
							currentPath={currentPath}
							path={Routes.REVIEW_SALE_DEED}
						/>
						<NavItem
							icon={writing}
							role={role}
							text={defaultValues.actions.set_registration_date}
							currentPath={currentPath}
							path={Routes.SET_REGISTRATION_DATE}
						/>
					</div>
				) : role === defaultValues.role_names.ca ? (
					<div className="nav inner">
						<NavItem
							icon={writing}
							role={role}
							text={defaultValues.actions.update_tds}
							currentPath={currentPath}
							path={Routes.UPDATE_TDS_DETAILS}
						/>
					</div>
				) : role === defaultValues.role_names.admin ? (
					<div className="nav">
						<NavItem
							icon={man}
							role={role}
							text={defaultValues.actions.manage_profile}
							currentPath={currentPath}
							path={Routes.MANAGE_PROFILE}
						/>
						<NavItem
							icon={manCog}
							role={role}
							text="Manage Users"
							currentPath={currentPath}
							path={"/manage-users"}
						/>
						<NavItem
							icon={properties}
							role={role}
							text={defaultValues.actions.manage_properties}
							currentPath={currentPath}
							path={Routes.MANAGE_PROPERTIES}
						/>
					</div>
				) : (
					<div className="nav">
						<NavItem
							icon={man}
							role={role}
							text={defaultValues.actions.manage_profile}
							currentPath={currentPath}
							path={Routes.MANAGE_PROFILE}
						/>
						<NavItem
							icon={manCog}
							role={role}
							text={defaultValues.actions.manage_admin}
							currentPath={currentPath}
							path={Routes.MANAGE_ADMINS}
						/>
						<NavItem
							icon={manCog}
							role={role}
							text="Manage Users"
							currentPath={currentPath}
							path={"/manage-users"}
						/>
						<NavItem
							icon={properties}
							role={role}
							text={defaultValues.actions.manage_properties}
							currentPath={currentPath}
							path={Routes.MANAGE_PROPERTIES}
						/>
						<NavItem
							icon={templates}
							role={role}
							text={defaultValues.actions.manage_templates}
							currentPath={currentPath}
							path={Routes.MANAGE_TEMPLATES}
						/>
						<NavItem
							icon={checklist}
							role={role}
							text={defaultValues.actions.manage_checklist}
							currentPath={currentPath}
							path={Routes.MANAGE_CHECKLISTS}
						/>
						<NavItem
							icon={cog}
							role={role}
							text={defaultValues.actions.settings}
							currentPath={currentPath}
							path={Routes.SUPER_ADMIN_SETTINGS}
						/>
					</div>
				)}

				{props?.showFooter && (
					<div className="nav footer-nav" onClick={()=>null}>
						<NavItem
							icon={logoutIcon}
							role={role}
							text={defaultValues.actions.logout}
							currentPath={currentPath}
							path={
								role === defaultValues.role_names.super_admin
									? Routes.SUPER_ADMIN_LOGIN
									: role === defaultValues.role_names.admin
									? Routes.ADMIN_LOGIN
									: Routes.HOME
							}
						/>
					</div>
				)}
			</>
		</>
	);
}

export default Page;