// ***** Note : To check Sigdesk production issue on dev change the signdesk API key and API Id to prod data
export const API = {
  currenEnv: "prod", //api server environment dev/uat/prod
  baseURL: {
    loc: process.env.REACT_APP_API_URL_LOC,
    dev: process.env.REACT_APP_API_URL_DEV,
    uat: process.env.REACT_APP_API_URL_UAT,
    prod: process.env.REACT_APP_API_URL_PROD,
  },
  signDeskAPI: {
    loc: process.env.REACT_APP_SIGN_DESK_API_ESIGN_UAT,
    dev: process.env.REACT_APP_SIGN_DESK_API_ESIGN_UAT,
    uat: process.env.REACT_APP_SIGN_DESK_API_ESIGN_UAT,
    prod: process.env.REACT_APP_SIGN_DESK_API_ESIGN_PROD,
  },
  signDeskAPIKey: {
    loc: process.env.REACT_APP_SIGN_DESK_UAT_API_KEY_ESIGN,
    dev: process.env.REACT_APP_SIGN_DESK_UAT_API_KEY_ESIGN,
    uat: process.env.REACT_APP_SIGN_DESK_UAT_API_KEY_ESIGN,
    prod: process.env.REACT_APP_SIGN_DESK_PROD_API_KEY_ESIGN,
  },
  signDeskAPIId: {
    loc: process.env.REACT_APP_SIGN_DESK_UAT_API_ID_ESIGN,
    dev: process.env.REACT_APP_SIGN_DESK_UAT_API_ID_ESIGN,
    uat: process.env.REACT_APP_SIGN_DESK_UAT_API_ID_ESIGN,
    prod: process.env.REACT_APP_SIGN_DESK_PROD_API_ID_ESIGN,
  },
  redirectUrl: {
    loc: process.env.REACT_APP_REDIRECT_URL_LOC,
    dev: process.env.REACT_APP_REDIRECT_URL_DEV,
    uat: process.env.REACT_APP_REDIRECT_URL_UAT,
    prod: process.env.REACT_APP_REDIRECT_URL_PROD,
  },
  footerUrl:{
    home: process.env.REACT_APP_HOME_URL,
    about_us: process.env.REACT_APP_ABOUT_US_URL,
    FAQs: process.env.REACT_APP_FAQS_URL,
    contact: process.env.REACT_APP_CONTACT_URL,
    userGuide: process.env.REACT_APP_USER_GUIDE_URL,
    privacy: process.env.REACT_APP_PRIVACY_POLICY_URL,
    terms_condition: process.env.REACT_APP_TERMS_CONDITIONS,
    telephone_url: process.env.REACT_APP_TELEPHONE_URL,
    telephone_number: process.env.REACT_APP_TELEPHONE_NUMBER,
    email_url: process.env.REACT_APP_EMAIL_URL,
    email: process.env.REACT_APP_EMAIL,
    logo_src: process.env.REACT_APP_LOGO_SRC_URL,
    instagram: process.env.REACT_APP_INSTAHOME_URL,
    linkedin: process.env.REACT_APP_LINKEDINHOME_URL,
    facebook: process.env.REACT_APP_FACEBOOKHOME_URL

  }
};
