import React, { useState, useEffect } from "react";
import Main from "../../layout/Main";
import {
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { updateSelectedProperty } from "../../pages/user-home/slices";
import { Form, FormLabel } from "react-bootstrap";
import { addPropertyHistory, updateProperty, updateSUbStages } from "../../services/api";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateCheckListDocument } from "../../pages/super-admin-manageProperties/service";
import { getSuccessMessage } from "../../constants/getMessages";
import { historyFormatter } from "../../utils/historyFormatter";
import { ERROR_KEY, SUCCESS_KEY } from "../../constants";
import { Routes } from "../../constants/routes";

const ApplyLoanTds = ({
  pageTitle,
  questionText,
  roleSide,
  props,
  path,
  toastMessage,
  loanAppliedProp,
  yesMessage,
  noMessage,
}) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  const [tdsDetails, setTdsDetails] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [documentSubmit, setDocumentSubmit] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [tdsData, setTdsData] = useState({});
  const currentDate = new Date().toISOString().split("T")[0];
  const user = useSelector((state) => state.loggedInUser.data.data?.userByEmail);
  const BuyerPropData = useSelector(
    (state) => state.buyerHomeData.buyerData?.properties
  );
  const accessToken =
    useSelector((state) => state.loggedInUser?.data?.data?.accessToken) || "";
  const propertyId =
    useSelector((state) => state?.buyerHomeData?.selectedProperty?._id) || "";
  const selectedProperty = useSelector(
    (state) => state?.buyerHomeData?.selectedProperty
  );
  const subStages = useSelector((state) => state?.stages?.subStages);

  const getSubStage = (order) =>
    subStages.filter((substage) => substage.order === order)[0]._id;

  const checkSellerExists = () => {
    const selectedProperty = BuyerPropData?.find(
      (property) => property?._id === propertyId
    );
    const propertyParties = selectedProperty?.property_parties_details || [];
    return propertyParties.some((party) => party.role_side === roleSide);
  };

  const getSubStageUpdated = async (propertyId, substageId) => {
    await updateSUbStages({propertyId, substageId, loggedInUser: user._id}).then(response => {
      dispatch(updateSelectedProperty(response.data.propertyData));
      addToast(getSuccessMessage("S-10006"), {
        appearance: SUCCESS_KEY,
        autoDismiss: true,
      });
      history.push(Routes.PROPERTIES);
    }).catch(err => {
      addToast(err.response.data.message, {
        appearance: ERROR_KEY,
        autoDismiss: true,
      });
    });
  };

  const schema = yup.object({
    tdsChallanNo: yup.string().required("TDS challan no is required"),
    tdsChallanDate: yup.string().required("TDS challan date is required"),
    bsr_code: yup.string().required("BSR code is required"),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  useEffect(() => {
    const hasSeller = checkSellerExists();
    if (hasSeller) {
      setSelectedOption(null);
    }
    if (selectedProperty.ca) {
      setTdsDetails(selectedProperty.ca.tds_amount > 0 ? true : false);
    }
    const pid = searchParams.get("pid");
    if (!accessToken) {
      history.push(Routes.HOME);
    } else {
      if (!propertyId) {
        history.push(Routes.USER_HOME);
      } else if (pid !== propertyId) {
        history.push(`/${path}?pid=${propertyId}`);
      }
    }
  }, [BuyerPropData]);

  const addData = (event) => {
    event.preventDefault();
    setDocumentSubmit(true);
    const formData = new FormData();
    selectedFiles.forEach((fileDoc) => {
      formData.append('propertydoc', fileDoc);
    });
    formData.append('property_id', propertyId);
    formData.append('checklist_name', 'TDS');
    dispatch(updateCheckListDocument(formData))
    addToast(getSuccessMessage('S-10031'), {
      appearance: SUCCESS_KEY,
      autoDismiss: true,
    })
  }

  const handleProceed = () => {
    const updatedState = {
      id: propertyId,
      [loanAppliedProp]: true,
      ca: {
        ...selectedProperty.ca,
        tds_challan_no: tdsData.tdsChallanNo,
        tds_challan_date: tdsData.tdsChallanDate,
        bsr_code: tdsData.bsr_code
      },
    };
    let message = '';
    updateProperty(updatedState)
      .then(() => {
        addToast(getSuccessMessage("S-10010"), {
          appearance: SUCCESS_KEY,
          autoDismiss: true,
        });
        if (path === "pay-tds") {
          message = 'has uploaded the TDS challan.';
          if (getSubStage(17) === selectedProperty.sub_current_stage)
            getSubStageUpdated(propertyId, getSubStage(18));
        }
        if (path === "apply-loan") {
          if (getSubStage(14) === selectedProperty.sub_current_stage)
            getSubStageUpdated(propertyId, getSubStage(15));
          if (getSubStage(14) === selectedProperty.sub_current_stage)
            getSubStageUpdated(propertyId, getSubStage(16));
        }
        const historyData = historyFormatter(user, user._id, propertyId, message);
        addPropertyHistory(historyData);
        history.push(`${Routes.SIGN_DEED}?pid=${propertyId}`);
      })
      .catch(() => {
        addToast(noMessage, {
          appearance: ERROR_KEY,
          autoDismiss: true,
        });
      });
  };

  return (
    <div>
      <Main showUserNavbar={!props?.showUserNavbar} showUser={!props?.showUser}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="start"
          alignItems="start"
          minHeight="70vh"
          bgcolor="var(--white)"
        >
          <div className="container">
            <div className="inner-container" style={{color:"black"}}>
              <Typography variant="h5" component="h3" sx={{ fontWeight: 100 }}>
                {!tdsDetails ? "No Details Available" : "TDS Details"}
              </Typography>
              {tdsDetails && (
                <Form onSubmit={handleSubmit(handleProceed)}>
                  <br />
                  <p>
                    <strong>TDS Amount ₹</strong> -{" "}
                    {selectedProperty.ca.tds_amount}
                  </p>
                  <p>
                    <strong>TDS Instructions</strong> -
                    {selectedProperty.ca.tds_instruction}
                  </p>
                  <br />

                  <div className="col-md-6 ">
                    <div className="row mt-4">
                      <div className="col-4">
                        <FormLabel className="form-label mt-3">
                          TDS Challan No
                        </FormLabel>
                      </div>
                      <div className="col-8">
                        <TextField
                          type="text"
                          variant="outlined"
                          placeholder="TDS Challan No"
                          className="col-12 custom-border"
                          name="tdsChallanNo"
                          value={tdsData.tdsChallanNo}
                          {...register("tdsChallanNo")}
                          error={Boolean(errors.tdsChallanNo)}
                          onChange={(e) => {
                            setTdsData({
                              ...tdsData,
                              tdsChallanNo: e.target.value,
                            });
                          }}
                        />
                        <div className="login-errors">
                          {errors.tdsChallanNo ? (
                            <>{errors.tdsChallanNo.message}</>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="row mt-4">
                      <div className="col-4">
                        <FormLabel className="form-label mt-3">
                          BSR Code
                        </FormLabel>
                      </div>
                      <div className="col-8">
                        <TextField
                          type="text"
                          variant="outlined"
                          placeholder="BSR Code"
                          className="col-12 custom-border"
                          name="bsr_code"
                          value={tdsData.bsr_code}
                          {...register("bsr_code")}
                          error={Boolean(errors.bsr_code)}
                          onChange={(e) => {
                            setTdsData({
                              ...tdsData,
                              bsr_code: e.target.value,
                            });
                          }}
                        />
                        <div className="login-errors">
                          {errors.bsr_code ? (
                            <>{errors.bsr_code.message}</>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-4">
                        <FormLabel className="form-label mt-3">
                          TDS Challan Date
                        </FormLabel>
                      </div>
                      <div className="col-8">
                        <TextField
                          type="date"
                          variant="outlined"
                          className="col-12 custom-border "
                          name="tdsChallanDate"
                          value={tdsData.tdsChallanDate}
                          {...register("tdsChallanDate")}
                          error={Boolean(errors.tdsChallanDate)}
                          onChange={(e) => {
                            setTdsData({
                              ...tdsData,
                              tdsChallanDate: e.target.value,
                            });
                          }}
                          inputProps={{ max: currentDate }}
                        />
                        <div className="login-errors">
                          {errors.tdsChallanDate ? (
                            <>{errors.tdsChallanDate.message}</>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-8 mt-3">
                    <strong> Upload Challan</strong>
                    <div className="col-md-8 mt-3">
                      <input
                        type="file"
                        id="fileField"
                        accept="image/x-png,image/gif,image/jpeg,application/pdf"
                        onChange={(e) => setSelectedFiles([...e.target.files])}
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        onClick={addData}
                        disabled={selectedFiles.length === 0}
                        style={{ backgroundColor: "var(--primaryButton)" }}
                      >
                        Upload
                      </Button>
                    </div>
                  </div>
                  <div className="col-md-3 mt-3">
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      style={{ backgroundColor: "var(--primaryButton)" }}
                    >
                      Proceed
                    </Button>
                  </div>
                </Form>
              )}
            </div>
          </div>
        </Box>
      </Main>
    </div>
  );
};

export default ApplyLoanTds;
