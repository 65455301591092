import { createAsyncThunk } from "@reduxjs/toolkit";
import { generateDoc, storeDoc } from "../../services/api";

export const postGenerateApi = createAsyncThunk(
  "initiateOfferSlice/postGenerateApi", async (payload, { rejectWithValue }) => {
    try {
      const { data } = await generateDoc(payload);
      return data;
    } catch (e) {
      rejectWithValue(e.response.data);
    }
  });

  export const storeDocument = createAsyncThunk(
    "initiateOfferSlice/storeDocument",
    (payload, { rejectWithValue }) => {
        storeDoc(payload).then(res=> res.blob())
        .then(data=>{
            const file = new File([data], 'offer_letter', {type:data.type});
            const url = URL.createObjectURL(file)
            return url;
        })
        .catch((e)=>{
          rejectWithValue(e);
        })
        
    }
  );