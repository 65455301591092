import React, { useEffect, useState } from "react";

import editIcon from "../../assets/edit-icon.svg";
import phone from "../../assets/Icons/Phone.svg";
import email from "../../assets/Icons/Email.svg";

import "./style.scss";
import { getProfilePic } from "../../services/api";

export const AdminCard = ({ele,  setCurrectEdit, setEditModal}) => {
    const [adminDp, setAdminDp] = useState(null);

    useEffect(() => {
        getProfilePic(ele._id).then(res => {
            if(res.data.success) {
                setAdminDp(res.data.url);
            }
        }).catch(e => {
          // handle error when required
        })
    }, [ele._id])
    return (
        <div className="cards">
        <div>
          <img src={adminDp ? adminDp : "/imgs/boy.svg"}  alt="" className="image" />
        </div>
        <div className="title">
          <div className="icon-group">
            <img
              className="icon"
              src={editIcon}
              onClick={() => {
                setCurrectEdit(ele);
                setEditModal(true);
              }}
              alt="edit"
            />
          </div>
          <h3 key={ele.id}>{ele.first_name} {ele.last_name}</h3>
        </div>
        <div className="des">
          <li>
            <img className="email" src={email} alt="email" />
            {ele.email_id}
          </li>
          <li>
            <img className="phone" src={phone} alt="phone" />
            {ele.phone}
          </li>
          <p>{ele.status?'Active':'Inactive'}</p>
        </div>
      </div> 
    )
}